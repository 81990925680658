/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import useFormOperations from "../../../../hooks/useFormOperations";
import useGetData from "../../../../hooks/useGetData";

import { Checkbox } from "@mui/material";
import { purple } from "@mui/material/colors";
import ThreeWayInput from "./ThreeWayInput";
import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { CustomSelect, CustomTextField, CustomYearPicker } from "../../../../mui/Input";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import { usePostData } from "../../../../hooks/usePostData";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const AddConducted = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/user/events?tab=Events+Conducted' },
    { name: 'Event Conducted', path: '/user/events?tab=Events+Conducted' },
    { name: 'Add Event Conducted', path: '/user/events/conducted/addConducted' }
  ]);

  const setLoading = useSetRecoilState(loadingState);
  const { handleSnackbarOpen } = useSnackbar();

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const [speakerCount, setSpeakerCount] = useState(1);
  const [isChecked, setIsChecked] = useState({ fy: false, sy: false, ty: false, ly: false });

  const defaultValues = {
    speakers: [{}, {}],
    audience: [],
  };

  const checkboxData = [
    { name: 'fy', label: 'FY' },
    { name: 'sy', label: 'SY' },
    { name: 'ty', label: 'TY' },
    { name: 'ly', label: 'LY' }
  ];

  const onSubmit = async (formData) => {
    const coordinatorsExists = formData.coordinators && formData.coordinators.some(coordinator => coordinator._id === userData._id);

    if (!coordinatorsExists) {
      handleSnackbarOpen("It appears you're trying to add a conducted event where you are not being listed as a Coordinator. This action is not permitted.", 'warning', 'top', 'center')
      return setLoading(false);
    }

    setLoading(true);
    const { success, error, message } = await usePostData('/api/eventConducted/addEventConducted', formData);
    setLoading(false);
    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    if (success) {
      handleSnackbarOpen("Event Conducted entry added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  };


  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/events?tab=Events+Conducted");

  const addSpeaker = () => setSpeakerCount(speakerCount + 1);

  const removeSpeaker = (index) => {
    if (index === 0) return handleSnackbarOpen("Oops! Removing the last speaker is not permitted.", 'error', 'top', 'center')
    setValue('speakers', formData.speakers.filter((_, i) => i !== index));
    setSpeakerCount(speakerCount - 1);
  };

  const handleSpeakerChange = (event) => {
    const { name, value } = event.target;
    const [, fieldName, index, propertyName] = name.match(/(\w+)\[(\d+)\]\.(\w+)/);

    setValue(fieldName, [...formData[fieldName].slice(0, index),
    { ...formData[fieldName][index], [propertyName]: value },
    ...formData[fieldName].slice(index + 1)
    ]);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsChecked((prevIsChecked) => ({ ...prevIsChecked, [name]: checked }));

    if (checked) setValue('audience', [...formData.audience, name]);
    else setValue('audience', formData.audience.filter(item => item !== name));
  };

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setIsChecked({ fy: checked, sy: checked, ty: checked, ly: checked });

    if (checked) setValue('audience', checkboxData.map(checkbox => checkbox.name));
    else setValue('audience', []);
  };

  const [isOthersChecked, setIsOthersChecked] = useState(false);

  const handleOtherCheckboxChange = (event) => {
    setIsOthersChecked(event.target.checked);

    if (!event.target.checked) setValue('otherAudience', null)
  };

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Add Conducted Events Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name" InputLabelProps={{ shrink: true }} value={userData?.name} />

        <CustomSelect
          label="Category"
          options={[
            { value: 'Seminar', label: 'Seminar' },
            { value: 'Conference', label: 'Conference' },
            { value: 'Workshop', label: 'Workshop' },
            { value: 'STTP', label: 'STTP' },
            { value: 'FDP', label: 'FDP' },
            { value: 'MOOCs', label: 'MOOCs' },
            { value: 'Refresher Course', label: 'Refresher Course' },
            { value: 'Summer School', label: 'Summer School' },
            { value: 'Winter School', label: 'Winter School' },
            { value: 'Sponsored Courses', label: 'Sponsored Courses' },
            { value: 'Guest Lecture', label: 'Guest Lecture' },
          ]}
          onChange={handleChange} name="eventCategory"
        />

        <CustomTextField className="col-span-full" label="Event Title"
          name="eventTitle" onChange={handleChange}
        />

        <AuthorInput
          className="col-span-full"
          name='coordinators'
          label="Event Coordinator's"
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder="Select or add Coordinator's"
        />

        <div className="col-span-full grid sm:grid-cols-3 tab:grid-cols-5 gap-5 bg-white rounded-lg p-3">
          <h2 className="col-span-full lb-p">Audience</h2>

          <label className="cursor-pointer select-none">
            <Checkbox
              checked={isChecked.fy && isChecked.sy && isChecked.ty && isChecked.ly}
              onChange={handleSelectAll}
              name="completeCSE"
              sx={{ color: purple[800], '&.Mui-checked': { color: purple[800] } }}
            />Select ALL
          </label>

          {checkboxData.map((checkbox) => (
            <label key={checkbox.name} className="cursor-pointer select-none">
              <Checkbox
                name={checkbox.name}
                checked={isChecked[checkbox.name]}
                onChange={handleCheckboxChange}
                sx={{ color: purple[800], '&.Mui-checked': { color: purple[800] } }}
              /> {checkbox.label}
            </label>
          ))}

          <label className="cursor-pointer col-span-full">
            <Checkbox
              name="others"
              checked={isOthersChecked}
              onChange={handleOtherCheckboxChange}
              sx={{ color: purple[800], '&.Mui-checked': { color: purple[800] } }}
            />
            Others
          </label>
        </div>

        {isOthersChecked && <CustomTextField
          className="col-span-full" label="Specify others"
          name="otherAudience" onChange={handleChange}
        />}

        {Array?.from({ length: speakerCount }, (_, index) => (
          <ThreeWayInput
            className='col-span-full'
            key={index}
            index={index}
            heading={`Speaker ${index + 1}`}
            formData={formData}
            handleChange={handleSpeakerChange}
            onDelete={index === speakerCount - 1 ? removeSpeaker : undefined}
          />
        ))}

        <ThreeWayInput
          className='col-span-full'
          heading="Add More Speaker"
          disabled={true}
          onAdd={addSpeaker}
        />

        <CustomYearPicker
          name='startingDate'
          label="Starting Date of Event"
          setValue={setValue}
        />

        <CustomSelect
          label="Approval Letter for Funding"
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]} onChange={handleChange} name="approvalLetter"
        />

        <CustomYearPicker
          name='endingDate'
          label="Ending Date of Event"
          setValue={setValue}
        />

        <CustomSelect
          label="Report Submission"
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]} onChange={handleChange} name="reportSubmitted"
        />

        <CustomSelect
          label="Type of Guest Lecture"
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
          ]} onChange={handleChange} name="eventType"
        />

        <CustomTextField
          label="Funding Agency" name="fundingAgency" onChange={handleChange} />

        <CustomTextField
          label="Event Photos Drive Link" name="eventPhotosLink"
          onChange={handleChange} />

        <CustomTextField
          label="Funding Amount" name="fundingAmount"
          onChange={handleChange} customType="decimal"
        />

        <CustomTextField
          placeholder='Enter the Objectives' label="Objectives"
          className='col-span-full' name='objective' onChange={handleChange}
          multiline minRows={4}
        />

        <CustomTextField className='col-span-full'
          placeholder='Enter the Summary details' label="Description / Summary"
          name='summary' onChange={handleChange}
          multiline minRows={4}
        />

        <CustomTextField className='col-span-full'
          placeholder='Enter the Outcomes of the event' label="Outcomes"
          name='outcome' onChange={handleChange}
          multiline minRows={4}
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form >
    </div >
  );
};

export default AddConducted;
import React from 'react'
import SchoolTile from './SchoolTile';
import Department from './Department';
import { useSearchParams } from 'react-router-dom';
import useGetData from '../../../hooks/useGetData';
import { useSetBreadcrumb } from '../../../hooks/useSetBreadcrumb';
import { Skeleton } from '@mui/material';

const Departments = () => {
    useSetBreadcrumb([
        { name: 'Departments', path: '/admin/departments' },
    ]);
    const { data: departments, isLoading, refreshData } = useGetData('/api/department/getDepartments');
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('department');

    return (
        <div className='flex flex-col space-y-5 w-full'>
            {activeTab ?
                <Department activeTab={activeTab} />
                : <>
                    <h2 className='h2-p'>My Departments</h2>
                    <div className='grid grid-cols-1 gap-5 xl:grid-cols-2'>
                        {isLoading ?
                            <div className='flex h-full w-full space-x-3 p-4 rounded-lg bg-white-sec 2xl:max-w-2xl'>
                                <Skeleton animation="wave" variant="circular" className="h-14 w-14" />
                                <div className='flex flex-col justify-center space-y-0.5 w-full'>
                                    <Skeleton animation="wave" className='lb-p w-4/5' />
                                    <Skeleton animation="wave" className='text-s w-1/3' />
                                </div>
                            </div>
                            : departments && departments?.map((element, index) => (
                                <SchoolTile key={index} obj={element} />
                            ))}
                    </div>
                </>
            }
        </div>
    )
}

export default Departments;
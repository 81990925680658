import React from 'react'
import CallIcon from '@mui/icons-material/Call';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
const ContactUs = () => {
  return (

    <div className='bg-white w-full flex flex-col space-y-5  justify-between'>
      <div className='w-full'>
        <h2 className='h2-p'> Contact Us</h2>
        <h5 className='h5-s text-sm ' > Questions or issues ?
          <br /> Contact our IT service desk for quick assistance.
        </h5>
      </div>

      <div className='grid grid-cols-2 w-full mt-16 '>
        <div className='flex flex-col md:flex-row w-full col-span-full md:col-span-1 text-center md:text-left justify-center md:justify-start '>
          <div className='w-full md:w-fit flex justify-center'>
            <div className=" bg-white w-12 h-12 my-2 rounded-full drop-shadow-xl flex items-center justify-center">
              <CallIcon color='error'/>
            </div>
          </div>
          <div className='grid grid-rows-2 mx-5'>
            <h2 className='h5-p '>Phone</h2>
            <a href="tel:8888688107" className='text-s'>+ 91 8888 688 107</a>
          </div>
        </div>

        <div className='flex flex-col mt-10 md:mt-0 md:flex-row w-full col-span-full md:col-span-1 text-center md:text-left justify-center md:justify-start '>
          <div className='w-full md:w-fit flex justify-center'>
            <div className=" bg-white w-12 h-12 my-2 rounded-full drop-shadow-xl flex items-center justify-center">
              <EmailOutlinedIcon style={{ color: '#007AFF' }} />
            </div>
          </div>

          <div className='grid grid-rows-2 mx-5'>
            <h2 className='h5-p  '>Mail</h2>
            <a className="text-s" href="mailto:sahil10.jai@gmail.com">sahil10.jai@gmail.com</a>
          </div>

        </div>

      </div>
    </div>
  );
};

export default ContactUs;

import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import useGetData from "../../../../hooks/useGetData";
import { Analytics, CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import useOperations from "../../../../hooks/useOperations";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import TabSmall from "../../../../mui/TabSmall";
import { Button } from '@mui/material'
import RecentFundedProject from "./RecentFundedProject";
import AVFundedProject from "./AVFundedProject";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const FundedProjectAD = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/admin/projectsAD?tab=Research+%26+Development&type=Recent' },
    { name: 'Research & Development', path: '/admin/projectsAD?tab=Research+%26+Development&type=Recent' }
  ]);

  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: fundedProjectsDataAD, isLoading } = useGetData('/api/fundedProject/getFundedProjects?school=total');
  const { data: tileDataAT, isLoading: tileLoadingAT } = useGetData('/api/stats/fundedProject/getStats?school=total&year=total');
  const { data: tileDataCY, isLoading: tileLoadingCY, } = useGetData(`/api/stats/fundedProject/getStats?school=total&year=${currentYear}`);
  const { onDownload } = useOperations('Consultancy', null, null, fundedProjectsDataAD, ['principalInvestigators']);

  const activeTab = searchParams.get('type');
  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];

  const buttonInfo = [
    // { title: 'Analysis', icon: <Analytics fontSize="large" className="text-[#010851]" />, onClick: navigateToAnalysis },
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
  ];


  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid sm:grid-cols-2 gap-5 p-5 rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Research & Development Projects"
          value={tileDataAT?.completed}
          icon={'/tabs/RnDPurple.svg'}
          loading={tileLoadingAT}
          message='Total Verified, Granted & Completed Research & Development projects'
        />

        <AnalysisTile
          heading={`Granted Research & Development projects in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={tileDataCY?.completed}
          icon={'/tabs/RnDPurple.svg'}
          loading={tileLoadingCY}
          message='Total Verified, Granted & Completed Research & Development projects'
        />

        <AnalysisTile
          heading={`Applied Research & Development projects in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={tileDataAT?.applied}
          icon={'/tabs/RnDPurple.svg'}
          loading={tileLoadingAT}
          message='Total Verified, Granted & Started Research & Development projects'
        />

        <AnalysisTile
          heading={`Applied Research & Development projects in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={tileDataCY?.applied}
          icon={'/tabs/RnDPurple.svg'}
          loading={tileLoadingCY}
          message='Total Verified, Applied Research & Development projects'
        />
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>

        {activeTab === "Recent" && <RecentFundedProject data={fundedProjectsDataAD} loading={isLoading} />}
        {activeTab === "Abstract View" && <AVFundedProject />}

      </div >
    </div >
  );
};

export default FundedProjectAD;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

export const NavMenu = ({ navLinks, open }) => {
    const filteredLinks = navLinks.filter(link => link.render);
    const location = useLocation();

    return filteredLinks.map((link, index) => (
        <Link key={index} to={link.to} className={`${link.to.startsWith(location?.pathname) ? 'navButtonActive' : 'navButtonInactive'} flex items-center py-1.5 px-2 rounded-sxl w-full space-x-3`} >
            <ReactSVG src={link.icon}
                beforeInjection={(svg) => {
                    if (link.to.startsWith(location?.pathname)) {
                        const pathElements = svg.querySelectorAll('path');

                        if (pathElements.length > 0) pathElements.forEach((path) => {
                            path.setAttribute(link?.attribute, '#0011ff');
                        });
                    }
                }}
            />

            {open && <p className="navText duration-300 transform line-clamp-1">
                {link.text}
            </p>}
        </Link>
    ))
};

import { useSearchParams } from "react-router-dom";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import TabSmall from "../../../../mui/TabSmall";
import { Button } from "@mui/material";
import { CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import RecentAttended from "./RecentAttended";
import AVAttended from "./AVAttended";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import ConductedTile from "../../../../components/Tiles/ConductedTile";
import useAcademicYear from "../../../../hooks/useAcademicYear";


const AttendedAD = () => {
  useSetBreadcrumb([
    { name: 'Events', path: '/admin/eventsAD?tab=Attended&type=Recent' },
    { name: 'Attended Events', path: '/admin/eventsAD?tab=Attended&type=Recent' }
  ]);

  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('type');
  const { data: attendedDataAD, isLoading } = useGetData('/api/eventAttended/getEventsAttended?school=total');
  const { data: tileData, isLoading: tileLoading, refreshData: tileRefresh } = useGetData(`/api/stats/eventAttended/getStats?year=total&school=total`);

  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];

  const { onDownload } = useOperations('Events Attended', null, null, attendedDataAD, ['coordinators']);

  const buttonInfo = [
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
  ];

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-5 gap-5 rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Events Attended"
          value={tileData?.eventAttended}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <AnalysisTile
          heading={`Events Attended  in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={tileData?.eventAttendedCY}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <div className="col-span-full md:col-span-2">
          <ClassificationTileSec
            heading={`Summary of Events Attended in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
            h1={'International'}
            h2={'National'}
            h3={'State'}
            v1={tileData?.international}
            v2={tileData?.national}
            v3={tileData?.state}
            loading={tileLoading}
          />
        </div>

        <div className="col-span-full">
          <ConductedTile heading='Event Categories' data={tileData} />
        </div>
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>

        {activeTab === "Recent" && <RecentAttended data={attendedDataAD} loading={isLoading} />}
        {activeTab === "Abstract View" && <AVAttended />}
      </div>
    </div>
  );
};

export default AttendedAD;

/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useGetData from "../../../../hooks/useGetData";
import useFormOperations from "../../../../hooks/useFormOperations";

import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";
import { usePostData } from "../../../../hooks/usePostData";


const AddConference = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Conference' },
    { name: 'Conference', path: '/user/publication?tab=Conference' },
    { name: 'Add Conference', path: 'user/publication/Conference/addConference' }
  ]);

  const { handleSnackbarOpen } = useSnackbar();
  const setLoading = useSetRecoilState(loadingState);

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const defaultValues = { doi: 'https://doi.org/' }

  const onSubmit = async (formData) => {
    const authorExists = formData.authors && formData.authors.some(author => author._id === userData._id);
    if (!authorExists) return handleSnackbarOpen("It appears you're trying to add a conference entry without being listed as a co-author. This action is not permitted.", 'warning', 'top', 'center')

    setLoading(true);
    const { success, error, message } = await usePostData('/api/conference/addConference', formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')

    if (success) {
      handleSnackbarOpen("Conference Data added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  }

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/publication?tab=Conference");

  const doiPattern = /^(https?:\/\/\S+[^.])$/;
  const dateLabel = formData?.category === 'Published' ? 'Published'
    : formData?.category === 'Accepted' ? 'Accepted' : 'Submitted';

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Add Conference Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>
        <CustomTextField label="Faculty Name"
          InputLabelProps={{ shrink: true }} value={userData?.name} />

        <AuthorInput
          className="col-span-full"
          name='authors'
          value={formData.authors}
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder='Select or add Authors / Co-Authors'
          label={'Authors'}
        />

        <CustomTextField
          label="Title of Paper" className="md:col-span-full" name="paperTitle"
          onChange={handleChange} value={formData?.paperTitle}
        />

        <CustomTextField
          label="Name of Conference" className="md:col-span-full" name="conferenceName"
          onChange={handleChange} value={formData?.conferenceName}
        />

        <div className="my-2 col-span-full" />

        <CustomSelect
          label="Category of Conference"
          options={[
            { value: 'Submitted', label: 'Submitted' },
            { value: 'Accepted', label: 'Accepted' },
            { value: 'Published', label: 'Published' },
          ]} onChange={handleChange} name="category"
        />

        <CustomSelect
          label="Conference Proceedings Indexed in"
          options={[
            { value: 'ACM', label: 'ACM' },
            { value: 'AIP', label: 'AIP' },
            { value: 'DBLP', label: 'DBLP (Digital Bibliography & Library Project)' },
            { value: 'IEEE Explore', label: 'IEEE Explore' },
            { value: 'Google Scholar', label: 'Google Scholar' },
            { value: 'MDPI', label: 'MDPI' },
            { value: 'ProQuest', label: 'ProQuest' },
            { value: 'PubMed', label: 'PubMed' },
            { value: 'Springer', label: 'Springer' },
            { value: 'Springer LNCS', label: 'Springer LNCS (Lecture Notes in Computer Science)' },
            { value: 'Science Direct (Elsevier)', label: 'Science Direct (Elsevier)' },
            { value: 'Web of Science (WoS)', label: 'Web of Science (WoS)' },
            { value: 'Others', label: 'Others' },
          ]} name="conferenceIndexedIn" onChange={handleChange}
        />

        <CustomSelect
          label="Type of Conference"
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
          ]} name="conferenceType" onChange={handleChange}
        />

        <CustomSelect
          label="Indexed in SCI"
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]} name="indexedInSCI" onChange={handleChange}
        />

        <CustomYearMonthPicker
          setValue={setValue}
          label={`${dateLabel} Date`}
          disableFuture
          yearVariable="publicationYear"
          monthVariable="publicationMonth"
        />

        <CustomSelect
          label="Indexed in Scopus"
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]} name="indexedInScopus" onChange={handleChange}
        />

        <CustomTextField
          onChange={handleChange} name="conferenceLink"
          label="Link of Conference" required={false} value={formData.conferenceLink} />

        <CustomSelect
          label="Affiliation Details"
          options={[
            { value: process.env.REACT_APP_COLLEGE_NAME, label: process.env.REACT_APP_COLLEGE_NAME },
            { value: 'Others', label: 'Others' },
          ]} onChange={handleChange} name="affiliationDetails"
        />

        <CustomTextField
          onChange={handleChange} name="doi" defaultValue={defaultValues.doi} className='h-28'
          label="DOI" error={formData?.doi && !doiPattern.test(formData?.doi)} required={formData.category === 'Published'}
          helperText={formData?.doi && !doiPattern.test(formData?.doi) ? "Enter a valid DOI (starts with 'https://doi.org/doi_no') or a direct https link to the publication. Ensure there are no trailing slashes." : null}
        />

        <CustomSelect
          label="Quartile" name="quartile"
          options={[
            { value: 'Q1', label: 'Q1' },
            { value: 'Q2', label: 'Q2' },
            { value: 'Q3', label: 'Q3' },
            { value: 'Q4', label: 'Q4' },
            { value: 'N/A', label: 'N/A' },
          ]}
          onChange={handleChange} value={formData?.quartile}
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form>

    </div>
  );
};

export default AddConference;
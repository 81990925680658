
import { useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { profilePhotoState, userTypeState } from '../recoil/state';

const useAuthenticate = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = location.pathname;

    const [_profilePhoto, setProfilePhoto] = useRecoilState(profilePhotoState);
    const [userType, setUserType] = useRecoilState(userTypeState);

    useEffect(() => {
        const permissions = sessionStorage.getItem('permissions');
        const token = sessionStorage.getItem('token');
        if (!token && pathname !== '/googleLogin') {
            sessionStorage.clear();
            setUserType(null);
            navigate('/');
            setProfilePhoto('');
        } else if (token) {
            setUserType(permissions && permissions?.length > 0 ? 'admin' : 'user');
        }

    }, [pathname, navigate]);

    useLayoutEffect(() => {
        if (pathname === '/') {
            sessionStorage.clear();
            setUserType(null);
            setProfilePhoto('');
        }
    }, [pathname]);

    const isAdmin = userType === 'admin';
    const isUser = userType === 'user';

    return { isAdmin, isUser, userType };
};

export default useAuthenticate;
import { useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";
import TabSmall from "../../../../mui/TabSmall";
import { GridViewOutlined, Schedule, CloudDownloadRounded, CloudUploadRounded } from "@mui/icons-material";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import RecentDesignPatent from "./RecentDesignPatent";
import AVDesignPatent from "./AVDesignPatent";

import useGetData from "../../../../hooks/useGetData";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import QuartileTile from "../../../../components/Tiles/QuartileTile";

const DesignPatentAD = () => {
    useSetBreadcrumb([
        { name: 'Patent', path: '/admin/PatentAD?type=Recent&tab=designPatentAD' },
        { name: 'Design Patent', path: '/admin/designPatentAD?type=Recent&tab=designPatentAD' },
    ]);
    const currentYear = useAcademicYear();
    const [searchParams] = useSearchParams();

    const { data: designPatentDataAD, isLoading } = useGetData("/api/designPatent/getDesignPatents?school=total");
    const { data: designPatentCount, isLoading: tileLoading } = useGetData('/api/stats/designPatent/getStats?school=total&year=total');
    const { data: designPatentCountCY, isLoading: tileLoadingCY } = useGetData(`/api/stats/designPatent/getStats?school=total&year=${currentYear}`);

    const activeTab = searchParams.get('type');
    const tabs = [
        { label: 'Recent', icon: <Schedule /> },
        { label: 'Abstract View', icon: <GridViewOutlined /> },
    ];

    const { onDownload } = useOperations('DesignPatent', null, null, designPatentDataAD);
    const buttonInfo = [
        { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
        { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
    ];

    return (
        <div className="flex flex-col space-y-5 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 p-5 gap-5 rounded-lg bg-white-sec">
                <AnalysisTile
                    heading="Total Design Patent"
                    value={designPatentCount?.total}
                    icon={'/tabs/round/DesignPatentPurple.svg'}
                    loading={tileLoading}
                />

                <AnalysisTile
                    heading={`Design Patent in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    value={designPatentCountCY?.total}
                    icon={'/tabs/round/DesignPatentPurple.svg'}
                    loading={tileLoading}
                />
                
                <div className="col-span-full">
                    <QuartileTile
                        className="col-span-2"
                        heading="Total Summary of Granted Design Patents"
                        h1='India' h2='South Africa' h3='United Kingdom' h4='Germany' h5='Australia' h6="Germany"
                        lb1='IND' lb2='SA' lb3='UK' lb4='Ger' lb5='AUS'
                        v1={designPatentCount?.india}
                        v2={designPatentCount?.southAfrica}
                        v3={designPatentCount?.uk}
                        v4={designPatentCount?.germany}
                        v5={designPatentCount?.australia}
                    />
                </div>

            </div>

            <div className="flex flex-col rounded-lg bg-white-sec">
                <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
                    <TabSmall tabs={tabs} />
                    <div className="flex space-x-5">
                        {buttonInfo.map((button, index) => (
                            <BlackToolTip key={index} title={button.title} arrow>
                                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                                    {button.icon}
                                </Button>
                            </BlackToolTip>
                        ))}
                    </div>
                </div>

                {activeTab === "Recent" && <RecentDesignPatent data={designPatentDataAD} loading={isLoading} />}
                {activeTab === "Abstract View" && <AVDesignPatent />}
            </div>
        </div>
    );
};

export default DesignPatentAD;

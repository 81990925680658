/* eslint-disable react-hooks/rules-of-hooks */
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import useFormOperations from "../../../hooks/useFormOperations";

import { CustomAsyncSelect, CustomMultiSelect, CustomTextField, CustomYearPicker } from '../../../mui/Input';
import { CloseButton, ConfirmButton } from '../../../mui/Button';
import { useRecoilState } from "recoil";
import { loadingState } from "../../../recoil/state";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { usePutData } from "../../../hooks/usePutData";
import useGetData from "../../../hooks/useGetData";
import { useSearchParams } from "react-router-dom";
import { AccountCircle, NoAccounts } from "@mui/icons-material";

const EditFacultyAD = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const { data: defaultValues, isLoading } = useGetData(`/api/user/getUser?id=${id}`);

  useSetBreadcrumb([
    { name: 'Faculties', path: '/admin/facultyAD' },
    { name: 'Edit Faculty', path: '/admin/facultyAD/editFaculty' },
  ]);

  const schoolOptions = JSON.parse(process.env.REACT_APP_SCHOOLS);

  const [, setLoading] = useRecoilState(loadingState);
  const { handleSnackbarOpen } = useSnackbar();

  const onSubmit = async (formData) => {
    setLoading(true);
    const { success, error, message } = await usePutData(`/api/user/updateUser/${id}`, formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')

    if (success) {
      handleSnackbarOpen("Faculty Data updated successfully!", 'success', 'top', 'center', 'upload')
      onDiscard();
    }

    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')

  }

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/admin/facultyAD", defaultValues);

  return (
    <div className="flex flex-col w-full px-5 gap-10">
      <div className="flex justify-between items-end">
        <h1 className="h2-p col-span-full">Edit Faculty Details</h1>
        {!isLoading && (defaultValues?.active ?
          <p className="flex flex-col items-center">
            <AccountCircle color="secondary" />
            <span className="text-xs font-medium text-purple-800">Active</span>
          </p>
          :
          <p className="flex flex-col items-center">
            <NoAccounts color='error' />
            <span className="text-xs font-medium text-red-700">Inactive</span>
          </p>
        )}
      </div>
      <form className="grid sm:grid-cols-2 gap-y-5 gap-x-10" onSubmit={handleSubmit}>

        <h2 className="h5-p col-span-full">General Information</h2>
        <div className="flex w-full col-span-full md:col-span-1">
          <CustomAsyncSelect
            label="Title" value={formData?.title}
            options={[
              { value: 'Dr', label: 'Dr' },
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
            ]} className='w-20' name="title" onChange={handleChange}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />

          <CustomTextField
            label="Faculty Name" name="name" value={formData?.name} onChange={handleChange}
            className='w-full' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} InputLabelProps={{ shrink: true }}
          />
        </div>

        <CustomTextField
          label="Employee ID" value={formData?.employeeID} onChange={handleChange}
          name="employeeID" customType="number" InputLabelProps={{ shrink: true }}
        />

        <CustomAsyncSelect
          label="Designation" value={formData?.designation}
          options={[
            { value: 'Dean', label: 'Dean' },
            { value: 'HOS', label: 'HOS' },
            { value: 'AHOS', label: 'AHOS' },
            { value: 'Professor', label: 'Professor' },
            { value: 'Associate Professor', label: 'Associate Professor' },
            { value: 'Assistant Professor', label: 'Assistant Professor' },
            { value: 'Research Executive', label: 'Research Executive' },
          ]} onChange={handleChange} name="designation" InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="Email" value={formData?.email}
          onChange={handleChange} name="email" InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="Contact Number" value={formData?.phNo} onChange={handleChange}
          name="phNo" customType="number" inputProps={{ maxLength: 10, pattern: '[0-9]*' }}
          InputLabelProps={{ shrink: true }}
        />

        <CustomYearPicker name='joiningDate' label="Date of Join"
          setValue={setValue} value={formData?.joiningDate} />

        <CustomAsyncSelect
          className='col-span-full' label="Department" InputLabelProps={{ shrink: true }}
          options={schoolOptions} onChange={handleChange} name="school" value={formData?.school}
        />

        <h2 className="col-span-full h5-p mt-10">Socio-academic Information</h2>

        <CustomTextField
          label="Google Scholar Link" value={formData?.googleScholarURL} onChange={handleChange}
          name="googleScholarURL" required={false} InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="Scopus Link" value={formData?.scopusURL} onChange={handleChange}
          name="scopusURL" required={false} InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="LinkedIn Link" value={formData?.linkedInURL} onChange={handleChange}
          name="linkedInURL" required={false} InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="ResearchGate Link" value={formData?.researchGateURL} onChange={handleChange}
          name="researchGateURL" required={false} InputLabelProps={{ shrink: true }}
        />

        <CustomTextField
          label="Orcid Link" value={formData?.orcidURL} onChange={handleChange}
          name="orcidURL" required={false} InputLabelProps={{ shrink: true }}
        />


        <h2 className="h5-p col-span-full mt-10">Special Permissions</h2>

        <CustomMultiSelect
          className="col-span-full"
          options={["Journal", "BookChapter", "Conference", "Book", "Consultancy",
            "FundedProject", "EventAttended", "EventConducted", "Patent",
            "Award", "Copyright", "DesignPatent", "Faculties",].
            map(permission => ({ value: permission, label: permission }))}
          onChange={handleChange}
          name='permissions'
          value={formData?.permissions || []}
          label="Select Admin access rights (if applicable)"
          loading={isLoading}
        />

        <CustomMultiSelect
          className="col-span-full"
          options={schoolOptions}
          onChange={handleChange}
          disabled={!(formData?.permissions?.length > 0)}
          clearSelection={!(formData?.permissions?.length > 0)}
          name='schoolPermissions'
          value={formData?.schoolPermissions || []}
          label="Select the Departments for access rights"
          disabledText='Select atleast one Portfolio to enable'
          loading={isLoading}
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form >

    </div >
  );
};

export default EditFacultyAD;

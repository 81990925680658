import ThreeWayTab from "../../../components/tabs/ThreeWayTab";
import JournalAD from "./journal/JournalAD";
import ConferenceAD from "./conference/ConferenceAD";
import BookChapterAD from "./bookChapter/BookChapterAD";
import { useSearchParams } from "react-router-dom";
import { Book as BookIcon } from "@mui/icons-material";
import BookAD from "./Book/BookAD";

const PublicationAD = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <div className="flex flex-col w-full">
      <ThreeWayTab btn1={"Journal"} btn2={"Book Chapter"} btn3={"Conference"} btn4={"Book"}
        icon1={<img src='/tabs/Journal.svg' />} icon2={<img src='/tabs/BookChapter.svg' />} icon3={<img src='/tabs/Conference.svg' />} icon4={<BookIcon />}
        iconDisabled1={<img src='/tabs/JournalGrey.svg' />} iconDisabled2={<img src='/tabs/BookChapterGrey.svg' />} iconDisabled3={<img src='/tabs/ConferenceGrey.svg' />} iconDisabled4={<BookIcon className="text-zinc-400" />}
      />
      {tab === "Journal" ? <JournalAD /> : null}
      {tab === "Book Chapter" ? <BookChapterAD /> : null}
      {tab === "Conference" ? <ConferenceAD /> : null}
      {tab === "Book" ? <BookAD /> : null}
    </div>
  );
};

export default PublicationAD;

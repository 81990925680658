import React, { useCallback, useContext, useState } from 'react'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';

import UserContext from '../../../../context/UserContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useSetBreadcrumb } from '../../../../hooks/useSetBreadcrumb';

const ChangePassword = () => {
    useSetBreadcrumb([
        { name: 'Settings', path: '/user/settings?type=Edit Profile' },
        { name: 'Change Password', path: '/user/settings?type=Change Password' },
    ]);

    const { updatePass } = useContext(UserContext);
    const [formData, setFormData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const { handleSnackbarOpen } = useSnackbar();
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        let result = { value: false };
        await updatePass(formData, result)
        if (result.value) {
            handleSnackbarOpen('Password Updated Successfully', 'success', 'top', 'center', 'password')
            setFormData({
                password: '',
                newPassword: '',
                confirmPassword: ''
            });
        }

    }, [formData]);

    const [passwordMatchError, setPasswordMatchError] = useState("");

    const handleNewPasswordBlur = () => {
        if (formData.newPassword !== formData.confirmPassword) {
            setPasswordMatchError("New Passwords do not match");
        } else {
            setPasswordMatchError("");
        }
    };

    const [passwordVisibility, setPasswordVisibility] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const handleTogglePassword = (fieldName) => {
        setPasswordVisibility((prevVisibility) => ({
            ...prevVisibility,
            [fieldName]: !prevVisibility[fieldName],
        }));
    };

    return (
        <div className="flex flex-col space-y-5">
            <div className='flex flex-col justify-center items-center'>
                <h2 className="sm:text-left text-center h2-p px-5 pt-8 pb-3">Change Password</h2>
                <hr className="w-full h-1" />
            </div>
            <form className="grid grid-cols-1 place-items-center gap-x-12 gap-y-8 p-5" onSubmit={handleSubmit}            >
                <TextField
                    label='Old Password'
                    className='w-64 sm:w-96 transition-all ease-in-out duration-200'
                    type={passwordVisibility.oldPassword ? 'text' : 'password'}
                    placeholder="Enter your old password"
                    name='password'
                    value={formData.password}
                    onChange={handleInputChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={() => handleTogglePassword('oldPassword')}>
                                    {passwordVisibility.oldPassword ? <VisibilityOffIcon style={{ color: '#1a0116' }} /> : <VisibilityIcon style={{ color: '#1a0116' }} />}
                                </IconButton>
                            </InputAdornment>),
                    }}
                    inputProps={{ minLength: 8 }}
                    required
                />

                <TextField
                    label='New Password'
                    className="w-64 sm:w-96  transition-all ease-in-out duration-200"
                    type={passwordVisibility.newPassword ? 'text' : 'password'}
                    placeholder="Must contain 8 letters"

                    name='newPassword'
                    value={formData.newPassword}
                    onChange={handleInputChange}
                    onKeyUp={handleNewPasswordBlur}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={() => handleTogglePassword('newPassword')}>
                                    {passwordVisibility.newPassword ? <VisibilityOffIcon style={{ color: '#1a0116' }} /> : <VisibilityIcon style={{ color: '#1a0116' }} />}
                                </IconButton>
                            </InputAdornment>),
                    }}
                    inputProps={{ minLength: 8 }}
                    required
                />

                < TextField
                    label='Confirm Password'
                    className="w-64 sm:w-96 transition-all ease-in-out duration-200"
                    type={passwordVisibility.confirmPassword ? 'text' : 'password'}

                    name='confirmPassword'
                    value={formData.confirmPassword}
                    onChange={handleInputChange}

                    onBlur={handleNewPasswordBlur}
                    error={Boolean(passwordMatchError)}
                    helperText={passwordMatchError}
                    FormHelperTextProps={{ style: { color: "#ffb300" } }}

                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={() => handleTogglePassword('confirmPassword')}>
                                    {passwordVisibility.confirmPassword ? <VisibilityOffIcon style={{ color: '#1a0116' }} /> : <VisibilityIcon style={{ color: '#1a0116' }} />}
                                </IconButton>
                            </InputAdornment>),
                    }} inputProps={{ minLength: 8, }} required
                />

                <div className="flex justify-center items-center w-64 sm:w-96">
                    <Button
                        fullWidth
                        variant="contained"
                        size='large'
                        style={{
                            backgroundColor: "#010851",
                            color: "white",
                            borderRadius: 4,
                        }}
                        disabled={passwordMatchError}
                        type='submit'
                    >
                        Set Password
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default ChangePassword
/* eslint-disable react-hooks/rules-of-hooks */
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import useFormOperations from "../../../hooks/useFormOperations";

import { CustomMultiSelect, CustomSelect, CustomTextField, CustomYearPicker } from '../../../mui/Input';
import { CloseButton, ConfirmButton } from '../../../mui/Button';
import { useRecoilState } from "recoil";
import { loadingState } from "../../../recoil/state";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { usePostData } from "../../../hooks/usePostData";

const AddFacultyAD = () => {
  useSetBreadcrumb([
    { name: 'Faculties', path: '/admin/facultyAD' },
    { name: 'Add Faculty', path: '/admin/facultyAD/addFaculty' },
  ]);

  const defaultValues = { permissions: [] }
  const schoolOptions = JSON.parse(process.env.REACT_APP_SCHOOLS);

  const [, setLoading] = useRecoilState(loadingState);
  const { handleSnackbarOpen } = useSnackbar();

  const onSubmit = async (formData) => {
    setLoading(true);
    const { success, error, message } = await usePostData('/api/user/addUser', formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')

    if (success) {
      handleSnackbarOpen("Faculty Data added successfully!", 'success', 'top', 'center', 'upload')
      onDiscard();
    }

    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')

  }
  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/admin/facultyAD");

  return (
    <div className="flex flex-col w-full px-5">
      <form className="grid sm:grid-cols-2 gap-y-5 gap-x-10" onSubmit={handleSubmit}>
        <h2 className="h5-p col-span-full">Faculty Details</h2>

        <div className="flex w-full col-span-full md:col-span-1">
          <CustomSelect
            label="Title" value={formData?.title}
            options={[
              { value: 'Dr', label: 'Dr' },
              { value: 'Mr', label: 'Mr' },
              { value: 'Mrs', label: 'Mrs' },
              { value: 'Ms', label: 'Ms' },
            ]} className='w-20' name="title" onChange={handleChange}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          />

          <CustomTextField
            label="Faculty Name" name="name" value={formData?.name} onChange={handleChange}
            className='w-full' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
          />
        </div>

        <CustomTextField
          label="Employee ID" value={formData?.employeeID} onChange={handleChange}
          name="employeeID" customType="number"
        />

        <CustomSelect
          label="Designation"
          options={[
            { value: 'Dean', label: 'Dean' },
            { value: 'HOS', label: 'HOS' },
            { value: 'AHOS', label: 'AHOS' },
            { value: 'Professor', label: 'Professor' },
            { value: 'Associate Professor', label: 'Associate Professor' },
            { value: 'Assistant Professor', label: 'Assistant Professor' },
            { value: 'Research Executive', label: 'Research Executive' },
          ]} onChange={handleChange} name="designation"
        />

        <CustomTextField
          label="Email" value={formData?.email} onChange={handleChange} name="email"
        />

        <CustomTextField
          label="Contact Number" value={formData?.phNo} onChange={handleChange}
          name="phNo" customType="number" inputProps={{ maxLength: 10, pattern: '[0-9]*' }}
        />

        <CustomYearPicker name='joiningDate' label="Date of Join" setValue={setValue} />

        <CustomSelect
          className='col-span-full' label="Department"
          options={schoolOptions} onChange={handleChange} name="school"
        />

        <h2 className="col-span-full h5-p mt-10">Other Details</h2>

        <CustomTextField
          label="Google Scholar Link" value={formData?.googleScholarURL} onChange={handleChange}
          name="googleScholarURL" required={false}
        />

        <CustomTextField
          label="Scopus Link" value={formData?.scopusURL} onChange={handleChange}
          name="scopusURL" required={false}
        />

        <CustomTextField
          label="LinkedIn Link" value={formData?.linkedInURL} onChange={handleChange}
          name="linkedInURL" required={false}
        />

        <CustomTextField
          label="ResearchGate Link" value={formData?.researchGateURL} onChange={handleChange}
          name="researchGateURL" required={false}
        />

        <CustomTextField
          label="Orcid Link" value={formData?.orcidURL} onChange={handleChange}
          name="orcidURL" required={false}
        />


        <h2 className="h5-p col-span-full mt-10">Special Permissions</h2>

        <CustomMultiSelect
          className="col-span-full"
          options={["Journal", "BookChapter", "Conference", "Book", "Consultancy",
            "FundedProject", "EventAttended", "EventConducted", "Patent",
            "Award", "Copyright", "DesignPatent", "Faculties",].
            map(permission => ({ value: permission, label: permission }))}
          onChange={handleChange}
          name='permissions'
          value={formData?.permissions}
          label="Select Admin access rights (if applicable)"
        />

        <CustomMultiSelect
          className="col-span-full"
          options={schoolOptions}
          onChange={handleChange}
          disabled={!(formData?.permissions?.length > 0)}
          clearSelection={!(formData?.permissions?.length > 0)}
          name='schoolPermissions'
          value={formData?.schoolPermissions}
          label="Select the Departments for access rights"
          disabledText='Select atleast one Portfolio to enable'
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form >

    </div >
  );
};

export default AddFacultyAD;

import "./PopupViewOnly.css";
const PopupViewOnly = ({ onClose, subText, heading, icon }) => {
  return (

    <div className="downPop" style={{ backgroundColor: "white" }}>
      <div className="downpopchild">
        <p className="downPopText">{heading}</p>
        <p className="downPopSuppText">{subText}</p>
      </div>
      <button className="downPopclose" onClick={onClose}>
        <img className="subtract-icon1" alt="" src="/cross.svg" />
      </button>
      <img className="featured-icon1" alt="" src={icon} />
    </div>
  );
};

export default PopupViewOnly;

import BarGraph from "../../graphs/BarGraph";
import { InfoOutlined } from "@mui/icons-material";
import Fade from '@mui/material/Fade';
import NoDataFound from '../../assets/noDataFound.webp'
import BlackToolTip from "../../buttons/BlackToolTip";
const OverYearsTile = ({ data, heading, total = true, message, loading, errorMessage = 'Opps No Data Found !!' }) => {

  return (
    <div className="flex flex-col space-y-2 rounded-lg box-border w-full bg-white border-0.5 border-solid ">
      <div className="flex items-center justify-between w-full p-5">
        <h5 className="h6-p">{heading}</h5>
        {message &&
          <BlackToolTip className="cursor-pointer" title={message} sx={{ maxWidth: '800px' }} TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}>
            <InfoOutlined className="text-base text-slate-900" />
          </BlackToolTip>
        }
      </div>

      {data && Object.values(data).some(yearData => Object.keys(yearData).length > 0) ?
        <BarGraph className="mt-2" data={data} heading={heading} total={total} />
        :
        <div className="w-auto h-64 flex flex-col col-span-10 justify-center items-center">
          <img draggable={false} className="h-40 w-54 select-none"  src={NoDataFound} alt="No Data Found" />
          <span className="text-s text-purple3">{errorMessage}</span>
        </div>
      }
    </div>
  );
};

export default OverYearsTile;

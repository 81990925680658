/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useContext } from 'react';
import { useSnackbar } from './useSnackbar';
import usePopup from './usePopup';
import UserContext from '../context/UserContext';
import { usePostData } from './usePostData';
import { useSetRecoilState } from 'recoil';
import { loadingState } from '../recoil/state';

const useOperations = (name, deleteFunc, refreshData, downloadData, arrayKeys = [], dateKeys = []) => {
    const setLoading = useSetRecoilState(loadingState);

    const { downloadExcel } = useContext(UserContext);
    const { handleSnackbarOpen } = useSnackbar();
    const { openPopup } = usePopup();

    const [isViewPopupOpen, setViewPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);


    const openViewDetails = (value) => {
        setViewPopupOpen(true);
        setPopupData(value);
    }

    const closeViewDetails = () => setViewPopupOpen(false);


    const onDelete = (value) => openPopup(`Delete ${name}`, `Are you sure you want to Delete the Current ${name} ?`, 'delete', () => handleDelete(value._id));


    const handleDelete = async (id) => {
        const result = await deleteFunc(id);
        if (result) {
            handleSnackbarOpen(`${name} successfully removed, as requested.`, 'warning', 'top', 'center', 'delete');
            refreshData()
        }
    };

    const onDownload = () => {
        if (!downloadData) return handleSnackbarOpen(`Oh snap! We couldn't find any ${name}s in your queue.`, 'warning', 'top', 'center');
        openPopup(`Download All ${name}`, `Are you sure you want to Download all ${name}s ?`, 'download', handleDownload)
    };

    const handleDownload = (data) => {
        const excludeKeys = ['_id', '__v', 'userID', 'createdAt', 'updatedAt', 'sequence', 'school'];
        const excelData = data || downloadData
        if (excelData) {
            const success = downloadExcel(excelData, excludeKeys, arrayKeys, dateKeys, name, name);
            if (!success) return handleSnackbarOpen('Oh snap! some Error while Downloading the Excel.', 'error', 'top', 'center', 'download');
            return handleSnackbarOpen(`That was fast! ${name} downloaded in record time.`, 'info', 'top', 'center', 'download');
        }
    };

    const getAdminExcelData = async (formData, type) => {
        setLoading(true);
        const model = name.charAt(0).toLowerCase() + name.slice(1);
        const response = await (type === 'complete' ?
            usePostData(`/api/excel/${model}`, formData) :
            usePostData(`/api/excel/${model}AV`, formData)
        );
        const { success, data } = response;
        setLoading(false);

        if (!success) return handleSnackbarOpen('Oh snap! some Error while Downloading the Excel.', 'error', 'top', 'center', 'download');
        if (data.length <= 0) return handleSnackbarOpen("Oh snap! We couldn't find any data for this duration.", 'warning', 'top', 'center', 'download');

        handleDownload(data)
    }


    return {
        popupData,
        setPopupData,
        isViewPopupOpen,
        openViewDetails,
        closeViewDetails,
        onDelete,
        onDownload,
        handleDownload,
        getAdminExcelData,
    };
};

export default useOperations;

import React, { useState, useEffect, useCallback } from 'react'
import UserContext from "./UserContext";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import PortalPopup from '../components/popups/PortalPopup';
import PopupViewOnly from '../components/popups/PopupViewOnly';
import { Box, CircularProgress } from '@mui/material';

const host = process.env.REACT_APP_HOST;

const UserState = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const startLoading = useCallback(() => {
        setIsLoading(true)
    }, []);
    const closeLoading = useCallback(() => { setIsLoading(false) }, []);

    const [putS3URL, setPutS3URL] = useState(false);
    const clearS3URL = useCallback(() => { setPutS3URL('') }, []);

    const [showAlert, setShowAlert] = useState(false);
    const [alertDetails, setAlertDetails] = useState({});

    const closeAlert = useCallback(() => { setShowAlert(false) }, []);

    const [tableLoading, setTableLoading] = useState(false);
    const closeTableLoading = useCallback(() => { setTableLoading(false) }, []);

    const openTableLoading = useCallback(() => { setTableLoading(true) }, []);

    const [quartileLoading, setQuartileLoading] = useState(false);
    const closeQuartileLoading = useCallback(() => { setQuartileLoading(false) }, []);
    const openQuartileLoading = useCallback(() => { setQuartileLoading(true) }, []);

    const token = sessionStorage.getItem('token');

    const [userData, setUserData] = useState({});

    const getUser = async () => {
        const response = await fetch(`${host}/api/user/getUser`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        const resp = await response.json();
        if (resp?.success) {
            setUserData(resp.user);
            sessionStorage.setItem('userName', resp?.user?.name);
            sessionStorage.setItem('userId', resp?.user?.email);
            sessionStorage.setItem('title', userData.title);
            sessionStorage.setItem('school', userData.school);
            sessionStorage.setItem('designation', userData.designation);
        } else {
            setAlertDetails({
                heading: "Session Expired",
                subText: "Your session has expired. Please log in again to continue."
            });
            setShowAlert(true);
        }
        // if (!resp.success) {
        //     handleSessionMismatch(); // Initiate logout
        // }

    };

    const updateLinks = async (details, result) => {
        setIsLoading(true);
        const response = await fetch(`${host}/api/user/updateLinks`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(details)
        });
        const resp = await response.json();
        if (resp?.success) {
            result.value = true
            setIsLoading(false);
        }
        else {
            setIsLoading(false);
            setAlertDetails({
                heading: "Details Updation Failed",
                subText: resp?.error
            })
            setShowAlert(true);
        }
    }

    const updatePass = async (details, result) => {
        setIsLoading(true);
        const response = await fetch(`${host}/api/user/updatePassword`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(details)
        });
        const resp = await response.json();

        if (resp?.success) { result.value = true; setIsLoading(false); }
        else {
            setIsLoading(false);
            setAlertDetails({
                heading: "Password Updation Failed",
                subText: resp?.error
            })
            setShowAlert(true);
        }
    }

    const deleteJournal = async (id) => {
        const response = await fetch(`${host}/api/journal/deleteJournal/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const result = await response.json();
        if (result.success) return true
        return false
    }

    const downloadExcel = (jsonData, excludeKeys, arrayKeys, dateKeys, tabName, excelName) => {
        // Exclude keys from jsonData
        const filteredData = jsonData.map(item => {
            const filteredItem = {};
            for (let key in item) {
                if (!excludeKeys.includes(key)) {
                    filteredItem[key] = item[key];
                }
            }

            for (let key in item) {
                if (arrayKeys.includes(key)) {
                    const extractedNames = item[key].map(i => i.name);
                    filteredItem[key] = extractedNames.join(', ');
                }
            }

            for (let key in item) {
                if (dateKeys.includes(key)) {
                    const extractedDates = new Date()
                    filteredItem[key] = extractedDates;
                }
            }

            return filteredItem;
        });


        // Convert filteredData to worksheet
        const worksheet = XLSX.utils.json_to_sheet(filteredData);

        // Create workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, tabName);

        // Convert workbook to binary
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        try {
            // Create Blob and save the file
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            FileSaver.saveAs(blob, `${excelName}.xlsx`);

            // Check if the browser supports the download attribute
            const isDownloadSupported = 'download' in document.createElement('a');
            return isDownloadSupported;
        } catch (error) {
            console.error('Error downloading file:', error);
            return false;
        }
    };

    const addRating = async (formData, result) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${host}/api/rating/addRating`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });
            const resp = await response.json();

            if (resp?.success) {
                result.value = true;
                setIsLoading(false);
            }

            else {
                setIsLoading(false);
                setAlertDetails({
                    heading: "Adding New Patent Failed",
                    subText: resp?.error
                })
                setShowAlert(true);
            }
        } catch (err) {
            setIsLoading(false);
            setAlertDetails({
                heading: "Adding New Patent Failed",
                subText: err.error
            })
            setShowAlert(true);
        }
    };

    const addSuggestion = async (formData, result) => {
        setIsLoading(true);
        try {
            const response = await fetch(`${host}/api/suggestion/addSuggestion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });
            const resp = await response.json();
            if (resp?.success) {
                result.value = true;
                setIsLoading(false);
            }

            else {
                setIsLoading(false);
                setAlertDetails({
                    heading: "Adding New Patent Failed",
                    subText: resp?.error
                })
                setShowAlert(true);
            }
        } catch (err) {
            setIsLoading(false);
            setAlertDetails({
                heading: "Adding New Patent Failed",
                subText: err.error
            })
            setShowAlert(true);
        }
    };

    const deleteConference = async (id) => {
        const response = await fetch(`${host}/api/conference/deleteConference/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true
    }

    const deleteBookChapter = async (id) => {
        const response = await fetch(`${host}/api/bookChapter/deleteBookChapter/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    const deleteEventAttended = async (id) => {
        const response = await fetch(`${host}/api/eventAttended/deleteEventAttended/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    const deleteEventConducted = async (id) => {
        const response = await fetch(`${host}/api/eventConducted/deleteEventConducted/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    const deleteConsultancy = async (id) => {
        const response = await fetch(`${host}/api/consultancy/deleteConsultancy/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    const deleteFundedProject = async (id) => {
        const response = await fetch(`${host}/api/fundedProject/deleteFundedProject/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    const deleteAward = async (id) => {
        const response = await fetch(`${host}/api/award/deleteAward/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const resp = await response.json();
        if (resp.success) return true;
        return false;
    }

    // ************************      AWS S3 CLOUD         ***********************

    const getDocument = async (folder, key) => {
        setIsLoading(true);
        const formData = { folder, key };
        try {
            const response = await fetch(`${host}/cloud/getViewURL`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });
            const resp = await response.json();

            if (resp?.success) {
                window.open(resp.url, '_blank');
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                setAlertDetails({
                    heading: "Fetching Document Failed",
                    subText: resp?.error
                })
                setShowAlert(true);
            }
        } catch (err) {
            setIsLoading(false);
            setAlertDetails({
                heading: "Fetching Document Failed",
                subText: err.error
            })
            setShowAlert(true);
        }
    };

    const updateDocumentImage = async (folder, key) => {
        setIsLoading(true);
        const contentType = 'image/jpeg'
        const fileName = key + '.jpeg';
        const formData = { folder, fileName, contentType };
        try {
            const response = await fetch(`${host}/cloud/getUpdateURL`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });
            const resp = await response.json();
            if (resp?.success) {
                setPutS3URL(resp.url)
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                setAlertDetails({
                    heading: "Fetching Uploading Link Failed",
                    subText: resp?.error
                })
                setShowAlert(true);
            }
        } catch (err) {
            setIsLoading(false);
            setAlertDetails({
                heading: "Fetching Uploading Link Failed",
                subText: err.error
            })
            setShowAlert(true);
        }
    };

    const updateDocumentFlag = async (type, id) => {
        setIsLoading(true);
        const formData = { type, id };
        try {
            const response = await fetch(`${host}/cloud/updateDocumentFlag`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });
            const resp = await response.json();

            if (resp?.success) {
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
                setAlertDetails({
                    heading: "Updating Status Failed",
                    subText: resp?.error
                })
                setShowAlert(true);
            }
        } catch (err) {
            setIsLoading(false);
            setAlertDetails({
                heading: "Updating Status Failed",
                subText: err.error
            })
            setShowAlert(true);
        }
    };


    return (<>
        <UserContext.Provider value={{
            putS3URL, clearS3URL, getDocument,
            updateDocumentImage, updateDocumentFlag,
            startLoading, closeLoading,
            userData, getUser, updateLinks, updatePass, addRating, addSuggestion,
            downloadExcel, quartileLoading, closeQuartileLoading, openQuartileLoading,

            deleteJournal, deleteConference, deleteBookChapter,
            deleteEventAttended, deleteEventConducted,
            deleteConsultancy, deleteFundedProject,
            deleteAward,

            tableLoading, closeTableLoading, openTableLoading
        }}>
            {props.children}
        </UserContext.Provider>

        {showAlert && (
            <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeAlert} >
                <PopupViewOnly heading={alertDetails.heading} subText={alertDetails.subText} icon={'/icons/Failed.svg'} onClose={closeAlert} type={"Upload"} />
            </PortalPopup>)
        }
        {isLoading && (
            <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="secondary" />
                </Box>
            </PortalPopup>
        )}
    </>
    );

}

export default UserState;

import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Sector } from "recharts";
import { Skeleton } from "@mui/material";

const PieChartComponent = ({ h1, h2, h3, h4, h5, v1, v2, v3, v4, v5, ir = 40, or = 80, ea = 360, ht = 200, textY = 105, loading }) => {

  const data = [
    { name: h1, value: v1, color: "#5D45DB" },
    { name: h2, value: v2, color: "#A698EB" },
    h3 && { name: h3, value: v3, color: "#D3CCF5" },
    h4 && { name: h4, value: v4, color: "#CFC5FF" },
    h5 && { name: h5, value: v5, color: "#ECE9FF" },
  ].filter(Boolean);

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback((_, index) => {
    setActiveIndex(index);
  },
    [setActiveIndex]
  );

  const isDataEmpty = !loading && data.every(item => item.value === 0);
  return (
    <>
      {loading ? (
        <Skeleton animation="wave" className="mx-5 my-7" variant="circular" width={160} height={ht - 40}
          style={ea === 180 ? {
            borderRadius: "50% 50% 0 0 / 100% 100% 0 0",
            overflow: "hidden",
          } : {}}

        />
      ) : !isDataEmpty ? (
        <PieChart width={200} height={ht}  >
          <Pie
            activeIndex={activeIndex}
            activeShape={({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }) => {
              const percent = ((data[activeIndex].value / data.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(2);
              return (
                <g>
                  <Sector
                    cx={105}
                    cy={105}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                  />
                  <Sector
                    cx={105}
                    cy={105}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 12}
                    fill={fill}
                  />
                  <text
                    x={105}
                    y={textY}
                    textAnchor="middle"
                    fill="#333"
                    fontSize="18"
                    fontWeight="bold"
                  >
                    {data[activeIndex].name}
                  </text>
                  <text
                    x={105}
                    y={textY}
                    dy={17}
                    textAnchor="middle"
                    fill="#333"
                    fontSize="12"
                  >
                    {`${percent}%`}
                  </text>
                </g>
              );
            }}
            data={data}
            cx={100}
            cy={100}
            innerRadius={ir}
            outerRadius={or}
            startAngle={0}
            endAngle={ea}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      ) : (
        <div className=" mx-5 my-7 flex flex-col"      >
          <div className={`w-40 ${ea === 180 ? 'h-20 rounded-t-full items-end' : 'h-40 rounded-full items-center'}  bg-[#ECE9FF] flex justify-center `}
          >
            <span className="text-purple-950 italic text-[13px] text-center mx-4" >
              No Verified found as of now
            </span>
          </div>

        </div >
      )}
    </>
  );
};

export default PieChartComponent;

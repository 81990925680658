import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { breadcrumbState } from '../recoil/state';

export const useSetBreadcrumb = (breadcrumbs) => {
    const [breadcrumb, setBreadcrumb] = useRecoilState(breadcrumbState);

    useEffect(() => {
        const setBreadcrumbValues = () => {
            const [first, second, third] = breadcrumbs;
            setBreadcrumb({
                firstCrumb: { name: first.name, path: first.path },
                secondCrumb: second ? { name: second.name, path: second.path } : {},
                thirdCrumb: third ? { name: third.name, path: third.path } : {}
            });
        };

        setBreadcrumbValues();
    }, []);

    return { breadcrumb };
};

import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../../../../components/popups/PortalPopup";

import UserContext from "../../../../context/UserContext";
import EventConductedPopup from "../../../../components/popupDetailed/EventConductedPopup";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import useMultiPDFPopup from "../../../../hooks/useMultiPDFPopup";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import ConductedTile from "../../../../components/Tiles/ConductedTile";
import QuartileTile from "../../../../components/Tiles/QuartileTile";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import MultiplePDFPopup from "../../../../components/popups/MultiPDFPopup";

const Conducted = () => {
  useSetBreadcrumb([
    { name: 'Events', path: '/user/events?tab=Conducted' },
    { name: 'Conducted', path: '/user/events?tab=Conducted' },
  ]);

  const navigate = useNavigate();
  const currentYear = useAcademicYear();
  const { deleteEventConducted } = useContext(UserContext);
  const { data: conductedData, isLoading, refreshData } = useGetData('/api/eventConducted/getEventsConducted');
  const { data: conductedCount, isLoading: tileLoading } = useGetData('/api/stats/eventConducted/getStats?year=total');

  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Conducted', deleteEventConducted, refreshData, conductedData, ['coordinators']);
  const onUploadNewFrameClick = useCallback(() => { navigate("/user/events/conducted/addConducted"); }, [navigate]);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Coordinators', minWidth: 130 },
    { id: 'eventTitle', label: 'Event Title', minWidth: 230 },
    { id: 'eventType', label: 'Event Type', minWidth: 90 },
    { id: 'eventCategory', label: 'Event Category' },
    { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 120 },
    { id: 'formattedEndingDate', label: 'Ending Date', minWidth: 120, align: 'center' },
    { id: 'tickIcons', label: 'Proof | Photos', minWidth: 155 },
    { id: 'operation', label: 'Operations', minWidth: 155, align: 'center' },
  ];
  const buttons = [
    { label: "Events Proof", cloudName: 'proof' },
    { label: "Photos PDF", cloudName: 'photo' },
  ];

  const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Event Conducted', 'eventConducted', 'events?tab=Conducted', buttons);

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-5 gap-5 rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Events Conducted"
          value={conductedCount?.eventConducted}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />
        <AnalysisTile
          heading={`Events Conducted  in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={conductedCount?.eventConductedCY}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading={`Summary of Events Conducted in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
            h1={'International'}
            h2={'National'}
            v1={conductedCount?.international}
            v2={conductedCount?.national}
            loading={tileLoading}
          />
        </div>
        <div className="col-span-1 sm:col-span-2">
          <ConductedTile heading='Event Categories' data={conductedCount} />
        </div>
        <div className="col-span-1 sm:col-span-2">
          <QuartileTile
            heading='Events Conducted by Year'
            h1='First Year' h2='Second Year' h3='Third Year' h4='Last Year'
            lb1='FY' lb2='SY' lb3='TY' lb4='LY'
            v1={conductedCount?.audienceCounts?.['fy']} v2={conductedCount?.audienceCounts?.['sy'] ?? 0} v3={conductedCount?.audienceCounts?.['ty'] ?? 0} v4={conductedCount?.audienceCounts?.['ly'] ?? 0}
          />
        </div>

      </div>
      <div className=" flex flex-col py-5 sm:flex-row w-full sm:space-y-0 space-y-5 sm:space-x-10 justify-center items-center bg-white-sec rounded-lg ">
        <UploadButton text='Event Conducted' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Event Conducted' />
      </div >

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Events Conducted'}
          text={'Uploaded Events Conducted'}
          columns={columns}
          data={conductedData}
          dateColumns={[{ 'formattedStartingDate': 'startingDate' }, { 'formattedEndingDate': 'endingDate' }]}
          sortParameters={'formattedStartingDate'}
          multiUsers={'coordinators'}
          operation={true}
          deleteRequired={true}
          uploadRequired={true}
          viewRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={(data) => openMultiPDFPopup(data, "upload")}
          tickFields={['proofDocument', 'photoDocument']}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen &&
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails} >
          <EventConductedPopup onClose={closeViewDetails} data={popupData}
            viewPDF={() => openMultiPDFPopup(popupData, "view")}
            uploadPDF={() => openMultiPDFPopup(popupData, "upload")}
            deletePDF={() => openMultiPDFPopup(popupData, "delete")}
          />
        </PortalPopup>
      }

      {isMultiPDFPopup?.open &&
        <MultiplePDFPopup
          heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
          icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
        />}
    </div>
  );
};

export default Conducted;

/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../../../hooks/useSnackbar";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import useGetData from "../../../hooks/useGetData";
import useFormOperations from "../../../hooks/useFormOperations";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../recoil/state";

import { CloseButton, ConfirmButton } from "../../../mui/Button";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../mui/Input";
import { usePutData } from "../../../hooks/usePutData";

const EditAward = () => {
    useSetBreadcrumb([
        { name: 'Awards', path: '/user/awards' },
        { name: 'Edit Award', path: '/user/awards/editAward' }
    ]);

    const location = useLocation();
    const defaultValues = location.state?.awardData;
    const { handleSnackbarOpen } = useSnackbar();
    const { data: userData } = useGetData('/api/user/getUser');
    const  setLoading = useSetRecoilState(loadingState);

    const onSubmit = async (formData) => {
        setLoading(true);
        const { success, error } = await usePutData(`/api/award/updateAward/${formData._id}`, formData);
        setLoading(false);

        if (success) {
            handleSnackbarOpen("Award updated successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
            onDiscard();
        }
        else if (error)
            handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    };


    const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/awards");

    return (
        <div className="flex flex-col w-full space-y-5">
            <h2 className=" h5-p px-5">Edit Award Details</h2>
            <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>
                <CustomTextField label="Awardee Name"
                    InputLabelProps={{ shrink: true }} value={userData?.name} />

                <CustomTextField
                    label="Award Name" className="md:col-span-full" name="awardName"
                    onChange={handleChange} value={formData?.awardName}
                />
                <CustomTextField
                    label="Awarding Agency" className="md:col-span-full" name="awardingAgency"
                    onChange={handleChange} value={formData?.awardingAgency}
                />
                <CustomSelect
                    label="Category of Award" value={formData.awardCategory}
                    options={[
                        { value: 'Innovation / Technology', label: 'Innovation / Technology' },
                        { value: 'Social', label: 'Social' },
                        { value: 'Others', label: 'Others' },
                    ]} onChange={handleChange} name="awardCategory"
                />

                <CustomSelect
                    label="Type of Award" value={formData.awardType}
                    options={[
                        { value: 'International', label: 'International' },
                        { value: 'National', label: 'National' },
                    ]}
                    onChange={handleChange} name="awardType"
                />

                <CustomYearMonthPicker
                    setValue={setValue}
                    label='Date of Award'
                    disableFuture
                    yearVariable="awardYear"
                    monthVariable="awardMonth"
                    yearValue={formData.awardYear}
                    monthValue={formData.awardMonth}
                />
                <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
                    <CloseButton onClick={onDiscard} />
                    <ConfirmButton type="submit" />
                </div>
            </form >
        </div >
    );
};

export default EditAward;

import { useNavigate } from "react-router-dom";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";
import useGetData from "../../../hooks/useGetData";
import { useSearchParams } from "react-router-dom";
import { Table } from "../../../mui/Table";
import { FactCheck, PendingActions } from "@mui/icons-material";
import usePopup from "../../../hooks/usePopup";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";

const ManageRequest = () => {
    useSetBreadcrumb([{ name: 'Manage Request', path: '/admin/manageRequest?tab=Pending' }]);
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('tab');
    const { openPopup } = usePopup();
    const navigate = useNavigate();

    const { data: request, isLoading: loading, refreshData } = useGetData(`/api/request/getRequests?status=${activeTab}`, {}, true);

    const pendingColumns = [
        { id: 'index', label: 'Sr. No' },
        { id: 'employeeID', label: 'Employee ID', minWidth: 120 },
        { id: 'name', label: 'Faculty Name', minWidth: 120 },
        { id: 'reason', label: 'Reason', minWidth: 120 },
        { id: 'requestStatus', label: 'Request Status', minWidth: 80 },
    ];

    const actionColumns = [
        { id: 'index', label: 'Sr. No' },
        { id: 'name', label: 'Faculty Name', minWidth: 120 },
        { id: 'response', label: 'Response', minWidth: 120 },
        { id: 'authorityName', label: 'Approving Authority', minWidth: 120 },
        { id: 'requestStatus', label: 'Request Status', minWidth: 80 },
    ];

    const openDetails = (data) => {
        const onConfirm = () => {
            const { statusIcon, operation, ...requestData } = data;
            navigate("/admin/manageRequest/request", { state: { requestData } });
        }
        openPopup('Edit Details', 'Are you sure you want to View Details ?', 'edit', onConfirm);
    }

    return (
        <div className="flex flex-col w-full space-y-5">
            <ThreeWayTab
                btn1={'Pending'} btn2={'Action'}
                icon1={<PendingActions className="text-[28px]" />} icon2={<FactCheck className="text-[28px]" />}
                iconDisabled1={<PendingActions className="text-zinc-400 text-[28px]" />} iconDisabled2={<FactCheck className="text-zinc-400 text-[28px]" />}
            />
            <div className="bg-white-sec rounded-lg">
                <Table
                    heading={`${activeTab} Request`}
                    text={'All Uploaded Requests'}
                    columns={activeTab === 'Pending' ? pendingColumns : actionColumns}
                    data={request}
                    operation={false}
                    viewRequired={activeTab === 'Pending' ? true : false}
                    click={openDetails}
                    loading={loading}
                />

            </div>
        </div>
    );
};

export default ManageRequest;


import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import { Table } from '../../../../mui/Table';
import useGetData from '../../../../hooks/useGetData';

const AVJournal = () => {
  const [year, setYear] = useRecoilState(sortYearState);
  const { data, isLoading } = useGetData(`/api/AV/journal/getAV?year=${year}&school=total`);

  const columnsAbstractView = [
    { id: "index", label: "Sr No", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 150 },
    { id: "total", label: "Total", minWidth: 50 },
    { id: "internationalPublished", label: "International", minWidth: 50 },
    { id: "nationalPublished", label: "National", minWidth: 50 },
    { id: "scimago", label: "JCR", minWidth: 50 },
    { id: "scopus", label: "Scopus", minWidth: 50 },
    { id: "google", label: "G.I.", minWidth: 50 },
    { id: "others", label: "Others", minWidth: 50 },
    { id: "naQuartileProviderCount", label: "NA", minWidth: 50 },
    { id: "q1Count", label: "Q1", minWidth: 50 },
    { id: "q2Count", label: "Q2", minWidth: 50 },
    { id: "q3Count", label: "Q3", minWidth: 50 },
    { id: "q4Count", label: "Q4", minWidth: 50 },
    { id: "naCount", label: "N/A", minWidth: 50 },
  ];

  return (
    <Table
      heading={'Total Journals'}
      text={'Faculties Journal'}
      columns={columnsAbstractView}
      data={data}
      operation={false}
      sortParameters={'total'}
      deleteRequired={false}
      uploadRequired={false}
      viewRequired={false}
      sortYear={true}
      loading={isLoading}
    />
  )
}

export default AVJournal;
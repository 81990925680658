import PortalPopup from '../../../../components/popups/PortalPopup';
import JournalVerify from '../../../../components/popupDetailed/JournalVerify';
import { Table } from '../../../../mui/Table';
import useOperations from '../../../../hooks/useOperations';
import usePDFPopup from '../../../../hooks/usePDFPopup';

const RecentJournal = ({ data, loading }) => {
  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Journal');

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'paperTitle', label: 'Title of Paper', minWidth: 230 },
    { id: 'category', label: 'Category', minWidth: 90 },
    { id: 'journalType', label: 'Type' },
    { id: 'formattedDate', label: 'Date of Publication', minWidth: 100 },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: "operation", label: "__", minWidth: 155, align: "center" },
  ];

  const { handleViewPDF } = usePDFPopup('journals');
  const viewPDF = () => { handleViewPDF(popupData._id) };

  return (
    <>
      <Table
        heading={'Published Journals'}
        text={'Uploaded Journals'}
        columns={columns}
        data={data}
        yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
        sortParameters={'formattedDate'}
        multiUsers={'authors'}
        statusDateCol={'lastVerified'}
        document={'document'}
        operation={true}
        deleteRequired={false}
        viewRequired={true}
        uploadRequired={false}
        click={openViewDetails}
        loading={loading}
      />

      {isViewPopupOpen &&
        <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
          <JournalVerify onClose={closeViewDetails} journal={popupData} viewPDF={viewPDF} />
        </PortalPopup>
      }
    </>
  )
}

export default RecentJournal
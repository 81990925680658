import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, } from 'recharts';
import BlackToolTip from '../buttons/BlackToolTip';

const BarGraph = ({ data }) => {
    const [sampleData, setSampleData] = useState([]);

    useEffect(() => {
        if (data) {
            const convertedData = convertData(data);
            setSampleData(convertedData);
        }
    }, [data]);

    const convertData = (data) =>
        Object?.entries(data)?.map(([year, yearData]) => ({
            year: parseInt(year),
            ...yearData,
            total: Object?.values(yearData)?.reduce((sum, value) => sum + value, 0),
        }));

    // const colors = ["#5D45DB", "#A698EB", "#CFC5FF", "#D3CCF5", "#ECE9FF"];
    const colors = ['#4F38C9', '#7664D2', '#9283DB', '#B9B1E3', '#D5D0EF']

    const renderPayload = (payload) => {
        let sum = 0;
        let count = -1;

        const mappedElements = payload.map((load, index) => {
            sum += load.value;
            count++;
            return (
                <div className='flex flex-col space-y-1.5' key={index}>
                    <hr className='border-none h-0.5' />
                    <span className='text-violet-900 text-s'>
                        {load.name} : {load?.value}
                    </span>
                </div>
            );
        });
        return { mappedElements, sum, count };
    };

    const CustomTooltip = ({ active, payload }) => {

        if (active && payload && payload.length) {
            const { mappedElements, sum, count } = renderPayload(payload)

            return (
                <div className='flex flex-col space-y-1 border rounded-sm shadow-sm bg-slate-50'>
                    <span className='px-1 pt-2 lb-p'>Year: {payload[0]?.payload.year}</span>
                    <hr className='border-none h-0.25 bg-zinc-300' />
                    <div className='flex flex-col p-2'>
                        {mappedElements}
                        {count > 0 && <span className='pt-3 lb-p'>Total: {sum}</span>}
                    </div>
                </div>
            );
        }
        else
            return null;
    };

    return (
        <div className='w-auto h-64'>
            <ResponsiveContainer className='transition-all duration-300 transform' width="100%" height="99%" >
                <BarChart data={sampleData} margin={0}>
                    <XAxis dataKey="year" className='text-s' />
                    <YAxis className='text-s' tickCount={5} />
                    <BlackToolTip content={<CustomTooltip />} />

                    {sampleData[0] && Object?.keys(sampleData[0])
                        ?.filter(key => key !== "year" && key !== "total")
                        ?.map((key, index) => (
                            <Bar
                                key={key}
                                dataKey={key}
                                stackId="a"
                                fill={colors[index % colors?.length]}
                                name={key}
                                barSize={30}
                            />
                        ))}

                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarGraph;

import { Button } from '@mui/material';
import { BorderColorOutlined, DeleteOutline, Edit, FileOpen, Upload } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePopup from '../../hooks/usePopup';
import { SquareIconButton } from '../../mui/SquareIconButton';

const EventAttendedPopup = ({ onClose, data, admin, uploadPDF, viewPDF, deletePDF }) => {

  const { guideName, eventTitle, eventType, eventCategory, organizationsName,
    organizationsVenue, certificates, certificatesType, financialSupport, financialSupportType,
    formattedStartingDate, formattedEndingDate, duration
  } = data;

  const navigate = useNavigate();
  const { openPopup } = usePopup();

  const onEditClick = useCallback(() => {
    const { operation, statusIcon, ...eventAttendedData } = data;
    navigate("/user/events/attended/editAttended", { state: { eventAttendedData } });
  }, [data, navigate]);

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const gridFields = [
    { label: "Faculty Name", value: guideName, colSpan: 8 },
    { label: "Title Of Attended Course", value: eventTitle, colSpan: 24 },
    { label: "Event (Category)", value: eventCategory, colSpan: 8 },
    { label: "Name of Organization", value: organizationsName, colSpan: 24 },
    { label: "Type of Event", value: eventType, colSpan: 8 },
    { label: "Venue of Event", value: organizationsVenue, colSpan: 8 },
    { label: "Certificate", value: certificates ? 'Yes' : 'No', colSpan: 8 },
    { label: "Certificate (Type)", value: certificatesType || '-', colSpan: 8 },
    { label: "Starting Date of Event", value: formattedStartingDate, colSpan: 8 },
    { label: "Ending Date of Event", value: formattedEndingDate, colSpan: 8 },
    { label: "Duration", value: duration, colSpan: 8 },
    { label: "Financial Support", value: financialSupportType, colSpan: 8 },
  ]

  return (
    <div className="bg-white flex flex-col rounded-xl w-full xl:w-[950px] h-full">
      <div className="relative flex justify-between p-5">
        <h2 className="h2-p">Event Attended Details </h2>
        <button className="w-fit h-fit" onClick={onClose} >
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
        </button>
      </div>
      <hr className='border-none bg-zinc-400 h-0.5' />

      <div class="max-h-[80%] h-full grid grid-cols-32 gap-5 p-5 break-words overflow-y-auto no-scrollbar">
        {gridFields.map(({ colSpan, label, value }) => (
          <div className={`flex flex-col col-span-${colSpan}`}>
            <label className="lb-s text-[13px]">{label}</label>
            <p className="lb-p">{value} </p>
          </div>
        ))}
      </div>

      <hr className='border-none bg-zinc-400 h-0.5' />
      <div className="flex justify-end p-5 space-x-10">
        {!admin &&
          <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="col-span-2 text-amber-500" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="col-span-2 text-blue-600" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutline />} className="col-span-2 text-red-500" />
          </>
        }
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpen />} className="col-span-3 text-slate-900" />
      </div>
    </div >
  );
};

export default EventAttendedPopup;

/* eslint-disable react-hooks/rules-of-hooks */
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import { usePostData } from "../../../../hooks/usePostData";
import useFormOperations from "../../../../hooks/useFormOperations";
import { CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";
import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const AddBook = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Journal' },
    { name: 'Book', path: '/user/publication?tab=Book' },
    { name: 'Add Book', path: '/user/publication/addBook' }
  ]);
  const { handleSnackbarOpen } = useSnackbar();
  const  setLoading = useSetRecoilState(loadingState);

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const defaultValues = {}

  const onSubmit = async (formData) => {
    const authorExists = formData.authors && formData.authors.some(author => author._id === userData._id);
    if (!authorExists) return handleSnackbarOpen("It appears you're trying to add a book entry without being listed as a co-author. This action is not permitted.", 'warning', 'top', 'center')

    setLoading(true);
    const { success, error, message } = await usePostData('/api/book/addBook', formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    if (success) {
      handleSnackbarOpen("Book Data added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  }

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/publication?tab=Book");

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Add Book Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name"
          InputLabelProps={{ shrink: true }} value={userData?.name} />

        <CustomTextField
          label="ISBN" value={formData?.isbnOrIssn} onChange={handleChange}
          name="isbn"
        />

        <AuthorInput
          className="col-span-full"
          name='authors'
          value={formData.authors}
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder='Select or add Authors / Co-Authors'
          label={'Authors'}
        />

        <CustomTextField
          label="Title of Book" className="md:col-span-full" name="bookTitle"
          onChange={handleChange} value={formData?.bookTitle}
        />

        <div className="my-2 col-span-full" />

        <CustomTextField
          label="Name of Publisher" name="publisher"
          value={formData?.publisher} onChange={handleChange} />

        <CustomTextField
          label="Country" name="country"
          value={formData?.country} onChange={handleChange} />


        <CustomTextField
          label="Name of Editor" name="editor"
          value={formData?.editor} onChange={handleChange} />

        <CustomTextField
          label="State" name="state"
          value={formData?.state} onChange={handleChange} />

        <CustomTextField
          label="Edition" name="edition"
          value={formData?.edition} onChange={handleChange} required={false} />

        <CustomTextField
          label="City" name="city"
          value={formData?.city} onChange={handleChange} />

        <CustomTextField
          label="Volume" name="volume" type="number"
          value={formData?.volume} onChange={handleChange} required={false} />

        <CustomYearMonthPicker
          setValue={setValue}
          label='Publication Date'
          disableFuture
          yearVariable="publicationYear"
          monthVariable="publicationMonth"
        />

        <CustomTextField
          label="Page Number" placeholder="01-02" name='pageNo'
          onChange={handleChange} required={false} customType='dashedNumber'
        />

        <CustomTextField
          onChange={handleChange} name="bookLink" label="Book Link"
          className='h-28' required={false} placeholder="https://book.org/bookName"
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>
      </form >
    </div>
  );
};

export default AddBook;
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import UserContext from '../../../../context/UserContext';
import { useSetBreadcrumb } from '../../../../hooks/useSetBreadcrumb';
import useGetData from '../../../../hooks/useGetData';
import useFormOperations from '../../../../hooks/useFormOperations';
import { useSnackbar } from '../../../../hooks/useSnackbar';

import PortalPopup from '../../../../components/popups/PortalPopup';
import PopupRegular from '../../../../components/popups/PopupRegular';
import { Button, } from "@mui/material";
import { CustomAsyncSelect, CustomTextField, CustomYearPicker } from '../../../../mui/Input';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/state';
import { usePostData } from '../../../../hooks/usePostData';

const EditProfile = () => {
    useSetBreadcrumb([
        { name: 'Settings', path: '/user/settings?type=Edit Profile' },
        { name: 'Edit Profile', path: '/user/settings?type=Edit Profile' },
    ]);
    const { handleSnackbarOpen } = useSnackbar();
    const { updateDocumentImage } = useContext(UserContext);

    const { data: defaultValues } = useGetData('/api/user/getUser');
    const [disabled, setDisabled] = useState(true);
    const [, setLoading] = useRecoilState(loadingState);

    const onSubmit = async (formData) => {
        const differingFields = Object.entries(formData).filter(([key, value]) =>
            !defaultValues.hasOwnProperty(key) || defaultValues[key] !== value);

        const differingFormData = Object.fromEntries(differingFields);

        if (Object.keys(differingFormData).length === 0)
            return handleSnackbarOpen("No changes made.", 'info', 'top', 'center');

        setLoading(true);
        const { success, error, message } = await usePostData('/api/request/addRequest', differingFormData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')

        if (success) {
            handleSnackbarOpen("Request sent successfully!", 'success', 'top', 'center', 'upload')
            clearForm();
        }

        else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    };

    const { formData, handleChange, setValue, clearForm, handleSubmit } = useFormOperations(defaultValues, onSubmit, '/user/settings?type=Edit%20Profile', defaultValues);

    useEffect(() => {
        if (!formData || !defaultValues) return
        const keyToIgnore = 'reason';
        const formDataMatchDefaultValues = Object.entries(formData).every(([key, value]) => {
            if (key === keyToIgnore) return true;
            return defaultValues.hasOwnProperty(key) && defaultValues[key] === value;
        });

        if (!formDataMatchDefaultValues) setDisabled(false);
        else setDisabled(true);

    }, [formData, defaultValues]);


    const [isEditDocumentsPopupOpen, setEditDocumentsPopupOpen] = useState(false);
    const openEditDocumentsPopup = useCallback(() => { setEditDocumentsPopupOpen(true); }, []);
    const closeEditDocumentsPopup = useCallback(() => { setEditDocumentsPopupOpen(false); }, []);

    const navigate = useNavigate();

    const addPhoto = () => {
        navigate('/user/uploadImage', {
            state: {
                path: '/user/settings?type=Edit+Profile',
                folder: 'profile',
                id: formData?._id
            }
        })
    };

    const handleUpdateDocument = async () => {
        if (formData?._id) {
            await updateDocumentImage("profile", formData?._id);
            addPhoto();
        }
    };

    const schools = JSON.parse(process.env.REACT_APP_SCHOOLS);
    const schoolOptions = schools.map(school => ({
        value: school.value,
        label: school.label
    }));

    return (
        <div className="flex flex-col gap-y-5">
            <div className='flex flex-col'>
                <h2 className="sm:text-left text-center h2-p px-5 pt-8 pb-3">Edit Profile</h2>
                <hr className="w-full h-1" />
            </div>

            <form className="grid md:grid-cols-2 gap-x-12 gap-y-8 p-5" onSubmit={handleSubmit} >
                <div className="flex w-full">
                    <CustomAsyncSelect
                        label="Title" value={formData?.title}
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} className='w-20' name="title" onChange={handleChange}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />

                    <CustomTextField
                        label="Name" name="name" value={formData?.name} onChange={handleChange}
                        className='w-full' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>

                <CustomAsyncSelect
                    label="Designation" value={formData?.designation}
                    options={[
                        { value: 'Dean', label: 'Dean' },
                        { value: 'Associate Dean', label: 'Associate Dean' },
                        { value: 'Associate Professor', label: 'Associate Professor' },
                        { value: 'Professor', label: 'Professor' },
                        { value: 'Assistant Professor', label: 'Assistant Professor' },
                    ]} name="designation" onChange={handleChange}
                />

                <CustomTextField type="email" label="Email" name="email"
                    value={formData?.email} onChange={handleChange}
                    InputLabelProps={{ shrink: true }} />

                <CustomTextField customType="number" label="Contact Number"
                    name="phNo" value={formData?.phNo} onChange={handleChange}
                    InputLabelProps={{ shrink: true }} />

                <CustomTextField label="Employee ID" name="employeeID"
                    value={formData?.employeeID} onChange={handleChange}
                    InputLabelProps={{ shrink: true }} />

                <CustomYearPicker
                    name='joiningDate'
                    label="Joining Date"
                    setValue={setValue}
                    value={formData?.joiningDate}
                />

                <CustomAsyncSelect
                    label="School" value={formData?.school}
                    options={schoolOptions} name="school" onChange={handleChange}
                />

                <CustomTextField
                    className='col-span-full'
                    placeholder='Enter the reason to Change Details' label="Reason"
                    multiline minRows={3} name='reason'
                    value={formData?.reason || ''} onChange={handleChange}
                />

                <div className="grid col-span-full md:grid-cols-3 lg:grid-cols-4 py-12 gap-4 ">
                    <Button size="large" style={{ zIndex: 1 }} color="success" type="button" onClick={openEditDocumentsPopup} variant="contained" className=" bg-green-700 ">Update Photo</Button>
                    <Button size="large" variant="outlined" color="error" type='button' onClick={clearForm} className='lg:col-start-3'>Discard Changes</Button>
                    <Button size="large" style={{ zIndex: 1 }} color="success" type="submit" variant="contained" className="bg-green-700" disabled={disabled}>Request Changes</Button>
                </div>
            </form >

            {isEditDocumentsPopupOpen && (
                <PortalPopup placement="Centered" onOutsideClick={closeEditDocumentsPopup} overlayColor="rgba(0,0,0,0.5)" >
                    <PopupRegular onClose={closeEditDocumentsPopup} heading={"Edit Photo"} subText={"Are you sure you want to Edit Photo ?"} icon={"/icons/Edit.svg"} onConfirm={handleUpdateDocument} />
                </PortalPopup>
            )}

        </div >
    );
};

export default EditProfile
import React from 'react';

const ConductedTile = ({ data, heading }) => {
    const dataGrid = [
        { label: 'Seminar', value: data?.seminar ?? 0 },
        { label: 'Conference', value: data?.conference ?? 0 },
        { label: 'Workshop', value: data?.workshop ?? 0 },
        { label: 'STTP', value: data?.STTP ?? 0 },
        { label: 'FDP', value: data?.FDP ?? 0 },
        { label: 'MOOCs', value: data?.MOOCs ?? 0 },
        { label: 'Summer School', value: data?.summerSchool ?? 0 },
        { label: 'Winter School', value: data?.winterSchool ?? 0 },
        { label: 'Guest Lecture', value: data?.guestLecture ?? 0 },
        { label: 'Sponsored Courses', value: data?.sponsoredCourses ?? 0 },
        { label: 'Refresher Course', value: data?.refresherCourse ?? 0 },
    ];

    return (
        <div className=" p-5 rounded-lg bg-white">
            <h5 className='h5-p mb-5'>{heading}</h5>
            <div className='grid sm:grid-cols-2 gap-5'>
                {dataGrid.map(({ label, value }, index) => (
                    <p key={index} className='label-p font-semibold flex items-center space-x-5'> <p className='rounded w-2 h-2 bg-purple4' /> <span>{label}</span> <span className='font-normal'>{value}</span></p>
                ))}
            </div>
        </div>
    );
};

export default ConductedTile;

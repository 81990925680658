import { useEffect } from "react";

const usePageMetadata = (pathname, isAdmin) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        const pageTitles = {
            "/": "Tracer MIT-WPU - Login",
        };

        const defaultTitle = isAdmin ? "Admin View" : "User View";
        const isAnyHome = /^\/[^/]+\/home$/.test(pathname);
        const title = isAnyHome ? `Welcome to Tracer - ${isAdmin ? 'Admin' : 'User'}` : pageTitles[pathname] || defaultTitle;

        document.title = title;
    }, [pathname, isAdmin]);
};

export default usePageMetadata;

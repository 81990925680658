/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from "react-router-dom";
import dayjs from 'dayjs';

import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";

import useGetData from "../../../../hooks/useGetData";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { usePutData } from "../../../../hooks/usePutData";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useFormOperations from "../../../../hooks/useFormOperations";
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { CustomSelect, CustomTextField, CustomYearPicker } from "../../../../mui/Input";


const EditAttended = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/user/events?tab=Attended' },
    { name: 'Event Attended', path: '/user/events?tab=Attended' },
    { name: 'Edit Event Attended', path: '/user/events/attended/editAttended' }
  ]);
  const setLoading = useSetRecoilState(loadingState);
  const { handleSnackbarOpen } = useSnackbar();
  const location = useLocation();
  const defaultValues = location.state?.eventAttendedData;

  const { data: userData } = useGetData('/api/user/getUser');

  const onSubmit = async (formData) => {
    setLoading(true);
    const { success, error, message } = await usePutData(`/api/eventAttended/updateEventAttended/${defaultValues._id}`, formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    if (success) {
      handleSnackbarOpen("Event Attended entry updated successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  };

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/events?tab=Attended");
  const certificatesTypeDisabled = formData?.certificates !== true;

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Edit Attended Events Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name" InputLabelProps={{ shrink: true }} value={userData?.name} />

        <CustomTextField
          label="Event Title" className="col-span-full" name="eventTitle"
          onChange={handleChange} value={formData?.eventTitle}
        />

        <CustomTextField
          label="Organization Name" className="col-span-full" name="organizationsName"
          onChange={handleChange} value={formData?.organizationsName}
        />

        <CustomTextField
          label="Organization Venue" className="col-span-full" name="organizationsVenue"
          value={formData?.organizationsVenue} onChange={handleChange} />

        <CustomYearPicker
          name='startingDate'
          label="Starting Date"
          setValue={setValue}
          value={formData?.startingDate}
        />

        <CustomSelect
          label="Event Type" value={formData?.eventType}
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
            { value: 'State', label: 'State' },
          ]} onChange={handleChange} name="eventType"
        />

        <CustomYearPicker
          name='endingDate'
          label="Ending Date"
          setValue={setValue}
          value={formData?.endingDate}
        />

        <CustomSelect
          label="Category" value={formData?.eventCategory}
          options={[
            { value: 'Seminar', label: 'Seminar' },
            { value: 'Conference', label: 'Conference' },
            { value: 'Workshop', label: 'Workshop' },
            { value: 'STTP', label: 'STTP' },
            { value: 'FDP', label: 'FDP' },
            { value: 'MOOCs', label: 'MOOCs' },
            { value: 'Refresher Course', label: 'Refresher Course' },
            { value: 'Summer School', label: 'Summer School' },
            { value: 'Winter School', label: 'Winter School' },
            { value: 'Sponsored Courses', label: 'Sponsored Courses' },
            { value: 'Guest Lecture', label: 'Guest Lecture' },
          ]}
          onChange={handleChange} name="eventCategory"
        />

        <CustomSelect
          label="Certificate received" value={formData?.certificates}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]}
          onChange={handleChange} name="certificates"
        />

        <CustomSelect
          label="Financial Support Type" value={formData?.financialSupportType}
          options={[
            { value: 'Free', label: 'Free' },
            { value: 'Custom', label: 'Custom' },
          ]}
          onChange={handleChange} name="financialSupportType"
        />

        <CustomSelect
          label="Certificate's Type" value={formData?.certificatesType}
          options={[
            { value: 'Participation', label: 'Participation' },
            { value: 'Completion', label: 'Completion' },
            { value: 'Honor Certificate', label: 'Honor Certificate' },
          ]}
          onChange={handleChange} name="certificatesType" required={!certificatesTypeDisabled}
        />

        <CustomTextField
          label="Enter Custom Financial Support Amount"
          name="financialSupportType"
          customType="decimal" onChange={handleChange}
          disabled={formData.financialSupportType === 'Free'}
          value={formData?.financialSupportType}
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>
      </form >
    </div>
  );
};

export default EditAttended;

import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PortalPopup from "../../../../components/popups/PortalPopup";
import UserContext from "../../../../context/UserContext";

import FundedProjectPopup from "../../../../components/popupDetailed/FundedProjectPopup";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useMultiPDFPopup from "../../../../hooks/useMultiPDFPopup";
import MultiplePDFPopup from "../../../../components/popups/MultiPDFPopup";

const FundedProject = () => {
    useSetBreadcrumb([
        { name: 'Projects', path: '/user/projects?tab=Research+%26+Development' },
        { name: 'Research & Development', path: '/user/projects?tab=Research+%26+Development' },
    ]);

    const currentYear = useAcademicYear();
    const navigate = useNavigate();
    const { deleteFundedProject } = useContext(UserContext);

    const { data: fundedProjectsData, isLoading, refreshData } = useGetData('/api/fundedProject/getFundedProjects');
    const { data: tileData, isLoading: tileLoading, refreshData: tileRefresh } = useGetData('/api/stats/fundedProject/getStats?year=total');
    const { data: tileDataCY, isLoading: tileLoadingCY, refreshData: tileRefreshCY } = useGetData(`/api/stats/fundedProject/getStats?year=${currentYear}`);
    const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('FundedProject', deleteFundedProject, refreshData, fundedProjectsData, ['principalInvestigators']);

    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'formattedAuthors', label: 'Principal Investigators', minWidth: 180 },
        { id: 'projectTitle', label: 'Project Title', minWidth: 230 },
        { id: 'category', label: 'Category', minWidth: 90 },
        { id: 'fundedProjectStatus', label: 'Status' },
        { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 100 },
        { id: 'formattedEndingDate', label: 'Ending Date', minWidth: 100 },
        { id: 'tickIcons', label: 'Proof [AL  CL  BS]', minWidth: 165 },
        { id: 'statusIcon', label: 'Status' },
        { id: 'operation', label: 'Operations', minWidth: 155 },
    ];

    const onUploadNewFrameClick = useCallback(() => {
        navigate("/user/projects/fundedProject/addFundedProject");
    }, [navigate]);

    const buttons = [
        { label: "Approval Letter", cloudName: 'approval' },
        { label: "Completion Letter", cloudName: 'completion' },
        { label: "Audited bank statement", cloudName: 'bankStatement' },
    ];

    const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Research & Development', 'fundedProjects', `projects?tab=Research%2B%2526%2BDevelopment`, buttons);

    return (
        <div className="flex flex-col space-y-5 my-5 ">
            <div className="grid sm:grid-cols-2 gap-5 p-5 rounded-lg bg-white-sec">
                <AnalysisTile
                    heading="Total completed Research & Development Projects"
                    value={tileData?.completed}
                    icon={'/tabs/RnDPurple.svg'}
                    loading={tileLoading}
                    message='Total Verified, Granted & Completed Research & Development projects'
                />

                <AnalysisTile
                    heading={`Completed Research & Development projects in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    value={tileDataCY?.completed}
                    icon={'/tabs/RnDPurple.svg'}
                    loading={tileLoading}
                    message='Total Verified, Granted & Completed Research & Development projects'
                />

                <AnalysisTile
                    heading="Total Applied Research & Development Projects"
                    value={tileData?.applied}
                    icon={'/tabs/RnDPurple.svg'}
                    loading={tileLoading}
                    message='Total Verified, Granted & Completed Research & Development projects'
                />

                <AnalysisTile
                    heading={`Applied Research & Development projects in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    value={tileDataCY?.applied}
                    icon={'/tabs/RnDPurple.svg'}
                    loading={tileLoading}
                    message='Total Verified, Applied Research & Development projects'
                />
            </div>

            <div className="flex flex-col py-5 sm:flex-row w-full sm:space-y-0 space-y-5 sm:space-x-10 justify-center items-center bg-white-sec rounded-lg ">
                <UploadButton text='R & D' onClick={onUploadNewFrameClick} />
                <DownloadButton onClick={onDownload} text='R & D' />
            </div >

            <div className="rounded-lg bg-white-sec">
                <Table
                    heading={'Research & Development Projects'}
                    text={'Uploaded R & D Projects'}
                    columns={columns}
                    data={fundedProjectsData}
                    yearMonthColumns={[{ 'formattedAppliedDate': ['appliedMonth', 'appliedYear'] }, { 'formattedStartingDate': ['startingMonth', 'startingYear'] }, { 'formattedEndingDate': ['endingMonth', 'endingYear'] }]}
                    sortParameters={'formattedAppliedDate'}
                    multiUsers={'principalInvestigators'}
                    operation={true}
                    deleteRequired={true}
                    viewRequired={true}
                    uploadRequired={true}
                    click={openViewDetails}
                    onDelete={onDelete}
                    statusDateCol={'lastVerified'}
                    tickFields={['approvalDocument', 'completionDocument', 'bankStatementDocument']}
                    onUpload={(data) => openMultiPDFPopup(data, "upload")}
                    loading={isLoading}
                />
            </div>

            {isViewPopupOpen &&
                <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails} >
                    <FundedProjectPopup onClose={closeViewDetails} data={popupData}
                        viewPDF={() => openMultiPDFPopup(popupData, "view")}
                        uploadPDF={() => openMultiPDFPopup(popupData, "upload")}
                        deletePDF={() => openMultiPDFPopup(popupData, "delete")}
                    />
                </PortalPopup>
            }

            {isMultiPDFPopup?.open &&
                <MultiplePDFPopup
                    heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
                    icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
                />}
        </div>
    );
};

export default FundedProject;

import React, { useMemo } from "react";
const FacRow = ({ facName, secCol, thirdCol, fourthCol, fifthCol, sixthCol, sevCol, eightCol, ninthCol }) => {

  const formattedName = useMemo(() => {
    const nameParts = facName?.split(" ");
    if (nameParts?.length === 1)
      return facName;
    else if (nameParts?.length === 2)
      return `${nameParts[0]} ${nameParts[1]}`;
    else if (nameParts?.length > 2)
      return `${nameParts[0]} ${nameParts[nameParts?.length - 1]}`;

    return facName;
  }, [facName]);


  return (
    <div className="grid grid-cols-54 w-full my-4 items-center">
      <img className="w-13 col-span-3" alt="" src="/profile.svg" />
      <p className="text-txtblackSecondry font-[Poppins] text-lg font-semibold col-span-10 ml-1">{formattedName}</p>
      <p className="text-txtblackSecondry text-base col-span-8">Total Journals :  {secCol}</p>
      <p className="text-txtblackSecondry text-base col-span-6">National : {thirdCol}</p>
      <p className="text-txtblackSecondry text-base col-span-8">International : {fourthCol}</p>
      <p className="text-txtblackSecondry text-base col-span-4">Q1 : {fifthCol}</p>
      <p className="text-txtblackSecondry text-base col-span-4">Q2 : {sixthCol}</p>
      <p className="text-txtblackSecondry text-base col-span-4">Q3 : {sevCol}</p>
      <p className="text-txtblackSecondry text-base col-span-3">Q4 : {eightCol}</p>
      <p className="text-txtblackSecondry text-base col-span-3">N/A : {ninthCol}</p>
    </div>
  );
};

export default FacRow;

import React from 'react'
import { Table } from '../../../../mui/Table';
import FundedProjectPopup from '../../../../components/popupDetailed/FundedProjectPopup';
import PortalPopup from '../../../../components/popups/PortalPopup';
import useOperations from '../../../../hooks/useOperations';
import useMultiPDFPopup from '../../../../hooks/useMultiPDFPopup';
import MultiplePDFPopup from '../../../../components/popups/MultiPDFPopup';

const RecentFundedProject = ({ data, loading }) => {
    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'formattedAuthors', label: 'Principle Investigators', minWidth: 130 },
        { id: 'projectTitle', label: 'Proposal Title', minWidth: 230 },
        { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 100 },
        { id: 'fundedProjectStatus', label: 'Status' },
        { id: 'tickIcons', label: 'Proof [AL  CL  BS]', minWidth: 165 },
        { id: 'statusIcon', label: 'Status', align: 'center' },
        { id: 'operation', label: '__' },
    ];

    const buttons = [
        { label: "Approval Letter", cloudName: 'approval' },
        { label: "Completion Letter", cloudName: 'completion' },
        { label: "Audited bank statement", cloudName: 'bankStatement' },
    ];

    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Research & Development');
    const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Research & Development', 'fundedProjects', '', buttons);

    return (
        <>
            <Table
                heading={'Research & Development Projects'}
                text={'Uploaded R & D Projects'}
                columns={columns}
                data={data}
                yearMonthColumns={[{ 'formattedAppliedDate': ['appliedMonth', 'appliedYear'] }, { 'formattedStartingDate': ['startingMonth', 'startingYear'] }, { 'formattedEndingDate': ['endingMonth', 'endingYear'] }]}
                sortParameters={'formattedAppliedDate'}
                multiUsers={'principalInvestigators'}
                operation={true}
                viewRequired={true}
                click={openViewDetails}
                statusDateCol={'lastVerified'}
                tickFields={['approvalDocument', 'completionDocument', 'bankStatementDocument']}
                loading={loading}
            />

            {isViewPopupOpen &&
                <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
                    <FundedProjectPopup onClose={closeViewDetails} data={popupData} admin={true}
                        viewPDF={() => openMultiPDFPopup(popupData, "view")} />
                </PortalPopup>
            }

            {isMultiPDFPopup?.open &&
                <MultiplePDFPopup
                    heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
                    icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
                />}

        </>
    )
}

export default RecentFundedProject;
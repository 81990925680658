/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from "react-router-dom";
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";

import { useRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useGetData from "../../../../hooks/useGetData";
import useFormOperations from "../../../../hooks/useFormOperations";
import { usePutData } from "../../../../hooks/usePutData";

const EditJournalPage = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Journal' },
    { name: 'Journal', path: '/user/publication?tab=Journal' },
    { name: 'Edit Journal', path: '/user/publication/editJournal' }
  ]);

  const location = useLocation();
  const defaultValues = location.state?.journalData;

  const { handleSnackbarOpen } = useSnackbar();
  const setLoading = useRecoilState(loadingState);

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const onSubmit = async (formData) => {
    const authorExists = formData.authors && formData.authors.some(author => author._id === userData._id);
    if (!authorExists) handleSnackbarOpen("It appears you're trying to update a journal entry without being listed as a co-author. This action is not permitted.", 'warning', 'top', 'center')

    setLoading(true);
    const { success, error, message } = await usePutData(`/api/journal/updateJournal/${formData._id}`, formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    if (success) {
      handleSnackbarOpen("Journal updated successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }

    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  }

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/publication?tab=Journal");

  const handleProviderChange = (e) => {
    setValue('quartile', 'N/A');
    setValue('quartileProvider', e.target.value);
  };

  const handleIssnChange = (e) => {
    let inputValue = e.target.value;
    const inputName = e.target.name;
    inputValue = inputValue.split('-')?.join('');
    inputValue = inputValue.replace(/[^\dX]/g, '');
    inputValue = inputValue.slice(0, 8);

    if (inputValue.length > 1) inputValue = inputValue.match(new RegExp('.{1,4}', 'g')).join('-');
    setValue(inputName, inputValue);
  };

  const doiPattern = /^(https?:\/\/\S+[^.])$/;

  const dateLabel = formData?.category === 'Published' ? 'Published'
    : formData?.category === 'Accepted' ? 'Accepted' : 'Submitted';

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Edit Journal Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name"
          InputLabelProps={{ shrink: true }} value={userData?.name} />

        <CustomTextField
          name="issn" value={formData?.issn}
          label="ISSN" onChange={handleIssnChange}
          inputProps={{
            pattern: "\\d{4}-\\d{4}", placeholder: "XXXX-XXXX",
            title: "Please enter a valid ISSN in the format XXXX-XXXX",
          }}
        />

        <AuthorInput
          className="col-span-full"
          name='authors'
          value={formData.authors}
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder='Select or add Authors / Co-Authors'
          label={'Authors'}
        />

        <CustomTextField
          label="Title of Paper" className="md:col-span-full" name="paperTitle"
          onChange={handleChange} value={formData?.paperTitle}
        />

        <CustomTextField
          label="Title of Journal" className="md:col-span-full" name="journalTitle"
          onChange={handleChange} value={formData?.journalTitle} />

        <div className="my-2 col-span-full" />

        <CustomSelect
          label="Category of Journal" value={formData.category}
          options={[
            { value: 'Submitted', label: 'Submitted' },
            { value: 'Accepted', label: 'Accepted' },
            { value: 'Published', label: 'Published' },
          ]}
          onChange={handleChange} name="category"
        />

        <CustomSelect
          label="Indexed in Scopus" value={formData.indexedInScopus}
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
          name="indexedInScopus" onChange={handleChange}
        />

        <CustomYearMonthPicker
          setValue={setValue}
          yearValue={formData.publicationYear}
          monthValue={formData.publicationMonth}
          label={`${dateLabel} Date`}
          disableFuture
          yearVariable="publicationYear"
          monthVariable="publicationMonth"
        />

        <CustomSelect
          label="Indexed in SCI / SCIE / WOS"
          value={formData.indexedInSCI}
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
          onChange={handleChange} name="indexedInSCI"
        />

        <CustomSelect
          label="Quartile Provider"
          value={formData.quartileProvider}
          options={[
            { value: 'JCR', label: 'JCR (Scimago)' },
            { value: 'Scopus', label: 'Scopus' },
            { value: 'Google', label: 'Google Indexed' },
            { value: 'Others', label: 'Others' },
            { value: 'N/A', label: 'N/A' },
          ]}
          name="quartileProvider" onChange={handleProviderChange}
        />

        <CustomSelect
          label="Google Indexed"
          value={formData.indexedInGS}
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
          onChange={handleChange} name="indexedInGS"
        />

        <CustomSelect
          label="Quartile" name="quartile"
          options={[
            { value: 'Q1', label: 'Q1' },
            { value: 'Q2', label: 'Q2' },
            { value: 'Q3', label: 'Q3' },
            { value: 'Q4', label: 'Q4' },
            { value: 'N/A', label: 'N/A' },
          ]}
          helperText='Quartile selection is only enabled when the quartile provider is set to Scimago (JCR).'
          onChange={handleChange} value={formData?.quartile} disabled={formData?.quartileProvider !== 'JCR'}
        />

        <CustomSelect
          label="Other Indexed" value={formData.indexedInOthers}
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]}
          onChange={handleChange} name="indexedInOthers"
        />

        <div className="my-2 col-span-full" />

        <CustomSelect
          label="Type of Journal Publication" value={formData.journalType}
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
          ]}
          name="journalType" onChange={handleChange}
        />

        <CustomTextField
          name='volume' label="Volume" onChange={handleChange}
          required={false} value={formData.volume}
        />

        <CustomSelect
          label="Affiliation Details" value={formData.affiliationDetails}
          options={[
            { value: process.env.REACT_APP_COLLEGE_NAME, label: process.env.REACT_APP_COLLEGE_NAME },
            { value: 'Others', label: 'Others' },
          ]}
          onChange={handleChange} name="affiliationDetails"
        />

        <CustomTextField
          label="Page Number" placeholder="01-02" name='pageNo'
          onChange={handleChange} required={false} value={formData.pageNo}
          inputProps={{ step: '0.001', min: 0 }} customType='dashedNumber'
        />

        <CustomTextField
          label="Impact Factor (WOS/SCI/SCOPUS)" name='impactFactor'
          onChange={handleChange} required={false} customType="decimal"
          inputProps={{ step: '0.001', min: 0 }} value={formData.impactFactor}
        />

        <CustomTextField
          label="Issue" name='issue' onChange={handleChange} value={formData.issue}
          required={false} type="number" inputProps={{ min: 0 }}
        />

        <CustomTextField
          onChange={handleChange} name="doi" defaultValue={defaultValues.doi} className='h-28'
          label="DOI" error={formData?.doi && !doiPattern.test(formData?.doi)} required={formData.category === 'Published'}
          helperText={formData?.doi && !doiPattern.test(formData?.doi) ? "Enter a valid DOI (starts with 'https://doi.org/doi_no') or a direct https link to the publication. Ensure there are no trailing slashes." : null}
        />

        <CustomTextField
          onChange={handleChange} name="journalLink" defaultValue={defaultValues.journalLink} className='h-28'
          label="Link of Journal" value={formData.journalLink}
          placeholder="https://journal.org/journalName"
          required={false}
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>
      </form >
    </div >
  );
};

export default EditJournalPage;

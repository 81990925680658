import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import mitLogo from '../assets/mit-logo.webp'
import ReactDOM from 'react-dom';
import { Download } from '@mui/icons-material';
import { useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import { formatDateString } from '../Functions/dateformatter/formatDateString';
import { formatName } from '../Functions/formatName';
import useMultiPDFPopup from '../hooks/useMultiPDFPopup';
import MultiplePDFPopup from '../components/popups/MultiPDFPopup';

const ReportTemplate = ({ id, userData, scoreData, citations, hIndex, statsData }) => {
  const fields = [
    { label: "Employee ID", data: userData?.employeeID || 'N/A', size: "col-span-4" },
    { label: "Phone No", data: userData?.phNo, size: "col-span-4 " },
    { label: "Email ID", data: userData?.email, size: "col-span-4 " },
    { label: "Date of Join", data: formatDateString(userData?.joiningDate), size: "col-span-4" },
    { label: "Experience", data: userData?.experience, size: "col-span-4" },
  ];

  const table = [
    [
      { title: 'Journal Papers', data: statsData?.publishedJ },
      { title: 'Book Chapters', data: statsData?.publishedBC },
      { title: 'Conference Papers', data: statsData?.publishedC },
      { title: 'Books', data: statsData?.book }
    ], [
      { title: 'Granted Tech Patents', data: statsData?.grantedP },
      { title: 'Published Tech Patents', data: statsData?.publishedP },
      { title: 'Filed Tech Patents', data: statsData?.filedP },
    ], [
      { title: 'Design Patents', data: statsData?.designPatent },
      { title: 'Copyrights', data: statsData?.copyright }
    ], [
      { title: 'Granted Consultancies Patents', data: statsData?.grantedConsultancy },
      { title: 'Granted Research & Development Projects', data: statsData?.grantedFP },
    ]
  ];

  const schools = JSON.parse(process.env.REACT_APP_SCHOOLS);
  const department = schools.find(school => school?.value === userData?.school)?.label;

  return (
    <div id='report-id' className='flex flex-col bg-white'>
      <section className="flex flex-col justify-between w-full h-[1123px] p-12 border-2">
        <div className='flex flex-col space-y-20'>
          <div className='flex flex-col items-center justify-center'>
            <img src={mitLogo} className='h-32 w-fit' alt="" />
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='flex mb-5'>
              <hr className='h-4 w-15 bg-green-600' />
              <hr className='h-4 w-15 bg-orange-600' />
            </div>

            <h2 className='h2-p text-5xl font-bold flex flex-col space-y-5'>
              <p>Faculty Scholarly</p>
              <p>Activity Report</p>
            </h2>
            <div className='pt-10'>
              <h5 className='h5-p text-xl text-purple5'>Dr. Vishwanath Karad MIT World Peace University </h5>
              <span className='text-s italic text-purple3'>2022-23</span>
            </div>
          </div>
        </div>

        <div className='flex flex-col pt-5 space-y-10 items-center justify-center'>
          <span className='text-s text-center' >
            Discover the remarkable achievements of
            <span className='text-p font-bold text-purple5'> {userData?.title}. {formatName(userData?.name)}</span> from
            <span className='text-p font-bold'> {department} </span>
            highlighting their impactful contributions to Academia.
          </span>

          <div className='flex flex-col space-y-4 items-center text-center my-10'>
            <p className='text-s text-xs text-purple3'>Powered By</p>
            <img src="/tracer.svg" className='h-10 w-fit' alt="" />
          </div>
        </div>
      </section>

      <section className="flex flex-col space-y-8 w-full h-[1140px] p-12">
        <div className='flex space-x-5 items-center bg-purple-50 px-3 pb-4'>
          <div className='flex items-center justify-center h-16 w-16 pt-4'>
            <img src="/profile.svg" className='h-16 w-16' alt="" />
          </div>
          <div className='flex flex-col space-y-1'>
            <p className='h5-p text-2xl'>{userData?.title} {formatName(userData?.name)}</p>
            <p className='text-s text-sm text-purple5'>{userData?.designation} | {department}</p>
          </div>
        </div>

        <div className='grid grid-cols-12 gap-5 p-3'>
          {fields.map((field, index) => (
            <div key={index} className={`flex flex-col space-y-0.5 ${field.size} w-full`}>
              <label className="text-s text-xs">{field.label}</label>
              <p className="text-p text-sm ">{field.data || 0}</p>
            </div>
          ))}
        </div>

        <div className='grid grid-cols-3 gap-5'>
          <div className='flex flex-col space-y-3'>
            <div className='flex flex-col space-y-0.5 w-full bg-purple-50 p-3'>
              <label className="h6-p">Total Citations</label>
              <p className="text-s">{citations || 0}</p>
            </div>

            <div className='flex flex-col space-y-0.5 w-full bg-purple-50 p-3'>
              <label className="h6-p">H-Index</label>
              <p className="text-s">{hIndex || 0}</p>
            </div>
          </div>

          <div className='flex flex-col space-y-3'>
            <div className='flex flex-col space-y-0.5 w-full bg-purple-50 p-3 pb-4'>
              <label className="h6-p">Departmental Rank</label>
              <p className="text-s">{scoreData?.departmentRank || 0}</p>
            </div>

            <div className='flex flex-col space-y-0.5 w-full bg-purple-50 px-3 pb-4'>
              <label className="h6-p">University Rank</label>
              <p className="text-s">{scoreData?.universityRank || 0}</p>
            </div>
          </div>

          <div className='flex flex-col space-y-2 items-center justify-center relative h-40'>
            <svg width="140" height="140" viewBox="0 0 160 160">
              <circle r="70" cx="80" cy="80" fill="transparent" stroke="#e0e0e0" stroke-width="12px"></circle>
              <circle r="70" cx="80" cy="80" fill="transparent" stroke='#4F38C9' stroke-linecap="round" stroke-width="12px"></circle>
            </svg>
            <h2 className='h2-p text-center text-4xl absolute bottom-[56%]'>{scoreData?.rating}</h2>
            <span className='text-p text-sm text-center'>Faculty Score</span>
          </div>
        </div>

        <div className='flex flex-col space-y-10 w-full'>
          {table.map((section, index) => (
            <div key={index} className={`grid grid-cols-${section.length} h6-s ${index % 2 === 0 ? 'bg-blue-50' : 'bg-purple-50'} pb-5 px-3`}>
              {section.map((tab, i) => (
                <div className='flex flex-col space-y-2'>
                  <label className='h6-p' key={i}>{tab.title} </label>
                  <p className="text-s" key={i}>{tab.data || 0} </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  )
};

const ReportCard = ({ id = '', userData, userDataLoading, scoreData, scoreDataLoading, citations, hIndex, googleScholarLoading }) => {
  const [downloading, setDownloading] = useState(false);
  const [popup, setPopup] = useState(false);
  const { isLoading: statsDataLoading, fetchData } = useFetchData();

  const generatePdf = async (reportType = null) => {
    setDownloading(true);
    const fetchedData = await fetchData(`/api/stats/facultyReport/getStats?id=${id}&year=${reportType}`);
    setDownloading(false);

    const element = document.createElement('div');
    document.body.appendChild(element);

    ReactDOM.render(
      <ReportTemplate id='report-id' userData={userData} scoreData={scoreData} citations={citations} hIndex={hIndex} statsData={fetchedData} />,
      element
    );

    const filename = reportType === 'total' ? 'Complete_Report_Tracer.pdf' : `Yearly_Report_Tracer_${reportType}.pdf`;

    await html2pdf().from(element).set({ filename }).save();
    document.body.removeChild(element);
  };

  const openPopup = () => setPopup(true)
  const closePopup = () => setPopup(false)

  const options = [
    { label: "Yearly Report", onClick: () => generatePdf() },
    { label: "Complete Report", onClick: () => generatePdf('total') },
  ];

  return (
    <>
      <button onClick={openPopup}>
        {(userDataLoading || scoreDataLoading || statsDataLoading) && downloading ? "Downloading your report..." :
          <div className='flex justify-between gap-3'> <Download /> Download Report </div>
        }
      </button >

      {popup && <MultiplePDFPopup buttons={options} heading='Report Type'
        subText='Which type of report you do like to download ?'
        icon='/icons/Download.svg' onClose={closePopup} />}
    </>
  );
}

export default ReportCard;
import React from 'react'
import { Table } from '../../../../mui/Table';
import useOperations from '../../../../hooks/useOperations';
import useMultiPDFPopup from '../../../../hooks/useMultiPDFPopup';
import PatentPopup from '../../../../components/popupDetailed/PatentPopup';
import MultiplePDFPopup from '../../../../components/popups/MultiPDFPopup';

const RecentPatent = ({ data, loading }) => {

  const buttons = [
    { label: "Application Receipt", cloudName: 'filed' },
    { label: "Published Certificate", cloudName: 'published' },
    { label: "Grant Certificate", cloudName: 'grant' },
  ];

  const columnsRecent = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'patentTitle', label: 'Title of Invention', minWidth: 230 },
    { id: 'formattedAuthors', label: 'Inventors', minWidth: 130 },
    { id: 'patentType', label: 'Patent Country' },
    { id: 'category', label: 'Category' },
    { id: 'tickIcons', label: 'Proofs [A  P  G]', minWidth: 130 },
    { id: 'statusIcon', label: 'Status', align: 'center', minWidth: 92 },
    { id: 'operation', label: '__', minWidth: 155 },
  ];

  const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Patent', 'patents', 'patents', buttons);
  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Patent');

  return (
    <>
      <Table
        heading={'Total Patents'}
        text={'Uploaded Patents'}
        columns={columnsRecent}
        data={data}
        multiUsers={'inventors'}
        statusDateCol={'lastVerified'}
        dateColumns={[{ 'formattedFiledDate': 'filedDate' }, { 'formattedPublishedDate': 'publishedDate' }, { 'formattedGrantedDate': 'grantedDate' }]}
        sortParameters={'formattedFiledDate'}
        operation={true}
        viewRequired={true}
        click={openViewDetails}

        tickFields={['filedDocument', 'publishedDocument', 'grantDocument']}
        loading={loading}
      />

      {isViewPopupOpen && <PatentPopup admin={true} onClose={closeViewDetails} data={popupData} viewPDF={() => openMultiPDFPopup(popupData, "view")} />
      }

      {isMultiPDFPopup?.open &&
        <MultiplePDFPopup
          heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
          icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
        />
      }

    </>
  )
}

export default RecentPatent;
import React from 'react'
import ClassificationTileSec from '../../../components/Tiles/ClassificationTileSec';
import AnalysisTile from '../../../components/Tiles/AnalysisTile';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import useGetData from '../../../hooks/useGetData';
import OverYearsTile from '../../../components/Tiles/OverYearsTile';

const ProjectsOverview = ({ year, years, currentYear, handleYearChange, id = null }) => {
    const { data: consultancyYear, isLoading: isLoadingCCY, } = useGetData(`/api/stats/consultancy/getStats?id=${id}&year=${year}`);
    const { data: consultancyAT, isLoading: isLoadingCTotal, } = useGetData(`/api/stats/consultancy/getStats?id=${id}&year=total`);

    const { data: fundedProjectYear, isLoading: isLoadingFPCY, } = useGetData(`/api/stats/fundedProject/getStats?id=${id}&year=${year}`);
    const { data: fundedProjectAT, isLoading: isLoadingFPTotal, } = useGetData(`/api/stats/fundedProject/getStats?id=${id}&year=total`);

    const { data: projects, isLoading: isLoadingP, } = useGetData(`/api/stats/project/getStatsOverYears?id=${id}`);

    return (
        <section className="flex flex-col w-full space-y-5 p-5 rounded-lg bg-white-sec">
            <div className="flex flex-col sm:flex-row items-center sm:justify-between col-span-4 space-y-3 sm:space-y-0">
                <h3 className="h2-p">Projects </h3>
                <FormControl className="sm:mr-4 w-full sm:w-36" color="primary" size="small" variant="outlined">
                    <InputLabel id="year-label" size="small">
                        Academic Year
                    </InputLabel>
                    <Select labelId="year-label" label="Academic Year" value={year} name="year" size="small" defaultValue={currentYear} onChange={handleYearChange}
                        MenuProps={{
                            PaperProps: { style: { maxHeight: "150px" } },
                        }}>
                        {years.map((year) => {
                            const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                            return (
                                <MenuItem key={formattedYear} value={year} size="small">
                                    {formattedYear}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Completed Consultancies"
                    value={consultancyAT?.completed}
                    icon={'/tabs/ConsultancyPurple.svg'}
                    message={'Verified & Completed Consultancies Projects'}
                />

                <AnalysisTile
                    heading={`Completed Consultancies in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={consultancyYear?.completed}
                    icon={'/tabs/ConsultancyPurple.svg'}
                />

                <div className='md:col-span-2 col-span-full'>
                    <ClassificationTileSec
                        heading="Total Consultancies summary"
                        h1='Government'
                        h2='Private'
                        v1={consultancyAT?.government}
                        v2={consultancyAT?.private}
                    />
                </div>
            </div>

            <div className="grid xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Research & Development projects"
                    value={fundedProjectAT?.completed}
                    icon={'/tabs/RnDPurple.svg'}
                    message={'Verified & Completed Research & Development Projects'}
                />

                <AnalysisTile
                    heading={`Research & Development projects in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={fundedProjectYear?.completed}
                    icon={'/tabs/RnDPurple.svg'}
                />
                <div className='md:col-span-2 col-span-full'>
                    <ClassificationTileSec
                        heading={'Total Research & Development projects summary'}
                        h1='Applied'
                        h2='Granted'
                        v1={fundedProjectAT?.applied}
                        v2={fundedProjectAT?.completed}
                    />
                </div>
            </div>

            <OverYearsTile
                heading={"Projects Completed & Granted Over Years"}
                data={projects?.projects} 
                message={'Completed & verified Consultancies | Granted & verified Research & Development Projects.'}/>
        </section >
    )
}

export default ProjectsOverview
import { useCallback, useEffect, useState } from "react";
import ReactCrop from 'react-easy-crop';

import { Button } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';

const ImageEditor = ({ file, onClose, storeBlob }) => {

    function getCroppedImg(imageUrl, crop) {
        return new Promise((resolve) => {
            const image = new Image();
            image.src = imageUrl;

            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = crop.width;
                canvas.height = crop.height;

                ctx.drawImage(
                    image,
                    crop.x,
                    crop.y,
                    crop.width,
                    crop.height,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                // Convert the canvas content to a Blob in JPEG format
                canvas.toBlob(
                    (blob) => {
                        // Convert the canvas content to a data URL (base64)
                        const dataUrl = canvas.toDataURL('image/jpeg');
                        resolve({ blob, dataUrl });
                    },
                    'image/jpeg',
                    1 // Quality (0 to 1)
                );
            };
        });
    }

    const [initialImage, setInitialImage] = useState(null);

    useEffect(() => {
        if (file && !initialImage) {
            const imageURL = URL.createObjectURL(file);
            if (imageURL) {
                setInitialImage(imageURL);
            }

        }
    }, [file]);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [completedCrop, setCompletedCrop] = useState(null);

    const onCropChange = (newCrop) => {
        setCrop(newCrop);
    };

    const onZoomChange = (newZoom) => {
        setZoom(newZoom);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCompletedCrop(croppedAreaPixels);
    };

    const getCroppedImage = async () => {
        if (completedCrop && initialImage) {
            const croppedImage = await getCroppedImg(initialImage, completedCrop);
            storeBlob(croppedImage.blob);
            onClose()
            return true;
        } else {
            return false
        }
    };

    return (
        <div className="bg-white relative flex flex-col justify-between rounded-2xl w-[600px] h-[590px] py-6 px-9">
            <div className="relative flex justify-between">
                <h2 className="text-2xl heading">Edit Image</h2>
                <button className="w-full h-full" onClick={onClose}>
                    <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
                </button>
            </div>

            <div className="flex justify-center ">
                {initialImage && (
                    <div className="relative w-full h-96">
                        <ReactCrop
                            image={initialImage}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={onCropChange}
                            onZoomChange={onZoomChange}
                            onCropComplete={onCropComplete}
                        // circularCrop={true}
                        />
                    </div>
                )}


            </div>

            <div className={`relative flex justify-center `}>
                <Button className="flex bg-[#010851] items-center rounded-lg w-56 h-12"
                    onClick={getCroppedImage}
                    variant="contained" type="button"
                    startIcon={<DoneAllIcon style={{ color: 'white' }} />}
                    style={{ backgroundColor: '#010851', color: 'white', borderRadius: 8 }}
                >Proceed</Button>
            </div>
        </div>
    );
};

export default ImageEditor;
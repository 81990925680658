import { useState, useEffect } from 'react';

const parseDateString = (dateString) => {
    const monthNames = [
        'jan', 'feb', 'mar', 'apr', 'may', 'jun',
        'jul', 'aug', 'sep', 'oct', 'nov', 'dec'
    ];

    const parts = dateString.split(' ');

    if (parts.length === 2) {
        const monthIndex = monthNames.findIndex(month => month.toLowerCase() === parts[0].toLowerCase());
        const year = parseInt(parts[1]);

        if (monthIndex !== -1 && !isNaN(year)) {
            return `${year}-${monthIndex + 1}`;
        }
    }

    return dateString;
};


const useSearchSort = (initialRows, sortParameters) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOption, setSortOption] = useState('none');
    const [currentRows, setCurrentRows] = useState(initialRows);

    useEffect(() => {
        const filteredRows = initialRows.filter((row) => {
            return Object.values(row).some((value) => {
                if (typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return true;
                }
                return false;
            });
        });

        const copyRows = [...filteredRows];

        if (sortOption === 'newest' && sortParameters) {
            copyRows.sort((a, b) => {
                const dateA = new Date(parseDateString(a[sortParameters])); 
                const dateB = new Date(parseDateString(b[sortParameters])); 
                return dateB - dateA;
            });
        } else if (sortOption === 'oldest' && sortParameters) {
            copyRows.sort((a, b) => {
                const dateA = new Date(parseDateString(a[sortParameters])); 
                const dateB = new Date(parseDateString(b[sortParameters])); 
                return dateA - dateB;
            });
        } else {
            copyRows.sort((a, b) => a.index - b.index);
        }

        setCurrentRows(copyRows);
    }, [searchQuery, sortOption, initialRows]);

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleSortChange = (option) => {
        setSortOption(option);
    };

    return {
        searchQuery,
        sortOption,
        currentRows,
        handleSearch,
        handleSortChange,
    };
};

export default useSearchSort;

import { useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";
import { CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import BlackToolTip from "../../../../buttons/BlackToolTip";

import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import useOperations from "../../../../hooks/useOperations";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import TabSmall from "../../../../mui/TabSmall";
import RecentBook from "./RecentBook";
import AVBook from "./AVBook";
import useGetData from "../../../../hooks/useGetData";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import ComingSoon from "../../../others/ComingSoon";
import DownloadPopup from "../../../../components/popups/DownloadPopup";

const BookAD = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/admin/PublicationAD?tab=Book+Chapter&type=Recent' },
    { name: 'Book', path: '/admin/PublicationAD?tab=Book+Chapter&type=Recent' }
  ]);

  const { data: bookDataAD, isLoading } = useGetData('/api/book/getBooks?school=total');
  const { data: bookStats, isLoading: tileLoading } = useGetData('/api/stats/book/getStats?school=total&year=total');
  const { data: bookStatsCY, isLoading: tileLoadingCY } = useGetData('/api/stats/book/getStats?school=total');

  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();
  const { getAdminExcelData, openViewDetails, closeViewDetails, isViewPopupOpen } = useOperations('Book', null, null, bookDataAD, ['authors']);

  const activeTab = searchParams.get('type');
  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];

  const buttonInfo = [
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: openViewDetails },
  ];

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid md:grid-cols-2 p-5 gap-5 w-full rounded-lg  bg-white-sec">
        <AnalysisTile
          heading="Total Published Book"
          value={bookStats?.total}
          icon={'/tabs/round/BookPurple.svg'}
          loading={tileLoading}
        />
        <AnalysisTile
          heading={`Published Book in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={bookStatsCY?.total}
          icon={'/tabs/round/BookPurple.svg'}
          loading={tileLoadingCY}
        />
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>

        {activeTab === "Recent" && <RecentBook data={bookDataAD} loading={isLoading} />}
        {/* {activeTab === "Abstract View" && <ComingSoon />} */}
        {activeTab === "Abstract View" && <AVBook />}
      </div>

      {isViewPopupOpen && <DownloadPopup onClose={closeViewDetails} onDownload={getAdminExcelData} />}
    </div>
  );
};

export default BookAD;

import useGetData from '../../../hooks/useGetData';
import { InfoOutlined, OpenInNewRounded } from '@mui/icons-material';
import { Fade, Skeleton, IconButton } from '@mui/material';
import TabHover from '../../../components/tabs/TabHover';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import RankPopup from '../../../components/popupDetailed/RankPopup';
import BlackToolTip from '../../../buttons/BlackToolTip';

const ScoreBoard = ({ id, scoreData, scoreDataLoading }) => {

    const [popup, setPopup] = useState(false);
    const togglePopup = () => setPopup(!popup);

    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('type');
    const type = activeTab === 'University Rank' ? 'university' : ''

    const { data: ranksData, isLoading: rankDataLoading } = useGetData(`/api/stats/facultyScore/getRankers?type=${type}&id=${id}&limit=5`);

    const currentRank = [
        { value: scoreData?.departmentRank || 0, label: 'Departmental Rank' },
        { value: scoreData?.universityRank || 0, label: 'University Rank' }
    ];

    const circumference = 2 * Math.PI * 70;
    const progress = scoreData?.rating / 10;
    const strokeDashOffset = circumference * (1 - progress);
    const strokeDashArray = circumference;

    const colors = ['#D5D0EF', '#B9B1E3', '#9283DB', '#7664D2', '#4F38C9'];
    const fillColor = colors[Math.max(Math.floor(scoreData?.rating / 2) - 1, 0)];

    return (
        <section className="grid grid-cols-8 bg-white drop-shadow-md border-0.5">
            <div className='col-span-full sm:col-span-3 xl:col-span-2 flex flex-col border-0.5 h-full'>
                <div className='flex flex-col p-5 h-full'>
                    <BlackToolTip className="cursor-pointer text-center" title={"Score considers a comprehensive view of faculty contribution."} TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }} arrow>
                        <InfoOutlined className="text-base text-slate-900" />
                    </BlackToolTip>

                    <div className='flex flex-col items-center justify-center relative h-40'>
                        <svg width="160" height="160" viewBox="0 0 160 160" style={{ transform: 'rotate(-90deg)' }}>
                            <circle r="70" cx="80" cy="80" fill="transparent" stroke="#e0e0e0" stroke-width="12px"></circle>
                            <circle r="70" cx="80" cy="80" fill="transparent" stroke={fillColor} stroke-linecap="round" stroke-width="12px" strokeDasharray={strokeDashArray} strokeDashoffset={strokeDashOffset}></circle>
                        </svg>
                        <h2 className='h2-p text-center text-4xl absolute'>{scoreData?.rating}</h2>
                        <span className='absolute mt-14 text-s text-xs font-semibold text-purple5 text-center w-24'>{scoreData?.grade}</span>
                    </div>
                    <h6 className='h6-p font-semibold text-center mt-2'>Research Score</h6>
                </div>

                {currentRank.map((rank, index) => (
                    <div key={index} className='border-y-0.5 py-3 px-5'>
                        {scoreDataLoading ? <>
                            <Skeleton className='h2-p w-1/6'></Skeleton>
                            <Skeleton className='h6-s'></Skeleton>
                        </> : <>
                            <h2 className='h2-p w-1/6'>{rank.value}</h2>
                            <span className='h6-s'>{rank.label}</span>
                        </>}
                    </div>
                ))}

            </div>

            <div className='col-span-full sm:col-span-5 xl:col-span-6 border-0.5 gap-2'>
                <div className='flex sm:flex-row flex-col sm:justify-between sm:items-center sm:pr-5 gap-3'>
                    <TabHover tabs={['Departmental Rank', 'University Rank']} />
                    <div onClick={togglePopup} className='flex gap-3 items-center justify-center rounded-md cursor-pointer sm:p-0 p-1.5 sm:m-0 m-3 sm:bg-transparent sm:hover:bg-transparent bg-purple5 hover:bg-purple5'>
                        <span className='sm:hidden text-white'>View All</span>
                        <IconButton size='small' aria-label="view_all_button" >
                            <OpenInNewRounded fontSize='small' className='sm:text-purple5 text-white' />
                        </IconButton>
                    </div>
                </div>

                <div className='flex flex-col gap-y-2.5 p-3 sm:p-5'>
                    {rankDataLoading ?
                        Array.from({ length: 5 }).map((_, index) => (
                            <div key={index} className={`grid grid-cols-24 gap-3 place-items-center py-2 px-3 ${index % 2 === 0 ? 'bg-purple-50' : ''}`}>
                                <p className='col-span-1 text-p'>-</p>
                                <div className='col-span-3 lg:col-span-2'>
                                    <Skeleton variant="circular" animation='wave' className='w-10 h-10' />
                                </div>

                                <div className='col-span-14 lg:col-span-15 flex flex-col space-y-0.5 w-full'>
                                    <Skeleton animation='wave' className='text-p w-2/3' />
                                    <Skeleton animation='wave' className='text-s text-xs w-1/3' />
                                </div>

                                <Skeleton animation='wave' className='col-span-3 text-p w-2/3' />
                                <Skeleton animation='wave' className='col-span-3 text-p w-1/3' />
                            </div>
                        )) :
                        ranksData && ranksData.ranks?.map((item, index) => (
                            <div key={index} className={`grid grid-cols-24 gap-3 place-items-center py-2 px-3 ${item._id === ranksData.id ? 'bg-purple1' : index % 2 === 0 ? 'bg-purple-50' : ''}`}>
                                <p className='col-span-1 text-p '>{index + 1}.</p>
                                <div className='col-span-3 lg:col-span-2'>
                                    <img src="/profile.svg" alt="image" className='w-10 h-10' />
                                </div>
                                <div className='col-span-14 lg:col-span-15 flex flex-col space-y-0.5 w-full'>
                                    <span className='text-p truncate'>{item.name}</span>
                                    <span className='text-s text-xs'>{item.school}</span>
                                </div>
                                <p className='col-span-3 text-p'>{item.score}</p>
                                <p className='col-span-3 text-p'>{item.rating}</p>
                            </div>
                        ))}
                </div>
            </div>

            {popup && <RankPopup id={id} type={type} onClose={togglePopup} />}
        </section>
    )
}

export default ScoreBoard
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSearchParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function TabSmall({ tabs, className, onClick }) {
    const isSmallScreen = useMediaQuery('(min-width:550px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('type')

    const changeTab = (event, newValue) => {
        const value = tabs[newValue].label;
        onClick && onClick(value);
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', value);
            return Object.fromEntries(newParams.entries());
        });
    };

    return (
        <div className={`xs:w-96 sm:w-fit bg-white rounded-lg ${className}`} >
            <Tabs
                className='h-14 flex justify-center items-center'
                value={tabs[activeTab]?.label}
                onChange={changeTab}
                variant="scrollable"
            >
                {tabs && tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon}
                        iconPosition={isSmallScreen ? 'start' : 'top'}
                        disableRipple
                        className=' text-base font-[Poppins] leading-6 h-8'
                        sx={{
                            color: activeTab === tab.label ? '#020617' : '#6b7280',
                            fontWeight: activeTab === tab.label ? 'bold' : 'normal',
                            textTransform: "none"
                        }}
                    />
                ))}
            </Tabs>
        </div>
    );
}

import { useState, useEffect } from "react";
import Button from '@mui/material/Button';

const Scopus = ({ title, startDate, lastCitedYear, citeScore, snip, sjr, issn, type, link, coverageInfo, loading }) => {
    useEffect(() => {

    }, []);
    return (
        <>

            <div className="text-2xl heading col-span-full">
                Data from Scopus
            </div>

            <div className="flex flex-col col-span-full">
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    Title Of Journal
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {title}
                </p>
            </div>

            <div className={type == "Scopus" ? "flex flex-col col-span-5" : "flex flex-col col-span-5"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    Publication Start Year
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {startDate}
                </p>
            </div>

            <div className={type == "Scopus" ? "flex flex-col col-span-4" : "flex flex-col col-span-4"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    Last Cited Year
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {lastCitedYear || 'Not Cited in Current Year (Journal might be Discontinued)'}
                </p>
            </div>

            <div className={type == "Scopus" ? "flex flex-col col-span-3" : "flex flex-col col-span-3"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    SJR
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {sjr}
                </p>
            </div>

            <div className={type == "Scopus" ? "flex flex-col col-span-5" : "flex flex-col col-span-5"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    ISSN
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {issn}
                </p>
            </div>

            <div className={type == "Scopus" ? "flex flex-col col-span-4" : "flex flex-col col-span-4"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    CiteScore
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {citeScore}
                </p>
            </div>
            <div className={type == "Scopus" ? "flex flex-col col-span-3" : "flex flex-col col-span-3"}>
                <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                    SNIP
                </label>
                <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                    {snip}
                </p>
            </div>

            <div className="flex flex-col col-span-full">
                <Button size="large" variant="outlined" color="error" className="px-8"
                    onClick={() => {
                        window.open(link, '_blank');
                    }}
                >View on Scopus</Button>

            </div>

            <div className="flex flex-col col-span-full">
                <label className="text-[#ff0066] text-[14px] font-medium font-[Poppins] cursor-pointer">
                    Scopus Coverage Year
                </label>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                        {coverageInfo}
                    </p>
                )}
            </div>

        </>
    );
};

export default Scopus;
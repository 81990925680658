import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../../../../components/popups/PortalPopup";
import UserContext from "../../../../context/UserContext";
import EventAttendedPopup from "../../../../components/popupDetailed/EventAttendedPopup";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import usePDFPopup from "../../../../hooks/usePDFPopup";
import ConductedTile from "../../../../components/Tiles/ConductedTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";

const Attended = () => {
  useSetBreadcrumb([{ name: 'Event Attended', path: '/user/events?tab=Attended' }]);
  const currentYear = useAcademicYear();
  const navigate = useNavigate();
  const { deleteEventAttended } = useContext(UserContext);
  const { data: eventAttendedData, isLoading, refreshData } = useGetData("/api/eventAttended/getEventsAttended");
  const { data: tileData, isLoading: tileLoading, refreshData: tileRefresh } = useGetData(`/api/stats/eventAttended/getStats?year=total`);
  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('EventAttended', deleteEventAttended, refreshData, eventAttendedData);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'guideName', label: 'Guides Name', minWidth: 130 },
    { id: 'eventTitle', label: 'Event Title', minWidth: 230 },
    { id: 'eventCategory', label: 'Event Category' },
    { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 100 },
    { id: 'formattedEndingDate', label: 'Ending Date', minWidth: 100, align: 'center' },
    { id: 'statusIcon', label: 'Document' },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/events/attended/addAttended");
  }, [navigate]);

  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('eventAttended', 'events?tab=Attended', 'single');

  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-5 gap-5 rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Events Attended"
          value={tileData?.eventAttended}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <AnalysisTile
          heading={`Events Attended  in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={tileData?.eventAttendedCY}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <div className="col-span-full md:col-span-2">
          <ClassificationTileSec
            heading={`Summary of Events Attended in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
            h1={'International'}
            h2={'National'}
            h3={'State'}
            v1={tileData?.international}
            v2={tileData?.national}
            v3={tileData?.state}
            loading={tileLoading}
          />
        </div>

        <div className="col-span-full">
          <ConductedTile heading='Event Categories' data={tileData} />
        </div>
      </div>

      <div className=" flex flex-col py-5 sm:flex-row w-full sm:space-y-0 space-y-5 sm:space-x-10 justify-center items-center bg-white-sec rounded-lg ">
        <UploadButton newText='Upload Attended Event Data' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Attended Events Data' />
      </div >

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Events Attended'}
          text={'Uploaded Events Attended'}
          columns={columns}
          data={eventAttendedData}
          dateColumns={[{ 'formattedStartingDate': 'startingDate' }, { 'formattedEndingDate': 'endingDate' }]}
          sortParameters={'formattedStartingDate'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          document={'document'}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen && (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails}>
          <EventAttendedPopup onClose={closeViewDetails} data={popupData}
            uploadPDF={uploadPDF} viewPDF={viewPDF} deletePDF={deletePDF} />
        </PortalPopup>
      )}

    </div>
  );
};

export default Attended;

/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useOperations from "../../../../hooks/useOperations";
import useGetData from "../../../../hooks/useGetData";
import useMultiPDFPopup from "../../../../hooks/useMultiPDFPopup";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import PatentPopup from "../../../../components/popupDetailed/PatentPopup";
import QuartileTile from "../../../../components/Tiles/QuartileTile";
import { Table } from "../../../../mui/Table";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import PopupDeleteSp from "../PopupDeleteSp";
import MultiplePDFPopup from "../../../../components/popups/MultiPDFPopup";
import { useDeleteData } from "../../../../hooks/useDeleteData";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const Patent = () => {

  useSetBreadcrumb([{ name: 'Patents', path: '/user/patents' }]);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'patentTitle', label: 'Title of Invention', minWidth: 230 },
    { id: 'formattedAuthors', label: 'Inventors', minWidth: 130 },
    { id: 'patentType', label: 'Applied Country' },
    { id: 'category', label: 'Category' },
    { id: 'tickIcons', label: 'Proofs [A  P  G]', minWidth: 130 },
    { id: 'statusIcon', label: 'Status', align: 'center', minWidth: 92 },
    { id: 'operation', label: '___', minWidth: 155, align: "center" },
  ];

  const navigate = useNavigate();
  const { handleSnackbarOpen } = useSnackbar();
  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/patents/addPatent?type=Applied through College");
  }, [navigate]);

  const { data: patentsData, isLoading, refreshData } = useGetData('/api/patent/getPatents');
  const { data: patentCount } = useGetData('/api/stats/patent/getStats?year=total');

  const buttons = [
    { label: "Application Receipt", cloudName: 'filed' },
    {
      label: "Published Certificate", cloudName: 'published'
      // , disabled: !['Published', 'Granted'].includes(popupData?.category)
    },
    {
      label: "Grant Certificate", cloudName: 'grant'
      // , disabled: popupData?.category !== 'Granted' 
    },
  ];

  const { closeViewDetails, isViewPopupOpen, popupData, onDownload, openViewDetails } = useOperations('Patent', undefined, refreshData, patentsData, ['applicants', 'inventors'], ['filedDate', 'publishedDate', 'grantedDate']);
  const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Patent', 'patents', 'patents?tab=Patent', buttons);

  const [deletePopup, setDeletePopup] = useState(false)
  const [patentData, setPatentData] = useState({})
  const openDeletePopup = (data) => {
    setDeletePopup(true);
    setPatentData(data);
  }


  const closeDeletePopup = () => { setDeletePopup(false); }

  const onDelete = async (applicant, inventor) => {
    const { error, message } = await useDeleteData(`/api/patent/deletePatent/${patentData._id}?applicant=${applicant}&inventor=${inventor}`);
    if (message) return handleSnackbarOpen(message, 'error', 'top', 'center', 'delete');
    if (error) return handleSnackbarOpen(`Opps ! Error occured while removing patent.`, 'error', 'top', 'center', 'delete');

    handleSnackbarOpen(`Patent successfully removed, as requested.`, 'warning', 'top', 'center', 'delete');
    closeDeletePopup();
    refreshData();
  }

  return (
    <div className="flex flex-col w-full space-y-5">
      <div className="grid lg:grid-cols-2 gap-5 p-5 rounded-lg bg-white-sec">
        <QuartileTile
          heading="Total Summary of Granted Patents"
          h1='India' h2='South Africa' h3='United Kingdom' h4='United States' h5='Australia'
          lb1='IND' lb2='SA' lb3='UK' lb4='US' lb5='AUS'
          v1={patentCount?.india}
          v2={patentCount?.southAfrica}
          v3={patentCount?.uk}
          v4={patentCount?.us}
          v5={patentCount?.australia}
        />
        <QuartileTile
          heading='Total Summary of Patents'
          h1='Granted' h2='Published' h3='Filed'
          lb1='G' lb2='P' lb3='F'
          v1={patentCount?.granted}
          v2={patentCount?.published}
          v3={patentCount?.filed}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-full py-5 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec ">
        <UploadButton text='Patent' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Patents' />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Patents'}
          text={'Uploaded Patents'}
          columns={columns}
          data={patentsData}
          dateColumns={[{ 'formattedFiledDate': 'filedDate' }, { 'formattedPublishedDate': 'publishedDate' }, { 'formattedGrantedDate': 'grantedDate' }]}
          sortParameters={'formattedFiledDate'}
          multiUsers={'inventors'}
          statusDateCol={'lastVerified'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={openDeletePopup}
          tickFields={['filedDocument', 'publishedDocument', 'grantDocument',]}
          onUpload={(data) => openMultiPDFPopup(data, "upload")}
          loading={isLoading}
        />
      </div>

      {deletePopup && (
        <PopupDeleteSp
          heading="Delete Patent"
          subText="What do you want to delete yourself as ? "
          icon={'/icons/Delete.svg'}
          onConfirm={onDelete}
          data={patentData}
          onClose={closeDeletePopup}
        />
      )}


      {isViewPopupOpen &&
        <PatentPopup onClose={closeViewDetails} data={popupData}
          viewPDF={() => openMultiPDFPopup(popupData, "view")}
          uploadPDF={() => openMultiPDFPopup(popupData, "upload")}
          deletePDF={() => openMultiPDFPopup(popupData, "delete")}
        />
      }

      {isMultiPDFPopup?.open &&
        <MultiplePDFPopup
          heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
          icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
        />
      }
    </div >
  );
};

export default Patent;

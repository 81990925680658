import Dashboard from "./Dashboard";
import { useRecoilState } from "recoil";
import { profilePhotoState } from "../../../recoil/state";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";

const Home = () => {
  useSetBreadcrumb([{ name: 'DashBoard', path: '/user/home' }]);
  const [profilePhoto, setProfilePhoto] = useRecoilState(profilePhotoState)
  return <Dashboard profilePhoto={profilePhoto} />;
};

export default Home;
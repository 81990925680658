import { useSearchParams } from "react-router-dom";

import ChangePassword from './settings/ChangePassword';
import EditProfile from "./settings/EditProfile";
import ChangeDetails from "./settings/ChangeDetails";

import MainFrameIcon from "../../../components/MainFrameIcon";
import TabSmall from "../../../mui/TabSmall";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LinkIcon from '@mui/icons-material/Link';

const Settings = () => {
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('type')

  const tabs = [
    { label: 'Edit Profile', icon: <ManageAccountsIcon /> },
    { label: 'Change Password', icon: <PublishedWithChangesIcon /> },
    { label: 'Update Links', icon: <LinkIcon /> }
  ];
  
  
  return (
    <div className="flex flex-col w-full">
      <MainFrameIcon />
      <div className="relative mt-10 sm:mt-0 sm:-top-10 rounded-xl bg-white-sec w-full sm:w-[93%] mx-auto drop-shadow-xl">
        <div className="w-full flex mt-8 justify-center items-center" >
          <TabSmall tabs={tabs} />
        </div>
        {activeTab === "Edit Profile" ? <EditProfile /> : null}
        {activeTab === "Change Password" ? <ChangePassword /> : null}
        {activeTab === "Update Links" ? <ChangeDetails /> : null}
      </div>
    </div>
  );
};  

export default Settings;

/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { profilePhotoState } from '../../../recoil/state';
import { usePostData } from "../../../hooks/usePostData";

import { Skeleton } from "@mui/material";
import { formatName } from "../../../Functions/formatName";

const UserPhoto = ({ loading, userData, open }) => {
    const [imageLoading, setImageLoading] = useState(false);
    const [profilePhoto, setProfilePhoto] = useRecoilState(profilePhotoState);
    // const [isMiniNavbar, setIsMiniNavbar] = useRecoilState(sideDrawerState);
    // const toggleNavbar = () => setIsMiniNavbar(!isMiniNavbar);
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const fetchProfilePhoto = async () => {
            setImageLoading(true);
            try {
                const { data: photo } = await usePostData(`/cloud/getViewURL`, { folder: 'profile' });
                setProfilePhoto(photo);
            } catch (error) {
                console.error("Error fetching profile photo:", error);
            } finally {
                setImageLoading(false);
            }
        };

        // if (!profilePhoto) 
        fetchProfilePhoto();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <div className={`grid ${open ? 'grid-cols-32' : 'grid-cols-1'} gap-2 w-full h-20 bg-blue-Sec p-2 transition-transform transform duration-300`}>
            <div className="col-span-8 flex items-center justify-center min-w-fit">
                {imageLoading ?
                    <Skeleton variant="circular" className="w-12 h-12 bg-[#2d2f3b]" />
                    :
                    <img
                        className='rounded-full w-12 h-12'
                        src={profilePhoto || "/profile.svg"}
                        alt={'Profile'}
                        onError={(event) => {
                            event.target.src = "/profile.svg";
                            event.target.alt = "profileImage";
                        }}
                    />
                }
            </div>
            {open &&
                <div className="col-span-24 flex flex-col justify-center space-y-0.5 transition-transform transform duration-300 line-clamp-1">
                    {loading ? (
                        <>
                            <Skeleton animation="wave" className="bg-[#2d2f3b] w-full text-p" />
                            <Skeleton animation="wave" className="bg-[#2d2f3b] w-full text-xs" />
                        </>
                    ) : (
                        <>
                            <p className="text-p text-white line-clamp-1 transition-transform transform duration-300">{formatName(userData?.name)}</p>
                            <p className='text-s text-xs truncate transition-transform transform duration-300'>{userData?.email}</p>
                        </>
                    )}
                </div>
            }
        </div>
    );
};

export default UserPhoto;

import { Table } from '../../../mui/Table';
import usePDFPopup from '../../../hooks/usePDFPopup';
import useOperations from '../../../hooks/useOperations';
import CopyrightPopup from '../../../components/popupDetailed/CopyrightPopup';

const RecentCopyrights = ({ data, loading }) => {
    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Copyright');

    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'copyrightTitle', label: 'Title of Invention', minWidth: 230 },
        { id: 'formattedAuthors', label: 'Applicants', minWidth: 130 },
        { id: 'copyrightType', label: 'Applied Country' },
        { id: 'formattedRegistrationDate', label: 'Registration Date' },
        { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
        { id: "operation", label: "___", minWidth: 155, align: "center" },
    ];

    const { handleViewPDF } = usePDFPopup('copyrights');
    const viewPDF = () => { handleViewPDF(popupData._id) };

    return (
        <>
            <Table
                heading={'Total Copyright'}
                text={'Uploaded Copyright'}
                columns={columns}
                data={data}
                dateColumns={[{ 'formattedRegistrationDate': 'registrationDate' }]}
                sortParameters={'formattedRegistrationDate'}
                multiUsers={'applicants'}
                statusDateCol={'lastVerified'}
                operation={true}
                deleteRequired={false}
                uploadRequired={false}
                viewRequired={true}
                click={openViewDetails}
                loading={loading}
                document={'document'}
            />

            {isViewPopupOpen &&
                <CopyrightPopup onClose={closeViewDetails} data={popupData} viewPDF={viewPDF} admin={true} />
            }
        </>
    )
}

export default RecentCopyrights
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Scopus from '../components/popupDetailed/Scopus';
import Scimago from '../components/popupDetailed/Scimago';

const host = process.env.REACT_APP_HOST;


const JournalImage = (props) => {
    const token = sessionStorage.getItem('token');


    const [imageUrl, setImageUrl] = useState('');
    const [scimagoLink, setScimagoLink] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [scopusData, setScopusData] = useState(false);


    const { journalIssn, quartileProvider, doi } = props;

    useEffect(() => {
        let isMounted = true;

        const fetchData = async (retryCount = 0) => {
            try {
                if (quartileProvider === "Scopus") {
                    const url = `${host}/webCrawling/scopusJournal?q=${journalIssn}`;

                    const response = await axios.get(url);

                    if (response.status === 200) {
                        const res = response.data.data;
                        setScopusData(res[Object.keys(res)[0]])
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }

                } else if (quartileProvider === "JCR") {
                    const url = `${host}/webCrawling/scimagoJournal/?q=${journalIssn}`;
                    const response = await axios.get(url);
                    const resp = response?.data
                    const filteredImages = resp?.filteredImages;
                    const scimagoLink = resp?.link;


                    if (filteredImages.length > 0 && isMounted) {
                        setImageUrl(filteredImages[0]);
                        setScimagoLink(scimagoLink)
                        setIsLoading(false);
                    }
                }
            } catch (error) {
                if (retryCount < 3) {
                    fetchData(retryCount + 1);
                } else {
                    // Max retries reached, handle the error
                    setIsLoading(false);
                    console.error(`Max retries reached. Unable to fetch data: ${error.message}`);
                }
            }
        };

        const timeout = setTimeout(() => {
            setIsLoading(false);
        }, 30000);

        fetchData();

        return () => {
            isMounted = false;
            clearTimeout(timeout);
        };
    }, [journalIssn, quartileProvider, doi]);

    const [title, setTitle] = useState('');
    const [issn, setIssn] = useState('');
    const [startDate, setStartDate] = useState('');
    const [lastCitedYear, setLastCitedYear] = useState('');
    const [link, setLink] = useState('');
    const [snip, setSnip] = useState('');
    const [sjr, setSjr] = useState('');
    const [citeScore, setCiteScore] = useState('');
    const [extra, setExtra] = useState('');

    useEffect(() => {
        if (scopusData) {
            const title = scopusData?.entry[0]['dc:title'];

            const startDate = scopusData?.entry[0]['coverageStartingYear'];
            const lastCitedYear = scopusData?.entry[0]['citeScoreYearInfoList']['citeScoreTrackerYear'];
            const issn = scopusData?.entry[0]['prism:issn'];
            const link = scopusData?.entry[0]['link'][0]['@href'];

            const sjr = scopusData?.entry[0]?.['SJRList']?.['SJR']?.[0]?.['$']
            const snip = scopusData?.entry[0]['SNIPList']?.['SNIP']?.[0]?.['$']
            const citeScore = scopusData?.entry[0]['citeScoreYearInfoList']['citeScoreTracker']


            setTitle(title);
            setIssn(issn);
            setStartDate(startDate);
            setLastCitedYear(lastCitedYear)
            setLink(link)
            setSjr(sjr)
            setSnip(snip)
            setCiteScore(citeScore)

        }
    }, [scopusData]);



    const [loading, setLoading] = useState(true);
    const [coverageInfo, setCoverageInfo] = useState('');
    useEffect(() => {
        async function fetchScopusCoverage() {
            try {
                // const crawlerHost = 'http://125.21.15.44:4000';
                const url = `${host}/webCrawling/scopusCoverage?url=${link}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                };
                const response = await fetch(url, {
                    method: 'GET', headers
                });

                const data = await response.text();
                setCoverageInfo(data);
            } catch (error) {


                setCoverageInfo('An error occurred while fetching Scopus coverage.');
            } finally {

                setLoading(false);
            }
        }
        if (link) {
            fetchScopusCoverage();
        }
    }, [link]);

    return (
        <>
            {isLoading ? (
                <p className="text-blue-800 text-sm font-light w-48">Loading...</p>

            ) : quartileProvider === "JCR" && imageUrl ? (
                <Scimago url={imageUrl} link={scimagoLink} />

            ) : quartileProvider === "Scopus" && scopusData ? (

                <Scopus
                    title={title} startDate={startDate} citeScore={citeScore}
                    lastCitedYear={lastCitedYear}
                    snip={snip} sjr={sjr} issn={issn} link={link}
                    coverageInfo={coverageInfo} loading={loading}
                />

            ) : (
                <p className="text-red-500 text-base w-72">Can't fetch the Data from the Provider.</p>
            )}
        </>
    );
};

export default JournalImage;

import { useSearchParams } from "react-router-dom";
import ConductedAD from "./conducted/ConductedAD";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";
import AttendedAD from "./attended/AttendedAD";
const EventsAD = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <div className="flex flex-col w-full">
      <ThreeWayTab
        btn1={"Events Conducted"} btn2={"Events Attended"}
        icon1={<img src='/tabs/Conducted.svg' />} icon2={<img src='/tabs/Attended.svg' />}
        iconDisabled1={<img src='/tabs/ConductedGrey.svg' />} iconDisabled2={<img src='/tabs/AttendedGrey.svg' />}
      />

      {tab === "Events Conducted" ? <ConductedAD /> : null}
      {tab === "Events Attended" ? <AttendedAD /> : null}
    </div>
  );
};

export default EventsAD;

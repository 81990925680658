import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'

const PopupExtra = ({ buttonOptions, handleReason }) => {
  const { heading, subText, buttons } = buttonOptions;
  return (
    <div className="flex flex-col p-5 space-y-10 w-full sm:w-fit bg-white rounded-lg">
      <div className="flex sm:w-[32rem] space-x-5">
        <img className="col-span-2 w-12 h-12" alt="" loading="eager" src={buttonOptions.icon} />
        <div>
          <h2 className="h5-p">{heading}</h2>
          <p className="text-s">{subText}</p>
        </div>
      </div>

      <div className="flex justify-between">
        {buttons.map((button, index) => (
          <Button
            key={index}
            size="large"
            variant={button.variant}
            color={button.color}
            type={button.type}
            onClick={button.onClick}
            className={button.className}
          >
            {button.label}
          </Button>
        ))}
      </div>
      <TextField className='h-full w-full'
        name='reason'
        label="Reason for Rejection"
        placeholder='Reason for Rejection'
        rows={3} multiline
        onChange={handleReason}
      />

    </div>
  );
};

export default PopupExtra;

import React from "react";
import { useNavigate } from "react-router-dom";

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PopupRegular from "../../../components/popups/PopupRegular";
import PortalPopup from "../../../components/popups/PortalPopup";
import AnalysisTile from "../../../components/Tiles/AnalysisTile";

import { Table } from "../../../mui/Table";
import useGetData from "../../../hooks/useGetData";
import useOperations from "../../../hooks/useOperations";
import { UploadButton } from "../../../mui/Button";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import { useDeleteData } from "../../../hooks/useDeleteData";
import { useSnackbar } from "../../../hooks/useSnackbar";
import usePopup from "../../../hooks/usePopup";

const FacultyAD = () => {
  useSetBreadcrumb([{ name: 'Faculties', path: '/admin/facultyAD' }]);

  const navigate = useNavigate();
  const { openPopup } = usePopup();
  const { handleSnackbarOpen } = useSnackbar();
  const { data: facultiesData, isLoading, refreshData } = useGetData('/api/user/getUsers?school=total');

  const DeleteUser = async (id) => {
    const { success, error, message } = await useDeleteData(`/api/user/deleteUser/${id}`);
    if (message) return handleSnackbarOpen(message, 'error', 'top', 'center', 'delete');
    if (error) return handleSnackbarOpen(`Opps ! Error occured while removing user.`, 'error', 'top', 'center', 'delete');

    handleSnackbarOpen(`User successfully removed, as requested.`, 'warning', 'top', 'center', 'delete');
    refreshData();
  }

  const handleViewFaculty = () => navigate(`/admin/facultyAD/Dashboard?id=${popupData._id}&tab=Publication&type=Departmental+Rank`);
  const navigateAddFaculty = () => navigate("/admin/facultyAD/addFaculty");
  const navigateEditFaculty = (data) => openPopup('Edit Faculty Details',
    'Do you really want to edit this faculty Details ?', 'edit',
    () => navigate(`/admin/facultyAD/editFaculty?id=${data._id}`))


  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, openViewDetails } = useOperations('Faculty', DeleteUser, refreshData, facultiesData);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'completeName', label: 'Faculty Name', minWidth: 190 },
    { id: 'designation', label: 'Designation', minWidth: 120 },
    { id: 'email', label: 'Official Mail ID', minWidth: 120 },
    { id: 'experience', label: 'Experience', minWidth: 80 },
    { id: 'active', label: 'Active', minWidth: 80 },
    { id: 'operation', label: 'Operations', minWidth: 50, align: 'center' }
  ];

  const totalFaculty = facultiesData?.length;
  const associate = facultiesData?.length > 0 ? facultiesData.filter((row) => row.designation === "Associate Professor").length : 0;
  const professor = facultiesData?.length > 0 ? facultiesData.filter((row) => row.designation === "Professor").length : 0;
  const assistant = facultiesData?.length > 0 ? facultiesData.filter((row) => row.designation === "Assistant Professor").length : 0;


  return (
    <div className="flex flex-col space-y-5 w-full">
      <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-5 rounded-lg p-5 bg-white-sec">
        <AnalysisTile
          heading="Total Faculties"
          value={totalFaculty}
          icon={'/icons/SuccessPurple.svg'}
        />

        <AnalysisTile
          heading={'Professor'}
          value={professor}
          icon={'/facultyIcons/Prof.svg'}
        />

        <AnalysisTile
          heading={'Associate Professor'}
          value={associate}
          icon={'/facultyIcons/AssociateProf.svg'}
        />

        <AnalysisTile
          heading={'Assistant Professor'}
          value={assistant}
          icon={'/facultyIcons/AssistantProf.svg'}
        />

      </div >

      <div className=" flex flex-col py-6 sm:flex-row  w-full sm:space-y-0 space-y-5 sm:space-x-10 justify-center items-center bg-white-sec rounded-lg ">
        <UploadButton newText={'Add New Faculty'} onClick={navigateAddFaculty} startIcon={<PersonAddIcon style={{ color: "white" }} />} />
        <UploadButton newText={'Upload Faculty Sheet'} />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'All Faculty Members'}
          text={'Faculty Members of School of Engineering'}
          columns={columns}
          data={facultiesData}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          loading={isLoading}
          onView={openViewDetails}
          actionRequired={true}
          modifyPermissions={navigateEditFaculty}
        />
      </div>

      {isViewPopupOpen &&
        <PortalPopup overlayColor="rgba(0,0,0,0.5)" placement="Centered" onOutsideClick={closeViewDetails}        >
          <PopupRegular onClose={closeViewDetails} heading={"View Faculty"} subText={"Do you want to view this Faculty ?"} icon={"/icons/SuccessPurple.svg"} onConfirm={handleViewFaculty} />
        </PortalPopup>
      }

    </div>
  );
};

export default FacultyAD;

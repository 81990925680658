import { Table } from '../../../mui/Table';
import ImagePopup from '../../../components/ImagePopup';
import useImagePopup from '../../../hooks/useImagePopup';
import AwardPopup from '../../../components/popupDetailed/AwardPopup';
import useOperations from '../../../hooks/useOperations';

const RecentAwards = ({ data, loading }) => {
    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'awardeeName', label: 'Awardee', minWidth: 130 },
        { id: 'awardName', label: 'Award Name', minWidth: 180 },
        { id: 'awardCategory', label: 'Category', minWidth: 90 },
        { id: 'awardType', label: 'Type' },
        { id: 'formattedDate', label: 'Date of Award' },
        { id: "statusIcon", label: "Upload", minWidth: 100, align: "center" },
        { id: 'operations', label: '__', minWidth: 155 },
    ];

    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Award', null, null, data,);
    const { imagePopup, imagesURL, openImagePopup, closeImagePopup } = useImagePopup('awards', 'awards');
    const viewPhoto = () => { openImagePopup(popupData._id); };

    return (
        <>
            <Table
                heading={'Awards'}
                text={'Uploaded awards'}
                columns={columns}
                data={data}
                yearMonthColumns={[{ 'formattedDate': ['awardMonth', 'awardYear'] }]}
                sortParameters={'formattedDate'}
                operation={true}
                deleteRequired={false}
                uploadRequired={false}
                viewRequired={true}
                click={openViewDetails}
                documentType='image'
                loading={loading}
            />

            {imagePopup && <ImagePopup urls={imagesURL} onClose={closeImagePopup} admin={true} />}
            {isViewPopupOpen && (
                <AwardPopup onClose={closeViewDetails} data={popupData} viewPhoto={viewPhoto} admin={true} />
            )}
        </>
    )
}

export default RecentAwards
import React from "react";
import PieChartComponent from "../../graphs/PieChartComponent";
import { Fade } from '@mui/material'
import { InfoOutlined } from "@mui/icons-material";
import BlackToolTip from "../../buttons/BlackToolTip";

const ClassificationTileSec = ({ h1, h2, h3, v1 = 0, v2 = 0, v3 = 0, heading = 'Classification', loading, message }) => {
    const data = [
        { label: h1, value: v1, color: 'bg-purple5' },
        { label: h2, value: v2, color: 'bg-purple3' },
        { label: h3, value: v3, color: 'bg-purple2' },
    ];

    const renderData = data.map((item, index) => (
        <React.Fragment key={index}>
            <p className="flex items-center px-3 col-span-25 text-p">
                {item.label && <div className={`rounded-full w-3 h-3 mx-3 ${item.color}`} />}
                {item.label && item.label}
            </p>
            <p className="flex items-center justify-center col-span-7 px-2 text-p">
                {item.label && item.value}
            </p>
        </React.Fragment>
    ));


    return (
        <div className='flex p-3 px-5 flex-col space-y-2 relative rounded-lg w-full sm:h-48 bg-white font-[Poppins]'>
            <div className="flex items-center justify-between border-b-2">
                <h5 className="pb-1 h6-p">{heading}</h5>
                {message && <BlackToolTip title={message} className="cursor-pointer" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                    <InfoOutlined className="text-base text-slate-900" />
                </BlackToolTip>}
            </div>
            <div className="grid w-full pr-6 sm:grid-cols-2 gap-y-5 sm:gap-y-0">
                <div className="flex items-center justify-center">
                    <PieChartComponent h1={h1?.charAt(0).toUpperCase()} h2={h2?.charAt(0).toUpperCase()}
                        h3={h3?.charAt(0).toUpperCase()}
                        v1={v1} v2={v2} v3={v3}
                        ir={60} ea={180} ht={125} textY={84}
                        loading={loading}
                    />
                </div>
                <div className="grid col-span-1 space-y-3 text-center grid-cols-32 sm:space-y-0">
                    {renderData}
                </div>
            </div>
        </div>
    );
};

export default ClassificationTileSec;
import React, { useEffect, useState } from "react";
import TableComponent from "../components/TableComponent";
import { VerifiedOutlined, CancelOutlined, PendingOutlined, PictureAsPdf } from '@mui/icons-material';
import { getMonthYear } from "../Functions/dateformatter/getMonthAndYear";
import { Button } from '@mui/material'
import BlackToolTip from "../buttons/BlackToolTip";
import { useSnackbar } from "../hooks/useSnackbar";

function createData(index, item, multiUsers, yearMonthColumns, dateColumns, statusDateCol, documentType, tickFields, showReason) {
    const formattedDate = {};
    yearMonthColumns && yearMonthColumns.forEach((colObj) => {
        const key = Object.keys(colObj)[0];
        const cols = colObj[key];
        const month = item[cols[0]];
        const year = item[cols[1]];
        const value = (month && year) ? `${new Date(`${year}-${month}`).toLocaleString('default', { month: 'short' })} ${year}` : '—';
        formattedDate[key] = value;
    });

    dateColumns && dateColumns.forEach((colObj) => {
        const key = Object.keys(colObj)[0];
        const cols = colObj[key];
        const date = new Date(item[cols]).toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' });
        const value = date || null;
        formattedDate[key] = value;
    });

    const formattedAuthors = item[multiUsers]?.map((author) => author.name).join(', ');
    const document = item.document
    const statusIcon = getStatusIcon(item.status, item[statusDateCol], document, documentType, showReason, item.reason);
    const tickIcons = tickFields?.length > 0 ? getTickIcons(item, tickFields) : null;

    return { index, ...item, statusIcon, tickIcons, formattedAuthors, ...formattedDate };
}

function getTickIcons(item, fields) {
    const colors = ['text-purple-400', 'text-purple-600', 'text-purple-800', 'text-purple-950']
    const tickIcons = fields.map((field, index) => (
        item[field] ? (
            <PictureAsPdf key={index} className={colors[index]} />
        ) : (
            <div key={index} className="w-6 h-6" />
        )
    ));

    return (
        <div className="flex space-x-2">{tickIcons}</div>
    );
}

function getStatusIcon(status, statusDateCol, document, documentType, showReason, reason) {
    let verifiedIcon = 0;
    switch (status) {
        case "Verified":
            verifiedIcon = (
                <div className="flex flex-col items-center ">
                    <VerifiedOutlined style={{ color: "green" }} />
                    <span className="pt-1">{getMonthYear(statusDateCol)}</span>
                </div>
            );
            break;

        case "Rejected":
            verifiedIcon = (
                <BlackToolTip title='View Reason'>
                    <Button onClick={(e) => showReason(e, reason)} className="flex flex-col items-center hover:bg-transparent" variant="text" style={{ textTransform: 'none' }}>
                        <CancelOutlined style={{ color: "red" }} />
                        <span className="pt-1 text-sm text-gray-500">{getMonthYear(statusDateCol)}</span>
                    </Button >
                </BlackToolTip>
            );
            break;

        case "Pending":
            verifiedIcon = (
                <BlackToolTip title='Pending'>
                    <div className="flex flex-col items-center">
                        <PendingOutlined style={{ color: "#fab300" }} />
                    </div>
                </BlackToolTip>
            );
            break;
    }

    let documentIcon;
    if (document) {
        const path = documentType === 'image' ? '/files/image.svg' : '/files/pdf.svg'
        documentIcon = <img className="w-8 h-8" alt="" src={path} loading="eager" />
    }

    return (
        <div className={`grid ${verifiedIcon === 0 ? 'grid-cols-1' : 'grid-cols-2'} place-items-center items-center gap-3 h-full w-full`}>
            {verifiedIcon === 0 ? null : <div> {verifiedIcon} </div>}
            <div>{documentIcon} </div>
        </div>
    )
}

export const Table = ({ columns, data, multiUsers, yearMonthColumns, dateColumns, statusDateCol, documentType, tickFields, ...props }) => {
    const [rows, setRows] = useState([]);
    const { handleSnackbarOpen } = useSnackbar();

    const showReason = (e, reason) => {
        e.stopPropagation();
        if (reason) return handleSnackbarOpen(reason, 'warning', 'top', 'center');
        else return handleSnackbarOpen("No Reason provided by Admin", 'warning', 'top', 'center');
    }

    useEffect(() => {
        if (data) {
            const mappedRows = data.map((item, index) => createData(index + 1, item, multiUsers, yearMonthColumns, dateColumns, statusDateCol, documentType, tickFields, showReason));
            setRows(mappedRows);
        }
    }, [data, yearMonthColumns, statusDateCol, multiUsers]);

    return <TableComponent columns={columns} rows={rows} {...props} />;
};
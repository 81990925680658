import { Button, Rating, TextField, Typography } from "@mui/material";
import ContactUs from "./ContactUs";
import { useCallback, useContext, useState } from "react";
import PortalPopup from "../../../components/popups/PortalPopup";
import PopupViewOnly from "../../../components/popups/PopupViewOnly";
import UserContext from "../../../context/UserContext";


const Support = () => {
  const [ratingFormData, setRatingFormData] = useState({ rating: 0 })

  const [showAlert, setShowAlert] = useState(false);
  const closeUploadSuccessfulPopup = useCallback(() => { setShowAlert(false); }, []);

  const { addRating, addSuggestion } = useContext(UserContext);

  const handleRatingSubmit = useCallback(async (event) => {
    event.preventDefault();
    let result = { value: false };
    await addRating(ratingFormData, result)

    if (result.value) {
      setShowAlert(true);

      setRatingFormData({ rating: 0, review: "" })
      setTimeout(() => { closeUploadSuccessfulPopup(); }, 2000);

    }
  }, [ratingFormData]);

  const handleRatingInputChange = (event) => {
    const { name, value } = event.target ?? { name: '', value: '' };
    setRatingFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  const [suggestionFormData, setSuggestionFormData] = useState({})

  const handleSuggestionSubmit = useCallback(async (event) => {
    event.preventDefault();
    let result = { value: false };
    await addSuggestion(suggestionFormData, result)

    if (result.value) {
      setShowAlert(true);

      setSuggestionFormData({ "ques1": "", "ques2": "", "ques3": "", "ques4": "" })
      setTimeout(() => { closeUploadSuccessfulPopup(); }, 2000);

    }
  }, [suggestionFormData]);

  const handleSuggestionChange = (event) => {
    const { name, value } = event.target ?? { name: '', value: '' };
    setSuggestionFormData((prevData) => ({ ...prevData, [name]: value }));
  }
  const suggestionForm = [
    {
      label: "How well do you think the institution acknowledges and celebrates your achievements?",
      name: "ques1",
    },
    {
      label: "What suggestions do you have to make the award recognition process more effective and meaningful?",
      name: "ques2",
    },
    {
      label: "What more functionalities would you like in your portal?",
      name: "ques3",
    },
    {
      label: "Are there any challenges you face in any submission process that you would like to highlight?",
      name: "ques4",
    },
  ]

  const isAnySuggestion = () => {
    for (const key in suggestionFormData) {
      if (suggestionFormData[key]) {
        return true;
      }
    }
    return false;
  };


  return (
    <div className="flex flex-col w-full">
      <div className=" bg-white-sec w-full text-left text-3xl text-[#5e3785] p-4 ">
        <div className="flex flex-row w-full justify-between h-auto overflow-hidden">
          <div className="w-full lg:w-1/2 space-y-5">
            <div className="flex items-center w-full rounded-lg shadow-sm bg-white ">
              <img
                className="w-[1rem] h-[1rem] ml-3 overflow-hidden "
                alt=""
                src="/icon.svg"
              />
              <input
                className="flex-grow text-xs h-10 rounded-md pl-2 border-none active:border-none"
                type="text"
                multiple={2}
                // placeholder="Ask a Question..."
                placeholder="Not working ...  "
              />

              <button className="cursor-pointer bg-[#0a215e] text-white mx-4 rounded-md w-[4.06rem] h-[1.56rem] ml-2">
                <div className="text-[0.75rem] leading-[150%] font-semibold font-text-sm-regular  text-center flex items-center justify-center w-full h-full">
                  Search
                </div>
              </button>
            </div>

            <h2 className="font-semibold w-fit mx-auto text-2xl xl:tracking-wide xl:text-3xl leading-normal ease-in-out duration-150 transition-all">
              “ Provide authentic feedback for <br className="sm:flex hidden" />
              meaningful enhancements ”
            </h2>

            <form className="bg-white w-full rounded-xl shadow-sm flex flex-col px-2 sm:px-4 py-4">
              <div>
                <Typography className="lb-s" component="legend">What do you think of this ?</Typography>
                <Rating
                  name="rating"
                  value={ratingFormData?.rating}
                  onChange={handleRatingInputChange}
                  precision={0.5}
                  size="large"
                />
              </div>

              <div className="flex flex-col space-y-3">
                <label className="lb-s pt-4">Please describe your Experience..</label>
                <TextField
                  className=" h-auto overflow-y-auto"
                  name="review" value={ratingFormData?.review}
                  onChange={handleRatingInputChange} multiline
                  maxRows={3}
                  minRows={2}
                />
                <Button
                  className="w-auto px-8"
                  onClick={handleRatingSubmit}
                  variant="contained" size="medium"
                  disabled={ratingFormData?.rating === 0}
                  sx={{
                    backgroundColor: ratingFormData?.rating === 0 ? "#cccccc !important" : "#010851 !important",
                    color: ratingFormData?.rating === 0 ? "#555555 !important" : "white !important",
                    borderRadius: 1,
                  }}
                >
                  Submit
                </Button>
              </div>

            </form>
          </div>
          <div className="relative md:w-1/2 min-w-[24rem] hidden md:flex overflow-hidden max-h-[26rem]">
            <img className="absolute right-5 top-4 w-22" alt="" src="/user/support/vector.svg" />
            <button className="cursor-pointer absolute right-7 top-7">
              <h5 className="text-xs font-semibold text-midnightblue-300">Go To FAQs</h5>
            </button>

            <div className="">
              <img className="" alt="" src="/user/support/supportMan.svg" />
            </div>

          </div>
        </div>

        <form className="rounded-xl bg-white w-full p-4 mt-8 flex flex-col ">
          <h2 className="h2-p sm:text-left text-center my-3 sm:my-0">Suggestions</h2>
          {suggestionForm.map((question, index) => (
            <div key={index}>
              <label className="lb-s mt-6">{question.label}</label>
              <TextField
                className="w-full my-2"
                multiline
                name={question.name}
                value={suggestionFormData?.[question.name]}
                onChange={handleSuggestionChange}
              />
            </div>
          ))}

          <Button className="mx-auto w-64 sm:w-80 mt-4 px-8 py-2  "
            onClick={handleSuggestionSubmit}
            variant="contained"
            type="button"
            disabled={!isAnySuggestion()}
            sx={{
              backgroundColor: !isAnySuggestion() ? "#cccccc !important" : "#010851 !important",
              color: !isAnySuggestion() ? "#555555 !important" : "white !important",
              borderRadius: 1,
            }}
          >
            Submit
          </Button>
        </form>
      </div>

      <div className="mt-4 w-full px-5">
        <ContactUs />
      </div>

      {showAlert && (
        <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeUploadSuccessfulPopup} >
          <PopupViewOnly heading={"Suggestions Uploaded"} subText={"Thanks alot for you review !!"} icon={'/icons/SuccessPurple.svg'} onClose={closeUploadSuccessfulPopup} />
        </PortalPopup>)}
    </div>
  );
};

export default Support;

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../popups/PortalPopup";
import { authorSplitter } from "../../Functions/authorSpliter";
import PopupExtra from "../popups/PopupExtra";
import { SquareIconButton } from "../../mui/SquareIconButton";
import { DeleteOutlineOutlined, Edit, FileOpenOutlined, Launch, SettingsBackupRestore, TaskAlt, Upload } from "@mui/icons-material";
import usePopup from "../../hooks/usePopup";
import useMediaQuery from '@mui/material/useMediaQuery';
import useVerifyPopup from "../../hooks/useVerifyPopup";


const BookChapterPopup = ({ onClose, data, admin, uploadPDF, deletePDF, viewPDF }) => {
  const onViewBtnClick = (link) => { window.open(link, '_blank'); }

  const mediumScreen = useMediaQuery('(max-width:1024px)');
  const smallScreen = useMediaQuery('(max-width:768px)');
  const navigate = useNavigate();
  const { openPopup } = usePopup();
  const { openVerifyPopup, verifyPopup, buttonOptions, closeVerifyPopup, handleReason, handleRevoke } = useVerifyPopup('bookChapter', data._id);

  const onEditClick = useCallback(() => {
    const { statusIcon, operation, ...bookChapterData } = data;
    navigate("/user/publication/bookChapter/editBookChapter", { state: { bookChapterData } });
  }, [data, navigate]);

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const items = [
    { label: 'First Author', content: data?.formattedAuthors?.split(",")[0], colSpan: smallScreen ? 'col-span-full' : mediumScreen ? 'col-span-10' : 'col-span-8' },
    { label: 'Title Of Book Chapter', content: data?.bookChapterTitle, colSpan: smallScreen ? 'col-span-full' : mediumScreen ? 'col-span-22' : 'col-span-24' },
    { label: "Co-Author’s Name", content: authorSplitter(data?.formattedAuthors), colSpan: mediumScreen ? 'col-span-full' : 'col-span-8' },
    { label: 'Title of Book', content: data?.bookTitle, colSpan: smallScreen ? 'col-span-full' : mediumScreen ? 'col-span-20' : 'col-span-24' },
    { label: 'Name of Publisher', content: data?.publisherName, colSpan: smallScreen ? 'col-span-full' : mediumScreen ? 'col-span-12' : 'col-span-8' },
    { label: 'Page', content: data?.pageNo, colSpan: smallScreen ? 'col-span-16' : mediumScreen ? 'col-span-10' : 'col-span-7' },
    { label: 'Date', content: data?.formattedDate, colSpan: smallScreen ? 'col-span-16' : mediumScreen ? 'col-span-10' : 'col-span-7' },
    { label: 'Volume/Edition', content: data?.volumeOrEdition || '_', colSpan: smallScreen ? 'col-span-16' : mediumScreen ? 'col-span-12' : 'col-span-10' },
    { label: 'Type Of Publication', content: data?.publisherType, colSpan: smallScreen ? 'col-span-16' : mediumScreen ? 'col-span-10' : 'col-span-8' },
    { label: 'ISBN/ISSN', content: data?.isbnOrIssn, colSpan: smallScreen ? 'col-span-16' : mediumScreen ? 'col-span-22' : 'col-span-7' },
    { label: 'Copyright Registered with Author', content: data?.copyrightRegistered ? 'Yes' : 'No', colSpan: mediumScreen ? 'col-span-16' : 'col-span-17' },
    { label: 'DOI', content: data?.doi, colSpan: mediumScreen ? 'col-span-full' : 'col-span-15' },
    { label: 'Book Chapter Link', content: data?.bookChapterLink || 'N/A', colSpan: mediumScreen ? 'col-span-full' : 'col-span-17' },
    { label: 'Affiliation Details', content: data?.affiliationDetails, colSpan: 'col-span-full' },
  ];

  return (
    <div className="bg-white relative flex flex-col justify-between rounded-2xl xl:w-[1094px] w-full h-full max-h-[700px]">
      <div className="relative flex justify-between p-5 space-x-3 text-center">
        <h2 className="h2-p ">Book Chapter Details</h2>
        <button className="w-fit h-fit" onClick={onClose}>
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
        </button>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="h-full overflow-y-auto no-scrollbar my-2 px-5 w-full">
        <div className="grid break-words grid-cols-32 gap-y-6 gap-x-4 w-full">
          {items.map((item, index) => (
            <div className={item.colSpan} key={index}>
              <label className="lb-s text-[13px]">
                {item.label}
              </label>
              <p className="lb-p">{item.content}</p>
            </div>
          ))}
        </div>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="relative grid items-center grid-cols-6 p-5 gap-y-3 gap-x-5 sm:flex justify-evenly md:justify-end sm:space-x-4">
        {admin ?
          <>
            <SquareIconButton title="Action" onClick={openVerifyPopup} icon={<TaskAlt />}
              className="col-span-2 text-green-600"
              disabled={data?.category !== "Published" || data?.status !== "Pending"}
            />
            <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
              className="col-span-2 text-red-500"
              disabled={data?.status === "Pending"}
            />
          </>
          :
          <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="col-span-2 text-amber-500" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="col-span-2 text-blue-600" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutlineOutlined />} className="col-span-2 text-red-500" />
          </>
        }
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpenOutlined />} className="col-span-2 text-slate-900" />
        <SquareIconButton title="View Paper Online" onClick={() => onViewBtnClick(data?.doi)} icon={<Launch />} className="col-span-2 text-slate-900" />
        <SquareIconButton title="View Book Chapter Online" disabled={!data?.bookChapterLink} onClick={() => onViewBtnClick(data?.bookChapterLink)} icon={<Launch />} className="col-span-2 text-slate-900" />
      </div>

      {verifyPopup &&
        <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
          <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
        </PortalPopup>
      }
    </div>
  );
};

export default BookChapterPopup;

import React, { useState } from 'react'
import PortalPopup from '../popups/PortalPopup'
import useGetData from '../../hooks/useGetData';
import { Skeleton } from '@mui/material';

const RankPopup = ({ id, onClose, type }) => {
    const { data: ranksData, isLoading: rankDataLoading } = useGetData(`/api/stats/facultyScore/getRankers?type=${type}&id=${id}`);

    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.8)" placement="Centered" onOutsideClick={onClose}>
            <div className="bg-white rounded-md h-full w-fit min-w-96 overflow-y-auto">
                <div className="flex sticky top-0 bg-white justify-between p-3">
                    <h2 className="h5-p">Research Score Rankings</h2>
                    <button className="w-fit h-fit" onClick={onClose}>
                        <img draggable={false} className="w-5 h-5 transition-transform transform hover:rotate-18 select-none" alt="" src="/cross.svg" />
                    </button>
                </div>

                <hr className="bg-gray-300 h-0.25 border-none" />
                <div className='' >
                    {rankDataLoading ?
                        Array.from({ length: 12 }).map((_, index) => (
                            <div key={index} className={`grid grid-cols-24 gap-3 place-items-center py-2 px-3 ${index % 2 === 0 ? 'bg-purple-50' : ''}`}>
                                <p className='col-span-1 text-p'>-</p>
                                <div className='col-span-3 lg:col-span-2'>
                                    <Skeleton variant="circular" animation='wave' className='w-10 h-10' />
                                </div>

                                <div className='col-span-14 lg:col-span-15 flex flex-col space-y-0.5 w-full'>
                                    <Skeleton animation='wave' className='text-p w-2/3' />
                                    <Skeleton animation='wave' className='text-s text-xs w-1/3' />
                                </div>

                                <Skeleton animation='wave' className='col-span-3 text-p w-2/3' />
                                <Skeleton animation='wave' className='col-span-3 text-p w-1/3' />
                            </div>
                        )) :
                        ranksData && ranksData.ranks?.map((item, index) => (
                            <div key={index} className={`grid grid-cols-24 gap-3 place-items-center py-2 px-3 ${item._id === ranksData.id ? 'bg-purple1 sticky bottom-0 top-0' : index % 2 === 0 ? 'bg-purple-50' : ''}`}>
                                <p className='col-span-1 text-p '>{index + 1}.</p>
                                <div className='col-span-3 lg:col-span-2'>
                                    <img src="/profile.svg" alt="image" className='w-10 h-10' />
                                </div>
                                <div className='col-span-14 lg:col-span-15 flex flex-col space-y-0.5 w-full'>
                                    <span className='text-p truncate'>{item.name}</span>
                                    <span className='text-s text-xs'>{item.school}</span>
                                </div>
                                <p className='col-span-3 text-p'>{item.score}</p>
                                <p className='col-span-3 text-p'>{item.rating}</p>
                            </div>
                        ))}
                </div>
            </div>
        </PortalPopup>
    )
}

export default RankPopup
/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, lazy } from 'react';
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import { CustomSelect, CustomTextField, CustomYearPicker } from "../../../../mui/Input";
import useFormOperations from "../../../../hooks/useFormOperations";
import useGetData from "../../../../hooks/useGetData";
import { useSearchParams } from "react-router-dom";
import { PermContactCalendar, School } from "@mui/icons-material";
import TabSmall from "../../../../mui/TabSmall";
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../recoil/state';
import { usePostData } from '../../../../hooks/usePostData';
import { useSnackbar } from '../../../../hooks/useSnackbar';
const InsideCollege = lazy(() => import('./InsideCollege'));
const OutsideCollege = lazy(() => import('./OutsideCollege'));


const AddPatent = () => {
    useSetBreadcrumb([
        { name: 'Patents', path: '/user/patents?tab=Patent' },
        { name: 'Add Patent', path: '/user/patents/addPatent' }
    ]);

    const setLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();
    const [searchParams] = useSearchParams();
    const activeTab = searchParams.get('type');
    const tabs = [
        { label: 'Applied through College', icon: <School /> },
        { label: 'Outside College', icon: <PermContactCalendar /> },
    ];

    const { data: userData } = useGetData('/api/user/getUser');
    const defaultValues = {
        applicants: [{ name: process.env.REACT_APP_COLLEGE_NAME }, {}],
        inventors: [{}, {}],
    };

    const onSubmit = async (formData) => {
        const authorExists = formData.applicants.some(applicant => applicant._id === userData._id) ||
            formData.inventors.some(inventor => inventor._id === userData._id);

        if (!authorExists)
            return handleSnackbarOpen("It appears you're trying to add a Patent entry without being listed as a Applicant or Inventor. This action is not permitted.", 'warning', 'top', 'center')

        const applicantIds = formData.applicants.map(applicant => applicant._id).filter(id => id);
        const inventorIds = formData.inventors.map(inventor => inventor._id).filter(id => id);

        const duplicateApplicantIds = applicantIds.filter((id, index) => applicantIds.indexOf(id) !== index);
        const duplicateInventorIds = inventorIds.filter((id, index) => inventorIds.indexOf(id) !== index);

        if (duplicateInventorIds.length > 0)
            return handleSnackbarOpen("Opps! Two same Inventors exist. Please check again.", 'warning', 'top', 'center');

        if (duplicateApplicantIds.length > 0)
            return handleSnackbarOpen("Opps! Two same Applicants exist. Please check again.", 'warning', 'top', 'center');

        setLoading(true);
        const { success, error, message } = await usePostData('/api/patent/addPatent', formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
        if (success) {
            handleSnackbarOpen("Patent added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
            onDiscard();
        }
        else if (error) return handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    }

    const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/patents?tab=Patent");
    const onTabsChange = (tabName) => {
        if (activeTab !== 'Applied through College' && tabName === 'Applied through College') {
            const applicants = [{ name: process.env.REACT_APP_COLLEGE_NAME }, {}];
            setValue('applicants', applicants)
        }
        else if (activeTab !== 'Outside College' && tabName === 'Outside College') {
            const applicants = [{}, {}]
            setValue('applicants', applicants)
        }
    }

    return (
        <div className="flex flex-col w-full space-y-5">
            <h2 className=" h5-p px-5">Add Patent Details</h2>
            <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>
                <CustomTextField label="Faculty Name"
                    InputLabelProps={{ shrink: true }} value={userData?.name} />

                <CustomTextField
                    label="Title of Invention" className="col-span-full" name="patentTitle"
                    onChange={handleChange} value={formData?.patentTitle} />

                <CustomSelect
                    label="Category of Patent"
                    options={[
                        { value: 'Filed', label: 'Filed' },
                        { value: 'Published', label: 'Published' },
                        { value: 'Granted', label: 'Granted' },
                    ]}
                    onChange={handleChange} name="category"
                />

                <CustomYearPicker name='filedDate' label="Filed Date" setValue={setValue} />

                <CustomTextField
                    label="Patent Number" name="patentNum"
                    onChange={handleChange} value={formData?.patentNum}
                    disabled={!(formData.category === 'Granted' || formData.category === 'Published')}
                />

                <CustomYearPicker name='publishedDate' label="Published Date" setValue={setValue}
                    disabled={(formData.category !== "Granted" && formData.category !== "Published") || formData.patentType !== 'India'}
                />

                <CustomSelect
                    label="Patent Applied Country"
                    options={[
                        { value: 'India', label: 'India' },
                        { value: 'Australia', label: 'Australia' },
                        { value: 'Germany', label: 'Germany' },
                        { value: 'South Africa', label: 'South Africa' },
                        { value: 'UK', label: 'UK' },
                        { value: 'US', label: 'US' },
                        { value: 'Others', label: 'Others' },
                    ]}
                    onChange={handleChange} name="patentType"
                />

                <CustomYearPicker name='grantedDate' label="Granted Date" setValue={setValue}
                    disabled={formData.category !== "Granted"} />

                <TabSmall onClick={onTabsChange} tabs={tabs} className="mt-10 mb-3 col-span-full" />

                <Suspense fallback={<div>Loading...</div>}>
                    {activeTab === "Applied through College" && <InsideCollege formData={formData} setValue={setValue} fieldName={'applicants'} maxValue={11} />}
                    {activeTab === "Outside College" && <OutsideCollege formData={formData} setValue={setValue} fieldName={'applicants'} maxValue={11} />}
                    <div className='col-span-full mt-10'>
                        <OutsideCollege formData={formData} setValue={setValue} fieldName={'inventors'} maxValue={11} />
                    </div>
                </Suspense>

                <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
                    <CloseButton onClick={onDiscard} />
                    <ConfirmButton type="submit" />
                </div>
            </form >
        </div >
    );
};

export default AddPatent;

import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';
import { Table } from '../../../../mui/Table';

const AVAttended = () => {
  const [year, setYear] = useRecoilState(sortYearState);
  const { data, isLoading } = useGetData(`/api/AV/eventAttended/getAV?year=${year}&school=total`);

  const columnsAbstractView = [
    { id: "index", label: "Sr No", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 150 },
    { id: "international", label: "International", minWidth: 50 },
    { id: "national", label: "National", minWidth: 50 },
    { id: "state", label: "State", minWidth: 50 },
    { id: "total", label: "Total", minWidth: 50 },
  ];

  return (
    <Table
      heading={'Total Attended Events'}
      text={'Faculties Attended Event'}
      columns={columnsAbstractView}
      data={data?.allEventAttendedAV}
      operation={false}
      sortParameters={'total'}
      deleteRequired={false}
      uploadRequired={false}
      viewRequired={false}
      sortYear={true}
      loading={isLoading}
    />
  )
}

export default AVAttended;
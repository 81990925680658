import React from 'react'
import { Table } from '../../../../mui/Table';
import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';

const AVPatent = () => {
    const [year, setYear] = useRecoilState(sortYearState);
    const { data, isLoading } = useGetData(`/api/AV/patent/getAV?year=${year}&school=total`);

    const columnsAbstractView = [
        { id: "index", label: "Sr No", minWidth: 50 },
        { id: "name", label: "Name", minWidth: 150 },
        { id: "filedApplicant", label: "Filed Applicant", minWidth: 50, align: 'center' },
        { id: "publishedApplicant", label: "Published Applicant", minWidth: 50, align: 'center' },
        { id: "grantedApplicant", label: "Granted Applicant", minWidth: 50, align: 'center' },

        { id: "filedInventor", label: "Filed Inventor", minWidth: 50, align: 'center' },
        { id: "publishedInventor", label: "Published Inventor", minWidth: 50, align: 'center' },
        { id: "grantedInventor", label: "Granted Inventor", minWidth: 50, align: 'center' },
    ];

    return (
        <Table
            heading={'Total Patents'}
            text={'Faculties Patent'}
            columns={columnsAbstractView}
            data={data?.patentAV}
            operation={false}
            sortParameters={'total'}
            deleteRequired={false}
            uploadRequired={false}
            viewRequired={false}
            sortYear={true}
            loading={isLoading}
        />
    )
}

export default AVPatent;
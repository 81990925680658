import { useSearchParams, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import TabSmall from "../../../mui/TabSmall";
import { GridViewOutlined, Schedule, CloudDownloadRounded, CloudUploadRounded, Analytics } from "@mui/icons-material";
import BlackToolTip from "../../../buttons/BlackToolTip";

import AnalysisTile from "../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../components/Tiles/ClassificationTileSec";
import useGetData from "../../../hooks/useGetData";
import RecentAwards from "./RecentAwards";
import AVAwards from "./AVAwards";
import useAcademicYear from "../../../hooks/useAcademicYear";
import useOperations from "../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";

const AwardsAD = () => {
    useSetBreadcrumb([
        { name: 'Awards', path: '/admin/awardsAD?type=Recent' },
    ]);
    const { data: awardsDataAD, isLoading } = useGetData("/api/award/getAwards?school=total");
    const { data: awardCount, isLoading: tileLoading } = useGetData('/api/stats/award/getStats?school=total');

    const currentYear = useAcademicYear();
    const [searchParams] = useSearchParams();

    const activeTab = searchParams.get('type');
    const tabs = [
        { label: 'Recent', icon: <Schedule /> },
        { label: 'Abstract View', icon: <GridViewOutlined /> },
    ];

    const { onDownload } = useOperations('Award', null, awardsDataAD,);

    const navigate = useNavigate();
    const navigateToAnalysis = () => {
        navigate("/admin/awards/awardsAnalysis");
    };

    const buttonInfo = [
        // { title: 'Analysis', icon: <Analytics fontSize="large" className="text-[#010851]" />, onClick: navigateToAnalysis },
        { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
        { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
    ];

    return (
        <div className="flex flex-col space-y-5 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-5 gap-5 rounded-lg bg-white-sec">
                <AnalysisTile
                    heading="Total Awards"
                    value={awardCount?.awards}
                    icon={'/icons/Success.svg'}
                    loading={tileLoading}
                />
                <AnalysisTile
                    heading={`Awards in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    value={awardCount?.awardsCY}
                    icon={'/icons/Success.svg'}
                    loading={tileLoading}
                />
                <div className="col-span-1 sm:col-span-2">
                    <ClassificationTileSec
                        heading={`Summary of Awards in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                        h1={'Technology'}
                        h2={'Social'}
                        h3={'Others'}
                        v1={awardCount?.TotalInnovationAndTechnologyCY}
                        v2={awardCount?.TotalSocialCY}
                        v3={awardCount?.TotalOthersCY}
                        loading={tileLoading}
                    />
                </div>
            </div>

            <div className="flex flex-col rounded-lg bg-white-sec">
                <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
                    <TabSmall tabs={tabs} />
                    <div className="flex space-x-5">
                        {buttonInfo.map((button, index) => (
                            <BlackToolTip key={index} title={button.title} arrow>
                                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                                    {button.icon}
                                </Button>
                            </BlackToolTip>
                        ))}
                    </div>
                </div>

                {activeTab === "Recent" && <RecentAwards data={awardsDataAD} loading={isLoading} />}
                {activeTab === "Abstract View" && <AVAwards />}
            </div>
        </div>
    );
};

export default AwardsAD;

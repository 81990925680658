const MapToJson = (data) => {
    if (!data || typeof data !== 'object') {
        return {};
    }

    return Object.fromEntries(
        Object.entries(data).map(([year, citations]) => [year, { citations }])
    );
};

export { MapToJson };
import { useSearchParams } from "react-router-dom";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import TabSmall from "../../../../mui/TabSmall";
import { Button } from "@mui/material";
import { CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import AVConducted from "./AVConducted";
import RecentConducted from "./RecentConducted";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import ConductedTile from "../../../../components/Tiles/ConductedTile";
import QuartileTile from "../../../../components/Tiles/QuartileTile";

const ConductedAD = () => {
  useSetBreadcrumb([
    { name: 'Events', path: '/admin/eventsAD?tab=Conducted&type=Recent' },
    { name: 'Conducted Events', path: '/admin/eventsAD?tab=Conducted&type=Recent' }
  ]);

  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('type');
  const { data: conductedDataAD, isLoading } = useGetData('/api/eventConducted/getEventsConducted?school=total');
  const { data: conductedCount, isLoading: tileLoading } = useGetData('/api/stats/eventConducted/getStats?school=total&year=total');

  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];
  const { onDownload } = useOperations('Events Conducted', null, null, conductedDataAD, ['coordinators']);
  const buttonInfo = [
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
  ];

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 p-5 gap-5 rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Events Conducted"
          value={conductedCount?.eventConducted}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <AnalysisTile
          heading={`Events Conducted  in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={conductedCount?.eventConductedCY}
          icon={'/icons/Success.svg'}
          loading={tileLoading}
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading={`Summary of Events Conducted in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
            h1={'International'}
            h2={'National'}
            v1={conductedCount?.international}
            v2={conductedCount?.national}
            loading={tileLoading}
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <ConductedTile heading='Event Categories' data={conductedCount} />
        </div>
        <div className="col-span-1 sm:col-span-2">
          <QuartileTile
            heading='Events Conducted by Year'
            h1='First Year' h2='Second Year' h3='Third Year' h4='Last Year'
            lb1='FY' lb2='SY' lb3='TY' lb4='LY'
            v1={conductedCount?.audienceCounts?.['fy']} v2={conductedCount?.audienceCounts?.['sy'] ?? 0} v3={conductedCount?.audienceCounts?.['ty'] ?? 0} v4={conductedCount?.audienceCounts?.['ly'] ?? 0}
          />
        </div>
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>
        {activeTab === "Recent" && <RecentConducted data={conductedDataAD} loading={isLoading} />}
        {activeTab === "Abstract View" && <AVConducted />}
      </div>
    </div>
  );
};

export default ConductedAD;

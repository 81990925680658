import { DeleteOutlineOutlined, Edit, FileOpenOutlined, Launch, SettingsBackupRestore, TaskAlt, Upload } from "@mui/icons-material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../popups/PortalPopup";
import { SquareIconButton } from "../../mui/SquareIconButton";
import useVerifyPopup from "../../hooks/useVerifyPopup";
import usePopup from "../../hooks/usePopup";
import PopupExtra from "../popups/PopupExtra";

const ConsultancyPopup = ({ onClose, data, admin, uploadPDF, deletePDF, viewPDF }) => {

  const { formattedAuthors, consultancyTitle, agency, category,
    consultancyType, consultancyStatus, revenueGenerated, formattedStartingDate, formattedEndingDate, duration,
    agencyEmail, agentEmail, agentPhone
  } = data;

  const navigate = useNavigate();
  const { openPopup } = usePopup();

  const onEditClick = useCallback(() => {
    const { statusIcon, tickIcons, operation, ...consultancyData } = data;
    navigate("/user/projects/consultancy/editConsultancy", { state: { consultancyData } });
  }, [data, navigate]);

  const { openVerifyPopup, verifyPopup, buttonOptions, closeVerifyPopup, handleReason, handleRevoke } = useVerifyPopup('consultancy', data._id);
  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const dataMap = [
    { label: "Consultant's Name", value: formattedAuthors, colSpan: 'col-span-10', },
    { label: "Title Of Consultancy Consultancy", value: consultancyTitle, colSpan: 'col-span-22', },
    { label: "Category", value: category, colSpan: 'col-span-10', },
    { label: "Consulting Sponsoring Agency", value: agency, colSpan: 'col-span-22', },
    { label: "Agency Email", value: agencyEmail, colSpan: 'col-span-10', },
    { label: "Agent Email", value: agentEmail, colSpan: 'col-span-12', },
    { label: "Agent Phone", value: agentPhone, colSpan: 'col-span-10', },
    { label: "Type of Consultancy", value: consultancyType, colSpan: 'col-span-10', },
    { label: "Category", value: category, colSpan: 'col-span-12', },
    { label: "Status", value: consultancyStatus, colSpan: 'col-span-10', },
    { label: "Start Date", value: formattedStartingDate, colSpan: 'col-span-10', },
    { label: "Completion Year", value: formattedEndingDate || 'N/A', colSpan: 'col-span-12', },
    { label: "Duration", value: `${duration} Months`, colSpan: 'col-span-10', },
    { label: "Revenue Generated", value: revenueGenerated, colSpan: 'col-span-12', },
  ];

  return (
    <div className="bg-white flex flex-col rounded-lg w-full xl:w-[1000px] h-full py-5">
      <div className="relative flex justify-between px-5 pb-2 space-x-3 text-center">
        <h2 className="h2-p">Consultancy Details</h2>
        <button className="w-fit h-fit" onClick={onClose}>
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
        </button>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="grid md:grid-cols-32 max-h-[80%] h-full overflow-y-auto no-scrollbar my-2 px-5 gap-3">
        {dataMap.map((item, index) => (
          <div key={index} className={`${item.colSpan}`}>
            <label className="lb-s text-[13px]">
              {item.label}
            </label>
            <p className="lb-p break-words">{item.value}</p>
          </div>
        ))}
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />
      <div className="relative grid items-center grid-cols-6 p-2 px-5 gap-y-3 gap-x-5 sm:flex justify-evenly md:justify-end sm:space-x-4">
        {admin ?
          <>
            <SquareIconButton title="Action" onClick={openVerifyPopup} icon={<TaskAlt />}
              className="col-span-2 text-green-600"
              disabled={data?.status !== "Pending"}
            />
            <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
              className="col-span-2 text-red-500" disabled={data?.status === "Pending"}
            />
          </>
          :
          <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="col-span-2 text-amber-500" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="col-span-2 text-blue-600" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutlineOutlined />} className="col-span-2 text-red-500" />
          </>
        }
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpenOutlined />} className="col-span-3 text-slate-900" />
      </div>

      {verifyPopup &&
        <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
          <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
        </PortalPopup>
      }
    </div >
  );
};

export default ConsultancyPopup;

import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../../../../components/popups/PortalPopup";

import UserContext from "../../../../context/UserContext";

import ConsultancyPopup from "../../../../components/popupDetailed/ConsultancyPopup";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import useMultiPDFPopup from "../../../../hooks/useMultiPDFPopup";
import MultiplePDFPopup from "../../../../components/popups/MultiPDFPopup";

const Consultancy = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/user/projects?tab=Consultancy' },
    { name: 'Consultancy', path: '/user/projects?tab=Consultancy' },
  ]);

  const { deleteConsultancy } = useContext(UserContext);
  const currentYear = useAcademicYear();
  const navigate = useNavigate();

  const { data: consultanciesData, isLoading, refreshData } = useGetData('/api/consultancy/getConsultancies');
  const { data: statsCY, isLoading: tileLoadingCY } = useGetData('/api/stats/consultancy/getStats');
  const { data: statsTotal, isLoading: tileLoadingTotal } = useGetData('/api/stats/consultancy/getStats?year=total');
  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Consultancy', deleteConsultancy, refreshData, consultanciesData, ['consultants']);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Consultant Names', minWidth: 130 },
    { id: 'consultancyTitle', label: 'Consultancy Title', minWidth: 230 },
    { id: 'consultancyStatus', label: 'Status' },
    { id: 'formattedStartingDate', label: 'Start Date', minWidth: 100 },
    { id: 'revenueGenerated', label: 'Revenue Generated' },
    { id: 'tickIcons', label: 'Proof [AL  CL  BS]', minWidth: 165 },
    { id: 'statusIcon', label: 'Status' },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/projects/consultancy/addConsultancy");
  }, [navigate]);

  const buttons = [
    { label: "Approval Letter", cloudName: 'approval' },
    { label: "Completion Letter", cloudName: 'completion' },
    { label: "Audited bank statement", cloudName: 'bankStatement' },
  ];

  const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Consultancy', 'consultancies', 'projects?tab=Consultancy', buttons);

  return (
    <div className="flex flex-col my-5 space-y-5">
      <div className="grid gap-5 p-5 rounded-lg 2xl:grid-cols-4 sm:grid-cols-2 bg-white-sec">
        <AnalysisTile
          heading="Total Completed Consultancies"
          value={statsTotal?.completed}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingTotal}
          message='Total Completed & Verified Consultancies'
        />

        <AnalysisTile
          heading="Total ongoing Consultancies"
          value={statsTotal?.inProgress}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingTotal}
          message='Total Inprogress & Verified Consultancies'
        />

        <AnalysisTile
          heading={`Consultancies completed in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={statsCY?.completed}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingCY}
        />

        <AnalysisTile
          heading={`Consultancies started in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={statsCY?.started}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingCY}
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading='Total completed Consultancies summary'
            h1={'International'}
            h2={'National'}
            v1={statsTotal?.international}
            v2={statsTotal?.national}
            icon={'/tabs/ConsultancyPurple.svg'}
            loading={tileLoadingTotal}
            message='Total Verified & completed Consultancies'
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading='Total completed Consultancies summary'
            h1={'Government'}
            h2={'Private'}
            v1={statsTotal?.government}
            v2={statsTotal?.private}
            icon={'/tabs/ConsultancyPurple.svg'}
            loading={tileLoadingTotal}
            message='Total Verified & completed Consultancies'
          />

        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full py-5 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec ">
        <UploadButton text='Consultancy Data' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Consultancies' />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Consultancies'}
          text={'Uploaded Consultancies Projects'}
          columns={columns}
          data={consultanciesData}
          yearMonthColumns={[{ 'formattedStartingDate': ['startingMonth', 'startingYear'] }, { 'formattedEndingDate': ['endingMonth', 'endingYear'] }]}
          sortParameters={'formattedStartingDate'}
          multiUsers={'consultants'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          statusDateCol={'lastVerified'}
          onDelete={onDelete}
          tickFields={['approvalDocument', 'completionDocument', 'bankStatementDocument']}
          onUpload={(data) => openMultiPDFPopup(data, "upload")}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen &&
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails} >
          <ConsultancyPopup onClose={closeViewDetails} data={popupData}
            viewPDF={() => openMultiPDFPopup(popupData, "view")}
            uploadPDF={() => openMultiPDFPopup(popupData, "upload")}
            deletePDF={() => openMultiPDFPopup(popupData, "delete")}
          />
        </PortalPopup>
      }

      {isMultiPDFPopup?.open &&
        <MultiplePDFPopup
          heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
          icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
        />
      }

    </div>
  );
};

export default Consultancy;

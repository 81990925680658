/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import BookPopup from "../../../../components/popupDetailed/BookPopup";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import { Table } from "../../../../mui/Table";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import usePDFPopup from "../../../../hooks/usePDFPopup";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import { useDeleteData } from "../../../../hooks/useDeleteData";
import { useSnackbar } from "../../../../hooks/useSnackbar";

const Book = () => {
  const { data: bookData, isLoading, refreshData } = useGetData('/api/book/getBooks');
  const { data: bookStats, isLoading: tileLoadingCY } = useGetData('/api/stats/book/getStats?year=total');
  const { data: bookStatsCY, isLoading: tileLoading } = useGetData('/api/stats/book/getStats');

  const navigate = useNavigate();
  const currentYear = useAcademicYear();
  const { handleSnackbarOpen } = useSnackbar();

  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Book' },
    { name: 'Book', path: '/user/publication?tab=Book' }
  ]);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'bookTitle', label: 'Title of Book', minWidth: 230 },
    { id: 'publisher', label: 'Publisher' },
    { id: 'country', label: 'Country' },
    { id: 'formattedDate', label: 'Date', minWidth: 100, align: 'center' },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/publication/book/addBook");
  }, [navigate]);

  const deleteBook = async (id) => {
    const { error, message } = await useDeleteData(`/api/book/deleteBook/${id}`);
    if (message) return handleSnackbarOpen(message, 'error', 'top', 'center', 'delete');
    else if (error) return handleSnackbarOpen(`Opps ! Error occured while removing copyright.`, 'error', 'top', 'center', 'delete');
    handleSnackbarOpen(`Copyright successfully removed, as requested.`, 'warning', 'top', 'center', 'delete');
    refreshData();
  }

  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Book', deleteBook, refreshData, bookData, ['authors']);
  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('books', 'publication?tab=Book', 'single');

  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };

  return (
    <div className="flex flex-col my-5 space-y-5">
      <div className="grid md:grid-cols-2 p-5 gap-5 w-full rounded-lg  bg-white-sec">
        <AnalysisTile
          heading="Total Published Book"
          value={bookStats?.total}
          icon={'/tabs/round/BookPurple.svg'}
          loading={tileLoading}
        />
        <AnalysisTile
          heading={`Published Book in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={bookStatsCY?.total}
          icon={'/tabs/round/BookPurple.svg'}
          loading={tileLoadingCY}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-full py-6 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec">
        <UploadButton text='Book Chapter' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Books' />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Books'}
          text={'Uploaded Books'}
          columns={columns}
          data={bookData}
          yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
          sortParameters={'formattedDate'}
          multiUsers={'authors'}
          statusDateCol={'lastVerified'}
          document={'document'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>
      {isViewPopupOpen && <BookPopup onClose={closeViewDetails} data={popupData} uploadPDF={uploadPDF} viewPDF={viewPDF} deletePDF={deletePDF} />}
    </div >

  );
};

export default Book;

import React, { useState } from 'react';
import {
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    Avatar,
    Skeleton, Button,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';

import BlackToolTip from '../../../../buttons/BlackToolTip';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import useGetData from '../../../../hooks/useGetData';
const NotificationTable = () => {
    const [page, setPage] = useState(1);

    const groupedNotifications = {};
    const { data: notificationData, isLoading } = useGetData(`/api/notification/getNotification?school=total&page=${page}`);
    // const navigate = useNavigate();

    // const openVerifyPopup = (value) => {
    //     value === 'Journal' && navigate("/admin/publicationAD", { state: { publicationTabs: 'Journal' } })
    //     value === 'BookChapter' && navigate("/admin/publicationAD", { state: { publicationTabs: 'Book Chapter' } })
    //     value === 'Conference' && navigate("/admin/publicationAD", { state: { publicationTabs: 'Conference' } })
    //     value === 'Patent' && navigate("/admin/publicationAD", { state: { publicationTabs: 'Conference' } })
    //     value === 'Award' && navigate("/admin/publicationAD", { state: { publicationTabs: 'Conference' } })
    // };

    const increasePage = () => { setPage((page) => page + 1) }
    const decreasePage = () => { setPage((page) => page - 1) }

    const getFormattedTime = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    if (Array.isArray(notificationData)) {
        notificationData.forEach((notification) => {
            const date = new Date(notification.createdAt).toLocaleDateString();
            if (!groupedNotifications[date]) {
                groupedNotifications[date] = [];
            }
            groupedNotifications[date].push(notification);
        });
    }

    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                {!isLoading && Object.keys(groupedNotifications).length === 0 ? (
                    <p key='no-notifications-message' className='italic lb-s'>No notifications found !!</p>

                ) : isLoading ? (
                    <TableContainer component={Paper} style={{ marginBottom: '15px', border: 'none', boxShadow: 'none' }}>
                        <Table style={{ border: 'none' }}>
                            <TableBody>
                                {Array.from({ length: 10 }).map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell key={index}>
                                            <Skeleton variant="rect" animation="wave" height={30} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                ) : (
                    Object.keys(groupedNotifications).map((date) => {
                        const currentDate = new Date().toLocaleDateString();
                        const yesterday = new Date();
                        yesterday.setDate(yesterday.getDate() - 1);
                        const yesterdayDate = yesterday.toLocaleDateString();

                        return (
                            <div key={date} style={{ border: 'none' }}>
                                {[
                                    { condition: date === currentDate, data: 'Today' },
                                    { condition: date === yesterdayDate, data: 'Yesterday' },
                                    { condition: date !== currentDate && date !== yesterdayDate, data: date },
                                ].map((item, index) => (
                                    item.condition && <h5 key={index} className='h5-p text-blue-Sec text-xl px-3 mt-5 mb-2'>{item.data}</h5>
                                ))}

                                <TableContainer component={Paper} style={{ marginBottom: '15px', border: 'none', boxShadow: 'none' }}>
                                    <Table style={{ border: 'none' }}>
                                        <TableBody>
                                            {
                                                notificationData && groupedNotifications[date]?.map((notification) => {
                                                    const senderNames = notification?.senderName?.split(' ');
                                                    const firstName = senderNames[0]?.charAt(0);
                                                    const lastName = senderNames[senderNames?.length - 1]?.charAt(0);

                                                    return (
                                                        <TableRow className='flex border-none' key={notification.id} >
                                                            <TableCell className='p-4 border-none w-fit' >
                                                                <Avatar className='w-12 h-12 text-p text-lg bg-[#E3E9F7]'
                                                                    alt={notification?.senderName} >
                                                                    {firstName + lastName}
                                                                </Avatar>
                                                            </TableCell>

                                                            <TableCell className='flex flex-col w-full border-none space-y-1'>
                                                                <p className='lb-p font-bold'>{notification?.senderName}</p>
                                                                <p className='text-s font-light'>{notification?.data}</p>
                                                            </TableCell>

                                                            <TableCell className='flex items-center justify-center text-purple5 border-none' >
                                                                {getFormattedTime(notification.createdAt)}
                                                            </TableCell>

                                                            <TableCell className='flex items-center justify-center border-none pt-4'>
                                                                <BlackToolTip title="View" arrow>
                                                                    <IconButton color="primary"
                                                                    // onClick={
                                                                    // () => openVerifyPopup(notification.type)
                                                                    // }
                                                                    >
                                                                        <LaunchOutlinedIcon style={{ color: '#010851' }} />
                                                                    </IconButton>
                                                                </BlackToolTip>
                                                                {/* <BlackToolTip title="Archive" arrow>
                                                                    <IconButton color="secondary">
                                                                        <ArchiveOutlinedIcon style={{ color: '#E91F63' }} />
                                                                    </IconButton>
                                                                </BlackToolTip> */}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    }))
                }
            </div>
            <div className='flex justify-between items-center'>
                <Button
                    className={`${page === 1 ? '' : 'bg-slate-950   hover:bg-slate-800  text-white'} min-w-[10rem]`}
                    variant='contained'
                    onClick={decreasePage}
                    startIcon={<NavigateBefore />}
                    disabled={page === 1}
                >
                    Previous
                </Button>
                <Button
                    className='bg-slate-950 min-w-[10rem] hover:bg-slate-800 text-white'
                    variant='contained'
                    onClick={increasePage}
                    endIcon={<NavigateNext />}
                    disabled={notificationData?.length < 10}
                >Next
                </Button>
            </div>
        </div>
    );
};

export default NotificationTable;

import { BorderColorOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import usePopup from "../../hooks/usePopup";
import { UploadButton } from "../../mui/Button";
import PortalPopup from "../popups/PortalPopup";

const AwardPopup = ({ onClose, data, admin, viewPhoto, addPhoto }) => {
  const { openPopup } = usePopup();
  const navigate = useNavigate();

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick);
  }

  const onEditClick = useCallback(() => {
    const { statusIcon, operation, ...awardData } = data;
    navigate("/user/awards/editAward", { state: { awardData } });
  }, [data, navigate]);

  const fields = [
    { label: 'Awardee', value: data?.awardeeName, size: 1 },
    { label: 'Date of Award', value: data?.formattedDate, size: 1 },
    { label: 'Award Name', value: data?.awardName, size: 2 },
    { label: 'Awarding Agency', value: data?.awardingAgency || '--', size: 2 },
    { label: 'Type Of Award', value: data?.awardType, size: 1 },
    { label: 'Category', value: data?.awardCategory, size: 1 },
    { label: 'School', value: data?.school, size: 1 },
  ];

  return (
    <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={onClose}>
      <div className="bg-white relative flex flex-col rounded-xl md:w-[650px] h-full max-h-[700px] py-6 space-y-5" >
        <div className="flex justify-center">
          <div className="relative flex flex-col items-center">
            <img className="w-[30px] h-[30px]" src="/user/award-icon.svg" alt="" loading="eager" />
            <h2 className="h2-p">Award Details</h2>
          </div>
          <button className=" absolute bg-[transparent] w-5 h-5 right-6"
            onClick={onClose}>
            <img
              className="w-5 h-5 transition-transform transform hover:rotate-180"
              alt="" loading="eager" src="/cross.svg"
            />
          </button>
        </div>

        <hr className="h-0.5 w-full bg-[#667085]" />

        <div className="h-full overflow-y-auto no-scrollbar mx-8">
          <div className="grid grid-cols-2 break-words gap-x-4 gap-y-8">
            {fields.map((field, index) => (
              <div key={index} className={`flex flex-col col-span-${field.size || 'full'}`}>
                <label className="lb-s text-[14px]">
                  {field.label}
                </label>
                <p className="text-p">
                  {field.value}
                </p>
              </div>
            ))}
          </div>
        </div>

        <hr className="h-0.5 w-full bg-[#667085]" />

        <div className={admin ? 'flex justify-center items-center' : 'grid grid-cols-1 md:grid-cols-2 place-items-center gap-y-3 px-3'}>
          {!admin &&
            <Button
              className="flex bg-[#010851] items-center rounded-lg w-75 h-12"
              onClick={openEditDetailsPopup} variant="contained"
              startIcon={<BorderColorOutlined style={{ color: 'white' }} />}
              style={{ backgroundColor: '#010851', color: 'white', borderRadius: 8 }}
            >Edit Details</Button>
          }

          <Button
            className="flex bg-[#010851] rounded-lg w-75 h-12"
            onClick={viewPhoto} variant="contained"
            startIcon={<RemoveRedEyeOutlined style={{ color: 'white' }} />}
            style={{ backgroundColor: '#010851', color: 'white', borderRadius: 8 }}
          >
            View Photo
          </Button>

          {!admin && <div className="col-span-full">
            <UploadButton newText={'Add Photos'} onClick={() => addPhoto(data)} />
          </div>
          }
        </div>
      </div >
    </PortalPopup>
  );
};

export default AwardPopup;

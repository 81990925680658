import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';
import { Table } from '../../../../mui/Table';

const AVConference = () => {
    const [year, setYear] = useRecoilState(sortYearState);
    const { data, isLoading } = useGetData(`/api/AV/conference/getAV?year=${year}&school=total`);

    const columnsAbstractView = [
        { id: "index", label: "Sr No", minWidth: 50 },
        { id: "name", label: "Name", minWidth: 150 },
        { id: "published", label: "Published", minWidth: 50 },
        { id: "accepted", label: "Accepted", minWidth: 50 },
        { id: "submitted", label: "Submitted", minWidth: 50 },
        { id: "internationalPublished", label: "International Published", minWidth: 50 },
        { id: "nationalPublished", label: "National Published", minWidth: 50 },
    ];

    return (
        <Table
            heading={'Total Conferences'}
            text={'Faculties Conference'}
            columns={columnsAbstractView}
            data={data?.allConferenceAV}
            operation={false}
            sortParameters={'total'}
            deleteRequired={false}
            uploadRequired={false}
            viewRequired={false}
            sortYear={true}
            loading={isLoading}
        />
    )
}

export default AVConference;
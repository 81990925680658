import { cloneElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useAcademicYear from "../../../hooks/useAcademicYear";
import useGetData from "../../../hooks/useGetData";

import Profile from "./Profile";
import AwardsOverview from "./AwardsOverview";
import PublicationsOverview from "./PublicationsOverview";
import ProjectsOverview from "./ProjectsOverview";

import ThreeWayTab from "../../../components/tabs/ThreeWayTab";
import OverYearsTile from "../../../components/Tiles/OverYearsTile";
import { MapToJson } from "../../../Functions/MapToJson";
import ScoreBoard from "./ScoreBoard";

const Dashboard = ({ profilePhoto }) => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');
    const id = searchParams.get('id');

    const { data: userData, isLoading: userDataLoading } = useGetData(`/api/user/getUser?id=${id}`);
    const { data: googleScholarData, isLoading: googleScholarLoading } = useGetData(`/webCrawling/googleScholar?id=${id}`);
    const { data: scoreData, isLoading: scoreDataLoading } = useGetData(`/api/stats/facultyScore/getRating?school=mine&id=${id}`);

    const currentYear = useAcademicYear();
    useEffect(() => { setYear(currentYear) }, [currentYear])

    const [year, setYear] = useState(currentYear)
    const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
    };

    const componentMap = {
        Publication: <PublicationsOverview />,
        Projects: <ProjectsOverview />,
        // Patents: <PatentsOverview />,
        Awards: <AwardsOverview />
    };

    return (
        <main className="flex flex-col w-full space-y-5">
            <Profile userData={userData} loading={userDataLoading} profilePhoto={profilePhoto} id={id}
                googleScholarData={googleScholarData} googleScholarLoading={googleScholarLoading}
                scoreData={scoreData} scoreDataLoading={scoreDataLoading}
            />

            {process.env?.REACT_APP_PRODUCTION === 'true' && <ScoreBoard id={id} scoreData={scoreData} scoreDataLoading={scoreDataLoading} />}

            <section className="col-span-full bg-white">
                <OverYearsTile
                    loading={googleScholarLoading}
                    heading={"Google Scholar citations over years"}
                    data={MapToJson(googleScholarData?.citations)}
                    total={false}
                    message="Google Scholar data is periodically updated every four months. Please refer to the official documentation for specific update timelines."
                    errorMessage='No Google Scholar citations found yet. Citations are updated periodically.'
                />
            </section>

            <ThreeWayTab
                btn1={"Publication"} btn2={"Projects"} btn3={"Patents"} btn4={"Awards"}
                icon1={<img src='/tabs/Publication.svg' />} icon2={<img src='/tabs/Project.svg' />} icon3={<img src='/tabs/Patent.svg' />} icon4={<img src='/tabs/Award.svg' />}
                iconDisabled1={<img src='/tabs/PublicationGrey.svg' />} iconDisabled2={<img src='/tabs/ProjectGrey.svg' />} iconDisabled3={<img src='/tabs/PatentGrey.svg' />} iconDisabled4={<img src='/tabs/AwardGrey.svg' />}
            />

            {componentMap[tab] && cloneElement(componentMap[tab], {
                id,
                year,
                years,
                currentYear,
                handleYearChange
            })}

        </main >
    );
};

export default Dashboard;
import { useState, useCallback } from 'react';

const HOST_URL = process.env.REACT_APP_HOST;

const useFetchData = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (url, options = {}) => {
    setIsLoading(true);
    setError(null);

    const token = sessionStorage.getItem('token');

    try {
      const response = await fetch(HOST_URL + url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        ...options,
      });

      const result = await response.json();
      if (result?.success) {
        setData(result?.data);
        return result?.data;
      }
      else setError(result?.error);
    } catch (error) {
      setError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, isLoading, error, fetchData };
};

export default useFetchData;

import React from "react";
import { useSearchParams } from "react-router-dom";

import Patent from "./patent/PatentAD";
import DesignPatent from "./designPatent/DesignPatentAD";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";

const PatentAD = () => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    return (
        <div className="flex flex-col space-y-5 w-full">
            <ThreeWayTab
                btn1={"Patent"} btn2={"Design Patent"}
                icon1={<img src='/tabs/TechPatent.svg' />} icon2={<img src='/tabs/DesignPatent.svg' />}
                iconDisabled1={<img src='/tabs/TechPatentGrey.svg' />} iconDisabled2={<img src='/tabs/DesignPatentGrey.svg' />}
            />
            {tab === "Patent" && <Patent />}
            {tab === "Design Patent" && <DesignPatent />}
        </div>

    );
};

export default PatentAD;

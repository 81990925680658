import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useImagePopup from "../../../hooks/useImagePopup";
import useGetData from "../../../hooks/useGetData";
import useOperations from "../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import useAcademicYear from "../../../hooks/useAcademicYear";

import UserContext from "../../../context/UserContext";
import AnalysisTile from "../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../components/Tiles/ClassificationTileSec";
import AwardPopup from "../../../components/popupDetailed/AwardPopup";
import ImagePopup from "../../../components/ImagePopup";
import { Table } from "../../../mui/Table";
import { DownloadButton, UploadButton } from "../../../mui/Button";

const Award = () => {
    useSetBreadcrumb([{ name: 'Awards', path: '/user/awards' }]);

    const { data: awardsData, isLoading, refreshData } = useGetData("/api/award/getAwards");
    const { data: awardCount, isLoading: tileLoading } = useGetData('/api/stats/award/getStats');

    const currentYear = useAcademicYear();
    const navigate = useNavigate();
    const { deleteAward } = useContext(UserContext);
    const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Award', deleteAward, refreshData, awardsData,);
    const { addPhoto, imagePopup, imagesURL, openImagePopup, closeImagePopup, deletePhoto } = useImagePopup('awards', 'awards');

    const onUploadNewFrameClick = useCallback(() => {
        navigate("/user/awards/addAward");
    }, [navigate]);


    const viewPhoto = () => { openImagePopup(popupData._id); };
    const handleDeletePhotoClick = (key) => { deletePhoto(key, popupData._id); };
    const handleAddPhotoClick = (award) => { addPhoto(award._id); };

    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'awardeeName', label: 'Awardee', minWidth: 130 },
        { id: 'awardName', label: 'Award Name', minWidth: 180 },
        { id: 'awardCategory', label: 'Category', minWidth: 90 },
        { id: 'awardType', label: 'Type' },
        { id: 'formattedDate', label: 'Date of Award' },
        { id: "statusIcon", label: "Uploaded", minWidth: 100, align: "center" },
        { id: 'operations', label: '___', minWidth: 155, align: 'center' },
    ];

    return (
        <div className="flex flex-col space-y-5 w-full">
            <div className="grid grid-cols-1 gap-5 p-5 rounded-lg xl:grid-cols-4 sm:grid-cols-2 bg-white-sec">
                <AnalysisTile
                    heading="Total Awards"
                    value={awardCount?.awards}
                    icon={'/icons/Success.svg'}
                />

                <AnalysisTile
                    heading={`Awards in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    value={awardCount?.awardsCY}
                    icon={'/icons/Success.svg'}
                />
                <div className="col-span-full sm:col-span-2">
                    <ClassificationTileSec
                        heading="Total Awards Summary"
                        h1={'Technology'}
                        h2={'Social'}
                        h3={'Others'}
                        v1={awardCount?.TotalInnovationAndTechnologyCY}
                        v2={awardCount?.TotalSocialCY}
                        v3={awardCount?.TotalOthersCY}
                        loading={tileLoading}
                    />
                </div>
            </div>

            <div className="flex flex-col items-center justify-center w-full py-6 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec">
                <UploadButton text='Award' onClick={onUploadNewFrameClick} />
                <DownloadButton onClick={onDownload} text='Awards' />
            </div>

            <div className="rounded-lg bg-white-sec">
                <Table
                    heading={'Total Awards'}
                    text={'Uploaded awards'}
                    columns={columns}
                    data={awardsData}
                    yearMonthColumns={[{ 'formattedDate': ['awardMonth', 'awardYear'] }]}
                    sortParameters={'formattedDate'}
                    operation={true}
                    deleteRequired={true}
                    uploadRequired={true}
                    onUpload={handleAddPhotoClick}
                    viewRequired={true}
                    click={openViewDetails}
                    onDelete={onDelete}
                    documentType='image'
                    loading={isLoading}
                />
            </div>

            {imagePopup && <ImagePopup urls={imagesURL} onClose={closeImagePopup} singleDelete={handleDeletePhotoClick} />}
            {isViewPopupOpen && (<AwardPopup onClose={closeViewDetails} data={popupData} viewPhoto={viewPhoto} addPhoto={handleAddPhotoClick} />)}

        </div >
    );
};

export default Award;

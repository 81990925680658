import PieChartComponent from "../../graphs/PieChartComponent";
import { InfoOutlined } from "@mui/icons-material";
import { Fade } from '@mui/material'
import React from "react";
import BlackToolTip from "../../buttons/BlackToolTip";

const QuartileTile = ({ heading, h1, h2, h3, h4, h5, lb1, lb2, lb3, lb4, lb5, v1 = 0, v2 = 0, v3 = 0, v4 = 0, v5 = 0, ir, message, loading }) => {
    const data = [
        { label: h1, value: v1, color: 'bg-purple5' },
        { label: h2, value: v2, color: 'bg-purple4' },
        { label: h3, value: v3, color: 'bg-purple3' },
        { label: h4, value: v4, color: 'bg-purple2' },
        { label: h5, value: v5, color: 'bg-purple1' },
    ];

    const renderTable = () => {
        return (
            data.map((item, index) => (
                <React.Fragment key={index}>
                    <label className={`flex text-p col-span-20`}>
                        {item.label && <>
                            <div className={`rounded-full mt-1 w-3 h-3 ml-3 mr-4 ${item.color}`} />
                            {item.label}
                        </>}
                    </label>
                    <p className="col-span-12 text-p">{item.label && (item.value ? item.value : 0)}</p>
                </React.Fragment>
            ))
        );
    }

    return (
        <div className="grid sm:grid-cols-2 h-full rounded-lg w-full bg-white-pri p-2 px-5">
            <div className="flex items-center justify-between w-full p-1 border-b-2 col-span-full">
                <h5 className="h6-p">{heading}</h5>
                {message &&
                    <BlackToolTip title={message} className="cursor-pointer" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                        <InfoOutlined className="text-base text-slate-900" />
                    </BlackToolTip>
                }
            </div>

            <div className="flex items-end justify-center w-full col-span-1">
                <PieChartComponent
                    h1={lb1} h2={lb2} h3={lb3} h4={lb4} h5={lb5}
                    v1={v1} v2={v2} v3={v3} v4={v4} v5={v5} ir={ir}
                    loading={loading}
                />
            </div>

            <div className="grid w-full h-full col-span-1 pb-3 mt-2 mr-4 text-center grid-cols-32 gap-y-2 sm:gap-y-1 sm:pt-3 sm:pb-0">
                {renderTable()}
            </div>

        </div>
    );
};

export default QuartileTile;

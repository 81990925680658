/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { usePostData } from './usePostData';
import { useSnackbar } from './useSnackbar';
import { useSetRecoilState } from 'recoil';
import { loadingState, uploadFileDataState } from '../recoil/state';
import { useNavigate } from 'react-router-dom';
const useImagePopup = (folder, path) => {
    const { handleSnackbarOpen } = useSnackbar();
    const navigate = useNavigate();

    const [imagePopup, setImagePopup] = useState(false);
    const [imagesURL, setImagesURL] = useState([]);

    const setUploadFileData = useSetRecoilState(uploadFileDataState);
    const setIsLoading = useSetRecoilState(loadingState);

    const openImagePopup = async (subFolder) => {
        const body = { folder, subFolder };

        setIsLoading(true)
        const { success, data: urls } = await usePostData('/cloud/getMultipleView', body);
        setIsLoading(false)

        if (!success) return handleSnackbarOpen("Oops! We couldn't find any images from your vault.", 'warning', 'top', 'center');
        setImagePopup(true);
        setImagesURL(urls);
    };

    const closeImagePopup = () => setImagePopup(false);

    const deletePhoto = async (key, id) => {
        setIsLoading(true)
        const body = { key };
        const { success } = await usePostData('/cloud/deleteKey', body);
        if (success) {
            const updatedUrls = imagesURL.filter(urlObj => urlObj.key !== key);
            setImagesURL(updatedUrls);

            const body = { type: folder, id, count: 1, modificationType: 'dec' };
            const { success } = await usePostData('/cloud/updateDocumentFlag', body);
            setIsLoading(false)
            closeImagePopup();

            if (!success)
                return handleSnackbarOpen("We're sorry, something went wrong while updating the image flag.", 'warning', 'top', 'center', 'delete');

            return handleSnackbarOpen('Image Deleted Successfully', 'success', 'top', 'center', 'delete');
        }

        handleSnackbarOpen("We're sorry, something went wrong while deleting the image.", 'warning', 'top', 'center');
        setIsLoading(false)
    };

    const addPhoto = (id) => {
        setIsLoading(true)
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];

        setUploadFileData({
            path, folder, subFolder: id,
            contentType: 'image/jpeg', extension: 'jpeg',
            allowedTypes: allowedTypes
        })

        setIsLoading(false)
        navigate(`/user/upload?type=${path}`)
    }


    return {
        addPhoto,
        imagePopup,
        imagesURL,
        openImagePopup,
        closeImagePopup,
        deletePhoto,
    };
};

export default useImagePopup;

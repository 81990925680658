import React from 'react'
import Test2 from './test2'
import Test3 from './test3'

const Test = () => {
    return (
        <div className='w-full'><Test2 /></div>
    )
}

export default Test;
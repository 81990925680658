import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RadarChart, PolarGrid, PolarAngleAxis, Radar, ResponsiveContainer, Text } from 'recharts';

const RadarGraph = ({ data, className, heading }) => {
    const largeScreen = useMediaQuery('(min-width:1400px)');
    const smallScreen = useMediaQuery('(min-width:640px)');

    const renderCustomAxisTick = (props) => {
        const { x, y, payload } = props;
        const value = payload?.value;
        return (
            <Text x={x} y={y} textAnchor="middle" verticalAnchor="middle" fontSize={12} fill="#666" className='text-p'>
                {value}
            </Text>
        );
    };

    return (
        <div className={className}>
            {/* <h2 className='h5-p mt-3'>{heading}</h2> */}
            <ResponsiveContainer width="98%" height="98%">
                <RadarChart outerRadius={largeScreen ? 175 : smallScreen ? 150 : 100} innerRadius={25} data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="label" tick={renderCustomAxisTick} />
                    <Radar name="Score" dataKey="total" stroke="#4F38C9" fill="#4F38C9" fillOpacity={0.7} />
                </RadarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default RadarGraph;

import React, { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import JournalPopup from "../../../../components/popupDetailed/JournalPopup";
import PortalPopup from "../../../../components/popups/PortalPopup";

import UserContext from "../../../../context/UserContext";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import OverYearsTile from "../../../../components/Tiles/OverYearsTile";
import QuartileTile from "../../../../components/Tiles/QuartileTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";

import useAcademicYear from "../../../../hooks/useAcademicYear";
import usePDFPopup from "../../../../hooks/usePDFPopup";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const JournalPage = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Journal' },
    { name: 'Journal', path: '/user/publication?tab=Journal' }
  ]);

  const currentYear = useAcademicYear();
  const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();
  const [year, setYear] = useState(`${currentYear}-${(currentYear + 1).toString().slice(-2)}`);

  const { data: journalData, isLoading, refreshData } = useGetData('/api/journal/getJournals');
  const { data: statsAT } = useGetData(`/api/stats/journal/getStats?year=total`);
  const { data: statsCY } = useGetData(`/api/stats/journal/getStats?year=${year}`);

  const { data: statsQT } = useGetData(`/api/stats/journal/getStatsQT?year=${year}`);
  const { data: statsQP } = useGetData(`/api/stats/journal/getStatsQP?year=${year}`);
  const { data: statsOverYears } = useGetData(`/api/stats/journal/getStatsOverYears?year=${year}`);

  const { deleteJournal } = useContext(UserContext);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
  };

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'paperTitle', label: 'Title of Paper', minWidth: 230 },
    { id: 'category', label: 'Category', minWidth: 90 },
    { id: 'journalType', label: 'Type' },
    { id: 'formattedDate', label: 'Date of Publication', minWidth: 100 },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: "operation", label: "___", minWidth: 155, align: "center" },
  ];

  const navigate = useNavigate();
  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/publication/addJournal");
  }, [navigate]);

  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Journal', deleteJournal, refreshData, journalData, ['authors']);
  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('journals', 'publication?tab=Journal', 'single');

  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };


  return (
    <div className="flex flex-col gap-y-5">
      <section className="flex flex-col w-full p-5 rounded-lg bg-white-sec space-y-5">
        <div className="flex flex-col items-center justify-between space-y-5 xs:flex-row xs:space-y-0 ">
          <h3 className="h2-p">Published Journals</h3>
          <FormControl className="w-full xs:w-36" color="primary" size="small" variant="outlined">
            <InputLabel id="year-label" size="small">Academic Year</InputLabel>

            <Select labelId="year-label" label="Academic Year" name="year" size="small" defaultValue={`${currentYear}-${(currentYear + 1).toString().slice(-2)}`} onChange={handleYearChange}
              MenuProps={{ PaperProps: { style: { maxHeight: "150px" } } }}>
              <MenuItem value="total" size="small">Total</MenuItem>
              {years.map((year) => {
                const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                return (
                  <MenuItem key={formattedYear} value={formattedYear} size="small">
                    {formattedYear}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="grid w-full md:grid-cols-2 xl:grid-cols-4 gap-5">
          <AnalysisTile
            heading="Total Published Journals"
            value={statsAT?.published}
            icon={'/tabs/round/JournalPurple.svg'}
            message={'Published & verified Journal only considered'}
          />

          <AnalysisTile
            heading={`Published Journals ${year === 'total' ? '' : year}`}
            value={statsCY?.published}
            icon={'/tabs/round/JournalPurple.svg'}
            message={'Published & verified Journal only considered'}
          />
          <div className="col-span-full md:col-span-2">
            <ClassificationTileSec
              heading={`Summary of Published Journals ${year === 'total' ? '' : year}`}
              h1='International'
              h2='National'
              v1={statsCY?.internationalPublished}
              v2={statsCY?.nationalPublished}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-5 xl:grid-cols-2">
          <QuartileTile
            heading={`Journal Quartile Segregation ${year === 'total' ? '' : year}`}
            h1={'Q 1'} h2={'Q 2'} h3={'Q 3'} h4={'Q 4'} h5={'N/A'}
            lb1={'Q 1'} lb2={'Q 2'} lb3={'Q 3'} lb4={'Q 4'} lb5={'N/A'}
            v1={statsQT?.quartiles['Q1']} v2={statsQT?.quartiles['Q2']}
            v3={statsQT?.quartiles['Q3']} v4={statsQT?.quartiles['Q4']}
            v5={statsQT?.quartiles?.['N/A']}
            message={'Published & verified Journals only considered'}
          />

          <QuartileTile
            heading={`Quartile Provider's Segregation ${year === 'total' ? '' : year}`}
            h1={'JCR'} h2={'Scopus'} h3={'Google'} h4={'Others'} h5={'N/A'}
            lb1={'JCR'} lb2={'S'} lb3={'G'} lb4={'O'} lb5={'N/A'}
            v1={statsQP?.quartileProviders['JCR']} v2={statsQP?.quartileProviders['Scopus']}
            v3={statsQP?.quartileProviders['Google']} v4={statsQP?.quartileProviders['Others']}
            v5={statsQP?.quartileProviders?.naQuartileProviderCount}
            ir={50}
            message={'Published & verified Journals only considered'}
          />
        </div >

        <OverYearsTile data={statsOverYears?.yearQuartileCounts}
          heading={'Journals Published over Years'}
          message={'Published & verified Journals only considered'}
        />

      </section >

      <div className="grid gap-5 p-5 rounded-lg sm:grid-cols-2 xl:grid-cols-4 bg-white-sec">
        <AnalysisTile
          heading={`Total Accepted Journals`}
          value={statsAT?.accepted}
          icon={'/tabs/round/JournalBlue.svg'}
        />

        <AnalysisTile
          heading={`Accepted Journals in ${currentYear}-${(currentYear + 1)?.toString()?.slice(-2)}`}
          value={statsCY?.accepted}
          icon={'/tabs/round/JournalBlue.svg'}
        />

        <AnalysisTile
          heading={`Total Submitted Journals`}
          value={statsAT?.accepted}
          icon={'/tabs/round/JournalOrange.svg'}
        />

        <AnalysisTile
          heading={`Submitted Journals in ${currentYear}-${(currentYear + 1)?.toString()?.slice(-2)}`}
          value={statsCY?.accepted}
          icon={'/tabs/round/JournalOrange.svg'}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-full py-6 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec">
        <UploadButton text='Journal' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Journals' />
      </div>

      <div className=" rounded-lg bg-white-sec">
        <Table
          heading={'Total Journals'}
          text={'Uploaded Journals'}
          columns={columns}
          data={journalData}
          yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
          sortParameters={'formattedDate'}
          multiUsers={'authors'}
          statusDateCol={'lastVerified'}
          document={'document'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen && (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails}        >
          <JournalPopup onClose={closeViewDetails} journal={popupData} viewPDF={viewPDF} deletePDF={deletePDF} uploadPDF={uploadPDF} />
        </PortalPopup>
      )}
    </div >
  );
};

export default JournalPage;
import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import { Fade } from '@mui/material'
import BlackToolTip from "../../buttons/BlackToolTip";


const AnalysisTile = ({ heading, value = 0, icon, loading, message }) => {
  return (
    <div className='flex flex-col justify-between w-full rounded-lg p-3 font-[Poppins] h-48 bg-bgPrimary min-w-[230px]'>
      <div className="flex justify-between w-full">
        <div className="flex flex-col space-y-3 ">
          <p className="h-8 text-sm h6-s">{heading}</p>
          <h5 className="text-4xl font-semibold text-slate-800">
            {value}
          </h5>
        </div>
        <div className="ml-3"><img className="w-[52px] h-[52px]" alt="" src={icon} /></div>
      </div>

      <div className="grid row-span-3 space-y-3">
        <div className="grid grid-cols-4">
          <span className="h-1 rounded-l-full bg-purple5 " />
          <span className="h-1 bg-purple3" />
          <span className="h-1 bg-purple2" />
          <span className="h-1 rounded-r-full bg-purple1" />
        </div>

        <div className="flex items-end justify-between">
          <div className="flex items-center">
            {message ?
              <BlackToolTip title={message} className="cursor-pointer" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                <InfoOutlined className="text-base text-slate-900" />
              </BlackToolTip>

              : <img className="rounded-lg w-5 h-5 ml-[2px]"
                alt="" src={'/icons/Bar.svg'} />
            }
          </div>
          <img
            className="relative w-[18px] h-5"
            alt=""
            src={'/icons/Triangle.svg'}
          />
        </div>
      </div>
    </div >
  );
};

export default AnalysisTile;
import OverYearsTile from "../../../../components/Tiles/OverYearsTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import BookRowSec from "../../../../components/rows/BookRowSec";
import BookRow from "../../../../components/rows/BookRow";
import { useState } from "react";

import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";

import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useGetData from "../../../../hooks/useGetData";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const BookChapterAnalysis = () => {
    useSetBreadcrumb([
        { name: 'Publication', path: '/admin/PublicationAD?tab=Book+Chapter&type=Recent' },
        { name: 'BookChapter', path: '/admin/PublicationAD?tab=Book+Chapter&type=Recent' },
        { name: 'Analysis', path: '/admin/PublicationAD/bookChapterAnalysis' }
    ]);


    const currentYear = useAcademicYear();
    const defaultYear = new Date().getFullYear();
    const [year, setYear] = useState(defaultYear - 1);

    const { data: topPerformersCY, isLoadingCY } = useGetData('/api/stats/bookChapter/getTopPerformers?school=total');
    const { data: topPerformersLY, isLoadingLY } = useGetData(`/api/stats/bookChapter/getTopPerformer?school=total&year=${currentYear - 1}`);
    const { data: topPerformerAT, isLoadingAT } = useGetData('/api/stats/bookChapter/getTopPerformer?school=total&year=total');
    const { data: bookChapterStatsYear, isLoadingStatsYear } = useGetData(`/api/stats/bookChapter/getStats?school=total&year=${year}`);
    const { data: bookChapterStatsAT, isLoadingStatsAT } = useGetData('/api/stats/bookChapter/getStats?school=total&year=total');
    const { data: statsOverYears, isLoadingStatsOverYears } = useGetData('/api/stats/bookChapter/getStatsOverYears?school=total');
    const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();

    const handleChange = (event) => { setYear(event.target.value); };

    return (
        <div className="flex flex-col space-y-5 w-full">
            <section className="grid sm:grid-cols-2 2xl:grid-cols-4 rounded-lg w-full p-5 gap-5 bg-white-sec">
                <div className="flex items-center justify-between col-span-full">
                    <h2 className="h2-p text-xl">Published Book Chapter</h2>
                    <FormControl className="w-36" color="primary" size="small" variant="outlined">
                        <InputLabel id="year-label" size="small">
                            Academic Year
                        </InputLabel>
                        <Select labelId="year-label" label="Academic Year" name="year" size="small"
                            defaultValue={currentYear}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: { style: { maxHeight: "150px" } },
                            }}>
                            {years?.map((year) => {
                                const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                                return (
                                    <MenuItem key={formattedYear} value={year} size="small">
                                        {formattedYear}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>

                <AnalysisTile
                    heading="Total Published"
                    value={bookChapterStatsAT?.published}
                    icon={'/tabs/round/BookChapterPurple.svg'}
                />
                <AnalysisTile
                    heading={`Published in ${year}-${(year + 1) % 100}`}
                    value={bookChapterStatsYear?.published}
                    icon={'/tabs/round/BookChapterPurple.svg'}

                />

                <div className="sm:col-span-2 col-span-1">
                    <ClassificationTileSec
                        heading={`Summary of Total Published`}
                        h1={'International'}
                        h2={'National'}
                        v1={bookChapterStatsAT?.internationalPublished}
                        v2={bookChapterStatsAT?.nationalPublished}
                    />
                </div>

                <div className="col-span-full">
                    <OverYearsTile data={statsOverYears} heading={'BookChapters Published Over Year'} />
                </div>
            </section>

            <section className="grid xl:grid-cols-2 gap-5 w-full">
                <div className="grid sm:grid-cols-2 p-5 gap-5 rounded-lg bg-white-sec">
                    <h5 className="h2-p text-xl col-span-full">Accepted Book Chapters</h5>

                    <AnalysisTile
                        heading="Total Accepted"
                        value={bookChapterStatsAT?.accepted}
                        icon={'/tabs/round/BookChapterBlue.svg'}
                    />

                    <AnalysisTile
                        heading={`Accepted in ${year}-${(year + 1) % 100}`}
                        icon={'/tabs/round/BookChapterBlue.svg'}
                        value={bookChapterStatsYear?.accepted}
                    />

                </div>

                <div className="grid sm:grid-cols-2 p-5 gap-5 rounded-lg bg-white-sec">
                    <h5 className="h2-p text-xl col-span-full">Submitted Book Chapters</h5>
                    <AnalysisTile
                        heading="Total Submitted"
                        value={bookChapterStatsAT?.submitted}
                        icon={'/tabs/round/BookChapterOrange.svg'}
                    />

                    <AnalysisTile
                        heading={`Submitted in ${year}-${(year + 1) % 100}`}
                        value={bookChapterStatsYear?.submitted}
                        icon={'/tabs/round/BookChapterOrange.svg'}
                    />

                </div>
            </section>

            <section className="flex flex-col rounded-lg bg-white-sec w-full p-5 gap-3">
                <div className="flex w-full justify-between items-center mb-3">
                    <h5 className="h2-p text-xl">Exemplary Performer of {currentYear}-{(currentYear + 1).toString().slice(2, 4)}</h5>
                    <IconButton>
                        <LaunchOutlinedIcon style={{ color: "#010851" }} />
                    </IconButton>
                </div>


                <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                        <img className="mr-3" alt="" src="/facultyphoto.svg" />
                        <p className="h5-p ">{topPerformersCY?.highest?.name}</p>
                    </div>

                    <IconButton>
                        <OpenInFullOutlinedIcon style={{ color: "red" }} />
                    </IconButton>
                </div>

                <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-5">
                    <AnalysisTile
                        heading='Total Published BookChapters'
                        value={topPerformersCY?.highest?.publishedAT}
                        icon={'/tabs/round/BookChapterPurple.svg'}
                    />

                    <AnalysisTile
                        heading={`Published BookChapters in ${currentYear}-${(currentYear + 1) % 100}`}
                        value={topPerformersCY?.highest?.published}
                        icon={'/tabs/round/BookChapterPurple.svg'}
                    />

                    <div className="col-span-1 sm:col-span-2">
                        <ClassificationTileSec
                            heading={`Published BookChapters in ${currentYear}-${(currentYear + 1) % 100}`}
                            h1={'International'}
                            h2={'National'}
                            v1={topPerformersCY?.highest?.internationalPublished}
                            v2={topPerformersCY?.highest?.nationalPublished}
                        />
                    </div>

                </div>

                <hr className="w-full h-[1.5px] bg-zinc-400 border-none" />

                <BookRowSec
                    name={topPerformersCY?.secondHighest?.name}
                    published={topPerformersCY?.secondHighest?.published}
                    accepted={topPerformersCY?.secondHighest?.accepted}
                    submitted={topPerformersCY?.secondHighest?.submitted}
                    national={topPerformersCY?.secondHighest?.nationalPublished}
                    international={topPerformersCY?.secondHighest?.internationalPublished} />

                <hr className="w-full h-[1.5px] bg-zinc-400 border-none" />

                <BookRowSec
                    name={topPerformersCY?.thirdHighest?.name}
                    published={topPerformersCY?.thirdHighest?.published}
                    accepted={topPerformersCY?.thirdHighest?.accepted}
                    submitted={topPerformersCY?.thirdHighest?.submitted}
                    national={topPerformersCY?.thirdHighest?.nationalPublished}
                    international={topPerformersCY?.thirdHighest?.internationalPublished} />

                <hr className="w-full h-[1.5px] bg-zinc-400 border-none" />
            </section>

            <section className="flex flex-col col-span-5 p-5 rounded-lg bg-[#f1f7f8] w-full">
                <h5 className="h2-p text-xl">Exceptional virtuoso of All Time</h5>
                <BookRow
                    name={topPerformerAT?.highest?.name}
                    published={topPerformerAT?.highest?.published}
                    accepted={topPerformerAT?.highest?.accepted}
                    submitted={topPerformerAT?.highest?.submitted}
                    national={topPerformerAT?.highest?.nationalPublished}
                    international={topPerformerAT?.highest?.internationalPublished}
                />

                <h5 className="h5-p">Exemplary Performer of 2022</h5>
                <BookRow
                    name={topPerformersLY?.highest?.name}
                    published={topPerformersLY?.highest?.published}
                    accepted={topPerformersLY?.highest?.accepted}
                    submitted={topPerformersLY?.highest?.submitted}
                    national={topPerformersLY?.highest?.nationalPublished}
                    international={topPerformersLY?.highest?.internationalPublished}
                />
            </section>
        </div>
    );
};

export default BookChapterAnalysis;
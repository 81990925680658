import React from 'react';

const PatentPopTab = ({ heading, data }) => {
    const filteredData = data?.filter(item => item?.name || item?._id);

    return (
        <div className='flex flex-col space-y-3 mt-8 w-full'>
            <h5 className='h5-p text-center'>{heading}</h5>

            <table className="w-full border-2">
                <thead>
                    <tr className="grid grid-cols-32 gap-1 py-3 bg-zinc-200 text-left">
                        <th className="lb-p col-span-6 break-words">Name</th>
                        <th className="lb-p col-span-12 break-words">Affiliation Details</th>
                        <th className="lb-p col-span-2 break-words">School</th>
                        <th className="lb-p col-span-3 break-words">Designation</th>
                        <th className="lb-p col-span-6 break-words">Email ID</th>
                        <th className="lb-p col-span-3 break-words">Phone No.</th>
                    </tr>
                </thead>
                <tbody className='flex flex-col space-y-5'>
                    {filteredData?.map((item, index) => (
                        <tr className="grid grid-cols-32 gap-1 py-2" key={index}>
                            <td className="text-p font-normal break-words col-span-6">{item?.name}</td>
                            <td className="text-p font-normal break-words col-span-12">{item?.affiliationDetail ? (item?.affiliationDetail === 'Others' ? item?.otherAffiliation : item?.affiliationDetail) : '-'}</td>
                            <td className="text-p font-normal break-words col-span-2">{item?.school || '-'}</td>
                            <td className="text-p font-normal break-words col-span-3">{item?.designation || '-'}</td>
                            <td className="text-p font-normal break-words col-span-6">{item?.emailID || '-'}</td>
                            <td className="text-p font-normal break-words col-span-3">{item?.phoneNo || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PatentPopTab;

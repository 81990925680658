import { useSearchParams } from "react-router-dom";
import Attended from "./attended/Attended";
import Conducted from "./conducted/Conducted";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";

const Events = () => {
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    return (
        <div className="flex flex-col space-y-5 w-full">
            <ThreeWayTab
                btn1={"Events Conducted"} btn2={"Events Attended"}
                icon1={<img src='/tabs/Conducted.svg' />} icon2={<img src='/tabs/Attended.svg' />}
                iconDisabled1={<img src='/tabs/ConductedGrey.svg' />} iconDisabled2={<img src='/tabs/AttendedGrey.svg' />}
            />
            {tab === "Events Conducted" && <Conducted />}
            {tab === "Events Attended" && <Attended />}
        </div>
    );
};

export default Events;

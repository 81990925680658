import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import { useState } from 'react';
import Button from '@mui/material/Button'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Carousel({ data, autoPlay = true, onStepChange }) {
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = data.length;

    const handleStepChange = (step) => {
        setActiveStep(step);
        if (onStepChange) onStepChange(step);
    };

    const handleNext = () => {
        if (onStepChange) onStepChange(activeStep + 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (onStepChange) onStepChange(activeStep - 1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className='w-full'>
            <AutoPlaySwipeableViews
                className={!autoPlay && 'overflow-y-auto h-[350px]'}
                // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={autoPlay ? handleStepChange : null}
                enableMouseEvents
            >
                {data.map((step, index) => (
                    <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            data[activeStep]?.imgPath && <div className="relative">
                                <img
                                    className="block w-full h-fit"
                                    src={step.imgPath}
                                    alt={step.label}
                                    loading="eager"
                                />
                            </div>
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>


            <MobileStepper
                className='bg-slate-50'
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        disableRipple
                        className='px-3 text-white bg-blue-sec hover:bg-blue-Pri drop-shadow-none shadow-none disabled:bg-zinc-300'
                        // endIcon={theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    >
                        Next
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disableRipple disabled={activeStep === 0}
                        className='px-3 text-white bg-blue-sec hover:bg-blue-Pri drop-shadow-none shadow-none disabled:bg-zinc-300'
                        // startIcon={theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    >
                        Back
                    </Button>
                }
            />
        </div>
    );
}

export default Carousel;
import { Route, Routes, useLocation } from 'react-router-dom';
import TopNavbar from '../components/NavBar/TopNavbar';
import NavBarAD from '../components/NavBar/SideNavbar/NavBar';

import Home from '../pages/user/home/Home';
import Publication from '../pages/user/publication/Publication';
import AddJournalPage from '../pages/user/publication/journal/AddJournal';
import EditJournalPage from '../pages/user/publication/journal/EditJournal';
import AddBookChapter from '../pages/user/publication/bookChapter/AddBookChapter';
import EditBookChapter from '../pages/user/publication/bookChapter/EditBookChapter';
import AddConference from '../pages/user/publication/conference/addConference';
import EditConference from '../pages/user/publication/conference/EditConference';

import Event from '../pages/user/event/Events';
import AddAttended from '../pages/user/event/attended/AddAttended';
import EditAttended from '../pages/user/event/attended/EditAttended';
import AddConducted from '../pages/user/event/conducted/AddConducted';
import EditConducted from '../pages/user/event/conducted/EditConducted';

import Project from '../pages/user/project/Project';
import AddConsultancy from '../pages/user/project/consultancy/AddConsultancy';
import EditConsultancy from '../pages/user/project/consultancy/EditConsultancy';
import AddFundedProject from '../pages/user/project/RnD/AddFundedProject';
import EditFundedProject from '../pages/user/project/RnD/EditFundedProject';

import Patents from '../pages/user/patent/Patents';
import AddPatent from '../pages/user/patent/patent/AddPatent';
import EditPatent from '../pages/user/patent/patent/EditPatent';

import Copyright from '../pages/user/copyright/Copyright';
import AddCopyrights from '../pages/user/copyright/AddCopyright';
import EditCopyright from '../pages/user/copyright/EditCopyright';
import Award from '../pages/user/award/Award';
import AddAward from '../pages/user/award/AddAward'
import EditAward from '../pages/user/award/EditAward';

import Support from '../pages/user/support/Support';
import Settings from '../pages/user/Others/Settings';
import Notifications from '../pages/user/Others/notification/Notifications';

import UploadProof from '../pages/others/UploadProof';
import UploadImage from '../pages/others/UploadImage';
import Upload from '../pages/others/Upload';
import AdminRoutes from './AdminRoutes';
import useAuthenticate from '../hooks/useAuthenticate';

import AddBook from '../pages/user/publication/book/AddBook';
import EditBook from '../pages/user/publication/book/EditBook';
import AddDesignPatent from '../pages/user/patent/designPatent/AddDesignPatent';
import EditDesignPatent from '../pages/user/patent/designPatent/EditDesignPatent';

import Test from '../Test';

const UserRoutes = () => {
    const location = useLocation();
    const shouldRenderNavBar = location.pathname !== '/';
    const { isUser, isAdmin } = useAuthenticate();

    return (
        <div className="flex w-full">
            <div className='duration-300 ease-in-out transition-transform w-fit'>
                <NavBarAD />
            </div>

            <div className='flex-col flex-grow overflow-x-hidden transition-all duration-300 ease-in-out'>
                {shouldRenderNavBar && <TopNavbar />}
                <div className='flex w-full min-h-screen px-3 my-10 transition'>
                    {(isUser || isAdmin) &&
                        <Routes>
                            {/* <Route exact path="*" element={<DefaultPage />} /> */}
                            <Route exact path="user/home" element={<Home />} />
                            <Route path="/test" element={<Test />} />

                            <Route exact path="user/publication" element={<Publication />} />
                            <Route exact path="user/publication/addJournal" element={<AddJournalPage />} />
                            <Route exact path="user/publication/editJournal" element={<EditJournalPage />} />
                            <Route exact path="user/publication/bookChapter/addBookChapter" element={<AddBookChapter />} />
                            <Route exact path="user/publication/bookChapter/editBookChapter" element={<EditBookChapter />} />
                            <Route exact path="user/publication/book/addBook" element={<AddBook />} />
                            <Route exact path="user/publication/book/editBook" element={<EditBook />} />
                            <Route exact path="user/publication/conference/addConference" element={<AddConference />} />
                            <Route exact path="user/publication/conference/editConference" element={<EditConference />} />

                            <Route exact path="user/events" element={<Event />} />
                            <Route exact path="user/events/attended/addAttended" element={<AddAttended />} />
                            <Route exact path="user/events/attended/editAttended" element={<EditAttended />} />
                            <Route exact path="user/events/Conducted/addConducted" element={<AddConducted />} />
                            <Route exact path="user/events/Conducted/EditConducted" element={<EditConducted />} />

                            <Route exact path="user/projects" element={<Project />} />
                            <Route exact path="user/projects/consultancy/addConsultancy" element={<AddConsultancy />} />
                            <Route exact path="user/projects/consultancy/editConsultancy" element={<EditConsultancy />} />

                            <Route exact path="user/projects/fundedProject/addFundedProject" element={<AddFundedProject />} />
                            <Route exact path="user/projects/fundedProject/editFundedProject" element={<EditFundedProject />} />

                            <Route exact path="user/patents" element={<Patents />} />
                            <Route exact path="user/patents/addPatent" element={<AddPatent />} />
                            <Route exact path="user/patents/editPatent" element={<EditPatent />} />

                            <Route exact path="user/designPatents/addDesignPatent" element={<AddDesignPatent />} />
                            <Route exact path="user/designPatents/editDesignPatent" element={<EditDesignPatent />} />

                            <Route exact path="user/copyrights" element={<Copyright />} />
                            <Route exact path="user/copyrights/addCopyright" element={<AddCopyrights />} />
                            <Route exact path="user/copyrights/editCopyright" element={<EditCopyright />} />

                            <Route exact path="user/awards" element={<Award />} />
                            <Route exact path="user/awards/addAward" element={<AddAward />} />
                            <Route exact path="user/awards/editAward" element={<EditAward />} />

                            <Route exact path="user/uploadProof" element={<UploadProof />} />
                            <Route exact path="user/uploadImage" element={<UploadImage />} />
                            <Route exact path="user/upload" element={<Upload />} />

                            <Route exact path="user/supports" element={<Support />} />
                            <Route exact path="user/settings" element={<Settings />} />

                            <Route exact path="user/notification" element={<Notifications />} />

                        </Routes>
                    }
                    {isAdmin && <AdminRoutes />}
                </div>
            </div>
        </div >
    );
};

export default UserRoutes;

/**
 * Formats a given date string to a string in the format "MMM YYYY" (e.g., "Jan 2023").
 * If the input date string is invalid, returns "N/A".
 *
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} - The formatted date string (e.g., "Jan 2023") or "N/A" if the date is invalid.
 */

function getMonthYear(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) return "N/A";

    const options = { year: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
}

export { getMonthYear };

import { Button } from '@mui/material';
import React from 'react';

const Scimago = ({ url, link }) => {
    return (
        <>
            <div className="text-2xl heading col-span-full">Data from Scimago</div>
            <div className="flex justify-center h-auto col-span-full">
                <img src={url} alt="Journal Image" />
            </div>
            <div className="flex flex-col col-span-full">
                <Button
                    size="large"
                    variant="outlined"
                    color="error"
                    className="px-8"
                    onClick={() => {
                        window.open(link, '_blank');
                    }}
                >
                    View on Scimago
                </Button>
            </div>
        </>
    );
};

export default Scimago;

import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";

import BlackToolTip from "../../../../buttons/BlackToolTip";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import TabSmall from "../../../../mui/TabSmall";
import AVConference from "./AVConference";
import RecentConference from "./RecentConference";
import useGetData from "../../../../hooks/useGetData";
import { Analytics, CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import DownloadPopup from "../../../../components/popups/DownloadPopup";

const ConferenceAD = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/admin/PublicationAD?tab=Conference&type=Recent' },
    { name: 'Conference', path: '/admin/PublicationAD?tab=Conference&type=Recent' }
  ]);

  const { data: conferenceDataAD, isLoading } = useGetData('/api/conference/getConferences?school=total');
  const { data: conferenceStats, isLoading: tileLoading } = useGetData('/api/stats/conference/getStats?school=total');
  const { data: conferenceStatsTotal, isLoading: tileLoadingTotal } = useGetData('/api/stats/conference/getStats?school=total&year=total');

  const navigate = useNavigate();
  const navigateToAnalysis = useCallback(() => {
    navigate("/admin/PublicationAD/ConferenceAnalysis");
  }, []);

  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('type');
  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];
  const { getAdminExcelData, openViewDetails, closeViewDetails, isViewPopupOpen } = useOperations('Conference', null, null, conferenceDataAD, ['authors']);
  const buttonInfo = [
    { title: 'Analysis', icon: <Analytics fontSize="large" className="text-[#010851]" />, onClick: navigateToAnalysis },
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: openViewDetails },
  ];

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid md:grid-cols-2 2xl:grid-cols-4 p-5  gap-5 w-full rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Total Published Conference"
          value={conferenceStatsTotal?.published}
          loading={tileLoadingTotal}
          icon={'/tabs/round/ConferencePurple.svg'}

        />
        <AnalysisTile
          heading={`Published Conferences in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.published}
          loading={tileLoading}
          icon={'/tabs/round/ConferencePurple.svg'}
        />
        <AnalysisTile
          heading={`Accepted Conferences in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.accepted}
          loading={tileLoading}
          icon={'/tabs/round/ConferenceBlue.svg'}
        />
        <AnalysisTile
          heading={`Submitted Conferences in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.submitted}
          loading={tileLoading}
          icon={'/tabs/round/ConferenceOrange.svg'}
        />
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>

        {activeTab === "Recent" && <RecentConference data={conferenceDataAD} loading={isLoading} />}
        {activeTab === "Abstract View" && <AVConference />}
      </div>

      {isViewPopupOpen && <DownloadPopup onClose={closeViewDetails} onDownload={getAdminExcelData} />}

    </div>
  );
};

export default ConferenceAD;

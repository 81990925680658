import { useState } from "react";
import useGetData from "../../../../hooks/useGetData";
import useAcademicYear from "../../../../hooks/useAcademicYear";

import { CustomAcademicYearPicker } from "../../../../mui/Input";
import { IconButton } from "@mui/material";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';

import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import OverYearsTile from "../../../../components/Tiles/OverYearsTile";
import QuartileTile from "../../../../components/Tiles/QuartileTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import FacRowSec from "../../../../components/rows/FacRowSec";
import FacRow from "../../../../components/rows/FacRow";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const JournalAnalysis = () => {
    useSetBreadcrumb([
        { name: 'Publication', path: '/admin/PublicationAD?tab=Journal&type=Recent' },
        { name: 'Journal', path: '/admin/PublicationAD?tab=Journal&type=Recent' },
        { name: 'Analysis', path: '/admin/PublicationAD/journalAnalysis' }
    ]);

    const currentYear = useAcademicYear();
    const lastYear = parseInt(currentYear) - 1;
    const [year, setYear] = useState(currentYear);

    const { data: statsAT } = useGetData('/api/stats/journal/getStats?school=total&year=total');
    const { data: statsCY, loading: loadingStatsCY } = useGetData(`/api/stats/journal/getStats?school=total&year=${year}`);
    const { data: statsQT } = useGetData(`/api/stats/journal/getStatsQT?school=total&year=${year}`);
    const { data: statsQP } = useGetData(`/api/stats/journal/getStatsQP?school=total&year=${year}`);
    const { data: statsOverYears } = useGetData(`/api/stats/journal/getStatsOverYears?school=total&year=${year}`);

    const { data: topPerformerCY } = useGetData(`/api/stats/journal/getTopPerformer?school=total&year=${currentYear}`);
    const { data: topPerformersCY } = useGetData(`/api/stats/journal/getTopPerformers?school=total&year=${currentYear}`);
    const { data: topPerformerAT } = useGetData(`/api/stats/journal/getTopPerformer?school=total&year=total`);
    const { data: topPerformerLY } = useGetData(`/api/stats/journal/getTopPerformer?school=total&year=${lastYear}`);
    const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();
    
    const handleChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
    };
    
    return (
        <div className="flex flex-col w-full space-y-5">
            <section className="flex flex-col w-full space-y-5 bg-white-sec p-2 sm:p-5">
                <header className="flex md:flex-row flex-col space-y-5 md:space-y-0 items-center justify-end">
                    {/* <h2 className="h5-p"> Published Journals </h2> */}
                    <CustomAcademicYearPicker className="w-full md:w-36" year={year} currentYear={currentYear} handleYearChange={handleChange} years={years} />
                </header>

                <div className="grid md:grid-cols-2 xl:grid-cols-4 rounded-lg gap-5 bg-white-sec">
                    <AnalysisTile
                        heading={`Published Journals`}
                        value={statsAT?.published}
                        icon={'/tabs/round/BookChapterPurple.svg'}
                    />

                    <AnalysisTile
                        heading={`Published Journals ${year === 'total' ? '' : year + '-' + ((parseInt(year) + 1) % 100)}`}
                        value={statsCY?.published}
                        icon={'/tabs/round/BookChapterPurple.svg'}
                    />

                    <div className="col-span-1 md:col-span-2">
                        <ClassificationTileSec
                            heading={`Summary of Journals published ${year === 'total' ? '' : year + '-' + ((parseInt(year) + 1) % 100)}`}
                            h1={'International'}
                            h2={'National'}
                            v1={statsCY?.internationalPublished}
                            v2={statsCY?.nationalPublished}
                            loading={loadingStatsCY}
                            icon={'/tabs/round/BookChapterPurple.svg'}
                        />
                    </div>

                    <div className="xl:col-span-2 col-span-full">
                        <QuartileTile
                            heading={'Journal Quartile Segregation'}
                            h1={'Q 1'} h2={'Q 2'} h3={'Q 3'} h4={'Q 4'} h5={'N/A'}
                            lb1={'Q 1'} lb2={'Q 2'} lb3={'Q 3'} lb4={'Q 4'} lb5={'N/A'}
                            v1={statsQT?.quartiles['Q1']} v2={statsQT?.quartiles['Q2']}
                            v3={statsQT?.quartiles['Q3']} v4={statsQT?.quartiles['Q4']}
                            v5={statsQT?.quartiles?.['N/A']}
                        />
                    </div>

                    <div className="xl:col-span-2 col-span-full">
                        <QuartileTile
                            heading={"Quartile Provider's"}
                            h1={'JCR'} h2={'Scopus'} h3={'Google'} h4={'Others'} h5={'N/A'}
                            lb1={'JCR'} lb2={'S'} lb3={'G'} lb4={'O'} lb5={'N/A'}
                            v1={statsQP?.quartileProviders['JCR']} v2={statsQP?.quartileProviders['Scopus']}
                            v3={statsQP?.quartileProviders['Google']} v4={statsQP?.quartileProviders['Others']}
                            v5={statsQP?.quartileProviders?.['N/A']}
                            ir={50}
                        />
                    </div>

                    <div className="col-span-full">
                        <OverYearsTile data={statsOverYears?.yearQuartileCounts} heading={'Journals Published Over Year'} />
                    </div>

                </div >
            </section>

            <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-5 p-5 bg-white-sec">
                <AnalysisTile
                    heading={'Journals Accepted'}
                    value={statsAT?.accepted}
                    icon={'/tabs/round/JournalOrange.svg'}
                />
                <AnalysisTile
                    heading={`Journals Accepted ${year === 'total' ? '' : year + '-' + ((parseInt(year) + 1) % 100)}`}
                    value={statsCY?.accepted}
                    icon={'/tabs/round/JournalOrange.svg'}
                />

                <AnalysisTile
                    heading={`Journals Submitted`}
                    value={statsAT?.submitted}
                    icon={'/tabs/round/JournalBlue.svg'}
                />
                <AnalysisTile
                    heading={`Journals Submitted ${year === 'total' ? '' : year + '-' + ((parseInt(year) + 1) % 100)}`}
                    value={statsCY?.submitted}
                    icon={'/tabs/round/JournalBlue.svg'}
                />
            </section>

            <section className="flex flex-col rounded-lg bg-white-sec w-full p-5 space-y-5">
                <div className="flex w-full justify-between items-center">
                    <h5 className="h5-p">Exemplary Performer of {currentYear}-{(currentYear + 1) % 100}</h5>
                    <IconButton>
                        <LaunchOutlinedIcon className="text-purple5" />
                    </IconButton>
                </div>

                <div className="flex w-full items-center justify-between">
                    <div className="flex items-center space-x-3">
                        <img className="h-10 w-10" alt="" src="/profile.svg" />
                        <h3 className="h5-s">{topPerformerCY?.highest?.name}</h3>
                        <h5 className="h5-p sm:ml-10">{topPerformerCY?.highest?.published}</h5>
                    </div>

                    <IconButton>
                        <OpenInFullOutlinedIcon className="text-red-600" />
                    </IconButton>
                </div>

                <div className="grid xl:grid-cols-2 gap-5">
                    <QuartileTile
                        heading={'Journal Quartile Segregation'}
                        h1={'Q 1'} h2={'Q 2'} h3={'Q 3'} h4={'Q 4'} h5={'N/A'}
                        lb1={'Q 1'} lb2={'Q 2'} lb3={'Q 3'} lb4={'Q 4'} lb5={'N/A'}
                        v1={topPerformerCY?.highest?.q1Count} v2={topPerformerCY?.highest?.q2Count}
                        v3={topPerformerCY?.highest?.q3Count} v4={topPerformerCY?.highest?.q4Count}
                        v5={topPerformerCY?.highest?.naCount}
                    />

                    <QuartileTile
                        heading={'Quartile Provider'}
                        h1={'JCR'} h2={'Scopus'} h3={'Google'} h4={'Others'} h5={'N/A'}
                        lb1={'JCR'} lb2={'S'} lb3={'G'} lb4={'O'} lb5={'N/A'}
                        v1={topPerformerCY?.highest?.scimago} v2={topPerformerCY?.highest?.scopus}
                        v3={topPerformerCY?.highest?.google} v4={topPerformerCY?.highest?.others}
                        v5={topPerformerCY?.highest?.naQuartileProviderCount}
                        ir={50}
                    />
                </div>

                <hr className="w-full h-0.5 border-none bg-gray-500" />
                <FacRowSec
                    name={topPerformersCY?.secondHighest?.name}
                    totalJournals={topPerformersCY?.secondHighest?.published}
                    nationalJournals={topPerformersCY?.secondHighest?.nationalPublished}
                    internationalJournals={topPerformersCY?.secondHighest?.internationalPublished}
                    q1Count={topPerformersCY?.secondHighest?.q1Count}
                    q2Count={topPerformersCY?.secondHighest?.q2Count}
                    q3Count={topPerformersCY?.secondHighest?.q3Count}
                    q4Count={topPerformersCY?.secondHighest?.q4Count}
                    naCount={topPerformersCY?.secondHighest?.naCount}
                />
                <hr className="w-full h-0.5 border-none bg-gray-500" />
                <FacRowSec
                    name={topPerformersCY?.thirdHighest?.name}
                    totalJournals={topPerformersCY?.thirdHighest?.published}
                    nationalJournals={topPerformersCY?.thirdHighest?.nationalPublished}
                    internationalJournals={topPerformersCY?.thirdHighest?.internationalPublished}
                    q1Count={topPerformersCY?.thirdHighest?.q1Count}
                    q2Count={topPerformersCY?.thirdHighest?.q2Count}
                    q3Count={topPerformersCY?.thirdHighest?.q3Count}
                    q4Count={topPerformersCY?.thirdHighest?.q4Count}
                    naCount={topPerformersCY?.thirdHighest?.naCount}
                />
                <hr className="w-full h-0.5 border-none bg-gray-500" />
            </section>

            <section className="relative py-5 px-7 rounded-2xl bg-[#f1f7f8] w-full my-7">
                <h5 className="h5-p">Exceptional virtuoso of All Time</h5>
                <FacRow
                    facName={topPerformerAT?.highest?.name}
                    secCol={topPerformerAT?.highest?.published}
                    thirdCol={topPerformerAT?.highest?.nationalPublished}
                    fourthCol={topPerformerAT?.highest?.internationalPublished}
                    fifthCol={topPerformerAT?.highest?.q1Count}
                    sixthCol={topPerformerAT?.highest?.q2Count}
                    sevCol={topPerformerAT?.highest?.q3Count}
                    eightCol={topPerformerAT?.highest?.q4Count}
                    ninthCol={topPerformerAT?.highest?.naCount} />
                <h5 className="h5-p">Exemplary Performer of {currentYear - 1}</h5>

                <FacRow
                    facName={topPerformerLY?.highest?.name}
                    secCol={topPerformerLY?.highest?.published}
                    thirdCol={topPerformerLY?.highest?.nationalPublished}
                    fourthCol={topPerformerLY?.highest?.internationalPublished}
                    fifthCol={topPerformerLY?.highest?.q1Count}
                    sixthCol={topPerformerLY?.highest?.q2Count}
                    sevCol={topPerformerLY?.highest?.q3Count}
                    eightCol={topPerformerLY?.highest?.q4Count}
                    ninthCol={topPerformerLY?.highest?.naCount}
                />
            </section>
        </div>
    );
};

export default JournalAnalysis;

import React from 'react'
import { CustomSelect, CustomYearMonthPicker } from '../../mui/Input';
import PortalPopup from './PortalPopup';
import useFormOperations from '../../hooks/useFormOperations';
import { Button } from '@mui/material';

const DownloadPopup = ({ onClose, onDownload, AVCount = true }) => {
    const buttons = [
        { label: 'Complete Data Format', key: 'complete' },
        AVCount && { label: 'Faculty Count Format', key: 'count' },
    ]
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const { formData, handleChange, setValue } = useFormOperations({ endYear: currentYear, endMonth: currentMonth, status: 'Verified' });
    const disableDownload = !Boolean(formData.school && formData.startYear && formData.startMonth && formData.endYear && formData.endMonth);

    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={onClose}>
            <div className="flex flex-col p-5 space-y-10 w-full sm:w-fit lg:min-w-[600px] bg-white rounded-lg">
                <div className="flex space-x-5 w-full">
                    <img className="w-12 h-12" alt="" loading="eager" src='/icons/Download.svg' />
                    <div className='flex flex-col w-full' >
                        <h2 className="h5-p flex justify-between w-full">
                            Download Excel Sheet
                            <button onClick={onClose}>
                                <img className="w-5 h-5 " alt="" loading="eager" src="/cross.svg" />
                            </button>
                        </h2>
                        <p className="text-s text-xs">Choose the Format to download</p>
                    </div>
                </div>


                <div className='grid grid-cols-32 gap-3 w-full place-items-center select-none'>
                    <CustomSelect
                        className='col-span-15 w-full'
                        label="Department"
                        options={[
                            { value: 'DCET', label: 'DCET' },
                            { value: 'DEET', label: 'DEET' },
                            { value: 'DMET', label: 'DMET' },
                        ]}
                        onChange={handleChange} name="school"
                    />
                    <p className='col-span-2'></p>
                    <CustomSelect
                        className='col-span-15 w-full'
                        label="Status"
                        value={formData.status}
                        defaultValue={'Verified'}
                        shrink
                        options={[
                            { value: '', label: 'Total' },
                            { value: 'Verified', label: 'Verified' },
                            { value: 'Pending', label: 'Pending' },
                            { value: 'Rejected', label: 'Rejected' },
                        ]}
                        onChange={handleChange} name="status"
                    />
                    <CustomYearMonthPicker
                        className='col-span-15 w-full'
                        setValue={setValue}
                        label={'From Date'}
                        disableFuture
                        yearVariable="startYear"
                        monthVariable="startMonth"
                    />

                    <p className='col-span-2'>-</p>

                    <CustomYearMonthPicker
                        className='col-span-15 w-full'
                        setValue={setValue}
                        label={'To Date'}
                        disableFuture
                        yearVariable="endYear"
                        monthVariable="endMonth"
                        monthValue={currentMonth}
                        yearValue={currentYear}
                    />

                </div>

                <div className={`flex flex-col gap-5`}>
                    {buttons.map((button, index) => {
                        return (<Button
                            key={index}
                            size="large"
                            color="success"
                            type="submit"
                            disabled={disableDownload}
                            variant="contained"
                            className="px-8 bg-green-700 z-1"
                            disableElevation
                            onClick={() => {
                                onDownload(formData, button.key);
                                onClose();
                            }}

                        > {button.label} </Button>)
                    })}
                </div>
            </div>

        </PortalPopup>
    )
}

export default DownloadPopup;
import { Button } from "@mui/material";

// ~ ***** A SIMPLE POPUP WITH 2 OPTION CANCEL AND SUBMIT

const PopupRegular = ({ onClose, heading, subText, icon, onConfirm }) => {
  return (
    <div className="flex flex-col p-5 space-y-10 w-full sm:w-fit bg-white rounded-lg">
      <div className="flex sm:w-[30rem] space-x-5">
        <img className="col-span-2 w-12 h-12" alt="" loading="eager" src={icon} />
        <div >
          <h2 className="h5-p">{heading}</h2>
          <p className="text-s">{subText}</p>
        </div>
      </div>
      
      <div className="flex sm:flex-row items-center justify-between">
        <Button size="large" variant="outlined" color="error" type='button' onClick={onClose} className="px-8">Cancel</Button>
        <Button
          size="large" color="success" type="submit" variant="contained"
          className="ml-10 px-8 bg-green-700 z-1"
          onClick={onConfirm}
        >Confirm
        </Button>
      </div>
    </div>
  );
};

export default PopupRegular;
import { Route, Routes } from 'react-router-dom';
import React from 'react';

import PublicationAD from '../pages/admin/publicationAD/PublicationAD';
import EventsAD from '../pages/admin/event/EventsAD';
import ProjectAD from '../pages/admin/projectAD/ProjectAD';
import PatentsAD from '../pages/admin/patent/PatentsAD';
import AwardsAD from '../pages/admin/award/AwardsAD';

import JournalAnalysis from '../pages/admin/analysis/publication/JournalAnalysis';
import BookChapterAnalysis from '../pages/admin/analysis/publication/BookChapterAnalysis';

import FacultyAD from '../pages/admin/faculty/FacultyAD';
import AddFacultyAD from '../pages/admin/faculty/AddFacultyAD';
import ManageRequest from '../pages/admin/request/ManageRequest';
import ConferenceAnalysis from '../pages/admin/analysis/publication/ConferenceAnalysis';
import Departments from '../pages/admin/departments/Departments';
import FacDashboard from '../pages/admin/faculty/FacDashboard';
import CopyrightsAD from '../pages/admin/copyright/CopyrightsAD';
import Request from '../pages/admin/request/Request';
import EditFacultyAD from '../pages/admin/faculty/EditFacultyAD ';

const AdminRoutes = () => {

    return (
        <Routes>
            <Route path="admin/PublicationAD" element={<PublicationAD />} />
            <Route path="admin/PublicationAD/journalAnalysis" element={<JournalAnalysis />} />
            <Route path="admin/PublicationAD/bookChapterAnalysis" element={<BookChapterAnalysis />} />
            <Route path="admin/PublicationAD/ConferenceAnalysis" element={<ConferenceAnalysis />} />
            <Route path="admin/patentsAD" element={<PatentsAD />} />
            <Route path="admin/copyrightsAD" element={<CopyrightsAD />} />
            <Route path="admin/awardsAD" element={<AwardsAD />} />
            <Route path="admin/projectsAD" element={<ProjectAD />} />
            <Route path="admin/eventsAD" element={<EventsAD />} />

            <Route exact path="admin/facultyAD" element={<FacultyAD />} />
            <Route exact path="admin/facultyAD/addFaculty" element={<AddFacultyAD />} />
            <Route exact path="admin/facultyAD/editFaculty" element={<EditFacultyAD />} />

            <Route path="admin/departments" element={<Departments />} />

            <Route path="admin/facultyAD/Dashboard" element={<FacDashboard />} />
            <Route path="admin/manageRequest" element={<ManageRequest />} />
            <Route path="admin/manageRequest/request" element={<Request />} />
        </Routes>
    );
};

export default AdminRoutes;

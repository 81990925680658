/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react'
import { useSnackbar } from '../../hooks/useSnackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParam } from '../../Functions/getQueryParam';
import { usePostData } from '../../hooks/usePostData';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../recoil/state';
import LoginPage from './Login';

const GoogleLogin = () => {
    const setLoading = useRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();


    const location = useLocation();
    const navigate = useNavigate();
    const pathName = location.href;

    useEffect(() => {
        setLoading(true);
        const code = getQueryParam(pathName, 'code');
        const fetchData = async () => {
            try {
                const body = { code };
                const { success, data } = await usePostData('/api/user/login/success', body);
                if (!success) {
                    handleSnackbarOpen('Whoops, wrong ID there! Login failed. Please give it another shot with the official one.', 'error');
                    navigate('/');
                } else {
                    const { role, token, permissions } = data;
                    sessionStorage.setItem('token', token);
                    sessionStorage.setItem('role', role);
                    sessionStorage.setItem('permissions', permissions);
                    handleSnackbarOpen('Login successful', 'success');
                    navigate('/user/home?tab=Publication');
                }
                setLoading(false)
            } catch (error) {
                handleSnackbarOpen("Uh oh, Google sign-up didn't work. Let's give it another go?", 'error');
                console.error('Error fetching user data:', error);
                navigate('/');
                setLoading(false)
            }
        };
        fetchData();
    }, []);

    return (
        <div><LoginPage /></div>
    )
}

export default GoogleLogin
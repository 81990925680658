import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider, } from "@mui/material";

import App from "./App";
import "./index.css";
import { RecoilRoot } from "recoil";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </StyledEngineProvider>
  </BrowserRouter >
);

import { useState } from 'react';
import usePDFPopup from './usePDFPopup';

const useMultiPDFPopup = (name, folderName, path, buttons) => {
    const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup(folderName, path, 'single', 'singleUpload');
    const [isMultiPDFPopup, setMultiPDFPopup] = useState({ open: false, heading: '', subHeading: '', icon: '', });
    const [buttonConfigurations, setButtonConfigurations] = useState(null);

    const openMultiPDFPopup = (data, type) => {
        switch (type) {
            case 'upload':
                setMultiPDFPopup({
                    open: true, heading: `Upload ${name} Proof`,
                    subHeading: "What type of Proof are you looking to upload ?",
                    icon: '/icons/Upload.svg'
                });

                setButtonConfigurations(
                    buttons.map((button) => ({
                        label: button.label,
                        onClick: () => handleUploadPDF(data._id, button.cloudName),
                        disabled: button.disabled
                    }))
                );
                break;

            case 'view':
                setMultiPDFPopup({
                    open: true, heading: `View ${name} Proof`,
                    subHeading: "What type of Proof are you looking to view ?",
                    icon: '/icons/SuccessPurple.svg'
                });
                setButtonConfigurations(
                    buttons.map((button) => ({
                        label: button.label,
                        onClick: () => handleViewPDF(data._id, data._id, button.cloudName),
                        disabled: button.disabled
                    }))
                );
                break;

            case 'delete':
                setMultiPDFPopup({
                    open: true, heading: `Delete ${name} Proof`,
                    subHeading: "What type of Proof are you looking to delete ?",
                    icon: '/icons/Delete.svg',
                });

                setButtonConfigurations(
                    buttons.map((button) => ({
                        label: button.label,
                        onClick: () => handleDeletePDF(data._id, button.cloudName),
                        disabled: button.disabled
                    }))
                );
                break;

            default:
                break;
        }
    };

    const closeMultiPDFPopup = () => { setMultiPDFPopup({ close: false }); };

    return {
        buttonConfigurations,
        isMultiPDFPopup,
        openMultiPDFPopup,
        closeMultiPDFPopup,
    };
}

export default useMultiPDFPopup;



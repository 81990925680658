import React from 'react'
import { Table } from '../../../../mui/Table';
import PortalPopup from '../../../../components/popups/PortalPopup';
import useOperations from '../../../../hooks/useOperations';
import ConferencePopup from '../../../../components/popupDetailed/ConferencePopup';
import usePDFPopup from '../../../../hooks/usePDFPopup';

const RecentConference = ({ data, loading }) => {
    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Conference');


    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'formattedAuthors', label: "Author's", minWidth: 130 },
        { id: 'paperTitle', label: 'Title of Paper', minWidth: 230 },
        { id: 'conferenceType', label: 'Type' },
        { id: 'category', label: 'Category' },
        { id: 'conferenceIndexedIn', label: 'Indexed in', minWidth: 110 },
        { id: 'formattedDate', label: 'Date of Publication', minWidth: 100 },
        { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
        { id: 'operation', label: 'Operations', minWidth: 155 },
    ];


    const { handleViewPDF } = usePDFPopup('conferences');
    const viewPDF = (data) => { handleViewPDF(data._id) };

    return (
        <>
            <Table
                heading={'Published Conferences'}
                text={'Uploaded Conferences'}
                columns={columns}
                data={data}
                yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
                sortParameters={'formattedDate'}
                multiUsers={'authors'}
                statusDateCol={'lastVerified'}
                document={'document'}
                operation={true}
                deleteRequired={false}
                viewRequired={true}
                uploadRequired={false}
                click={openViewDetails}
                loading={loading}
            />


            {isViewPopupOpen &&
                <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
                    <ConferencePopup onClose={closeViewDetails} data={popupData} admin={true} viewPDF={viewPDF} />
                </PortalPopup>
            }


        </>
    )
}

export default RecentConference;
import React, { useState } from 'react'
import PortalPopup from './popups/PortalPopup'
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import BlackToolTip from '../buttons/BlackToolTip';
import Carousel from '../mui/Carousel';
import usePopup from '../hooks/usePopup';

const ImagePopup = ({ onClose, admin, urls, singleDelete }) => {
  const data = urls.map((url, index) => ({ imgPath: url, id: index + 1 }));

  const handleZip = () => {
    // const imageSrc = urls[0];
    // const imageName = 'temp';

    // useDownloadImage(urls);
    // const link = document.createElement("a");
    // link.href = imageSrc;
    // link.download = imageName;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }


  const { openPopup } = usePopup();
  const [step, setState] = useState(0);
  const handleCarouselStepChange = (step) => {
    setState(step)
  };

  const handleDelete = () => {
    openPopup("Delete Image", "Do you want to Delete the current Image ?", "delete", () => singleDelete(urls[step]))
  };


  return (
    <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" >
      <div className="flex flex-col space-y-10 w-full sm:w-fit bg-white rounded-lg">
        <div className="bg-white relative flex flex-col rounded-xl md:w-[650px] w-full h-full py-5">
          <div className="flex justify-center py-2">
            <div className="relative flex flex-row items-center justify-center space-x-5">
              <PhotoFilterIcon />
              <h2 className="h2-p">Uploaded Images</h2>
            </div>
            <button className=" absolute bg-[transparent] w-[21px] h-[21px] right-6"
              onClick={onClose}
            >
              <img
                className="w-[21px] h-[21px]"
                alt=""
                loading="eager"
                src="/cross.svg"
              />
            </button>
          </div>

          <hr className="h-0.5 w-full bg-[#667085]" />
          <Carousel data={data} autoPlay={false} onStepChange={handleCarouselStepChange} />
          <hr className="h-0.5 w-full bg-[#667085]" />

          <div className='flex items-center justify-center gap-3 mt-3 '>
            {!admin &&
              <BlackToolTip title='Delete Current'>
                <IconButton onClick={handleDelete}>
                  <DeleteOutlineIcon className='text-red-500' />
                </IconButton>
              </BlackToolTip>
            }
            {/* <BlackToolTip title='Download Current'>
                <IconButton onClick={() => { }} size='large'>
                  <FileDownloadIcon className='text-blue-500' fontSize='large' />
                </IconButton>
              </BlackToolTip> */}

            {/* <div>
              <Button variant="text" disableRipple
                className='bg-slate-50 text-slate-900 lb-p font-semibold p-2'
                sx={{ textTransform: "none" }}
                onClick={handleZip}>
                Download all Images
              </Button>
            </div> */}
          </div >
        </div >
      </div >

    </PortalPopup>
  )
}

export default ImagePopup
import React from 'react'
import ClassificationTileSec from '../../../components/Tiles/ClassificationTileSec';
import AnalysisTile from '../../../components/Tiles/AnalysisTile';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import LatBox from '../../../components/Tiles/LatBox';
import useGetData from '../../../hooks/useGetData';

function getShortMonthName(monthCount, year) {
    const date = new Date(2000, monthCount - 1, 1);
    const monthName = date.toLocaleString('default', { month: 'short' });
    return monthName + ' ' + year;
}

const AwardsOverview = ({ id = null, year, years, currentYear, handleYearChange }) => {
    const { data: awardYear, isLoading: isLoadingCCY, } = useGetData(`/api/stats/award/getStats?id=${id}&year=${year}`);
    const { data: awardAT, isLoading: isLoadingCTotal, } = useGetData(`/api/stats/award/getStats?id=${id}&year=total`);

    return (
        <section className="flex flex-col rounded-lg w-full p-5 space-y-5 bg-white-sec">
            <div className="flex flex-col sm:flex-row items-center sm:justify-between col-span-4 space-y-3 sm:space-y-0">
                <h2 className="h2-p"> Awards </h2>
                <FormControl className="sm:mr-4 sm:w-36 w-full" color="primary" size="small" variant="outlined">
                    <InputLabel id="year-label" size="small">
                        Academic Year
                    </InputLabel>
                    <Select labelId="year-label" label="Academic Year" value={year} name="year" size="small" defaultValue={currentYear} onChange={handleYearChange}
                        MenuProps={{
                            PaperProps: { style: { maxHeight: "150px" } },
                        }}>
                        {years.map((year) => {
                            const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                            return (
                                <MenuItem key={formattedYear} value={year} size="small">
                                    {formattedYear}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>

            {/* <div className="w-full mt-6">
                {userAllCountStatic?.LatestAwards[0]?.awardName
                    && <LatBox
                        heading="Latest Awards"
                        v1={userAllCountStatic?.LatestAwards[0]?.awardName}
                        v2={userAllCountStatic?.LatestAwards[1]?.awardName}
                        d1={getShortMonthName(userAllCountStatic?.LatestAwards[0]?.awardMonth, userAllCountStatic?.LatestAwards[0]?.awardYear)}
                        d2={getShortMonthName(userAllCountStatic?.LatestAwards[1]?.awardMonth, userAllCountStatic?.LatestAwards[0]?.awardYear)}
                    />
                }
            </div> */}

            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3 w-full">
                <AnalysisTile
                    heading="Total Awards"
                    value={awardAT?.awards}
                    icon={'/tabs/AwardPurple.svg'}
                />
                <AnalysisTile
                    heading={`Awards in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={awardYear?.awards}
                    icon={'/tabs/AwardPurple.svg'}
                />
                <div className="md:col-span-2 col-span-full">
                    <ClassificationTileSec
                        heading="Awards summary"
                        h1='Technology'
                        h2='Social'
                        h3='Others'
                        v1={awardAT?.technology}
                        v2={awardAT?.social}
                        v3={awardAT?.others}
                    />
                </div>
            </div>
        </section>
    )
}

export default AwardsOverview
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { usePutData } from './usePutData';
import { useSnackbar } from './useSnackbar';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { loadingState, popupState } from '../recoil/state';

const useVerifyPopup = (route, id) => {
    const [verifyPopup, setVerifyPopup] = useState(false);
    const [reason, setReason] = useState('');
    const { handleSnackbarOpen } = useSnackbar();
    const [popup, setPopup] = useRecoilState(popupState);
    const setLoading = useSetRecoilState(loadingState);

    const openVerifyPopup = () => setVerifyPopup(true);
    const closeVerifyPopup = () => setVerifyPopup(false);

    const handleVerifyAction = async (status) => {
        const apiEndpoint = `/api/${route}/updateStatus/${id}`;
        setLoading(true);
        const { success, message } = await usePutData(apiEndpoint, { status, reason });
        setLoading(false);

        if (message) handleSnackbarOpen(`${message}.`, 'warning', 'top', 'center');
        else if (!success) handleSnackbarOpen(`An unexpected error prevented your status update. Give it another go!`, 'error', 'top', 'center');
        handleSnackbarOpen(`Changes applied! New status: ${status}.`, 'success', 'top', 'center');
        closeVerifyPopup();
    };

    const handleRevoke = () => {
        const onConfirm = () => handleVerifyAction('Pending')

        setPopup({
            open: true,
            icon: "/icons/Edit.svg",
            heading: "Revoke Status",
            message: "Are you sure you want to revoke the current status ? Be careful !",
            onConfirm: onConfirm,
            onClose: () => setPopup({ ...popup, open: false }),
        });
    }

    const onVerify = async () => handleVerifyAction('Verified');
    const onReject = async () => handleVerifyAction('Rejected');
    const handleReason = (e) => setReason(e.target.value);

    const buttonOptions = {
        heading: 'Review Changes',
        subText: 'You have made changes. Do you want to discard or save them?',
        icon: '/icons/Edit.svg',
        buttons: [
            { label: 'Close', variant: 'outlined', color: 'error', type: 'button', onClick: closeVerifyPopup, className: 'px-8' },
            { label: 'Reject', variant: 'contained', color: 'error', type: 'submit', onClick: onReject, className: 'ml-10 px-8 bg-red-700 z-1' },
            { label: 'Verify', variant: 'contained', color: 'success', type: 'button', onClick: onVerify, className: 'ml-10 px-8 bg-green-700 z-1' },
        ],
    };

    return {
        verifyPopup,
        reason, handleReason, handleRevoke,
        openVerifyPopup,
        closeVerifyPopup,
        buttonOptions,
    };
};

export default useVerifyPopup;

/* eslint-disable react-hooks/rules-of-hooks */
import { usePostData } from './usePostData';
import { useSnackbar } from './useSnackbar';
import { useSetRecoilState } from 'recoil';
import { loadingState, uploadFileDataState } from '../recoil/state';
import { useNavigate } from 'react-router-dom';

const usePDFPopup = (folder, path, count) => {
    const setIsLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();
    const navigate = useNavigate();

    const setUploadFileData = useSetRecoilState(uploadFileDataState);

    const handleViewPDF = async (subFolder, key, suffix) => {
        setIsLoading(true)
        const body = { folder, subFolder, key, suffix };
        if (key) {
            const { success, data: url } = await usePostData('/cloud/getViewURL', body);
            if (success && !url) {
                handleSnackbarOpen("Oops! The requested PDF cannot be found.", 'warning', 'top', 'center');
                return setIsLoading(false)
            }

            if (success) {
                window.open(url, '_blank');
                return setIsLoading(false)
            }
        }

        else {
            const { success, data: urls } = await usePostData('/cloud/getMultipleView', body);
            if (success) {
                urls.forEach((url) => {
                    window.open(url, '_blank');
                });
                return setIsLoading(false)
            }
        }

        setIsLoading(false)
        handleSnackbarOpen("Oops! We couldn't find any PDF from your vault.", 'warning', 'top', 'center');
    };

    const handleDeletePDF = async (id, suffix) => {
        setIsLoading(true)
        const body = { folder, id, suffix };
        const { success, data } = await usePostData('/cloud/deleteKey', body);

        if (success && !data) {
            handleSnackbarOpen("Oops! The requested PDF cannot be found.", 'warning', 'top', 'center');
            return setIsLoading(false)
        }

        if (success) {
            const body = { type: folder, id, count: 1, modificationType: 'dec', suffix };
            const { success } = await usePostData('/cloud/updateDocumentFlag', body);

            if (success)
                handleSnackbarOpen('PDF Deleted Successfully', 'success', 'top', 'center', 'delete');
        }

        else handleSnackbarOpen("We're sorry, something went wrong while deleting the PDF.", 'warning', 'top', 'center');
        setIsLoading(false)
    };

    const handleUploadPDF = (id, suffix) => {
        setIsLoading(true)
        const allowedTypes = ['application/pdf'];

        setUploadFileData({
            path, folder, count,
            subFolder: id,
            suffix,
            contentType: 'application/pdf',
            extension: 'pdf',
            allowedTypes: allowedTypes
        })

        setIsLoading(false)
        navigate(`/user/upload?type=${path}`)
    }

    return {
        handleUploadPDF,
        handleViewPDF,
        handleDeletePDF,
    };
};

export default usePDFPopup;

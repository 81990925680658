import MainFrameIcon from "../../../../components/MainFrameIcon";
import NotificationTable from "./NotificationTable";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const Notifications = () => {
  useSetBreadcrumb([
    { name: 'Notification', path: '/user/notification' },
  ]);
  
  return (
    <div className="w-full flex flex-col">
      <MainFrameIcon />
      <div className="relative -top-20 rounded-xl bg-white w-11/12 mx-auto text-lg text-black shadow-lg font-sans">
        <div className="flex justify-between p-6">
          <h1 className="h2-p">Recent Notifications</h1>
          {/* <button className="text-lg cursor-pointer text-[#333] hover:underline active:underline font-semibold mr-4 "  > Archives </button> */}
        </div>

        <hr className="border-b-0.5" />
        <div className="no-scrollbar p-5">
          <NotificationTable />
        </div>

      </div>
    </div>
  );
};

export default Notifications;

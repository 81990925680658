/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { usePostData } from '../../../hooks/usePostData';
import { useSetBreadcrumb } from '../../../hooks/useSetBreadcrumb';
import Dashboard from '../../user/home/Dashboard';

const FacDashboard = () => {
    useSetBreadcrumb([
        { name: 'DashBoard', path: '/admin/facultyAD' }
    ]);

    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [profilePhoto, setProfilePhoto] = useState('')
    const formData = { folder: 'profile', key: id };

    useEffect(() => {
        const fetchProfilePhoto = async () => {
            const { data: photo } = await usePostData(`/cloud/getViewURL`, formData);
            setProfilePhoto(photo);
        };
        fetchProfilePhoto();
    }, [id]);


    return (<Dashboard profilePhoto={profilePhoto} />);
};

export default FacDashboard;

/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useLocation } from "react-router-dom";
import { CustomTextField } from '../../../mui/Input';
import Checkbox from '@mui/material/Checkbox'
import { CloseButton, ConfirmButton } from '../../../mui/Button';
import Button from '@mui/material/Button'
import useFormOperations from '../../../hooks/useFormOperations';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useRecoilState } from 'recoil';
import { loadingState } from '../../../recoil/state';
import { usePutData } from '../../../hooks/usePutData';
import { useSetBreadcrumb } from '../../../hooks/useSetBreadcrumb';

const Request = () => {
  useSetBreadcrumb([{ name: 'Manage Request', path: '/admin/manageRequest?tab=Pending' }]);
  const setLoading = useRecoilState(loadingState);
  const { handleSnackbarOpen } = useSnackbar();
  const location = useLocation();
  const requestData = location.state?.requestData;

  const defaultValues = {
    newTitle: false, newName: false,
    newPhNo: false, newEmail: false,
    newDesignation: false, newJoiningDate: false, newEmployeeID: false, response: ''
  };

  const onReject = async () => {
    setLoading(true);
    const { success, error, message } = await usePutData(`/api/request/handleRequestChanges/${requestData._id}`, formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    else if (success) {
      handleSnackbarOpen("Changes successfully rejected!", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) return handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  }

  const onSubmit = async (formData) => {
    const atleastOneChecked = Object.keys(formData).filter(key => key !== "response" && requestData[key] !== undefined).some(key => formData[key]);
    if (!atleastOneChecked) return handleSnackbarOpen("It appears you're trying to accept details without selecting a single field. This action is not permitted.", 'warning', 'top', 'center')

    setLoading(true);
    const { success, error, message } = await usePutData(`/api/request/handleRequestChanges/${requestData._id}`, formData);
    setLoading(false);

    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    else if (success) {
      handleSnackbarOpen("Changes successfully applied! They will be auto-reflected to the user", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) return handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
  }

  const { formData, onDiscard, setValue, handleSubmit, handleChange } = useFormOperations(defaultValues, onSubmit, '/admin/manageRequest?tab=Pending');

  const handleSelectAll = () => {
    const fieldsWithChanges = Object.keys(formData).filter(key => key !== "response" && requestData[key] !== undefined);
    const allChecked = fieldsWithChanges.every(key => formData[key]);
    fieldsWithChanges.forEach(key => { setValue(key, !allChecked) });
  };

  const handleCheckBox = (fieldName) => {
    const newValue = !formData[fieldName];
    setValue(fieldName, newValue);
  };

  const formDataFields = [
    { key: "newTitle", label: "Title", value: requestData?.title || '', updatedValue: requestData?.newTitle },
    { key: "newName", label: "Name", value: requestData?.name || '', updatedValue: requestData?.newName },
    { key: "newDesignation", label: "Designation", value: requestData?.designation || '', updatedValue: requestData?.newDesignation },
    { key: "newJoiningDate", label: "Joining Date", value: requestData?.joiningDate || '', updatedValue: requestData?.newJoiningDate },
    { key: "newEmail", label: "Email ID", value: requestData?.email || '', updatedValue: requestData?.newEmail },
    { key: "newPhNo", label: "Phone Number", value: requestData?.phNo || '', updatedValue: requestData?.newPhNo },
    { key: "newEmployeeID", label: "Employee ID", value: requestData?.employeeID || '', updatedValue: requestData?.newEmployeeID }
  ];

  return (
    <form className='grid grid-cols-11 gap-5 w-full h-fit p-3' onSubmit={handleSubmit}>
      <h2 className='h5-p col-span-5 flex flex-col'>Old Details
        <span className='text-s'>Previously Submitted Faculty Details</span>
      </h2>

      <h2 className='h5-p col-span-4 flex flex-col'>New Details
        <span className="text-s">Latest Faculty Information</span>
      </h2>

      <div className='col-span-2 flex justify-end items-center w-full' >
        <Button className='cursor-pointer w-full' onClick={handleSelectAll} color='secondary' disableRipple>
          {Object.keys(formData).filter(key => key !== "response" && requestData[key] !== undefined).every(key => formData[key]) ? "Unselect all" : "Select all"}
          <Checkbox
            id="SelectAllCheckbox"
            color="secondary"
            checked={Object.keys(formData).filter(key => key !== "response" && requestData[key] !== undefined).every(key => formData[key])}
          />
        </Button>
      </div>

      {formDataFields && formDataFields?.map((field, index) => (field?.updatedValue &&
        <div key={index} className={`col-span-full grid grid-cols-11 p-3 gap-5 ${index % 2 === 0 ? 'bg-slate-100' : ''}`}>
          <CustomTextField label={field.label} value={field.value} className="col-span-5"
            InputLabelProps={{ shrink: true }} fullWidth disabled />
          <CustomTextField label={`Updated ${field.label}`} value={field.updatedValue} className="col-span-5"
            InputLabelProps={{ shrink: true }} fullWidth />

          <div className='grid place-items-center'>
            <Checkbox
              checked={formData[field.key]}
              onChange={() => handleCheckBox(field.key)}
              color="secondary"
            />
          </div>
        </div>
      ))}

      <CustomTextField
        className='col-span-full'
        label="Reason Provided by the user"
        value={requestData?.reason || "No Reason Provided"}
        rows={3} multiline={true}
      />

      <CustomTextField
        label="Response" className='col-span-full'
        rows={3} multiline={true}
        name="response" value={formData?.response}
        onChange={handleChange} required={false}
      />

      <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
        <CloseButton onClick={onDiscard} />
        <CloseButton text="Reject" variant='contained' className="bg-red-600" onClick={onReject} />
        <ConfirmButton type="submit" />
      </div>

    </form>

  )
}

export default Request
import React from 'react';
import { Button } from '@mui/material';
import BlackToolTip from '../buttons/BlackToolTip';

export const SquareIconButton = ({ title, onClick, icon, className, ...props }) => (
    <BlackToolTip arrow title={title}>
        <Button onClick={onClick} variant="text" size="large" className={`bg-stone-50 ${className}`} {...props}>
            {icon}
        </Button>
    </BlackToolTip>
);
import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import RecentConsultancy from "./RecentConsultancy";
import AVConsultancy from "./AVConsultancy";
import TabSmall from "../../../../mui/TabSmall";
import useOperations from "../../../../hooks/useOperations";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import {  CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import useGetData from "../../../../hooks/useGetData";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";

const ConsultancyAD = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/admin/projectsAD?tab=Consultancy&type=Recent' },
    { name: 'Consultancies', path: '/admin/projectsAD?tab=Consultancy&type=Recent' }
  ]);
  const { data: consultanciesDataAD, isLoading } = useGetData('/api/consultancy/getConsultancies?school=total');
  const { data: statsCY, isLoading: tileLoadingCY } = useGetData('/api/stats/consultancy/getStats?school=total');
  const { data: statsTotal, isLoading: tileLoadingTotal } = useGetData('/api/stats/consultancy/getStats?school=total&year=total');

  const navigate = useNavigate();

  const navigateToAnalysis = useCallback(() => {
    navigate("/admin/projectAD/consultancyAnalysis");
  }, []);

  const { onDownload } = useOperations('Consultancy', null, null, consultanciesDataAD, ['consultants']);
  
  const currentYear = useAcademicYear();

  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('type');
  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];

  const buttonInfo = [
    // { title: 'Analysis', icon: <Analytics fontSize="large" className="text-[#010851]" />, onClick: navigateToAnalysis },
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
  ];

  return (
    <div className="flex flex-col space-y-5 my-5">
      <div className="grid gap-5 p-5 rounded-lg 2xl:grid-cols-4 sm:grid-cols-2 bg-white-sec">
        <AnalysisTile
          heading="Total Completed Consultancies"
          value={statsTotal?.completed}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingTotal}
          message='Total Completed & Verified Consultancies'
        />

        <AnalysisTile
          heading="Total ongoing Consultancies"
          value={statsTotal?.inProgress}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingTotal}
          message='Total Inprogress & Verified Consultancies'
          />

        <AnalysisTile
          heading={`Consultancies completed in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={statsCY?.completed}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingCY}
        />

        <AnalysisTile
          heading={`Consultancies started in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={statsCY?.started}
          icon={'/tabs/ConsultancyPurple.svg'}
          loading={tileLoadingCY}
          message='Total Consultancies granted & started in current year'
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading='Total completed Consultancies summary'
            h1={'International'}
            h2={'National'}
            v1={statsTotal?.international}
            v2={statsTotal?.national}
            icon={'/tabs/ConsultancyPurple.svg'}
            loading={tileLoadingTotal}
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading='Total completed Consultancies summary'
            h1={'Government'}
            h2={'Private'}
            v1={statsTotal?.government}
            v2={statsTotal?.private}
            icon={'/tabs/ConsultancyPurple.svg'}
            loading={tileLoadingTotal}
          />

        </div>
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>


        {activeTab === "Recent" && <RecentConsultancy data={consultanciesDataAD} loading={isLoading} />}
        {activeTab === "Abstract View" && <AVConsultancy />}

      </div >
    </div>
  );
};

export default ConsultancyAD;

import React, { useContext, useEffect, useState } from 'react'
import { Table } from '../../../mui/Table'
import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../recoil/state';
import useGetData from '../../../hooks/useGetData';

const AVAwards = () => {
  const [year, setYear] = useRecoilState(sortYearState);
  const { data: copyrightAbstractCount, isLoading: tileLoading } = useGetData(`/api/AV/copyright/getAV?year=${year}&school=total`);

  const columnsAbstractView = [
    { id: "index", label: "Sr No", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 350 },
    { id: "totalApplicant", label: "Total Applicants", minWidth: 50 },
  ];

  return (
    <Table
      heading={'Total Awards'}
      text={'Faculties awards'}
      columns={columnsAbstractView}
      data={copyrightAbstractCount?.copyrightAV}
      operation={false}
      sortParameters={'total'}
      deleteRequired={false}
      uploadRequired={false}
      viewRequired={false}
      sortYear={true}
      loading={tileLoading}
    />
  )
}

export default AVAwards
const HOST_URL = process.env.REACT_APP_HOST;

export const useDeleteData = async (url, id) => {
    const token = sessionStorage.getItem('token');
    try {
        const response = await fetch(HOST_URL + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        const result = await response.json();
        
        if (!response.ok) throw new Error(result?.message || 'Network response was not ok');
        return { success: result.success, message: result.message, data: result.data, result };

    } catch (err) {
        return { success: false, error: err.message };
    }
};

import useMediaQuery from '@mui/material/useMediaQuery';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSearchParams } from "react-router-dom";

export default function ThreeWayTab({ btn1, btn2, btn3, btn4, ...props }) {
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab');
    const changeTab = (value) => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('tab', value);
            return Object.fromEntries(newParams.entries());
        });
    }

    const tabsData = [
        { value: btn1, label: btn1, icon: tab === btn1 ? props.icon1 : props.iconDisabled1 },
        { value: btn2, label: btn2, icon: tab === btn2 ? props.icon2 : props.iconDisabled2 },
        { value: btn3, label: btn3, icon: tab === btn3 ? props.icon3 : props.iconDisabled3 },
        { value: btn4, label: btn4, icon: tab === btn4 ? props.icon4 : props.iconDisabled4 },
    ];

    const variant = isSmallScreen ? 'scrollable' : 'fullWidth';

    return (
        <div className='bg-white-sec rounded-lg w-full'>
            <Tabs variant={variant} value={tab} onChange={(event, value) => changeTab(value)}
                TabIndicatorProps={{
                    style: { backgroundColor: '#0f172a' }
                }}
            >
                {tabsData.map((tab, index) => tab.value &&
                    <Tab
                        className='h6-s font-[Poppins] leading-8 mx-3'
                        key={index}
                        value={tab.value}
                        label={tab.label}
                        icon={tab.icon}
                        iconPosition={'start'}
                        disableRipple
                        sx={{
                            '&.Mui-selected': { color: '#0f172a' },
                            textTransform: 'none'
                        }}
                    />
                )}
            </Tabs >
        </div>
    );
}
import React from 'react';
import Button from '@mui/material/Button';

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CustomButton = ({ additionalClass, buttonName, ...props }) => {
    const {
        className,
        onClick,
        variant,
        color,
        type,
        startIcon,
        endIcon,
        style,
        size,
        children,
        disabled,
    } = props;
    return (
        <Button
            className={`${className} ${additionalClass}`}
            onClick={onClick}
            variant={variant}
            color={color}
            type={type}
            startIcon={startIcon}
            endIcon={endIcon}
            size={size}
            style={disabled ? {} : style}
            disabled={disabled}

        // {...props}
        >
            {buttonName || children}
        </Button>
    );
};

export const CloseButton = ({ className, variant = "outlined", onClick, type, text }) => (
    <CustomButton
        size="large" variant={variant} color="error" type={type ? type : "button"}
        additionalClass={className} onClick={onClick} className='min-w-[10rem]' >
        {text ? text : 'Discard'}
    </CustomButton>
);

export const ConfirmButton = ({ className, onClick, type, startIcon, endIcon, text = 'Confirm' }) => (
    <CustomButton additionalClass={className} className='bg-green-700 min-w-[10rem]'
        style={{ zIndex: 1 }} color="success" variant="contained" size="large"
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon}
    >
        {text}
    </CustomButton>
);

export const DownloadButton = ({ className, onClick, text }) => (
    <CustomButton
        className="py-3 min-w-[19rem]"
        additionalClass={className}
        onClick={onClick}
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon style={{ color: 'white' }} />}
        style={{ zIndex: 1, backgroundColor: "#010851", color: "white", borderRadius: 8 }}
    >
        {`Download All ${text}`}
    </CustomButton>
);

export const UploadButton = ({ className, onClick, text, newText, ...props }) => (
    <CustomButton
        className="py-3 min-w-[19rem]"
        additionalClass={className}
        onClick={onClick}
        variant="contained"
        startIcon={<CloudUploadOutlinedIcon style={{ color: 'white' }} />}
        style={{ zIndex: 1, backgroundColor: "#010851", color: "white", borderRadius: 8 }}
        {...props}
    >
        {newText ? newText : `Upload New ${text}`}
    </CustomButton>
);

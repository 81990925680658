import React from 'react'
import useOperations from '../../../../hooks/useOperations';
import usePDFPopup from '../../../../hooks/usePDFPopup';
import { Table } from '../../../../mui/Table';
import PortalPopup from '../../../../components/popups/PortalPopup';
import BookChapterPopup from '../../../../components/popupDetailed/BookChapterPopup';

const RecentBookChapter = ({ data, loading }) => {
  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'bookChapterTitle', label: 'Title of Book Chapter', minWidth: 230 },
    { id: 'category', label: 'Category', minWidth: 90 },
    { id: 'publisherType', label: 'Type' },
    { id: 'formattedDate', label: 'Date', minWidth: 100, align: 'center' },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: 'operation', label: 'Operations', minWidth: 155, align: 'center' },
  ];
  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Book Chapter');

  const { handleViewPDF } = usePDFPopup('bookChapters');
  const viewPDF = (data) => { handleViewPDF(data._id) };

  return (
    <>
      <Table
        heading={'Published Book Chapter'}
        text={'Uploaded Book Chapters'}
        columns={columns}
        data={data}
        yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
        sortParameters={'formattedDate'}
        multiUsers={'authors'}
        statusDateCol={'lastVerified'}
        document={'document'}
        operation={true}
        deleteRequired={false}
        viewRequired={true}
        uploadRequired={false}
        click={openViewDetails}
        loading={loading}
      />

      {isViewPopupOpen &&
        <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
          <BookChapterPopup onClose={closeViewDetails} data={popupData} admin={true} viewPDF={viewPDF} />
        </PortalPopup>
      }
    </>
  )
}



export default RecentBookChapter
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

const SchoolTile = ({ obj }) => {
    
    const { deanID, deanName, school, departmentName } = obj;
    const navigate = useNavigate()
    const handleViewFaculty = () => { navigate(`/admin/facultyAD/Dashboard?id=${deanID}&tab=Publication`); };

    return (
        <div className='flex h-full w-full space-x-3 p-4 rounded-lg bg-white-sec 2xl:max-w-2xl'>
            <img className='h-14 w-14 rounded-full cursor-pointer' src='/profile.svg' alt="img" />
            <div className='flex flex-col justify-center space-y-0.5'>
                <Link to={`?department=${school}&dean=${deanName}`} className='lb-p hover:underline cursor-pointer truncate'>{departmentName}</Link>
                <p onClick={handleViewFaculty} className='text-s hover:text-purple5 cursor-pointer'>{deanName} </p>
            </div>
        </div>
    )
}

export default SchoolTile;
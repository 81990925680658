/* eslint-disable react-hooks/rules-of-hooks */
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import { usePostData } from "../../../../hooks/usePostData";
import useFormOperations from "../../../../hooks/useFormOperations";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";
import AuthorInput from "../../../../buttons/Input/AuthorInput";

const AddFundedProject = () => {
    useSetBreadcrumb([
        { name: 'Projects', path: '/user/projects?tab=Research+%26+Development' },
        { name: 'Research & Development', path: '/user/projects?tab=Research+%26+Development' },
        { name: 'Add Research & Development', path: '/user/projects/addResearch+%26+Development' }
    ]);

    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const defaultValues = {};

    const { data: userData } = useGetData('/api/user/getUser');
    const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

    const onSubmit = async (formData) => {
        setLoading(true);
        const principalInvestigatorsExists = formData.principalInvestigators && formData.principalInvestigators.some(principalInvestigator => principalInvestigator._id === userData._id);

        if (!principalInvestigatorsExists) {
            handleSnackbarOpen("It appears you're trying to add a Research & Development Project entry without being listed as a Co-Principal Investigator. This action is not permitted.", 'warning', 'top', 'center')
            return setLoading(false);
        }

        const { success, error, message } = await usePostData('/api/fundedProject/addFundedProject', formData);
        if (message) {
            handleSnackbarOpen(message, 'warning', 'top', 'center')
            return setLoading(false);
        }

        if (success) {
            handleSnackbarOpen("Research & Development Project entry added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
            onDiscard();
        }

        else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
        setLoading(false);
    };

    const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/projects?tab=Research+%26+Development");

    return (
        <div className="flex flex-col w-full space-y-5 ">
            <h2 className=" h5-p px-5">Add Research & Development Project Details</h2>
            <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

                <CustomTextField label="Faculty Name" InputLabelProps={{ shrink: true }} value={userData?.name} />

                <AuthorInput
                    className="col-span-full"
                    name='principalInvestigators'
                    value={formData.principalInvestigators}
                    setValue={setValue}
                    options={faculties}
                    loading={loadingFaculties}
                    placeholder='Select or add Principal / Co-Principal Investigator'
                    label='Principal Investigators'
                />

                <CustomTextField
                    label="Funded Project Title" className="col-span-full" name="projectTitle"
                    onChange={handleChange} value={formData?.projectTitle}
                />

                <CustomTextField
                    label="Funding Agency Name" name="agency"
                    onChange={handleChange} value={formData?.agency}
                />

                <CustomSelect
                    label="R & D Status"
                    options={[
                        { value: 'Applied', label: 'Applied' },
                        { value: 'Granted', label: 'Granted' },
                    ]} onChange={handleChange} name="fundedProjectStatus"
                />

                <CustomTextField
                    label="Funding Agency Email Id" name="agencyEmail"
                    onChange={handleChange} type='email' value={formData?.agencyEmail}
                />

                <CustomSelect
                    label="R & D Category"
                    options={[
                        { value: 'Government', label: 'Government' },
                        { value: 'Corporate Houses', label: 'Corporate Houses' },
                        { value: 'Endowments', label: 'Endowments' },
                        { value: 'Industry', label: 'Industry' },
                        { value: 'International Body for Research Project', label: 'International Body for Research Project' },
                        { value: 'Research Chairs', label: 'Research Chairs' },
                    ]} onChange={handleChange} name="category"
                />

                <CustomTextField
                    label="Funding Agent Email Id" name="agentEmail" required={false}
                    onChange={handleChange} type='email' value={formData?.agentEmail}
                />

                <CustomYearMonthPicker
                    setValue={setValue}
                    label='Applied Date'
                    disableFuture
                    yearVariable="appliedYear"
                    monthVariable="appliedMonth"
                />

                <CustomTextField
                    label="Funding Agent Phone No" name="agentPhone" required={false}
                    onChange={handleChange} customType='number' value={formData?.agentPhone}
                />

                <CustomYearMonthPicker
                    setValue={setValue}
                    label='Starting Date'
                    disableFuture
                    yearVariable="startingYear"
                    monthVariable="startingMonth"
                    disabled={!(formData.fundedProjectStatus === 'Granted')}
                />

                <CustomTextField
                    label="Funding Amount" name="amount"
                    onChange={handleChange} customType='decimal' value={formData?.amount}
                    InputProps={{ startAdornment: <span className='mr-3'> ₹ </span> }}
                />

                <CustomYearMonthPicker
                    setValue={setValue}
                    label='Ending Date'
                    disableFuture
                    yearVariable="endingYear"
                    monthVariable="endingMonth"
                    disabled={!(formData.fundedProjectStatus === 'Granted')}
                />

                <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
                    <CloseButton onClick={onDiscard} />
                    <ConfirmButton type="submit" />
                </div>

            </form >
        </div >
    );
};

export default AddFundedProject;
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

const TabHover = ({ tabs }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get('type');

    const changeTab = (event, newValue) => {
        const value = tabs[newValue];
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set('type', value);
            return Object.fromEntries(newParams.entries());
        });
    };

    return (
        <div className='flex space-x-3 h-12'>
            <Tabs variant='scrollable' scrollButtons={false} onChange={changeTab}
                value={tabs.findIndex(tab => tab === activeTab)}
                TabIndicatorProps={{ style: { backgroundColor: '#4f38c9' } }}
            >
                {tabs?.map((tab, index) => (
                    <Tab
                        className='h6-s font-[Poppins] leading-8 mx-3'
                        key={index}
                        label={tab}
                        value={tab.value}
                        disableRipple
                        sx={{
                            textTransform: 'none',
                            '&.Mui-selected': { color: '#4f38c9' },
                        }}
                    />
                ))}
            </Tabs>
        </div >
    );
};

export default TabHover;

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PatentPopTab from "../PatentPopTab";
import { DeleteOutlineOutlined, Edit, FileOpenOutlined, SettingsBackupRestore, TaskAlt, Upload } from "@mui/icons-material";
import { SquareIconButton } from "../../mui/SquareIconButton";
import usePopup from "../../hooks/usePopup";
import PortalPopup from "../popups/PortalPopup";
import PopupExtra from "../popups/PopupExtra";
import useVerifyPopup from "../../hooks/useVerifyPopup";

const DesignPatentPopup = ({ onClose, data, admin = false, uploadPDF, viewPDF, deletePDF }) => {
  const navigate = useNavigate();
  const { openPopup } = usePopup();

  const onEditClick = useCallback(() => {
    const { operation, tickIcons, statusIcon, ...patentsData } = data;
    navigate('/user/designPatents/editDesignPatent', { state: { patentsData } });
  }, [data, navigate]);

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const gridElements = [
    { label: 'Patent Number', value: data?.designPatentNum, span: 8 },
    { label: 'Title Of Patent', value: data?.designPatentTitle, span: 24 },
    { label: 'Type Of Patent', value: data?.designPatentType, span: 8 },
    { label: 'Registration Date', value: data?.formattedRegistrationDate, span: 8 },
  ]

  const { openVerifyPopup, verifyPopup, buttonOptions, closeVerifyPopup, handleReason, handleRevoke } = useVerifyPopup('designPatent', data._id);

  return (
    <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={onClose}>
      <div className="bg-white rounded-xl w-full max-w-[1500px] py-5 h-[550px] my-5 space-y-5">
        <div className="flex justify-between px-5">
          <h2 className="h2-p">Patent Details</h2>
          <button className="w-fit h-fit" onClick={onClose}>
            <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
          </button>
        </div>

        <hr className="bg-gray-500 h-0.5 border-none" />

        <div className="grid overflow-y-auto no-scrollbar space-y-10 px-5 h-[350px]">
          <div className="grid grid-cols-32 gap-x-4 gap-y-8 break-words h-full">
            {gridElements.map((item, index) => (
              <div key={index} className={`flex flex-col col-span-${item.span}`}>
                <label className="lb-s text-[13px]"> {item.label} </label>
                <p className="lb-p"> {item.value} </p>
              </div>
            ))}
          </div>
          <PatentPopTab heading="Applicants" data={data?.applicants} />
        </div>

        <hr className="bg-gray-500 h-0.5 border-none" />

        <div className="col-span-full flex w-full justify-around sm:justify-end sm:space-x-10 md:px-10">
          {!admin ? <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="text-amber-500 col-span-2" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="text-blue-600 col-span-2" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutlineOutlined />} className="text-red-500 col-span-2" />
          </>
            :
            <>
              <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
                className="col-span-2 text-red-500" disabled={data?.status === "Pending"}
              />
              <SquareIconButton title="Action" onClick={openVerifyPopup} icon={<TaskAlt />}
                className="text-green-600 col-span-2" disabled={data?.status === "Verified" || data?.status === "Rejected"}
              />
            </>
          }

          <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpenOutlined />} className="text-slate-900 col-span-3" />
        </div>

        {verifyPopup &&
          <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
            <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
          </PortalPopup>
        }
      </div>
    </PortalPopup>
  );
};

export default DesignPatentPopup;

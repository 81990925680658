import { TextField } from "@mui/material";

const ThreeWayInput = ({ className, heading, formData, handleChange, index, onAdd, onDelete, disabled }) => {
    return (
        <div className={`w-full bg-bgPrimary flex flex-col rounded-lg p-4 space-y-5 ${className}`}>

            {disabled && <button onClick={onAdd} className="relative" type="button"                    >
                <div className="absolute -top-7 w-5 h-5 transition-transform transform hover:scale-125 duration-300">
                    <img className="w-5 h-5" alt="" src="/icons/AddIcon.svg" />
                </div>
            </button>}

            <div className="flex justify-between">
                <h5 className="h5-p">{heading}</h5>
                {(!disabled && onDelete) &&
                    <button onClick={() => onDelete(index)} type="button">
                        <img className="w-5 h-5 transition-transform transform hover:scale-105 duration-300" alt="" src="/cross.svg" />
                    </button>
                }
            </div>

            <div className="grid tab:grid-cols-2 gap-5">
                <TextField
                    type="text"
                    label="Name Of the Speaker"
                    name={`speakers[${index}].name`}
                    value={formData?.speakers[index]?.name}
                    onChange={(event) => { handleChange(event, index) }}
                    required disabled={disabled}
                />

                <TextField
                    type="text"
                    label="Designation Of the Speaker"
                    name={`speakers[${index}].designation`}
                    value={formData?.speakers[index]?.designation}
                    onChange={(event) => { handleChange(event, index) }}
                    disabled={disabled}
                />

                <TextField
                    type="text"
                    className="col-span-full"
                    label="Organization of the Speaker"
                    name={`speakers[${index}].organization`}
                    value={formData?.speakers[index]?.organization}
                    onChange={(event) => { handleChange(event, index) }}
                    disabled={disabled} required
                />
            </div>
        </div>
    );
};

export default ThreeWayInput;
import React from 'react'
import OverYearsTile from '../../../components/Tiles/OverYearsTile';
import ClassificationTileSec from '../../../components/Tiles/ClassificationTileSec';
import AnalysisTile from '../../../components/Tiles/AnalysisTile';
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import useGetData from '../../../hooks/useGetData';

const PublicationsOverview = ({ id = null, year, years, currentYear, handleYearChange }) => {

    const { data: journalYear, isLoading: isLoadingJCY, } = useGetData(`/api/stats/journal/getStats?id=${id}&year=${year}`);
    const { data: journalAT, isLoading: isLoadingJTotal, } = useGetData(`/api/stats/journal/getStats?id=${id}&year=total`);

    const { data: bookChapterYear, isLoading: isLoadingBCY, } = useGetData(`/api/stats/bookChapter/getStats?id=${id}&year=${year}`);
    const { data: bookChapterAT, isLoading: isLoadingBTotal, } = useGetData(`/api/stats/bookChapter/getStats?id=${id}&year=total`);

    const { data: conferenceYear, isLoading: isLoadingCCY, } = useGetData(`/api/stats/conference/getStats?id=${id}&year=${year}`);
    const { data: conferenceAT, isLoading: isLoadingCTotal, } = useGetData(`/api/stats/conference/getStats?id=${id}&year=total`);

    const { data: publications, isLoading: isLoadingP, } = useGetData(`/api/stats/publication/getStatsOverYears?id=${id}`);

    const publicationAT = (journalAT?.published + bookChapterAT?.published + conferenceAT?.published) || 0;
    const publicationYear = (journalYear?.published + bookChapterYear?.published + conferenceYear?.published) || 0;

    return (
        <section className="flex flex-col w-full p-5 space-y-5 rounded-lg bg-white-sec">
            <div className="flex flex-col sm:flex-row items-center sm:justify-between col-span-4 space-y-3 sm:space-y-0">
                <h3 className="h2-p"> Publications </h3>
                <FormControl className="sm:mr-4 sm:w-36 w-full" color="primary" size="small" variant="outlined">
                    <InputLabel id="year-label" size="small" shrink={true} >
                        Academic Year
                    </InputLabel>
                    <Select labelId="year-label" label="Academic Year" value={year} name="year" size="small" defaultValue={currentYear} onChange={handleYearChange}
                        MenuProps={{
                            PaperProps: { style: { maxHeight: "150px" } },
                        }} InputLabelProps={{ shrink: true }}
                    >
                        {years.map((year) => {
                            const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                            return (
                                <MenuItem key={formattedYear} value={year} size="small">
                                    {formattedYear}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>

            <div className="grid md:col-span-2 xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Publications"
                    value={publicationAT}
                    icon={'/icons/Success.svg'}
                    message={"Total verified & published Publications across all time"}
                />

                <AnalysisTile
                    heading={`Publications in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={publicationYear}
                    icon={'/icons/Success.svg'}
                    message={"Total verified & published Publications of current academic Year"}
                />

                <div className="col-span-full md:col-span-2">
                    <ClassificationTileSec
                        heading="Total Publication summary"
                        h1='Journals'
                        h2='Book Chapter'
                        h3='Conference'
                        v1={journalAT?.published}
                        v2={bookChapterAT?.published}
                        v3={conferenceAT?.published}
                        message={"Total verified Publications Summary across all academic years"}
                    />
                </div>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Journals"
                    icon={'/tabs/JournalPurple.svg'}
                    value={journalAT?.published}
                />
                <AnalysisTile
                    heading={`Journals in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={journalYear?.published}
                    icon={'/tabs/JournalPurple.svg'}
                />

                <div className='md:col-span-2 col-span-full'>
                    <ClassificationTileSec
                        heading="Total Journal summary"
                        h1='International'
                        h2='National'
                        v1={journalAT?.internationalPublished}
                        v2={journalAT?.nationalPublished}
                    />
                </div>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Book Chapters"
                    value={bookChapterAT?.published}
                    icon={'/tabs/BookChapterPurple.svg'}
                />

                <AnalysisTile
                    heading={`Book Chapters in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={bookChapterYear?.published}
                    icon={'/tabs/BookChapterPurple.svg'}
                />
                <div className="md:col-span-2 col-span-full">
                    <ClassificationTileSec
                        heading="Total Book Chapter summary"
                        h1='International'
                        h2='National'
                        v1={bookChapterAT?.internationalPublished}
                        v2={bookChapterAT?.nationalPublished}
                    />
                </div>
            </div>

            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
                <AnalysisTile
                    heading="Total Conferences"
                    value={conferenceAT?.published}
                    icon={'/tabs/ConferencePurple.svg'}
                />

                <AnalysisTile
                    heading={`Conferences in ${year}-${(year + 1).toString().slice(-2)}`}
                    value={conferenceYear?.published}
                    icon={'/tabs/ConferencePurple.svg'}
                />

                <div className="col-span-full md:col-span-2">
                    <ClassificationTileSec
                        heading="Total Conference summary"
                        h1='International'
                        h2='National'
                        v1={conferenceAT?.internationalPublished}
                        v2={conferenceAT?.nationalPublished}
                    />
                </div>
            </div>

            <OverYearsTile
                heading={"Publications Published Over Years"}
                data={publications?.publications}
                message={'Published & verified Journals, BookChapter, Conferences.'} />
        </section >
    )
}

export default PublicationsOverview
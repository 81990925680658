/* eslint-disable react-hooks/rules-of-hooks */
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import useFormOperations from "../../../../hooks/useFormOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";
import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";
import { usePostData } from "../../../../hooks/usePostData";

const AddConsultancy = () => {
  useSetBreadcrumb([
    { name: 'Projects', path: '/user/projects?tab=Consultancy' },
    { name: 'Consultancy', path: '/user/projects?tab=Consultancy' },
    { name: 'Add Consultancy', path: '/user/projects/addConsultancy' }
  ]);

  const { handleSnackbarOpen } = useSnackbar();
  const setLoading = useSetRecoilState(loadingState);
  const defaultValues = {};

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const onSubmit = async (formData) => {
    setLoading(true);
    const consultantExists = formData.consultants && formData.consultants.some(consultant => consultant._id === userData._id);

    if (!consultantExists) {
      handleSnackbarOpen("It appears you're trying to add a Consultancy entry without being listed as a consultant. This action is not permitted.", 'warning', 'top', 'center')
      return setLoading(false);
    }

    const { success, error, message } = await usePostData('/api/consultancy/addConsultancy', formData);
    if (message) {
      handleSnackbarOpen(message, 'warning', 'top', 'center')
      return setLoading(false);
    }

    if (success) {
      handleSnackbarOpen("Consultancy entry added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }

    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    setLoading(false);
  };

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/projects?tab=Consultancy");

  return (
    <div className="flex flex-col w-full space-y-5">
      <h2 className=" h5-p px-5">Add Consultancy Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name" InputLabelProps={{ shrink: true }} value={userData?.name} />

        <AuthorInput
          className="col-span-full"
          name='consultants'
          value={formData.consultants}
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder='Select or add Guides / Consultants'
          label={'Consultants'}
        />

        <CustomTextField
          label="Title of Consultancy" className="md:col-span-full" name="consultancyTitle"
          onChange={handleChange} value={formData?.consultancyTitle}
        />

        <div className="my-2 col-span-full" />

        <CustomTextField
          label="Consulting Sponsoring agency" name="agency"
          onChange={handleChange} value={formData?.agency}
        />

        <CustomSelect
          label="Consultancy Type"
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
          ]} onChange={handleChange} name="consultancyType"
        />

        <CustomTextField
          label="Consulting Sponsoring agency Email Id" name="agencyEmail"
          onChange={handleChange} value={formData?.agencyEmail} type="email"
        />

        <CustomSelect
          label="Consultancy Category"
          options={[
            { value: 'Government', label: 'Government' },
            { value: 'Private', label: 'Private' },
          ]} onChange={handleChange} name="category"
        />

        <CustomTextField
          label="Consulting Sponsoring agent Email Id" name="agentEmail"
          onChange={handleChange} value={formData?.agentEmail} type="email"
        />

        <CustomSelect
          label="Consultancy Status"
          options={[
            { value: 'In Progress', label: 'In Progress' },
            { value: 'Completed', label: 'Completed' },
          ]} onChange={handleChange} name="consultancyStatus"
        />

        <CustomTextField
          label="Consulting Sponsoring agent Contact Number" name="agentPhone"
          onChange={handleChange} customType='number' value={formData?.agentPhone}
        />

        <CustomTextField
          label="Revenue Generated" name="revenueGenerated"
          onChange={handleChange} customType='decimal' value={formData?.revenueGenerated}
          InputProps={{ startAdornment: <span className="mr-2">₹</span> }}
        />

        <div className="my-2 col-span-full" />

        <CustomYearMonthPicker
          setValue={setValue}
          label='Starting Date'
          disableFuture
          yearVariable="startingYear"
          monthVariable="startingMonth"
        />

        <CustomTextField
          label="Consultancy Duration" name="duration"
          onChange={handleChange} customType='decimal' value={formData?.duration}
          InputProps={{ endAdornment: (<span>Months</span>) }}
        />

        <CustomYearMonthPicker
          setValue={setValue}
          label='Ending Date'
          disableFuture
          yearVariable="endingYear"
          monthVariable="endingMonth"
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form >
    </div>
  );
};

export default AddConsultancy;
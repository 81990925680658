import { DeleteOutlineOutlined, Edit, FileOpenOutlined, Launch, RemoveRedEyeOutlined, SettingsBackupRestore, TaskAlt, Upload } from "@mui/icons-material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import usePopup from "../../hooks/usePopup";
import PortalPopup from "../popups/PortalPopup";
import { SquareIconButton } from "../../mui/SquareIconButton";
import useVerifyPopup from "../../hooks/useVerifyPopup";
import PopupExtra from "../popups/PopupExtra";
import PatentPopTab from "../PatentPopTab";

const CopyrightPopup = ({ onClose, data, admin, uploadPDF, deletePDF, viewPDF }) => {
  const { openPopup } = usePopup();
  const navigate = useNavigate();

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick);
  }

  const onEditClick = useCallback(() => {
    const { statusIcon, operation, ...copyrightsData } = data;
    navigate("/user/copyrights/editCopyright", { state: { copyrightsData } });
  }, [data, navigate]);

  const { openVerifyPopup, verifyPopup, buttonOptions, closeVerifyPopup, handleReason, handleRevoke } = useVerifyPopup('copyright', data._id);

  const fields = [
    { label: 'Copyright Number', value: data?.copyrightNum, size: 8 },
    { label: 'Copyright Title', value: data?.copyrightTitle, size: 24 },
    { label: 'Registered Date', value: data?.formattedRegistrationDate, size: 8 },
    { label: 'Applied Country', value: data?.copyrightType, size: 8 },
  ];

  return (
    <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={onClose}>
      <div className="bg-white relative flex flex-col rounded-xl w-full h-full max-h-[700px] py-6 space-y-5" >
        <div className="flex justify-center">
          <div className="relative flex flex-col items-center">
            <img className="w-[30px] h-[30px]" src="/user/copyright-icon.svg" alt="" loading="eager" />
            <h2 className="h2-p">Copyright Details</h2>
          </div>
          <button className=" absolute bg-[transparent] w-5 h-5 right-6"
            onClick={onClose}>
            <img
              className="w-5 h-5 transition-transform transform hover:rotate-180"
              alt="" loading="eager" src="/cross.svg"
            />
          </button>
        </div>

        <hr className="h-0.5 w-full bg-[#667085]" />

        <div className="h-full overflow-y-auto no-scrollbar mx-8">
          <div className="grid grid-cols-32 break-words gap-x-4 gap-y-8">
            {fields.map((field, index) => (
              <div key={index} className={`flex flex-col col-span-${field.size || 'full'}`}>
                <label className="lb-s text-[14px]"> {field.label} </label>
                <p className="text-p"> {field.value} </p>
              </div>
            ))}
          </div>
          <PatentPopTab heading="Applicants" data={data?.applicants} />
        </div>

        <hr className="h-0.5 w-full bg-[#667085]" />

        <div className="grid grid-cols-6 gap-y-3 gap-x-5 sm:flex justify-evenly md:justify-end sm:space-x-4 p-5 items-center">
          {admin ?
            <>
              <SquareIconButton title="Action" onClick={openVerifyPopup} icon={<TaskAlt />}
                className="col-span-2 text-green-600"  disabled={data?.status === "Verified" || data?.status === "Rejected" }
              />
              <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
                className="col-span-2 text-red-500" disabled={data?.status === "Pending"}
              />
            </>
            :
            <>
              <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="text-amber-500 col-span-2" />
              <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="text-blue-600 col-span-2" />
              <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutlineOutlined />} className="text-red-500 col-span-2" />
            </>
          }

          <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpenOutlined />} className="text-slate-900 col-span-3" />
        </div>
      </div >
      {verifyPopup &&
        <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
          <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
        </PortalPopup>
      }
    </PortalPopup>
  );
};

export default CopyrightPopup;

import { atom } from "recoil";

export const snackbarState = atom({
    key: 'snackbarState',
    default: {
        isSnackbarOpen: false,
        snackbarMessage: '',
        snackbarType: 'info',
        snackbarIcon: undefined,
        vertical: '',
        horizontal: ''
    },
});

export const popupState = atom({
    key: 'popupState',
    default: {
        open: false,
        icon: '',
        heading: '',
        message: '',
        onConfirm: () => { },
        onClose: () => { },
    },
});

export const loadingState = atom({
    key: 'loadingState',
    default: false,
});

export const loggedInState = atom({
    key: 'loggedInState',
    default: false,
});

export const sideDrawerState = atom({
    key: 'sideDrawerState',
    default: false,
});

export const userTypeState = atom({
    key: 'userTypeState',
    default: null,
});

export const userState = atom({
    key: 'userState',
    default: {},
});

export const uploadFileDataState = atom({
    key: 'uploadFileDataState',
    default: {
        path: '',
        folder: '',
        subFolder: '',
        contentType: '',
        extension: '',
        count: '',
        allowedTypes: [],
    },
});

export const tabState = atom({
    key: 'tabState',
    default: ''
});

export const sortYearState = atom({
    key: 'sortYearState',
    default: 'total'
});

export const profilePhotoState = atom({
    key: 'profilePhotoState',
    default: ''
});

export const breadcrumbState = atom({
    key: 'breadcrumbState',
    default: {
        firstCrumb: {
            name: '',
            path: ''
        },
        secondCrumb: {
            name: '',
            path: ''
        },
        thirdCrumb: {
            name: '',
            path: ''
        }
    }
});



import React, { useMemo } from "react";

const FacRowSec = ({ name, totalJournals, nationalJournals, internationalJournals, q1Count, q2Count, q3Count, q4Count, naCount }) => {


  const formattedName = useMemo(() => {
    const nameParts = name?.split(" ");
    if (nameParts?.length === 1)
      return name;
    else if (nameParts?.length === 2)
      return `${nameParts[0]} ${nameParts[1]}`;
    else if (nameParts?.length > 2)
      return `${nameParts[0]} ${nameParts[nameParts?.length - 1]}`;
    return name;
  }, [name]);

  return (
    <div className=" relative grid grid-cols-54 w-full my-3 ml-6 mr-5">
      <div className='text-txtblackSecondry text-base col-span-9'>{formattedName}</div>
      <div className='text-txtblackSecondry text-base col-span-8'>Total Journals : {totalJournals}</div>
      <div className='text-txtblackSecondry text-base col-span-8'>International : {internationalJournals}</div>
      <div className='text-txtblackSecondry text-base col-span-8'>National: {nationalJournals}</div>
      <div className='text-txtblackSecondry text-base col-span-4'>Q1 : {q1Count}</div>
      <div className='text-txtblackSecondry text-base col-span-4'>Q2 : {q2Count}</div>
      <div className='text-txtblackSecondry text-base col-span-4'>Q3 : {q3Count}</div>
      <div className='text-txtblackSecondry text-base col-span-4'>Q4 : {q4Count}</div>
      <div className='text-txtblackSecondry text-base col-span-4'>N/A : {naCount || 0}</div>
    </div>
  );
};

export default FacRowSec;




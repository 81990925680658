import { Button } from '@mui/material'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../context/UserContext';
import PopupViewOnly from '../../components/popups/PopupViewOnly';
import PortalPopup from '../../components/popups/PortalPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageEditor from '../../components/popupDetailed/ImageEditor';
import { CloseButton, UploadButton } from '../../mui/Button';

const UploadImage = () => {
    const fileInputRef = useRef(null);
    const dragZoneRef = useRef(null);

    const [uploadedFileName, setUploadedFileName] = useState('');
    const [updatedBlob, setUpdatedBlob] = useState(null);

    const storeBlob = useCallback((value) => {
        setUpdatedBlob(value);
    }, []);

    const [error, setError] = useState(false);
    const [alert, setAlert] = useState(false);
    const [success, setSuccess] = useState(false);

    const closeError = useCallback(() => { setError(false); }, []);
    const closeAlert = useCallback(() => { setAlert(false); }, []);
    const closeSuccess = useCallback(() => { setSuccess(false); }, []);


    const showError = useCallback(() => { setError(true); }, []);
    const showAlert = useCallback(() => { setAlert(true); }, []);
    const showSuccess = useCallback(() => { setSuccess(true); }, []);

    const { putS3URL, clearS3URL, startLoading, closeLoading, updateDocumentFlag } = useContext(UserContext);


    const handleBrowseClick = () => {
        setFile(null);
        fileInputRef.current.click();
    };

    const [file, setFile] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        if (files?.length > 0) {
            const fileName = files[0].name;

            if (['.jpg', '.jpeg', '.png'].some(ext => fileName.toLowerCase().endsWith(ext))) {
                setFile(files[0]);
                setUploadedFileName(fileName);
                openEditor()

            } else {
                showAlert()
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleRemoveFile = () => {
        setUploadedFileName('');
        setFile(null);
    };

    useEffect(() => {
        if (!putS3URL) {
            setTimeout(() => {
                showError();
            }, 2200);
            setTimeout(() => {
                closeError()
                onNext()
            }, 3500);
        }
    }, [putS3URL])

    const handleUploadFile = async () => {
        if (putS3URL) {
            startLoading()

            try {
                if (!file) {
                    showAlert()
                    closeLoading();
                    return;
                }

                const formData = new FormData();
                formData.append('file', file);

                const myFile = new File([updatedBlob], 'image.jpeg', {
                    type: updatedBlob.type,
                });

                const response = await fetch(putS3URL, {
                    method: 'PUT',
                    body: myFile,
                });

                if (response.ok) {
                    updateDocumentFlag(folder, id);
                    clearS3URL();
                    closeLoading();
                    showSuccess()

                    setTimeout(() => {
                        closeSuccess();
                    }, 1800);

                    setTimeout(() => {
                        onNext()
                    }, 2000);

                } else {
                    showError();
                    closeLoading();
                }
            } catch (error) {
                showError();
                closeLoading();
            }
        }
    };

    const location = useLocation();
    const navigate = useNavigate();

    const path = location.state?.path;
    const folder = location.state?.folder;
    const id = location.state?.id;

    const onNext = useCallback(() => {
        navigate(path)
    }, [navigate]);

    const [editor, setEditor] = useState(false);
    const closeEditor = useCallback(() => { setEditor(false); }, []);
    const openEditor = useCallback(() => { setEditor(true); }, []);


    return (
        <div className='flex w-full flex-col space-y-5'>
            <h2 className='h2-p'>Upload the Profile Photo</h2>
            <div className='w-full md:px-32 px-10 flex justify-center items-center'>
                <div className='bg-blue-200 w-full bg-opacity-20 border-dashed border-gray-500 border-2 rounded-2xl p-10'
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    ref={dragZoneRef}
                >
                    <div className='flex items-center justify-center flex-col space-y-4'>
                        <img className="mx-auto py-4 top-10" alt="" src="/user/upload-icon.svg" />
                        <p className="lb-s text-center">Drag and Drop your files here</p>
                        <p className="lb-p text-center">OR</p>

                        <Button
                            className="w-60 xl:text-base bg-[#006aff] hover:bg-[#006affef] text-white py-3 xl:w-80 "
                            style={{ borderRadius: 8 }}
                            onClick={handleBrowseClick}
                            size='large'
                            disabled={file}

                        >Browse Files
                        </Button>

                        <p className='text-s text-red-500 italic text-center'> only Image is allowed (&lt;= 3 mb)</p>

                        <div className="flex flex-row justify-center w-full h-10 mt-4">
                            {uploadedFileName && (<>
                                <img className="" alt="" src="/user/image.svg" />

                                <p className='my-auto'>{uploadedFileName}</p>
                                <button className="ml-12 mr-3" onClick={handleRemoveFile}>
                                    <img className="" alt="" src="/cross.svg" />
                                </button>
                            </>
                            )}
                        </div>

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                const selectedFile = e.target.files[0];
                                if (selectedFile && ['.jpg', '.jpeg', '.png'].some(ext => selectedFile.name.toLowerCase().endsWith(ext))) {
                                    setFile(selectedFile)
                                    setUploadedFileName(selectedFile.name);
                                    openEditor()
                                } else {
                                    showAlert()
                                }
                            }
                            }
                        />
                    </div>
                </div>
            </div>

            <div className=" flex sm:flex-row space-y-5 sm:space-y-0 flex-col w-full justify-around">
                <CloseButton onClick={onNext} text={'Skip as of now'} />
                <UploadButton onClick={handleUploadFile} disabled={!uploadedFileName} text={'Upload Image'} />
            </div>

            {alert && (
                <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeAlert}>
                    <PopupViewOnly heading={"Invalid Extension"} subText={"The file format must be either one of the following only: jpg, jpeg, png"} icon={'/icons/Failed.svg'} onClose={closeAlert} />
                </PortalPopup>
            )}

            {success && (
                <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeSuccess} >
                    <PopupViewOnly heading={"Document Uploaded"} subText={"Your document was successfully uploaded Successfully"} icon={'/icons/Success.svg'} onClose={closeSuccess} />
                </PortalPopup>
            )}

            {error && (
                <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeError} >
                    <PopupViewOnly heading={"Document Uploading Failed"} subText={"Uploading Session Over. Pls try again.."} icon={'/icons/Failed.svg'} onClose={closeError} />
                </PortalPopup>
            )}

            {editor && (
                <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeEditor} >
                    <ImageEditor file={file} onClose={closeEditor} storeBlob={storeBlob} />
                </PortalPopup>
            )}

        </div>

    )
}

export default UploadImage
import React from 'react'
import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';
import { Table } from '../../../../mui/Table';

const AVConsultancy = () => {

  const [year, setYear] = useRecoilState(sortYearState);
  const { data, isLoading } = useGetData(`/api/AV/consultancy/getAV?year=${year}&school=total`);


  const columnsAbstractView = [
    { id: "index", label: "Sr No", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 150 },
    { id: "international", label: "International", minWidth: 50 },
    { id: "national", label: "National", minWidth: 50 },
    { id: "privateConsultancy", label: "Private Consultancy", minWidth: 50 },
    { id: "governmentConsultancy", label: "Government Consultancy", minWidth: 50 },
    { id: "inProgress", label: "Ongoing", minWidth: 50 },
    { id: "completed", label: "Completed", minWidth: 50 },
    { id: "total", label: "Total", minWidth: 50 },
  ];

  return (
    <Table
      heading={'Total Consultancies'}
      text={'Faculties Consultancy Projects'}
      columns={columnsAbstractView}
      data={data?.allConsultancyAV}
      operation={false}
      sortParameters={'total'}
      deleteRequired={false}
      uploadRequired={false}
      viewRequired={false}
      sortYear={true}
      loading={isLoading}
    />
  )
}

export default AVConsultancy;
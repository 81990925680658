/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { usePostData } from '../../hooks/usePostData';
import { useRecoilState } from 'recoil';
import { loadingState, uploadFileDataState } from '../../recoil/state';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from '../../hooks/useSnackbar';

const Upload = () => {
    const { handleSnackbarOpen } = useSnackbar();

    const [isLoading, setIsLoading] = useRecoilState(loadingState);
    const [uploadFileData] = useRecoilState(uploadFileDataState);
    const { folder, subFolder, allowedTypes, contentType, extension, count, suffix } = uploadFileData
    const fileExtensions = allowedTypes.map(type => type.split('/')[1].toUpperCase()).join(' | ');

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');

    useEffect(() => {
        if (!folder || !subFolder || !allowedTypes || !contentType || !extension) {
            handleSnackbarOpen('Oops! It seems your uploading session has expired. Please try again.', 'error', 'top', 'center');
            navigate(`/user/${type}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [folder, subFolder, allowedTypes, contentType, extension])


    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);

    const handleFile = (file) => {

        if (count === 'single' && files.length >= 1) return handleSnackbarOpen('Oops, single file zone! Multiple files are not permitted here.', "warning", "top", "center")
        if (!file) return handleSnackbarOpen(`Invalid file.`, "error", "top", "center")


        const fileType = file.type.toLowerCase();
        const isValidType = allowedTypes.some(type => fileType.includes(type));
        if (!isValidType) return handleSnackbarOpen(`Invalid file format. Only ${fileExtensions} are allowed`, "error", "top", "center")

        const isDuplicate = files.some(existingFile => existingFile.name === file.name);
        if (isDuplicate) return handleSnackbarOpen('Oops! Duplicate detected.', "warning", "top", "center")

        setFiles(prevFiles => [...prevFiles, file]);
    };

    const addNewFile = (event) => {
        event.preventDefault();
        const newFile = event.target.files[0];
        handleFile(newFile);
    };

    const handleDrop = (event) => {
        event.preventDefault();

        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
            const newFile = files[i];
            handleFile(newFile);
        }
    };

    const handleUploadButton = () => fileInputRef.current.click();
    const handleDragOver = (event) => event.preventDefault();

    const removeFile = (index) => setFiles((prevFiles) => prevFiles.filter((_file, i) => i !== index));


    const handleSubmit = async () => {
        setIsLoading(true)
        const body = { folder, subFolder, contentType, extension, totalObjects: files.length, single: true, suffix }
        const { success, data: urls } = await usePostData('/cloud/multipleUpload', body);

        if (success) {
            const uploadPromises = urls?.map(async (url, index) => {
                const file = files[index];

                const uploadResponse = await fetch(url,
                    { method: 'PUT', body: file, }
                );

                if (!uploadResponse.ok) handleSnackbarOpen(`Failed to upload file ${file.name}`, "error", "top", "center")
            });

            const total = await Promise.all(uploadPromises);
            const body = { type: folder, id: subFolder, count: total.length, modificationType: 'inc', suffix }
            const { success: updateSuccess } = await usePostData('/cloud/updateDocumentFlag', body)
            setIsLoading(false)

            if (updateSuccess) {
                handleSnackbarOpen("Documents deposited! Your files safely locked away in the vault.", "success", "top", "center", "upload")
                navigate(`/user/${type}`)
                return
            }

            handleSnackbarOpen(`Failed to update file status`, "error", "top", "center")
        }
        handleSnackbarOpen('Failed to upload files', "error", "top", "center")
        setIsLoading(false);
    }

    return (
        <div className='w-full h-full p-2 xl:px-10 '>
            <div className='grid grid-cols-1 tab:grid-cols-2 gap-y-5 w-full'>
                <h2 className='h2-p col-span-full h-fit'>Upload File</h2>
                <div onDragOver={handleDragOver} onDrop={handleDrop} className='col-span-1 flex flex-col items-center justify-evenly w-full bg-slate-100 rounded-l-lg border-dashed border-2'>
                    <p className='text-s text-center max-w-[350px]'>Safe & sound. Future-proof encryption shields your data. </p>
                    <div className='p-10 text-center flex flex-col space-y-3 items-center justify-center'>
                        <h5 className='h5-p'>Drag & Drop files here</h5>
                        <span className='text-p'>OR</span>
                        <Button color="secondary" variant='contained' style={{ backgroundColor: "#f97316" }} startIcon={<AddCircleOutlineIcon />}
                            onClick={handleUploadButton} disableTouchRipple>
                            Add Files
                            <input ref={fileInputRef} onChange={addNewFile}
                                type="file" className='hidden' />
                        </Button>
                    </div>

                    <div className='flex items-center justify-center flex-col'>
                        <p className='lb-s font-bold'>Supported Files</p>
                        <p className='text-p mb-2'>{fileExtensions}</p>
                        <p className='text-s'>Max Upload size of each file &lt; 5mb</p>
                    </div>
                </div>

                <div className='h-96 w-full bg-gray-50 rounded-r-lg p-5 xl:p-10'>
                    <div className='bg-white w-full h-full overflow-y-scroll'>
                        {files.map((file, index) => (
                            <div className='flex my-5'>
                                <img
                                    className='w-14 h-14 mx-2 rounded-md'
                                    src={extension === 'pdf' ? '/files/pdf.svg' : URL.createObjectURL(file)}
                                    alt={file.name}
                                />

                                <div className='flex flex-col w-full space-y-1'>
                                    <div className='flex flex-row justify-between space-x-1 w-full'>
                                        <h5 className='h5-p '>{file?.name}</h5>
                                        <button className='flex w-auto min-w-fit px-2 py-1' onClick={() => removeFile(index)}>
                                            <img className='flex w-5 h-5' src="/cross.svg" alt="" />
                                        </button>
                                    </div>
                                    <span className='text-s'>{(file?.size / (1024 * 1024)).toFixed(2)} mb</span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

                <div className='col-span-full flex items-center justify-center mt-10'>
                    <Button
                        className='bg-blue-Pri hover:bg-blue-sec w-96'
                        onClick={handleSubmit}
                        endIcon={<FileUploadIcon />}
                        loading={isLoading}
                        loadingPosition="end"
                        variant="contained"
                        size='large'
                        disabled={files.length === 0}
                    >
                        <span>Upload All Files</span>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Upload
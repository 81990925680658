/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useOperations from "../../../hooks/useOperations";
import useGetData from "../../../hooks/useGetData";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import usePDFPopup from "../../../hooks/usePDFPopup";
import { useDeleteData } from "../../../hooks/useDeleteData";
import { useSnackbar } from "../../../hooks/useSnackbar";

import { DownloadButton, UploadButton } from "../../../mui/Button";
import { Table } from "../../../mui/Table";
import CopyrightPopup from "../../../components/popupDetailed/CopyrightPopup";
import QuartileTile from "../../../components/Tiles/QuartileTile";

const Copyright = () => {
  useSetBreadcrumb([{ name: 'Copyright', path: '/user/copyrights' }]);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'copyrightTitle', label: 'Title of Invention', minWidth: 230 },
    { id: 'formattedAuthors', label: 'Applicants', minWidth: 130 },
    { id: 'copyrightType', label: 'Applied Country' },
    { id: 'formattedRegistrationDate', label: 'Registration Date' },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: "operation", label: "___", minWidth: 155, align: "center" },
  ];

  const { handleSnackbarOpen } = useSnackbar();
  const navigate = useNavigate();
  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/copyrights/addCopyright?type=Applied through College");
  }, [navigate]);

  const { data: copyrightsData, isLoading, refreshData } = useGetData('/api/copyright/getCopyrights');
  const { data: tileData } = useGetData('/api/stats/copyright/getStats?year=total');

  const deleteCopyright = async (id) => {
    const { error, message } = await useDeleteData(`/api/copyright/deleteCopyright/${id}`);
    if (message) return handleSnackbarOpen(message, 'error', 'top', 'center', 'delete');
    if (error) return handleSnackbarOpen(`Opps ! Error occurred while removing copyright.`, 'error', 'top', 'center', 'delete');
    handleSnackbarOpen(`Copyright successfully removed, as requested.`, 'warning', 'top', 'center', 'delete');
    refreshData();
  }

  const { closeViewDetails, isViewPopupOpen, popupData, onDownload, openViewDetails, onDelete } = useOperations('Copyright', deleteCopyright, refreshData, copyrightsData, ['applicants']);
  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('copyrights', 'copyrights', 'single');

  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };

  return (
    <div className="flex flex-col w-full space-y-5">
      <div className="grid gap-5 p-5 rounded-lg bg-white-sec">
        <QuartileTile
          heading="Total Summary of Granted Copyright"
          h1='India' h2='South Africa' h3='United Kingdom' h4='Germany' h5='Australia' h6="Germany"
          lb1='IND' lb2='SA' lb3='UK' lb4='Ger' lb5='AUS'
          v1={tileData?.india}
          v2={tileData?.southAfrica}
          v3={tileData?.uk}
          v4={tileData?.germany}
          v5={tileData?.australia}
        />
      </div>

      <div className="flex flex-col items-center justify-center w-full py-5 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec ">
        <UploadButton text='Copyright' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Copyrights' />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Copyright'}
          text={'Uploaded Copyright'}
          columns={columns}
          data={copyrightsData}
          dateColumns={[{ 'formattedRegistrationDate': 'registrationDate' }]}
          sortParameters={'formattedRegistrationDate'}
          multiUsers={'applicants'}
          statusDateCol={'lastVerified'}
          operation={true}
          document={'document'}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen &&
        <CopyrightPopup onClose={closeViewDetails} data={popupData}
          viewPDF={viewPDF} uploadPDF={uploadPDF} deletePDF={deletePDF}
        />
      }
    </div >
  );
};

export default Copyright;

import { Table } from '../../../../mui/Table';
import usePDFPopup from '../../../../hooks/usePDFPopup';
import useOperations from '../../../../hooks/useOperations';
import DesignPatentPopup from '../../../../components/popupDetailed/DesignPatentPopup';

const RecentDesignPatent = ({ data, loading }) => {
    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('DesignPatent');

    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'designPatentTitle', label: 'Title of Invention', minWidth: 230 },
        { id: 'formattedAuthors', label: 'Applicants', minWidth: 130 },
        { id: 'designPatentType', label: 'Applied Country' },
        { id: 'formattedRegistrationDate', label: 'Registration Date' },
        { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
        { id: "operation", label: "___", minWidth: 155, align: "center" },
    ];

    const { handleViewPDF } = usePDFPopup('DesignPatent');
    const viewPDF = () => { handleViewPDF(popupData._id) };

    return (
        <>
            <Table
                heading={'Total Design Patents'}
                text={'Uploaded Design Patents'}
                columns={columns}
                data={data}
                dateColumns={[{ 'formattedRegistrationDate': 'registrationDate' }]}
                sortParameters={'formattedRegistrationDate'}
                multiUsers={'applicants'}
                statusDateCol={'lastVerified'}
                operation={true}
                deleteRequired={false}
                uploadRequired={false}
                viewRequired={true}
                click={openViewDetails}
                loading={loading}
                document={'document'}
            />

            {isViewPopupOpen &&
                <DesignPatentPopup onClose={closeViewDetails} data={popupData} viewPDF={viewPDF} admin={true} />
            }
        </>
    )
}

export default RecentDesignPatent
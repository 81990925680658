import React from 'react';

const PatentPopTab = ({ data }) => {
    return (
        <div className='flex flex-col space-y-3 py-3 w-full border-2 border-zinc-400'>
            <div className="grid grid-cols-3 p-2">
                <p className="text-s sm:lb-s">Speaker's Name</p>
                <p className="text-s sm:lb-s">Designation</p>
                <p className="text-s sm:lb-s">Company</p>
            </div>

            <hr className="col-span-full h-0.5 w-full bg-zinc-400 mt-2" />

            <div className="grid grid-cols-3 break-words gap-5">
                {data.map((person, index) => (
                    <React.Fragment key={index}>
                        <p className="text-xs sm:text-sm text-p px-2">{person?.name}</p>
                        <p className="text-xs sm:text-sm text-p px-2">{person?.designation}</p>
                        <p className="text-xs sm:text-sm text-p px-2">{person?.organization}</p>
                        {index < data?.length - 1 && <hr className="col-span-full w-full h-[1px] bg-[#C4C4C4]" />}
                    </React.Fragment>
                ))}
            </div>

        </div>
    );
};

export default PatentPopTab;

import React from 'react'

import { Button } from "@mui/material";
import PortalPopup from './PortalPopup';

const MultiplePDFPopup = ({ onClose, heading, subText, icon, buttons }) => {
    return (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={onclose}>
            <div className="flex flex-col p-5 space-y-8 w-full sm:w-fit lg:min-w-[600px] bg-white rounded-lg">
                <div className="flex space-x-5 w-full">
                    <img className="w-12 h-12" alt="" loading="eager" src={icon} />
                    <div className='flex flex-col w-full' >
                        <h2 className="h5-p flex justify-between w-full">
                            {heading}
                            <button onClick={onClose}>
                                <img className="w-5 h-5 " alt="" loading="eager" src="/cross.svg" />
                            </button>
                        </h2>
                        <p className="text-s">{subText}</p>
                    </div>
                </div>

                <div className={`grid lg:grid-cols-${buttons?.length} gap-5`}>
                    {buttons.map((button, index) => {
                        return (<Button
                            key={index}
                            size="large"
                            color="success"
                            type="submit"
                            disabled={button.disabled}
                            variant="contained"
                            className="px-8 bg-green-700 z-1"
                            onClick={() => { button.onClick(); onClose(); }}
                        >
                            {button.label}
                        </Button>)
                    })}
                </div>
            </div>
        </PortalPopup>
    );
};

export default MultiplePDFPopup;
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, TextField } from "@mui/material";

import UserContext from '../../../../context/UserContext';
import PortalPopup from '../../../../components/popups/PortalPopup';
import PopupRegular from '../../../../components/popups/PopupRegular';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { useSetBreadcrumb } from '../../../../hooks/useSetBreadcrumb';

const ChangeDetails = () => {
    useSetBreadcrumb([
        { name: 'Settings', path: '/user/settings?type=Edit Profile' },
        { name: 'Update Links', path: '/user/settings?type=Update Links' },
    ]);

    const { handleSnackbarOpen } = useSnackbar();
    const { updateLinks, userData, getUser } = useContext(UserContext);

    const [formData, setFormData] = useState({
        googleScholarURL: userData?.googleScholarURL,
        scopusURL: userData?.scopusURL,
        researchGateURL: userData?.researchGateURL,
        orcidURL: userData?.orcidURL,
        linkedInURL: userData?.linkedInURL,
    });

    useEffect(() => {
        const fetchData = async () => {
            await getUser();
            setFormData({
                googleScholarURL: userData?.googleScholarURL,
                scopusURL: userData?.scopusURL,
                researchGateURL: userData?.researchGateURL,
                orcidURL: userData?.orcidURL,
                linkedInURL: userData?.linkedInURL,
            });
        };
        fetchData();
    }, [userData._id]);


    const clearForm = () => {
        setFormData({
            googleScholarURL: userData?.googleScholarURL,
            scopusURL: userData?.scopusURL,
            researchGateURL: userData?.researchGateURL,
            orcidURL: userData?.orcidURL,
            linkedInURL: userData?.linkedInURL,
        });
    }
    const [disabled, setDisabled] = useState(false)

    const updatedData = {};


    useEffect(() => {
        for (const key in formData) {
            const cleanedKey = key.replace(/^new/, '');
            const finalKey = cleanedKey.charAt(0).toLowerCase() + cleanedKey.slice(1);

            if (formData[key] && formData[key] !== userData[finalKey]) {
                updatedData[key] = formData[key];
            }
        }
        if (Object.keys(updatedData).length === 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }

    }, [formData, userData]);


    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        let result = { value: false };
        await updateLinks(updatedData, result);
        closeSubmitDetails()

        if (result.value) {
            handleSnackbarOpen('Your changes have been updated successfully !', 'success', 'top', 'center', 'password')
        }
    }, [formData, userData]);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const [submitDetails, setSubmitDetails] = useState(false)
    const closeSubmitDetails = useCallback(() => {
        setSubmitDetails(false);
    }, []);
    const openSubmitDetails = useCallback(() => {
        setSubmitDetails(true);
    }, []);

    const links = [
        { label: "Google Scholar Link", name: "googleScholarURL", placeholder: "Enter your Google Scholar URL" },
        { label: "Scopus Link", name: "scopusURL", placeholder: "Enter your Scopus URL" },
        { label: "ResearchGate Link", name: "researchGateURL", placeholder: "Enter your ResearchGate URL" },
        { label: "Orcid Link", name: "orcidURL", placeholder: "Enter your Orcid URL" },
        { label: "LinkedIn Link", name: "linkedInURL", placeholder: "Enter your LinkedIn URL" },
    ]
    return (
        <div className="px-6">
            <h2 className="h2-p text-center sm:text-left pt-2">Edit Links</h2>
            <hr className="w-full border-b-[1.5px] my-5" />

            <form className="grid grid-cols-1 sm:grid-cols-2 w-full gap-10 pb-10 pt-5"          >
                {links.map((field, index) => (
                    <TextField
                        key={index}
                        className="col-span-2 sm:col-span-1"
                        type="url"
                        label={field.label}
                        name={field.name}
                        value={formData?.[field.name]}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        placeholder={field.placeholder}
                    />
                ))}

                <div className="flex col-span-2 sm:flex-row flex-col space-y-5 sm:space-y-0 justify-end sm:space-x-8 py-12">
                    <Button size="large" variant="outlined" color="error" type='button' onClick={clearForm} >Discard Changes </Button>
                    <Button size="large" style={{ zIndex: 1 }} color="success" type="button" variant="contained"
                        className=" bg-green-700" onClick={openSubmitDetails}
                        disabled={disabled}
                    >Change Details</Button>
                </div>
            </form >

            {submitDetails && (
                <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeSubmitDetails}>
                    <PopupRegular onClose={closeSubmitDetails} heading={"Update Details ?"} subText={"Are you sure you want to update the changes ?"} icon={"/icons/Edit.svg"} onConfirm={handleSubmit} />
                </PortalPopup>
            )}

        </div >
    );
};

export default ChangeDetails
import { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Skeleton, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import BlackToolTip from "../buttons/BlackToolTip";
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';
import useSearchSort from "../hooks/useSearchSort";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useAcademicYear from "../hooks/useAcademicYear";
import { useRecoilState } from "recoil";
import { sortYearState } from "../recoil/state";
import { ManageAccounts } from "@mui/icons-material";

const TableComponent = ({ heading, sortParameters, text, columns, rows, operation, deleteRequired, viewRequired, click, onDelete, uploadRequired, onUpload, sortYear = false, loading = true, actionRequired = false, modifyPermissions }) => {
    const isTabScreen = useMediaQuery('(max-width:890px)');

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (_event, newPage) => setPage(newPage);

    const handleDeleteClick = (e, row) => {
        e.stopPropagation();
        onDelete(row);
    };

    const handleUploadClick = (e, row) => {
        e.stopPropagation();
        onUpload(row);
    };

    const handleModifyPermissionsClick = (e, row) => {
        e.stopPropagation();
        modifyPermissions(row);
    };

    const [year, setYear] = useRecoilState(sortYearState);
    const currentYear = useAcademicYear();
    const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();

    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
    };

    const { searchQuery, sortOption, currentRows, handleSearch, handleSortChange } = useSearchSort(rows, sortParameters);

    return (
        <div className="flex flex-col min-h-[20rem] space-y-5 py-4">
            <div className="px-2 sm:px-6 flex flex-col">
                <h2 className="h2-p">{heading} </h2>
                <p className="lb-p text-[#16c098]">{text}</p>
            </div>

            <div className="flex sm:flex-row flex-col px-2 sm:px-5 space-y-5 sm:space-y-0 sm:space-x-10 sm:justify-between " >
                <div className='flex items-center h-14 space-x-2 bg-white px-2 sm:px-3 rounded-lg w-full'>
                    <IconButton size={isTabScreen ? 'small' : 'medium'} onClick={() => handleSearch(searchQuery)} aria-label="search Icon">
                        <SearchIcon fontSize={isTabScreen ? 'small' : 'medium'} />
                    </IconButton>
                    <input
                        type="text"
                        placeholder='Search here...'
                        className='flex w-full items-center justify-center font-sans font-medium text-gray-900 '
                        style={{ outline: 'none', padding: '2px', fontSize: '15px' }}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div>

                <FormControl size="medium" className="sm:w-64 w-full">
                    {sortYear ? <>
                        <InputLabel >Academic year</InputLabel>
                        <Select value={year} label="Academic year" onChange={(e) => handleYearChange(e)} MenuProps={{
                            PaperProps: { style: { maxHeight: "150px" } },
                        }}>
                            <MenuItem value="total">Total</MenuItem>
                            {years.map((year) => (
                                <MenuItem key={year} value={year.toString()}>
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                        :
                        <>
                            <InputLabel >Sort by </InputLabel>
                            <Select value={sortOption} label="Sort by" onChange={(e) => handleSortChange(e.target.value)} >
                                <MenuItem value="none">Recent</MenuItem>
                                <MenuItem value={"newest"}>Newest</MenuItem>
                                <MenuItem value={"oldest"}>Oldest</MenuItem>
                            </Select>
                        </>
                    }
                </FormControl>


            </div>

            <Paper sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 850 }} >
                    <Table stickyHeader aria-label="sticky table" key={'table'}>
                        <TableHead key={'headers'}>
                            <TableRow key={'headers'}>
                                {columns.map((column, index) =>
                                    <TableCell key={index} align={column.align}
                                        style={{ minWidth: column.minWidth, fontWeight: "bold", color: "black", }}
                                    >
                                        {column.label}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading ? (Array.from({ length: rowsPerPage }).map((_, index) => (
                                <TableRow key={index} >
                                    {columns?.map((column, columnIndex) => (
                                        <TableCell key={columnIndex} aria-label={`Column ${columnIndex + 1}`}>
                                            <Skeleton variant="rect" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))) : (currentRows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.index} style={{ cursor: viewRequired ? 'pointer' : 'default' }} onClick={viewRequired ? () => click(row) : undefined}>
                                        {columns.map((column, columnIndex) => {
                                            const value = row[column.id];
                                            const isLastColumn = columnIndex === columns?.length - 1; // Adjusted index

                                            return (
                                                <TableCell key={column.index} align={column.align}>
                                                    {isLastColumn && operation ? (
                                                        <>

                                                            {uploadRequired &&
                                                                <BlackToolTip title="Upload / Modify Documents" arrow >
                                                                    <IconButton aria-label="upload/modify Document" onClick={(e) => handleUploadClick(e, row)}>
                                                                        <FileUploadIcon className="text-blue-500" />
                                                                    </IconButton>
                                                                </BlackToolTip>
                                                            }


                                                            {viewRequired &&
                                                                <BlackToolTip title="View" arrow>
                                                                    <IconButton aria-label="launch" onClick={() => click(row)}>
                                                                        <LaunchOutlinedIcon style={{ color: "#010851" }} />
                                                                    </IconButton>
                                                                </BlackToolTip>
                                                            }

                                                            {actionRequired &&
                                                                <BlackToolTip title="Modify Permissions" arrow >
                                                                    <IconButton aria-label="modify permissions" onClick={(e) => handleModifyPermissionsClick(e, row)}>
                                                                        <ManageAccounts className="text-blue-500" />
                                                                    </IconButton>
                                                                </BlackToolTip>
                                                            }

                                                            {deleteRequired &&
                                                                <BlackToolTip title="Delete" arrow >
                                                                    <IconButton aria-label="delete" onClick={(e) => handleDeleteClick(e, row)}>
                                                                        <DeleteOutlineOutlinedIcon style={{ color: "red" }} />
                                                                    </IconButton>
                                                                </BlackToolTip>
                                                            }
                                                        </>
                                                    ) : (column.format && typeof value === "number" ? column.format(value) : value)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={currentRows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper >
        </div>
    );
};

export default TableComponent;

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { authorSplitter } from "../../Functions/authorSpliter";
import usePopup from "../../hooks/usePopup";

import { Edit, Upload, FileOpenOutlined, DeleteOutlineOutlined, Launch } from "@mui/icons-material";
import { SquareIconButton } from "../../mui/SquareIconButton";


const JournalPopup = ({ onClose, journal, uploadPDF, deletePDF, viewPDF }) => {
  const {
    paperTitle, journalTitle, journalType, issn,
    affiliationDetails, formattedDate, indexedInScopus, indexedInSCI, indexedInGS, indexedInOthers, impactFactor, quartile, quartileProvider, totalCitationCountWoS, totalCitationCountScopus, volume, issue, pageNo, doi,
    formattedAuthors, journalLink,
  } = journal;

  const navigate = useNavigate();
  const { openPopup } = usePopup();

  const onViewBtnClick = useCallback((link) => { window.open(link, '_blank') }, []);

  const onEditClick = useCallback(() => {
    const { statusIcon, operation, ...journalData } = journal;
    navigate("/user/publication/editJournal", { state: { journalData } });
  }, [journal, navigate]);

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const paperDetails = [
    { label: 'First Author', value: formattedAuthors?.split(",")[0], colSpan: 'col-span-8' },
    { label: 'Title Of Paper', value: paperTitle, colSpan: 'col-span-24' },
    { label: 'Co-Author’s Name', value: authorSplitter(formattedAuthors), colSpan: 'col-span-8' },
    { label: 'Journal Title', value: journalTitle, colSpan: 'col-span-24' },
    { label: 'Type Of Publication', value: journalType, colSpan: 'col-span-8' },
    { label: 'Indexed in Scopus (Yes/No)', value: indexedInScopus ? 'Yes' : 'No', colSpan: 'col-span-9' },
    { label: 'Issue', value: issue !== undefined ? (issue !== 0 ? issue : '0') : '_', colSpan: 'col-span-5' },
    { label: 'Impact Factor (Provided by Clarivate analytics)', value: impactFactor, colSpan: 'col-span-10' },
    { label: 'Date', value: formattedDate, colSpan: 'col-span-8' },
    { label: 'Indexed in SCI / SCIE / WOS (Yes/No)', value: indexedInSCI ? 'Yes' : 'No', colSpan: 'col-span-9' },
    { label: 'Volume', value: volume !== undefined ? (volume !== 0 ? volume : '0') : '_', colSpan: 'col-span-5' },
    { label: 'Page No.', value: pageNo !== undefined ? (pageNo !== 0 ? pageNo : '0') : '_', colSpan: 'col-span-10' },
    { label: 'Quartile /Category', value: quartile, colSpan: 'col-span-8' },
    { label: 'Indexed in Google Scholar (Yes/No)', value: indexedInGS ? 'Yes' : 'No', colSpan: 'col-span-9' },
    { label: 'ISSN', value: issn, colSpan: 'col-span-15' },
    { label: 'Quartile - JCR or Scimago', value: quartileProvider, colSpan: 'col-span-8' },
    { label: 'Indexed in Others (Yes/No)', value: indexedInOthers ? 'Yes' : 'No', colSpan: 'col-span-9' },
    { label: 'Affiliation Details', value: affiliationDetails, colSpan: 'col-span-15' },
    { label: 'DOI', value: doi, colSpan: 'col-span-17' },
    { label: 'Link of Journal', value: journalLink || 'N/A', colSpan: 'col-span-15' },
  ];

  return (
    <div className="bg-white flex flex-col justify-between rounded-2xl w-full xl:w-[1094px] h-full max-h-[700px]">
      <div className="flex justify-between p-5 sm:px-8">
        <h2 className="h2-p">Journal Details</h2>
        <button className="w-fit h-fit" onClick={onClose}>
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" loading="eager" />
        </button>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="h-full overflow-y-auto no-scrollbar my-2 px-5 sm:px-8">
        <div className="grid grid-cols-32 gap-x-4 gap-y-7 break-words">
          {paperDetails.map((field, index) => (
            <div key={index} className={`flex flex-col ${field?.colSpan}`}>
              <label className="text-[#6C7073] text-[12px] font-medium font-[Poppins] cursor-pointer">
                {field.label}
              </label>
              <p className="text-[#1F2021] text-[15px] font-[Poppins] font-medium">
                {field.value}
              </p>
            </div>
          ))}
        </div>

      </div>
      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="grid grid-cols-6 gap-y-3 gap-x-5 sm:flex justify-evenly md:justify-end sm:space-x-4 p-5 items-center">
        <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="text-amber-500 col-span-2" />

        <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(journal)} icon={<Upload />} className="text-blue-600 col-span-2" />
        <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(journal)} icon={<DeleteOutlineOutlined />} className="text-red-500 col-span-2" />
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(journal)} icon={<FileOpenOutlined />} className="text-slate-900 col-span-3" />
        <SquareIconButton title="View Paper Online" onClick={() => onViewBtnClick(doi)} icon={<Launch />} className="text-slate-900 col-span-2" />
        <SquareIconButton title="View Journal Online" disabled={!journalLink} onClick={() => onViewBtnClick(journalLink)} icon={<Launch />} className="text-slate-900 col-span-2" />
      </div>

    </div >
  );
};

export default JournalPopup;
/* eslint-disable react-hooks/rules-of-hooks */
import { CloseButton, ConfirmButton } from "../../../../mui/Button";
import { useSnackbar } from "../../../../hooks/useSnackbar";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState } from "../../../../recoil/state";
import useGetData from "../../../../hooks/useGetData";
import { usePostData } from "../../../../hooks/usePostData";
import useFormOperations from "../../../../hooks/useFormOperations";
import { CustomSelect, CustomTextField, CustomYearMonthPicker } from "../../../../mui/Input";
import AuthorInput from "../../../../buttons/Input/AuthorInput";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const AddBookChapter = () => {
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Book Chapter' },
    { name: 'Book Chapter', path: '/user/publication?tab=Book Chapter' },
    { name: 'Add Book Chapter', path: '/user/publication/addBookChapter' }
  ]);

  const { handleSnackbarOpen } = useSnackbar();
  const setLoading = useSetRecoilState(loadingState);

  const { data: userData } = useGetData('/api/user/getUser');
  const { data: faculties, isLoading: loadingFaculties } = useGetData('/api/user/getUsersNameOnly');

  const defaultValues = { doi: 'https://doi.org/' }

  const onSubmit = async (formData) => {
    setLoading(true);
    const authorExists = formData.authors && formData.authors.some(author => author._id === userData._id);

    if (!authorExists) {
      handleSnackbarOpen("It appears you're trying to add a book chapter entry without being listed as a co-author. This action is not permitted.", 'warning', 'top', 'center')
      setLoading(false);
      return
    }

    const { success, error, message } = await usePostData('/api/bookChapter/addBookChapter', formData);
    if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
    if (success) {
      handleSnackbarOpen("Book Chapter Data added successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
      onDiscard();
    }
    else if (error) handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    setLoading(false);
  }

  const handleIssnChange = (e) => {
    let inputValue = e.target.value;
    const inputName = e.target.name;
    inputValue = inputValue.split('-')?.join('');
    inputValue = inputValue.replace(/[^\dX]/g, '');

    if (inputValue.length > 8) {
      inputValue = inputValue.slice(0, 13);
      const value = inputValue
      let formattedValue = '';

      for (let i = 0; i < value.length; i++) {
        if (i === 3 || i === 6 || i === 8 || i === 12) formattedValue += '-';
        formattedValue += value[i];
      }
      inputValue = formattedValue
    }
    else if (inputValue.length > 1)
      inputValue = inputValue.match(new RegExp('.{1,4}', 'g')).join('-');

    setValue(inputName, inputValue);
  };

  const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/publication?tab=Book Chapter");

  const doiPattern = /^(https?:\/\/\S+[^.])$/;
  const dateLabel = formData?.category === 'Published' ? 'Published'
    : formData?.category === 'Accepted' ? 'Accepted' : 'Submitted';

  return (
    <div className="flex flex-col w-full space-y-5 ">
      <h2 className=" h5-p px-5">Add Book Chapter Details</h2>
      <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>

        <CustomTextField label="Faculty Name"
          InputLabelProps={{ shrink: true }} value={userData?.name} />

        <CustomTextField
          value={formData?.isbnOrIssn} onChange={handleIssnChange}
          label="ISBN / ISSN" name="isbnOrIssn"

          inputProps={{
            pattern: "\\d{3}-\\d{3}-\\d{2}-\\d{4}-\\d{1}|\\d{4}-\\d{4}",
            placeholder: "XXX-XXX-XX-XXXX-X or XXXX-XXXX",
            title: "Please enter a valid ISBN/ISSN in the format XXX-XXX-XX-XXXX-X or XXXX-XXXX",
          }}
        // \\d{3}-\\d{3}-\\d{2}-\\d{4}-\\d{1}|\\d{4}-\\d{4}|\\d{1}-\\d{4}-\\d{4}-\\d{1}|\\d{3}-\\d{1}-\\d{4}-\\d{4}-\\d{1}",
        // placeholder: "XXX-XXX-XX-XXXX-X or XXXX-XXXX or X-XXXX-XXXX-XXXX-X or XXX-X-XXXX-XXXX-X",
        // title: "Please enter a valid ISBN/ISSN in the format XXX-XXX-XX-XXXX-X or XXXX-XXXX or X-XXXX-XXXX-XXXX-X or XXX-X-XXXX-XXXX-X",
        />

        <AuthorInput
          className="col-span-full"
          name='authors'
          value={formData.authors}
          setValue={setValue}
          options={faculties}
          loading={loadingFaculties}
          placeholder='Select or add Authors / Co-Authors'
          label={'Authors'}
        />

        <CustomTextField
          label="Title of Book Chapter" className="md:col-span-full" name="bookChapterTitle"
          onChange={handleChange} value={formData?.bookChapterTitle}
        />

        <CustomTextField
          label="Title of Book" className="md:col-span-full" name="bookTitle"
          onChange={handleChange} value={formData?.bookTitle}
        />

        <div className="my-2 col-span-full" />

        <CustomSelect
          label="Category of Book Chapter"
          options={[
            { value: 'Submitted', label: 'Submitted' },
            { value: 'Accepted', label: 'Accepted' },
            { value: 'Published', label: 'Published' },
          ]} onChange={handleChange} name="category"
        />

        <CustomSelect
          label="Affiliation Details"
          options={[
            { value: process.env.REACT_APP_COLLEGE_NAME, label: process.env.REACT_APP_COLLEGE_NAME },
            { value: 'Others', label: 'Others' },
          ]} onChange={handleChange} name="affiliationDetails"
        />

        <CustomSelect
          label="Type of Publisher"
          options={[
            { value: 'International', label: 'International' },
            { value: 'National', label: 'National' },
          ]} name="publisherType" onChange={handleChange}
        />

        <CustomTextField
          label="Volume / Edition" name="volumeOrEdition" type="number"
          value={formData?.volumeOrEdition} onChange={handleChange} required={false} />

        <CustomTextField
          label="Name of Publisher" name="publisherName"
          value={formData?.publisherName} onChange={handleChange} />

        <CustomTextField
          label="Page Number" placeholder="01-02" name='pageNo'
          onChange={handleChange} required={false} customType='dashedNumber'
        />

        <CustomYearMonthPicker
          setValue={setValue}
          label={`${dateLabel} Date`}
          disableFuture
          yearVariable="publicationYear"
          monthVariable="publicationMonth"
        />

        <CustomSelect
          label="Copyright Registered"
          options={[
            { value: 'true', label: 'Yes' },
            { value: 'false', label: 'No' },
          ]} onChange={handleChange} name="copyrightRegistered"
        />

        <CustomTextField
          onChange={handleChange} name="doi" defaultValue={defaultValues.doi} className='h-28'
          label="DOI" error={formData?.doi && !doiPattern.test(formData?.doi)} required={formData.category === 'Published'}
          helperText={formData?.doi && !doiPattern.test(formData?.doi) ? "Enter a valid DOI (starts with 'https://doi.org/doi_no') or a direct https link to the publication. Ensure there are no trailing slashes." : null}
        />
        <CustomTextField
          onChange={handleChange} name="bookChapterLink" label="Book Chapter Link"
          className='h-28' required={false} placeholder="https://bookChapter.org/bookChapterName"
        />

        <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
          <CloseButton onClick={onDiscard} />
          <ConfirmButton type="submit" />
        </div>

      </form >
    </div>
  );
};

export default AddBookChapter;
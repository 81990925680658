import { useMemo } from "react";
import { nameFormatter } from "../../Functions/nameFormatter";
// import "./FacRowSec.css";

const BookRowSec = ({ name, submitted, accepted, published, national, international }) => {
  return (

    <div className="grid grid-cols-54 w-full my-3 font-[Poppins] font-[450]">
      <div className='text-txtblackSecondry text-base col-span-13 ml-2'>{nameFormatter(name)}</div>
      <div className='text-txtblackSecondry text-base col-span-9'>Submitted: {submitted}</div>
      <div className='text-txtblackSecondry text-base col-span-9'>Accepted: {accepted}</div>
      <div className='text-txtblackSecondry text-base col-span-9'>Published: {published}</div>
      <div className='text-txtblackSecondry text-base col-span-7'>National: {national}</div>
      <div className='text-txtblackSecondry text-base col-span-7'>International: {international}</div>
    </div>

  );
};

export default BookRowSec;
import React from 'react'
import EventAttendedPopup from '../../../../components/popupDetailed/EventAttendedPopup';
import PortalPopup from '../../../../components/popups/PortalPopup';
import usePDFPopup from '../../../../hooks/usePDFPopup';
import { Table } from '../../../../mui/Table';
import useOperations from '../../../../hooks/useOperations';

const RecentAttended = ({ data, loading }) => {

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'guideName', label: 'Guides Name', minWidth: 130 },
    { id: 'eventTitle', label: 'Event Title', minWidth: 230 },
    { id: 'eventCategory', label: 'Event Category' },
    { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 100 },
    { id: 'formattedEndingDate', label: 'Ending Date', minWidth: 100, align: 'center' },
    { id: 'statusIcon', label: 'Document' },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const { handleViewPDF } = usePDFPopup('eventAttended', 'events?tab=Attended', 'single');
  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('EventAttended');

  const viewPDF = (data) => { handleViewPDF(data._id) };

  return (
    <>
      <Table
        heading={'Events Attended'}
        text={'Uploaded Events Attended'}
        columns={columns}
        data={data}
        dateColumns={[{ 'formattedStartingDate': 'startingDate' }, { 'formattedEndingDate': 'endingDate' }]}
        sortParameters={'formattedStartingDate'}
        operation={true}
        viewRequired={true}
        click={openViewDetails}
        document={'document'}
        loading={loading}
      />

      {isViewPopupOpen && (
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails}>
          <EventAttendedPopup onClose={closeViewDetails} data={popupData}
            viewPDF={viewPDF} admin={true} />
        </PortalPopup>
      )}

    </>
  )
}

export default RecentAttended;
import React from "react";
import { useSearchParams } from "react-router-dom";

import Consultancy from "./consultancy/Consultancy";
import FundedProject from "./RnD/FundedProject";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";

const Project = () => { const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    return (
        <div className="flex flex-col space-y-5 w-full">
            <ThreeWayTab
                btn1={"Consultancy"} btn2={"Research & Development"}
                icon1={<img src='/tabs/Consultancy.svg' />} icon2={<img src='/tabs/RnD.svg' />}
                iconDisabled1={<img src='/tabs/ConsultancyGrey.svg' />} iconDisabled2={<img src='/tabs/RnDGrey.svg' />}
            />
            {tab === "Consultancy" && <Consultancy />}
            {tab === "Research & Development" && <FundedProject />}
        </div>
   
    );
};

export default Project;

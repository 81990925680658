import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOutline, Edit, FileOpen, Upload } from "@mui/icons-material";
import ConductedPopTab from "../ConductedPopTab";
import { SquareIconButton } from "../../mui/SquareIconButton";
import usePopup from "../../hooks/usePopup";

const EventConductedPopup = ({ data, onClose, admin, uploadPDF, deletePDF, viewPDF }) => {
  const {
    formattedAuthors, eventTitle, speakers, eventType, eventCategory, formattedStartingDate, formattedEndingDate,
    fundingAgency, fundingAmount, objective, summary, outcome, audience, otherAudience, reportSubmitted, eventPhotosLink, approvalLetter, userID, _id,
  } = data;

  const navigate = useNavigate();
  const { openPopup } = usePopup();

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const onEditClick = useCallback(() => {
    const { operation, tickIcons, statusIcon, ...eventConductedData } = data;
    navigate("/user/events/conducted/editConducted", { state: { eventConductedData } });
  }, [data, navigate]);

  const gridData = [
    { label: 'Coordinators', data: formattedAuthors, size: 'full' },
    { label: 'Title of Conducted Course', data: eventTitle, size: 2 },
    { label: 'Category of Event', data: eventCategory, size: 1 },
    { label: 'Type of Event', data: eventType, size: 1 },
    { label: 'Starting Date of Event', data: formattedStartingDate, size: 1},
    { label: 'Ending Date of Event', data: formattedEndingDate, size: 1 },
    { label: 'Audience', data: audience.length > 0 ? audience.join(", ").toUpperCase() + (otherAudience ? ", " + otherAudience : "") : otherAudience, size: 1 },
    { label: 'Report Submission', data: reportSubmitted ? 'Yes' : 'No', size: 1 },
    { label: 'Approval letter for funding', data: approvalLetter ? 'Yes' : 'No', size: 1 },
    { label: 'Funding Agency', data: fundingAgency || '-', size: 1 },
    { label: 'Funding Amount', data: fundingAmount || '-', size: 1 },
  ];

  const gridData2 = [
    { label: 'Outcomes', data: outcome || '-----' },
    { label: 'Objectives', data: objective || '-----' },
    { label: 'Summary', data: summary || '-----' },
  ];

  return (
    <div className="bg-white relative flex flex-col justify-between rounded-2xl w-full lg:w-[950px] h-full">
      <div className="relative flex justify-between p-5">
        <h2 className="h2-p">Event Conducted Details </h2>
        <button className="w-fit h-fit" onClick={onClose} >
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
        </button>
      </div>

      <hr className='border-none bg-zinc-400 h-0.5' />


      <div className="h-[85%] overflow-y-auto no-scrollbar p-5">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 break-words">
          {gridData.map(({ label, data, size }, index) => (
            <div key={index} className={`col-span-${size}`}>
              <label className="lb-s text-[13px]"> {label} </label>
              <p className="lb-p text-[13px]"> {data} </p>
            </div>
          ))}

          <div className='flex flex-col col-span-1'>
            <label className="lb-s text-[13px]"> Event Photographs</label>
            {eventPhotosLink ? (
              <Link to={eventPhotosLink} target="_blank" className="lb-p text-[13px]" >
                {eventPhotosLink}
              </Link>) : (<i className="lb-s text-[13px] italic">Link not available</i>)}
          </div>

          <div className="col-span-full">
            <ConductedPopTab heading="Speakers" type="event" data={speakers} />
          </div>

          {gridData2.map(({ label, data }, index) => (
            <div key={index} className="flex flex-col col-span-full">
              <label className="lb-s text-[13px]">{label} </label>
              <p className="lb-p text-[13px]">{data}</p>
            </div>
          ))}
        </div>
      </div>

      <hr className='border-none bg-zinc-400 h-0.5' />

      <div className="flex justify-end p-5 space-x-5 md:space-x-10">
        {!admin &&
          <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="col-span-2 text-amber-500" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="col-span-2 text-blue-600" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutline />} className="col-span-2 text-red-500" />
          </>
        }
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpen />} className="col-span-3 text-slate-900" />
      </div>
    </div >
  );
};

export default EventConductedPopup;
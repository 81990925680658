import { useSearchParams } from "react-router-dom";

import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import useAcademicYear from "../../../../hooks/useAcademicYear";

import { CloudDownloadRounded, CloudUploadRounded, GridViewOutlined, Schedule } from "@mui/icons-material";
import RecentPatent from "./RecentPatent";
import AVPatent from "./AVPatent";
import BlackToolTip from "../../../../buttons/BlackToolTip";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";

import { Button } from '@mui/material'
import TabSmall from "../../../../mui/TabSmall";
import QuartileTile from "../../../../components/Tiles/QuartileTile";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const PatentAD = () => {
  useSetBreadcrumb([
    { name: 'Patents', path: '/admin/patentsAD?type=Recent&tab=Patent' },
    { name: 'Patent', path: '/admin/patentsAD?type=Recent&tab=Patent' },
  ]);

  const { data: patentsDataAD, isLoading } = useGetData('/api/patent/getPatents?school=total');
  const { data: patentCountTotal, isLoading: tileLoading } = useGetData('/api/stats/patent/getStats?school=total&year=total');
  const { data: patentCountCY, isLoading: tileLoadingCY } = useGetData('/api/stats/patent/getStats?school=total');

  const { onDownload } = useOperations('Patent', null, null, patentsDataAD, ['applicants', 'inventors'], ['filedDate', 'publishedDate', 'grantedDate']);
  const currentYear = useAcademicYear();
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get('type');

  const tabs = [
    { label: 'Recent', icon: <Schedule /> },
    { label: 'Abstract View', icon: <GridViewOutlined /> },
  ];

  const buttonInfo = [
    { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
    { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
  ];

  return (
    <div className="flex flex-col space-y-5 w-full">
      <div className="grid md:grid-cols-2 xl:grid-cols-4 p-5 gap-5 w-full rounded-lg bg-white-sec">
        <AnalysisTile
          heading="Granted Patents"
          value={patentCountTotal?.granted}
          icon={'/tabs/round/PatentPurple.svg'}
        />

        <AnalysisTile
          heading={`Granted Patents in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={patentCountCY?.granted}
          icon={'/tabs/round/PatentPurple.svg'}
        />

        <AnalysisTile
          heading={'Published Patents'}
          value={patentCountTotal?.published}
          icon={'/tabs/round/PatentOrange.svg'}
        />

        <AnalysisTile
          heading={`Filed Patents`}
          value={patentCountTotal?.filed}
          icon={'/tabs/round/PatentBlue.svg'}
        />

        <div className="md:col-span-2 col-span-full">
          <QuartileTile
            heading="Summary of Granted Patents"
            h1='India' h2='South Africa' h3='United Kingdom' h4='United States' h5='Australia'
            lb1='IND' lb2='SA' lb3='UK' lb4='US' lb5='AUS'
            v1={patentCountTotal?.india}
            v2={patentCountTotal?.southAfrica}
            v3={patentCountTotal?.uk}
            v4={patentCountTotal?.us}
            v5={patentCountTotal?.australia}
          />
        </div>

        <div className="md:col-span-2 col-span-full">
          <QuartileTile
            heading={`Summary of Patents in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
            h1='Granted' h2='Published' h3='Filed'
            lb1='G' lb2='P' lb3='F'
            v1={patentCountCY?.granted}
            v2={patentCountCY?.published}
            v3={patentCountCY?.filed}
          />
        </div>
      </div>

      <div className="flex flex-col rounded-lg bg-white-sec h-full">
        <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
          <TabSmall tabs={tabs} />
          <div className="flex space-x-5">
            {buttonInfo.map((button, index) => (
              <BlackToolTip key={index} title={button.title} arrow>
                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                  {button.icon}
                </Button>
              </BlackToolTip>
            ))}
          </div>
        </div>

        <div className="rounded-lg bg-white-sec">
          {activeTab === "Recent" && <RecentPatent data={patentsDataAD} loading={isLoading} />}
          {activeTab === "Abstract View" && <AVPatent />}
        </div>
      </div>
    </div>
  );
};

export default PatentAD;

import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { sideDrawerState } from "../../../recoil/state";
import useGetData from "../../../hooks/useGetData";

import UserPhoto from "./UserPhoto";
import { NavMenu } from "./NavMenu";
import {useMediaQuery,Drawer} from '@mui/material';
import PortalPopup from "../../popups/PortalPopup";
import PopupRegular from "../../popups/PopupRegular";

const NavBarAD = () => {

  const [isLogOutPopupOpen, setLogOutPopupOpen] = useState(false);
  const openLogOutPopup = useCallback(() => { setLogOutPopupOpen(true); }, []);
  const closeLogOutPopup = useCallback(() => { setLogOutPopupOpen(false); }, []);

  let navigate = useNavigate();

  const onLogout = async () => {
    closeLogOutPopup();
    navigate("/");
  };

  const { data, isLoading } = useGetData('/api/user/getUser');
  const permissionsString = sessionStorage.getItem('permissions');
  const permissions = permissionsString && permissionsString.trim() !== '' ? permissionsString.split(',') : [];

  const navLinks = [
    { to: '/user/home?tab=Publication&type=Departmental+Rank', attribute: 'stroke', icon: '/nav/sidenav-home.svg', text: 'DashBoard', render: true },
    { to: '/user/publication?tab=Journal', attribute: 'fill', icon: '/nav/sidenav-publication.svg', text: 'Publications', render: true },
    { to: '/user/events?tab=Events+Conducted', attribute: 'fill', icon: '/nav/sidenav-event.svg', text: 'Events', render: true },
    { to: '/user/projects?tab=Consultancy', attribute: 'fill', icon: '/nav/sidenav-project.svg', text: 'Projects', render: true },
    { to: '/user/patents?tab=Patent', attribute: 'fill', icon: '/nav/sidenav-patents.svg', text: 'Patents', render: true },
    { to: '/user/copyrights', attribute: 'fill', icon: '/nav/sidenav-copyright.svg', text: 'Copyright', render: true },
    { to: '/user/awards', attribute: 'fill', icon: '/nav/sidenav-awards.svg', text: 'Awards', render: true },
  ];

  const navLinksOthers = [
    { to: '/user/notification', attribute: 'stroke', icon: '/nav/sidenav-notification.svg', text: 'Notifications', render: true },
    { to: '/user/supports', attribute: 'stroke', icon: '/nav/sidenav-support.svg', text: 'Support', render: true },
    { to: '/user/settings?type=Edit Profile', attribute: 'stroke', icon: '/nav/sidenav-settings.svg', text: 'Settings', render: true },
  ];

  const publicationPermissions = ['Journal', 'BookChapter', 'Conference'].every(permission => permissions.includes(permission));
  const eventPermissions = ['EventAttended', 'EventConducted'].every(permission => permissions.includes(permission));
  const projectPermissions = ['FundedProject', 'Consultancy'].every(permission => permissions.includes(permission));
  const patentPermissions = ['Patent'].every(permission => permissions.includes(permission));
  const copyrightPermissions = ['Copyright'].every(permission => permissions.includes(permission));
  const awardPermissions = ['Award'].every(permission => permissions.includes(permission));
  const facultiesPermissions = ['Faculties'].every(permission => permissions.includes(permission));

  const adminLinks = [
    { to: '/admin/PublicationAD?tab=Journal&type=Recent', attribute: 'fill', icon: '/nav/sidenav-publication.svg', text: 'Publications', render: publicationPermissions },
    { to: '/admin/eventsAD?tab=Events+Conducted&type=Recent', attribute: 'fill', icon: '/nav/sidenav-event.svg', text: 'Events', render: eventPermissions },
    { to: '/admin/projectsAD?tab=Consultancy&type=Recent', attribute: 'fill', icon: '/nav/sidenav-project.svg', text: 'Projects', render: projectPermissions },
    { to: '/admin/patentsAD?type=Recent&tab=Patent', attribute: 'fill', icon: '/nav/sidenav-patents.svg', text: 'Patents', render: patentPermissions },
    { to: '/admin/copyrightsAD?type=Recent', attribute: 'fill', icon: '/nav/sidenav-copyright.svg', text: 'Copyrights', render: copyrightPermissions },
    { to: '/admin/awardsAD?type=Recent&type=Recent', attribute: 'fill', icon: '/nav/sidenav-awards.svg', text: 'Awards', render: awardPermissions },
    { to: '/admin/facultyAD', attribute: 'stroke', icon: '/nav/sidenav-faculty.svg', text: 'Faculty', render: facultiesPermissions },
    { to: '/admin/departments', attribute: 'fill', icon: '/nav/sidenav-department.svg', text: 'Departments', render: facultiesPermissions },
    { to: '/admin/manageRequest?tab=Pending', attribute: 'fill', icon: '/nav/sidenav-managerequest.svg', text: 'Manage Users', render: facultiesPermissions },
  ];

  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  const [open, setOpen] = useRecoilState(sideDrawerState);
  const toggleNavbar = () => { setOpen(!open); };
  const openNavbar = () => { setOpen(true); };
  const closeNavbar = () => { setOpen(false); };

  const nav = (open) => {
    return (
      <nav className="z-50 w-[inherit] lg:flex flex-col no-scrollbar bg-blue-Pri fixed h-full overflow-y-auto" onMouseEnter={openNavbar} onMouseLeave={closeNavbar} >
        <div className="flex flex-col">
          <UserPhoto loading={isLoading} userData={data} open={open} />

          <div className="flex flex-col px-2 my-6">
            <p className="px-2 text-s">GENERAL</p>
            <div className="flex flex-col space-y-3 px-2.5 pr-5 py-3">
              <NavMenu navLinks={navLinks} open={open} />
            </div>

            <hr className={`${open ? 'mx-10' : 'mx-2'}  transform duration-300 mt-5 `} />

            {permissions && permissions?.length > 0 && (
              <div className="mt-5" >
                <p className="text-s px-2">ADMIN</p>
                <div className="flex flex-col space-y-3 px-2.5 pr-5 py-3">
                  <NavMenu navLinks={adminLinks} open={open} />
                </div>
                <hr className={`${open ? 'mx-10' : 'mx-2'}  transform duration-300 mt-5 `} />
              </div>
            )}

            <p className="text-s px-2 mt-5">OTHER</p>
            <div className="flex flex-col space-y-3 px-2.5 pr-5 py-3">
              <NavMenu navLinks={navLinksOthers} open={open} />
            </div>
          </div>

          {!isSmallScreen &&
            <button onClick={openLogOutPopup} className="mb-10 p-2 mx-3 rounded-lg flex items-center justify-center bg-stop hover:bg-red-800 hover:rgb(140, 07, 11)  transform duration-300" >
              <img className="w-8 h-8" alt="" src="/nav/sidenav-logout.svg" />
              {open && <p className="navText px-4 text-white  transform duration-300 line-clamp-1">LOGOUT</p>}
            </button>
          }
        </div>
      </nav>
    );
  }
  
  return (
    <div className={`flex transition-all duration-300 ${open ? 'lg:w-72' : 'lg:w-22'}`}>
      {isSmallScreen ? (
        <Drawer
          open={open} onClose={toggleNavbar} onClick={toggleNavbar} defaultValue={false}
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: (theme) => theme.zIndex.drawer + 1 }}        >
          {nav(true)}
        </Drawer>
      ) : (nav(open))}

      {isLogOutPopupOpen &&
        <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={closeLogOutPopup}>
          <PopupRegular onClose={closeLogOutPopup} heading={"Are you sure you want to logout?"}
            subText={"Come Back Soon !"} icon={"/icons/Logout.svg"} onConfirm={onLogout}
          />
        </PortalPopup>
      }
    </div >
  );
};

export default NavBarAD;
import { useRecoilState } from "recoil";
import { snackbarState } from "../recoil/state";
import { DeleteOutline, FileDownload, FileUpload, PublishedWithChanges } from '@mui/icons-material';
export const useSnackbar = () => {
    const [snackbar, setSnackbar] = useRecoilState(snackbarState);

    const {
        isSnackbarOpen,
        snackbarMessage,
        snackbarType,
        snackbarIcon,
        vertical,
        horizontal,
    } = snackbar;

    const iconComponents = {
        'download': <FileDownload />,
        'upload': <FileUpload />,
        'delete': <DeleteOutline />,
        'password': <PublishedWithChanges />,

    };

    const handleSnackbarOpen = (message, type = 'info', v = 'bottom', h = 'left', icon) => {

        const updatedIcon = icon ? iconComponents[icon] : null;

        setSnackbar({
            ...snackbar,
            isSnackbarOpen: true,
            snackbarMessage: message,
            snackbarType: type,
            snackbarIcon: updatedIcon,
            vertical: v,
            horizontal: h,
        });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, isSnackbarOpen: false });
    };

    return {
        isSnackbarOpen,
        snackbarMessage,
        snackbarType,
        snackbarIcon,
        vertical,
        horizontal,
        handleSnackbarOpen,
        handleSnackbarClose,
    };
};
import { Button } from '@mui/material'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../context/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseButton, UploadButton } from '../../mui/Button';
import { useSnackbar } from '../../hooks/useSnackbar';

const UploadProof = () => {
  const fileInputRef = useRef(null);
  const dragZoneRef = useRef(null);

  const [uploadedFileName, setUploadedFileName] = useState('');
  const { handleSnackbarOpen } = useSnackbar()

  const { putS3URL, clearS3URL, startLoading, closeLoading, updateDocumentFlag } = useContext(UserContext);

  const handleBrowseClick = () => {
    // ~ HANDLE IF EXTNESION IS NOT .PDF 
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (files?.length > 0) {
      const fileName = files[0].name;

      if (fileName.endsWith('.pdf')) {
        setFile(files[0])
        setUploadedFileName(fileName);
      } else {
        handleSnackbarOpen('The file format must be .pdf only', 'info', 'top', 'center')
      }
    }
  };


  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = () => {
    setUploadedFileName('');
  };

  useEffect(() => {
    if (!putS3URL) {
      handleSnackbarOpen('Token Expired. Pls try again !', 'error', 'top', 'center')
      onNext()
    }
  }, [putS3URL])

  const handleUploadFile = async () => {
    if (putS3URL) {
      startLoading()

      try {
        if (!file) {
          handleSnackbarOpen('The file format must be .pdf only', 'info', 'top', 'center')
          closeLoading();
          return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(putS3URL, {
          method: 'PUT',
          body: file,
        });

        if (response.ok) {
          updateDocumentFlag(folder, id,);
          clearS3URL();
          closeLoading();
          handleSnackbarOpen('The file uploaded successfully', 'success', 'top', 'center', 'upload')
          onNext()

        } else {
          handleSnackbarOpen('Token Expired. Pls try again !', 'error', 'top', 'center')
          closeLoading();
        }
      } catch (error) {
        handleSnackbarOpen('Token Expired. Pls try again !', 'error', 'top', 'center')
        closeLoading();
      }
    }
  };


  const location = useLocation();
  const navigate = useNavigate();

  const path = location.state?.path;
  const folder = location.state?.folder;
  const id = location.state?.id;

  const onNext = useCallback(() => {
    navigate(path)
  }, [navigate]);


  return (
    <div className='flex w-full flex-col space-y-5'>
      <h2 className='h2-p'>Upload the Patent Proof</h2>
      <div className='w-full md:px-32 px-10 flex justify-center items-center'>
        <div className='bg-blue-200 w-full bg-opacity-20 border-dashed border-gray-500 border-2 rounded-2xl p-10'
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          ref={dragZoneRef}
        >
          <div className='flex items-center justify-center flex-col space-y-4'>
            <img className="mx-auto py-4 top-10" alt="" src="/user/upload-icon.svg" />
            <p className="lb-s text-center">Drag and Drop your files here</p>
            <p className="lb-p text-center">OR</p>

            <Button
              className="w-60 xl:w-80"
              onClick={handleBrowseClick}
              style={{ backgroundColor: "#006aff", color: "white", borderRadius: 8 }}
              size='large'
            >Browse Files
            </Button>

            <p className='text-s text-red-500 italic text-center'> only .pdf is allowed (&lt;= 3 mb)</p>

            <div className="flex flex-row justify-center w-full h-10 mt-4">
              {uploadedFileName && (<>
                <img className="" alt="" src="/files/pdf.svg" />

                <p className='my-auto'>{uploadedFileName}</p>
                <button className="ml-12 mr-3" onClick={handleRemoveFile}>
                  <img className="" alt="" src="/cross.svg" />
                </button>
              </>
              )}
            </div>

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                if (selectedFile && selectedFile.name.endsWith('.pdf')) {
                  setFile(selectedFile)
                  setUploadedFileName(selectedFile.name);
                } else {
                  handleSnackbarOpen('The file format must be .pdf only', 'info', 'top', 'center')
                }
              }}
            />
          </div>
        </div>
      </div>

      <div className=" flex sm:flex-row space-y-5 sm:space-y-0 flex-col w-full justify-around">
        <CloseButton onClick={onNext} text={'Skip as of now'} />
        <UploadButton onClick={handleUploadFile} disabled={!uploadedFileName} text={'Patents Document'} />
      </div>
    </div >

  )
}

export default UploadProof
import React from 'react'
import useOperations from '../../../../hooks/useOperations';
import usePDFPopup from '../../../../hooks/usePDFPopup';
import { Table } from '../../../../mui/Table';
import PortalPopup from '../../../../components/popups/PortalPopup';
import BookPopup from '../../../../components/popupDetailed/BookPopup';

const RecentBook = ({ data, loading }) => {
  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'bookTitle', label: 'Title of Book', minWidth: 230 },
    { id: 'publisher', label: 'Publisher' },
    { id: 'country', label: 'Country' },
    { id: 'formattedDate', label: 'Date', minWidth: 100, align: 'center' },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: 'operation', label: 'Operations', minWidth: 155, align: 'center' },
  ];

  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Book');
  const { handleViewPDF } = usePDFPopup('books');
  const viewPDF = (data) => { handleViewPDF(data._id) };

  return (
    <>
      <Table
        heading={'Published Book'}
        text={'Uploaded Books'}
        columns={columns}
        data={data}
        yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
        sortParameters={'formattedDate'}
        multiUsers={'authors'}
        statusDateCol={'lastVerified'}
        document={'document'}
        operation={true}
        deleteRequired={false}
        viewRequired={true}
        uploadRequired={false}
        click={openViewDetails}
        loading={loading}
      />

      {isViewPopupOpen &&
        <BookPopup onClose={closeViewDetails} data={popupData} admin={true} viewPDF={viewPDF} />
      }
    </>
  )
}



export default RecentBook
import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, Box, Chip, Autocomplete } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Cancel } from '@mui/icons-material';

const CustomTextField = ({ className = '', customType, onChange = () => { }, required = true, style, ...props }) => {
    const handleArrowKey = (event) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown")
            event.preventDefault();
    };

    const handleInputChange = (event) => {
        if (customType === 'dashedNumber') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9-]/g, '');
            event.target.value = sanitizedValue;
        }
        if (customType === 'number') {
            const inputValue = event.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
            event.target.value = sanitizedValue;
        }

        if (customType === 'decimal') {
            const inputValue = event.target.value;
            let sanitizedValue = inputValue.replace(/[^0-9.]/g, '');

            if ((sanitizedValue.match(/\./g) || []).length > 1)
                sanitizedValue = sanitizedValue.substring(0, sanitizedValue.indexOf('.') + 1) + sanitizedValue.substring(sanitizedValue.indexOf('.') + 1).replace(/\./g, '');

            event.target.value = sanitizedValue;
        }

        onChange(event);
    };

    return (
        <TextField
            className={className}
            style={style}
            variant="outlined"
            required={required}
            onChange={handleInputChange}
            {...props}
            onKeyDown={handleArrowKey}
            onKeyUp={handleArrowKey}
        />
    );
};

const CustomSelect = ({ className, size = '', options, onChange, required, helperText, disabled, value, shrink, ...props }) => (
    <FormControl className={className} variant="outlined" required disabled={disabled} size={size}>
        <InputLabel shrink={shrink} size={size} color="primary">{props.label}</InputLabel>
        <Select color="primary" onChange={onChange} required value={value} displayEmpty {...props} >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
        {helperText && <FormHelperText className='text-xs text-s'>{helperText}</FormHelperText>}
    </FormControl>
);

const CustomMultiSelect = ({ className = '', name, options, onChange, value, label, required = false, disabled, disabledText = '', clearSelection = false, size = 'medium', fullWidth = true, helperText, loading, ...props }) => {
    const onClearSelection = () => onChange({ target: { name, value: [] } });
    useEffect(() => { if (clearSelection) onClearSelection() }, [clearSelection]);

    const handleDelete = (itemToDelete) => {
        const updatedValue = value?.filter(item => item !== itemToDelete);
        onChange(updatedValue);
    };


    return (
        <FormControl fullWidth={fullWidth} className={className} variant="outlined" required={required} disabled={disabled} size={size}>
            <Autocomplete
                loading={loading}
                disabled={disabled}
                disableCloseOnSelect
                multiple
                options={options?.map(option => option?.value)}
                value={value}
                onChange={(_, newValue) => onChange({ target: { name, value: newValue } })}
                renderTags={(selected, getTagProps) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.85 }}>
                        {selected.map((item, index) => (
                            <Chip
                                key={item}
                                label={options?.find(option => option?.value === item)?.label}
                                onDelete={() => handleDelete(item)}
                                deleteIcon={<Cancel />}
                                {...getTagProps({ index })}
                                sx={{
                                    bgcolor: '#010851',
                                    color: 'white',
                                    '& .MuiChip-deleteIcon': { color: 'white' },
                                    '& .MuiChip-deleteIcon:hover': { color: 'white' }
                                }}
                            />
                        ))}
                    </Box>
                )}

                renderInput={(params) => (
                    <TextField
                        label={label} variant="outlined"
                        required={required} disabled={disabled}
                        size={size} fullWidth={fullWidth} {...params}
                    />
                )}

                renderOption={(props, option) => (
                    <MenuItem key={option} sx={{ fontWeight: value?.includes(option) ? 'bold' : 'regular' }} {...props}>
                        {options?.find(o => o?.value === option)?.label}
                    </MenuItem>
                )}
                {...props}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {disabled && disabledText && <FormHelperText>{disabledText}</FormHelperText>}
        </FormControl>
    );
};

const CustomAsyncSelect = ({ className, value, options, onChange, required, helperText, disabled, ...props }) => {
    const [data, setData] = useState('')
    useEffect(() => { setData(value) }, [value]);

    return (
        <FormControl className={className} variant="outlined" required disabled={disabled} >
            <InputLabel color="primary">{props.label}</InputLabel>
            <Select color="primary" onChange={onChange} name={props.name} required value={data} {...props}  >
                {options && options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText className='text-xs text-s'>{helperText}</FormHelperText>}
        </FormControl>
    )
};

const CustomYearMonthPicker = ({ setValue, yearValue, monthValue, className, formData, yearVariable, monthVariable, ...props }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                localeText={{
                    fieldMonthPlaceholder: () => 'MM',
                }}
                className={className}
                views={['year', 'month']}
                variant="outlined"
                defaultValue={yearValue && dayjs().year(yearValue).month(monthValue - 1)}
                onChange={(date) => {
                    const selectedDate = dayjs(date);
                    const year = selectedDate.year();
                    const month = selectedDate.month() + 1;
                    setValue(yearVariable, year);
                    setValue(monthVariable, month);
                }}

                {...props}
                slotProps={{ textField: { required: true } }}
            />
        </LocalizationProvider>
    );
};

const CustomAcademicYearPicker = ({ className = 'w-36', currentYear, handleYearChange, years, year }) => {
    return (

        <FormControl className={className} size="small" variant="outlined">
            <InputLabel id="year-label" size="small" shrink={true}>Academic Year </InputLabel>
            <Select labelId="year-label" label="Academic Year" value={year} name="year" size="small" defaultValue={currentYear} onChange={handleYearChange}
                MenuProps={{ PaperProps: { style: { maxHeight: "150px" } } }}
                InputLabelProps={{ shrink: true }}
            >
                <MenuItem value='total'>Total</MenuItem>
                {years.map((year) => {
                    const formattedYear = `${year}-${(year + 1).toString().slice(-2)}`;
                    return (
                        <MenuItem key={formattedYear} value={year} size="small">
                            {formattedYear}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
};

const CustomYearPicker = ({ setValue, name, value = null, className, ...props }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                className={className}
                variant="outlined"
                value={value && dayjs(value)}
                onChange={(newValue) => setValue(name, newValue)}
                disableFuture
                slotProps={{ textField: { required: true } }}
                {...props}
            />
        </LocalizationProvider>
    );
};



export { CustomTextField, CustomSelect, CustomMultiSelect, CustomAsyncSelect, CustomYearMonthPicker, CustomYearPicker, CustomAcademicYearPicker };

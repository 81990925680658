/* eslint-disable react-hooks/rules-of-hooks */
import { Suspense, lazy } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { loadingState } from '../../../recoil/state';

import { usePutData } from '../../../hooks/usePutData';
import useGetData from "../../../hooks/useGetData";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import { useSnackbar } from '../../../hooks/useSnackbar';
import useFormOperations from "../../../hooks/useFormOperations";

import { CustomSelect, CustomTextField, CustomYearPicker } from "../../../mui/Input";
import { CloseButton, ConfirmButton } from "../../../mui/Button";

const OutsideCollege = lazy(() => import('../patent/patent/OutsideCollege'));

const EditCopyright = () => {
    useSetBreadcrumb([
        { name: 'Copyrights', path: '/user/copyrights' },
        { name: 'Edit Copyright', path: '/user/copyrights/editCopyright' }
    ]);

    const setLoading = useSetRecoilState(loadingState);
    const { handleSnackbarOpen } = useSnackbar();
    const location = useLocation();
    const defaultValues = location.state?.copyrightsData;

    const { data: userData } = useGetData('/api/user/getUser');

    const onSubmit = async (formData) => {
        const authorExists = formData.applicants.some(({ _id }) => _id === userData._id);
        if (!authorExists) return handleSnackbarOpen("It appears you're trying to update a Copyright entry without being listed as a Applicant or Inventor. This action is not permitted.", 'warning', 'top', 'center');

        const duplicateApplicantIds = formData.applicants.map(({ _id }) => _id).filter((id, index, arr) => arr.indexOf(id) !== index && id);
        if (duplicateApplicantIds.length) return handleSnackbarOpen("Opps! Two same Applicants exist. Please check again.", 'warning', 'top', 'center');

        setLoading(true);
        const { success, error, message } = await usePutData(`/api/copyright/updateCopyright/${formData._id}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning', 'top', 'center')
        if (success) {
            handleSnackbarOpen("Copyright updated successfully! Access your personal insights anytime.", 'success', 'top', 'center', 'upload')
            onDiscard();
        }
        else if (error) return handleSnackbarOpen("Whoops! An error occurred. Please try again.", 'error', 'top', 'center')
    }

    const { formData, handleChange, setValue, handleSubmit, onDiscard } = useFormOperations(defaultValues, onSubmit, "/user/copyrights");

    return (
        <div className="flex flex-col w-full space-y-5">
            <h2 className="h5-p px-5">Edit Copyright Details</h2>
            <form className="grid gap-5 p-5 rounded-lg md:grid-cols-2 gap-x-20 bg-white-sec" onSubmit={handleSubmit}>
                <CustomTextField label="Faculty Name"
                    InputLabelProps={{ shrink: true }} value={userData?.name} />

                <CustomTextField
                    label="Title of Invention" className="col-span-full" name="copyrightTitle"
                    onChange={handleChange} value={formData?.copyrightTitle} />

                <CustomTextField
                    label="Copyright Number" name="copyrightNum"
                    onChange={handleChange} value={formData?.copyrightNum}
                />
                <CustomYearPicker name='registrationDate' label="Registration Date" setValue={setValue} value={formData?.registrationDate} />

                <CustomSelect
                    label="Copyright Applied Country"
                    options={[
                        { value: 'India', label: 'India' },
                        { value: 'Australia', label: 'Australia' },
                        { value: 'Germany', label: 'Germany' },
                        { value: 'South Africa', label: 'South Africa' },
                        { value: 'UK', label: 'UK' },
                        { value: 'US', label: 'US' },
                        { value: 'Others', label: 'Others' },
                    ]}
                    onChange={handleChange} name="copyrightType"
                    value={formData?.copyrightType}
                />

                <Suspense fallback={<div>Loading...</div>}>
                    <OutsideCollege formData={formData} setValue={setValue} fieldName={'applicants'} maxValue={11}
                        startLength={formData?.applicants?.length} />
                </Suspense>

                <div className="flex md:flex-row flex-col md:justify-end mt-10 md:space-x-10 md:space-y-0 space-y-10 col-span-full">
                    <CloseButton onClick={onDiscard} />
                    <ConfirmButton type="submit" />
                </div>
            </form >
        </div >
    );
};

export default EditCopyright;

import { nameFormatter } from "../../Functions/nameFormatter";

const BookRow = ({ name, submitted, accepted, published, national, international, }) => {

  return (
    <div className="grid grid-cols-54 w-full my-4 items-center font-[450] font-[Poppins]">
      <img className="w-13 col-span-3" alt="" src="/facultyphoto.svg" />
      <p className="text-txtblackSecondry font-[Poppins] text-lg font-semibold col-span-12 ml-2">{nameFormatter(name)}</p>
      <p className="text-txtblackSecondry text-base col-span-8">Submitted:  {submitted}</p>
      <p className="text-txtblackSecondry text-base col-span-8">Accepted: {accepted}</p>
      <p className="text-txtblackSecondry text-base col-span-8">Published: {published}</p>
      <p className="text-txtblackSecondry text-base col-span-8">National: {national}</p>
      <p className="text-txtblackSecondry text-base col-span-7">International: {international}</p>
    </div>
  );
};

export default BookRow;
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../popups/PortalPopup";
import { authorSplitter } from "../../Functions/authorSpliter";
import PopupExtra from "../popups/PopupExtra";
import usePopup from "../../hooks/usePopup";
import { SquareIconButton } from "../../mui/SquareIconButton";
import { DeleteOutlineOutlined, Edit, FileOpenOutlined, Launch, Upload, TaskAlt, SettingsBackupRestore } from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
import useVerifyPopup from "../../hooks/useVerifyPopup";

const ConferencePopup = ({ onClose, data, admin, uploadPDF, viewPDF, deletePDF }) => {
  const { openPopup } = usePopup();
  const navigate = useNavigate();
  const mediumScreen = useMediaQuery('(max-width:1024px)');
  const { openVerifyPopup, verifyPopup, buttonOptions, closeVerifyPopup, handleReason, handleRevoke } = useVerifyPopup('conference', data._id);

  const openEditDetailsPopup = () => {
    openPopup('Edit Details', 'Are you sure you want to Edit Details ?', 'edit', onEditClick)
  };

  const onViewBtnClick = () => { window.open(data.doi, '_blank'); }

  const onEditClick = useCallback(() => {
    const { statusIcon, operation, ...conferenceData } = data;
    navigate("/user/publication/conference/editConference", { state: { conferenceData } });
  }, [data, navigate]);


  const gridItems = [
    { label: 'First Author', content: data?.formattedAuthors?.split(",")[0], colSpan: mediumScreen ? 'full' : 3 },
    { label: 'Title Of Paper', content: data?.paperTitle, colSpan: mediumScreen ? 'full' : 7 },
    { label: "Co-Author’s Name", content: authorSplitter(data?.formattedAuthors), colSpan: mediumScreen ? 'full' : 3 },
    { label: 'Name Of Conference', content: data?.conferenceName, colSpan: mediumScreen ? 'full' : 7 },
    { label: 'Type Of Publication', content: data?.conferenceType, colSpan: mediumScreen ? 5 : 3 },
    { label: 'DOI', content: data?.doi, colSpan: mediumScreen ? 'full' : 7 },
    { label: 'Date', content: data?.formattedDate, colSpan: mediumScreen ? 'full' : 3 },
    { label: 'Conference Link', content: data?.conferenceLink, colSpan: mediumScreen ? 'full' : 7 },
    { label: 'Proceedings Indexed in', content: data?.conferenceIndexedIn, colSpan: mediumScreen ? 'full' : 3 },
    { label: 'Indexed in Scopus', content: data?.indexedInScopus ? 'Yes' : 'No', colSpan: mediumScreen ? 'full' : 3 },
    { label: 'Indexed in SCI', content: data?.indexedInSCI ? 'Yes' : 'No', colSpan: mediumScreen ? 'full' : 2 },
    { label: 'Quartile', content: data?.quartile ?? '_', colSpan: mediumScreen ? 'full' : 2 },
    { label: 'Affiliation Details', content: data?.affiliationDetails, colSpan: mediumScreen ? 'full' : 7 },
  ];

  return (
    <div className="bg-white relative flex flex-col justify-between rounded-2xl lg:w-[950px] w-full h-full max-h-[700px]">
      <div className="relative flex justify-between p-5 sm:px-8">
        <h2 className="h2-p md:text-left text-center">Conference Details</h2>
        <button className="w-fit h-fit" onClick={onClose}>
          <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
        </button>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="h-[400px] overflow-y-auto no-scrollbar my-2 px-5 sm:px-8 ">
        <div className="grid grid-cols-10 gap-x-4 gap-y-8 break-words">
          {gridItems.map((item, index) => (
            <div key={index} className={`flex flex-col col-span-${item.colSpan || 1}`}>
              <label className="text-[#6C7073] text-[13px] font-medium font-[Poppins] cursor-pointer">
                {item.label}
              </label>
              <p className="text-[#1F2021] text-base font-[Poppins] font-medium">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>

      <hr className="bg-gray-500 h-0.5 border-none" />

      <div className="relative grid grid-cols-6 gap-y-3 gap-x-5 sm:flex justify-evenly md:justify-end sm:space-x-4 p-5 items-center">
        {admin ?
          <>
            <SquareIconButton title="Action" onClick={openVerifyPopup} icon={<TaskAlt />}
              className="text-green-600 col-span-2"
              disabled={data?.category !== "Published" || data?.status !== "Pending"}
            />
            <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
              className="col-span-2 text-red-500" disabled={data?.status === "Pending"}
            />
          </>
          :
          <>
            <SquareIconButton title="Edit Details" onClick={openEditDetailsPopup} icon={<Edit />} className="text-amber-500 col-span-2" />
            <SquareIconButton title="Upload / Modify PDF" onClick={() => uploadPDF(data)} icon={<Upload />} className="text-blue-600 col-span-2" />
            <SquareIconButton title="Delete Uploaded PDF" onClick={() => deletePDF(data)} icon={<DeleteOutlineOutlined />} className="text-red-500 col-span-2" />
          </>
        }
        <SquareIconButton title="Open Uploaded PDF" onClick={() => viewPDF(data)} icon={<FileOpenOutlined />} className="text-slate-900 col-span-3" />
        <SquareIconButton title="View Paper Online" onClick={onViewBtnClick} icon={<Launch />} className="text-slate-900 col-span-3" />
      </div>

      {verifyPopup &&
        <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
          <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
        </PortalPopup>
      }

    </div>
  );
};

export default ConferencePopup;

import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../../../../components/popups/PortalPopup";
import UserContext from "../../../../context/UserContext";
import BookChapterPopup from "../../../../components/popupDetailed/BookChapterPopup";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import { Table } from "../../../../mui/Table";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import usePDFPopup from "../../../../hooks/usePDFPopup";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";


const BookChapter = () => {
  const { data: bookChapterData, isLoading, refreshData } = useGetData('/api/bookChapter/getBookChapters');
  const { data: bookChapterStatsUser, isLoading: tileLoading } = useGetData('/api/stats/bookChapter/getStats');
  const { data: bookChapterStatsUserAllTime, isLoading: tileLoading2 } = useGetData('/api/stats/bookChapter/getStats?year=total');

  const navigate = useNavigate();
  const currentYear = useAcademicYear();
  const { deleteBookChapter } = useContext(UserContext);
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Book Chapter' },
    { name: 'BookChapter', path: '/user/publication?tab=Book Chapter' }
  ]);
  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Author Name', minWidth: 130 },
    { id: 'bookChapterTitle', label: 'Title of Book Chapter', minWidth: 230 },
    { id: 'category', label: 'Category', minWidth: 90 },
    { id: 'publisherType', label: 'Type' },
    { id: 'formattedDate', label: 'Date', minWidth: 100, align: 'center' },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/publication/bookChapter/addBookChapter");
  }, [navigate]);

  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Book Chapter', deleteBookChapter, refreshData, bookChapterData, ['authors']);
  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('bookChapters', 'publication?tab=Book Chapter', 'single');

  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };

  return (
    <div className="flex flex-col my-5 space-y-5">
      <div className="grid w-full grid-cols-1 gap-5 p-5 sm:grid-cols-2 xl:grid-cols-4 bg-white-sec">
        <AnalysisTile
          heading="Total Published Book Chapters"
          value={bookChapterStatsUserAllTime?.published}
          loading={tileLoading2}
          icon={'/tabs/round/BookChapterPurple.svg'}
          message={'Published & verified BookChapter only considered'}
        />

        <AnalysisTile
          heading={`Published Book Chapters in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={bookChapterStatsUser?.published}
          loading={tileLoading}
          icon={'/tabs/round/BookChapterPurple.svg'}
          message={'Published & verified BookChapter only considered'}
        />

        <AnalysisTile
          heading={`Accepted Book Chapters in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={bookChapterStatsUser?.accepted}
          loading={tileLoading}
          icon={'/tabs/round/BookChapterBlue.svg'}
        />

        <AnalysisTile
          heading={`Submitted Book Chapters in ${currentYear} - ${(currentYear + 1).toString().slice(-2)}`}
          value={bookChapterStatsUser?.submitted}
          loading={tileLoading}
          icon={'/tabs/round/BookChapterOrange.svg'}
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading="Total Published Book Chapters Summary"

            h1='International'
            h2='National'
            v1={bookChapterStatsUserAllTime?.internationalPublished}
            v2={bookChapterStatsUserAllTime?.nationalPublished}
            loading={tileLoading2}
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading="Total Book Chapters Summary"
            h1='Published'
            h2='Accepted'
            h3='Submitted'
            v1={bookChapterStatsUserAllTime?.published}
            v2={bookChapterStatsUserAllTime?.accepted}
            v3={bookChapterStatsUserAllTime?.submitted}
            loading={tileLoading2}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full py-6 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec">
        <UploadButton text='Book Chapter' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Book Chapters' />
      </div>

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Book Chapters'}
          text={'Uploaded Book Chapters'}
          columns={columns}
          data={bookChapterData}
          yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
          sortParameters={'formattedDate'}
          multiUsers={'authors'}
          statusDateCol={'lastVerified'}
          document={'document'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen &&
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails}>
          <BookChapterPopup onClose={closeViewDetails} data={popupData} uploadPDF={uploadPDF} viewPDF={viewPDF} deletePDF={deletePDF} />
        </PortalPopup>
      }
    </div >

  );
};

export default BookChapter;

import React from 'react'
import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';
import { Table } from '../../../../mui/Table';

const AVFundedProject = () => {

    const [year, setYear] = useRecoilState(sortYearState);
    const { data, isLoading } = useGetData(`/api/AV/fundedProject/getAV?year=${year}&school=total`);

    const columnsAbstractView = [
        { id: "index", label: "Sr No", minWidth: 50 },
        { id: "name", label: "Name", minWidth: 150 },
        { id: "granted", label: "Granted", minWidth: 50 },
        { id: "applied", label: "Applied", minWidth: 50 },
        { id: "government", label: "Government", minWidth: 50 },
        { id: "nonGovernment", label: "Non-Government", minWidth: 50 },
        { id: "industry", label: "industry", minWidth: 50 },
        { id: "total", label: "Total", minWidth: 150 },
    ];
    return (
        <Table
            heading={'Total Research & Development Projects'}
            text={'Faculties Research & Development Project'}
            columns={columnsAbstractView}
            data={data?.allFundedProjectAV}
            operation={false}
            sortParameters={'total'}
            deleteRequired={false}
            uploadRequired={false}
            viewRequired={false}
            sortYear={true}
            loading={isLoading}
        />
    )
}

export default AVFundedProject;
import { memo, useCallback } from "react";
import PortalPopup from "../popups/PortalPopup";
import PopupExtra from "../../components/popups/PopupExtra";
import JournalImage from "../../Images/JournalImage";

import Button from '@mui/material/Button';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { authorSplitter } from "../../Functions/authorSpliter";
import useVerifyPopup from "../../hooks/useVerifyPopup";
import { SquareIconButton } from "../../mui/SquareIconButton";
import { SettingsBackupRestore } from "@mui/icons-material";


const JournalVerify = ({ viewPDF, ...props }) => {
  const data = props.journal;

  const openJournal = useCallback(() => {
    window.open(data.doi, '_blank');
  }, []);

  const { openVerifyPopup, handleRevoke, verifyPopup, buttonOptions, closeVerifyPopup, handleReason } = useVerifyPopup('journal', data._id);

  return (
    <>
      <div className="bg-white relative flex flex-col justify-between rounded-2xl w-[1094px] h-[590px] py-6 px-9">
        <div>
          <div className="relative flex justify-between">
            <h2 className="text-2xl heading"> Journal Details </h2>
            <button className="w-fit h-fit"
              onClick={props.onClose}            >
              <img className="w-5 h-5 transition-transform transform hover:rotate-180" alt="" src="/cross.svg" />
            </button>
          </div>

          <div className="h-[400px] overflow-y-auto no-scrollbar mt-6">
            <form className="grid grid-cols-32 gap-x-4 gap-y-6 break-words">

              <div className="flex flex-col col-span-8">
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  First Author
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.formattedAuthors?.split(",")[0]}
                </p>
              </div>

              <div className="flex flex-col col-span-24">
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Title Of Paper
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.paperTitle}</p>
              </div>

              <div className="flex flex-col col-span-8">
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Co-Author’s Name
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {authorSplitter(data?.formattedAuthors)}
                </p>
              </div>

              <div className="flex flex-col col-span-24">
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Journal Title
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.journalTitle}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "flex flex-col col-span-8"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Type Of Publication
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.journalType}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-9"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Indexed in Scopus (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInScopus ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-5"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">Issue</label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.issue}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-10"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">Total Citation Count (Scopus)</label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.totalCitationCountScopus}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-8"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Date
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.formattedDate}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-9"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Google Indexed (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInSCIE ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-5"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Volume
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.volume}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-10"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Total Citation Count (Web of Science)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.totalCitationCountWoS}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-8"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Quartile /Category
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.quartile}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-9"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Indexed in SCI/SCIE/WOS (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInSCI ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-5"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Page No.
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.pageNo}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-10"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Impact Factor (Clarivate analytics)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.impactFactor}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-8"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Quartile - JCR or Scimago
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.quartileProvider}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-9"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Affiliation Data
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInWOS ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-5"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  ISSN
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data.issn}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "flex flex-col col-span-10"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  DOI
                </label>
                <a className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium cursor-pointer" target="_blank" onClick={openJournal}               >
                  {data?.doi}
                </a>
              </div>




              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Quartile /Category
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.quartile}
                </p>
              </div>

              <div className={
                data?.quartileProvider === "Scopus"
                  ? "grid col-span-15 row-span-9 bg-white-sec p-6 rounded-lg grid-cols-12 gap-y-5"
                  : data?.quartileProvider === "JCR"
                    ? "grid-cols-12 gap-y-5 grid col-span-20 bg-white-sec p-6 rounded-lg"
                    : "hidden"
              }>         <JournalImage quartileProvider='Scopus' journalIssn={data.issn} />
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "hidden" : "col-span-1"} />
              <div className={data?.quartileProvider == "JCR" ? "grid  col-span-10 bg-white-sec p-6 rounded-lg grid-cols-12 gap-y-5" : "hidden"}>
                <JournalImage quartileProvider='JCR' journalIssn={data.issn} />
              </div>




              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Publication Date
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.formattedDate}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Quartile - JCR or Scimago
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.quartileProvider}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">Issue</label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.issue}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Indexed in Scopus (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInScopus ? "Yes" : "No"}

                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Volume
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.volume}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Google Indexed (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInSCIE ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Page No.
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.pageNo}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Indexed in SCI/SCIE/WOS (Yes/No)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.indexedInSCI ? "Yes" : "No"}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  ISSN
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data.issn}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">Total Citation Count (Scopus)</label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.totalCitationCountScopus}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Impact Factor (Clarivate analytics)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.impactFactor}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Total Citation Count (Web of Science)
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.totalCitationCountWoS}
                </p>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-8" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  DOI
                </label>
                <a className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium cursor-pointer" target="_blank" onClick={openJournal}               >
                  {data?.doi}
                </a>
              </div>

              <div className={data?.quartileProvider == "Scopus" ? "flex flex-col col-span-9" : "hidden"}>
                <label className="text-txtlight text-xs font-medium font-[Poppins] cursor-pointer">
                  Affiliation Data
                </label>
                <p className="text-txtblackSecondry text-[15px] font-[Poppins] font-medium">
                  {data?.affiliationDetails}
                </p>
              </div>
            </form>
          </div>
        </div>

        <div className="relative flex justify-between">
          <Button
            className={`flex items-center rounded-lg w-56 h-12 ${data?.category !== "Published"
              ? 'bg-[#8fb39f]'
              : 'bg-[#00A14B] hover:bg-[#01a84f]'
              }`}
            onClick={openVerifyPopup}
            variant="contained"
            startIcon={<VerifiedOutlinedIcon style={{ color: 'white' }} />}
            disabled={data?.category !== "Published" || data?.status !== "Pending"}
          >
            Verify Status
          </Button>
          <SquareIconButton title="Revoke Status" onClick={handleRevoke} icon={<SettingsBackupRestore />}
            className="col-span-2 text-red-500" disabled={data?.status === "Pending"}
          />

          <Button className="flex bg-[#010851] items-center rounded-lg w-56 h-12" onClick={viewPDF} variant="contained"
            startIcon={<RemoveRedEyeOutlinedIcon style={{ color: 'white' }} />}
            style={{ backgroundColor: '#010851', color: 'white', borderRadius: 8 }}
          >
            View Uploaded PDF
          </Button>

          <Button className="flex bg-[#010851] items-center rounded-lg w-56 h-12" onClick={openJournal} variant="contained"
            startIcon={<RemoveRedEyeOutlinedIcon style={{ color: 'white' }} />}
            style={{ backgroundColor: '#010851', color: 'white', borderRadius: 8 }}
          >
            View Paper
          </Button>
        </div>
      </div>

      {verifyPopup &&
        <PortalPopup placement="Centered" overlayColor="rgba(0, 0, 0, 0.7)" onOutsideClick={closeVerifyPopup}>
          <PopupExtra buttonOptions={buttonOptions} handleReason={handleReason} />
        </PortalPopup>
      }
    </>
  );
};



export default JournalVerify;

import React from 'react';
import BlackToolTip from '../../../buttons/BlackToolTip';
import IconButton from '@mui/material/IconButton';

const SocialHandle = ({
    googleScholarURL,
    scopusURL,
    orcidURL,
    researchGateURL,
    linkedInURL,
}) => {
    const socialProfiles = [
        { title: 'Google Scholar', image: 'GoogleScholar.svg', url: googleScholarURL },
        { title: 'Scopus', image: 'Scopus.svg', url: scopusURL },
        { title: 'Orcid', image: 'Orcid.svg', url: orcidURL },
        { title: 'Research Gate', image: 'ResearchGate.svg', url: researchGateURL },
        { title: 'LinkedIn', image: 'LinkedIn.svg', url: linkedInURL },
    ];

    return (
        <div className="flex flex-col space-y-3">
            <h5 className="h6-p"> Academic Profiles </h5>
            <div className="flex flex-wrap xl:justify-normal lg:justify-center gap-3">
                {socialProfiles.map((profile, index) => (
                    <BlackToolTip key={index} title={profile.title} arrow>
                        <IconButton className="w-8 h-8"
                            size="small" href={profile.url} target="_blank" >
                            <img src={`/sites/${profile.image}`} alt="" className="w-5 h-5" />
                        </IconButton>
                    </BlackToolTip>
                ))}
            </div>
        </div>
    );
};

export default SocialHandle;

import { Button, Skeleton } from "@mui/material";
import { formatDateString } from "../../../Functions/dateformatter/formatDateString";
import SocialHandle from "./SocialHandle";
import { MapSum } from "../../../Functions/MapSum";
import { formatName } from "../../../Functions/formatName";
import Report from "../../../pdf/Report";

const renderSkeleton = (variant, className) => (
    <Skeleton animation="wave" variant={variant} className={`max-w-xs m-0 p-0 ${className || 'text-p'}`} />
);

const Profile = ({ userData, loading, profilePhoto, id, googleScholarData, googleScholarLoading, scoreData, scoreDataLoading }) => {

    const schools = JSON.parse(process.env.REACT_APP_SCHOOLS);
    const isAdmin = userData?.permissions.length > 0;

    const renderPhotoSkeleton = () => (
        <Skeleton variant="circular" animation="wave" className='h-14 w-14 rounded-full' />
    );

    const handleImageError = ({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = "/profile.svg";
    };

    const fields = [
        { label: "Designation", data: userData?.designation, size: "md:col-span-3 xs:col-span-5 col-span-full" },
        { label: "Phone No", data: userData?.phNo, size: "md:col-span-3 xs:col-span-5 col-span-full" },
        { label: "Email ID", data: userData?.email, size: "md:col-span-4 xs:col-span-5 col-span-full" },
        { label: "Date of Join", data: formatDateString(userData?.joiningDate), size: "md:col-span-3 xs:col-span-5 col-span-full" },
        { label: "Employee ID", data: userData?.employeeID || 'N/A', size: "md:col-span-3 xs:col-span-5 col-span-full" },
        { label: "Special Permissions", data: userData?.displayPermissions?.length > 0 ? userData?.displayPermissions?.join(', ') : 'N/A', size: "md:col-span-3 xs:col-span-5 col-span-full" },
        {
            label: "School",
            data: schools.find(school => school?.value === userData?.school)?.label,
            size: "md:col-span-7 col-span-full"
        },
    ];

    return (
        <section className="grid grid-cols-16 bg-white drop-shadow-md">
            <div className="col-span-full grid grid-cols-8 gap-3 p-5 border-0.5">
                <header className="col-span-full flex flex-col md:flex-row justify-between w-full space-y-5 md:space-y-0">
                    <div className="flex space-x-2">
                        {profilePhoto ? <img className="h-14 w-14 select-none rounded-full" src={profilePhoto} alt="profilePhoto" onError={handleImageError} />
                            : renderPhotoSkeleton()
                        }
                        <p className="flex flex-col space-y-0.5">
                            {loading ?
                                <>
                                    <Skeleton animation="wave" className="h5-p md:text-xl lg:text-2xl flex w-64" />
                                    <Skeleton animation="wave" className="text-s text-sm text-purple5 w-32" />
                                </>
                                :
                                <>
                                    <p className="h5-p md:text-xl lg:text-2xl flex">{userData?.title}. {formatName(userData?.name)}
                                        <span className="mx-2 hidden xs:flex items-center justify-center w-fit bg-purple1 text-purple5 text-xs py-1 px-2.5 rounded-xl">{process.env.REACT_APP_COLLEGE_SHORT_NAME}</span>
                                    </p>
                                    <span className="text-s text-sm text-purple5">Faculty {isAdmin && '| Admin'} </span>
                                </>
                            }
                        </p>
                    </div>

                    <div className="flex items-end md:justify-center">
                        <Button fullWidth variant="contained" aria-label="" className="py-2 px-5 bg-purple5 hover:bg-purple5" disableRipple disableElevation  >
                            <Report id={id} userData={userData} userDataLoading={loading}
                                scoreData={scoreData} scoreDataLoading={scoreDataLoading}
                                citations={MapSum(googleScholarData?.citations)} hIndex={googleScholarData?.hIndex}
                                googleScholarLoading={googleScholarLoading}
                            />
                        </Button>
                    </div>
                </header>
            </div>

            <div className="col-span-full lg:col-span-12 border-0.5 p-5 w-full">
                <div className="grid grid-cols-10 gap-5 w-full">
                    {fields.map((field, index) => (
                        <div key={index} className={`flex flex-col space-y-0.5 ${field.size} w-full`}>
                            <label className="text-s text-xs">{field.label}</label>
                            {!loading ? (
                                <p className="text-p text-sm break-words md:truncate">{field.data}</p>
                            ) : renderSkeleton('text')}
                        </div>
                    ))}
                </div >
            </div>

            <div className="col-span-full lg:col-span-4 flex lg:flex-col sm:flex-row flex-col border-r-0.5 ">
                <div className="p-5 border-0.5 flex-grow">
                    <SocialHandle
                        googleScholarURL={userData?.googleScholarURL}
                        scopusURL={userData?.scopusURL}
                        orcidURL={userData?.orcidURL}
                        researchGateURL={userData?.researchGateURL}
                        linkedInURL={userData?.linkedInURL}
                    />
                </div>

                <div className="grid grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2 border-0.5 p-5 gap-3 2xl:gap-0 flex-grow">
                    <div>
                        <h5 className="h6-p">Total Citations</h5>
                        <span className="text-s">{MapSum(googleScholarData?.citations) || 0}</span>
                    </div>
                    <div>
                        <h5 className="h6-p">H-Index</h5>
                        <span className="text-s">{googleScholarData?.hIndex || 0}</span>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Profile;
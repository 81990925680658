/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "../../mui/Carousel";
import { IconButton, Button, InputAdornment, } from "@mui/material";

import { AccountCircle, Lock, LoginRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { usePostData } from "../../hooks/usePostData";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../recoil/state";
import { CustomTextField } from "../../mui/Input";
const HOST_URL = process.env.REACT_APP_HOST;

const LoginPage = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const setIsLoading = useSetRecoilState(loadingState);

  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({ email: '', password: '' })

  const handleSubmit = async (e) => {
    sessionStorage.clear('token')
    e.preventDefault();
    const isValid = /@mitwpu\.edu\.in$/.test(credentials.email);

    // if (!isValid) return handleSnackbarOpen("Hang on! It seems you're not using your Official email.");

    const url = '/api/user/login';
    setIsLoading(true)
    const result = await usePostData(url, credentials);
    setIsLoading(false);

    if (!result.success)
      return handleSnackbarOpen('Invalid Credentials', 'error');

    const { token, permissions } = result.data
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('permissions', permissions);

    navigate(`/user/home?tab=Publication&type=Departmental+Rank`);
    handleSnackbarOpen('Login successful', 'success');
  };

  const googleLogin = async () => window.open(`${HOST_URL}/api/user/google`, "_self");
  const onChange = (e) => setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const data = [
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image1.avif', label: 'Image 1' },
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image2.avif', label: 'Image 2' },
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image3.avif', label: 'Image 3' },
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image4.avif', label: 'Image 4' },
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image5.avif', label: 'Image 5' },
    { imgPath: 'https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/loginPage/image6.avif', label: 'Image 6' },
  ];

  return (
    <main className="grid place-items-center p-5 sm:p-10 bg-[#11111d] h-full min-h-svh">
      <div className="grid lg:grid-cols-2">
        <div className='lg:flex hidden w-full justify-center items-center p-5 bg-neutral-100 rounded-l-lg'>
          <div className='lg:max-w-[550px] w-full max-w-[650px]'>
            <Carousel data={data} />
          </div>
        </div>

        <div className="flex flex-col justify-center w-full p-5 py-3 bg-white rounded-lg lg:rounded-none lg:rounded-r-xl">
          <h1 className="hidden text-center text-red-500 h2-p">Login</h1>
          <header className="grid select-none place-items-center">
            <img className="h-16 w-16 sm:h-24 sm:w-24 mb-3" alt="MIT Logo" src="/mit.svg" draggable="false" loading="lazy" />
            <img className="w-64 sm:w-75 h-20" src='https://tracer-mitwpu-cdn.s3.ap-south-1.amazonaws.com/Tracer.svg' alt="Tracer Logo" draggable="false" loading="eager" />
          </header>

          <p className="flex flex-col items-center justify-center w-full text-base text-slate-800 text-center py-10 sm:px-10 max-w-xl ">
            Effortlessly access, upload, and organize research papers, seminars,
            and academic materials with our all-in-one online platform for
            teachers.
          </p>

          <form onSubmit={handleSubmit} className="flex flex-col w-full px-2 py-5 space-y-4 text-center sm:px-16 sm:py-0 ">
            <CustomTextField fullWidth type="email" label="Email"
              name='email' onChange={onChange} placeholder="Enter your Official Email Id"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              required />

            <CustomTextField fullWidth variant="outlined" label="Password"
              name='password' onChange={onChange} required
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="visibleOnOff-icon" onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />


            <div className="grid w-full gap-5">
              <Button fullWidth className="p-3" disableElevation
                variant="contained" startIcon={<LoginRounded style={{ color: 'white' }} />}
                style={{ backgroundColor: '#010851', color: 'white', borderRadius: 6 }}
                type='submit'>
                Login
              </Button>

              <button className="flex items-center justify-center gap-2 p-3 rounded-lg hover:bg-slate-100 bg-slate-50 hover:text-slate-900" type='button' onClick={googleLogin}>
                <div className="flex items-center justify-center space-x-3">
                  <img className="w-6 h-6" src="/google.svg" loading="eager" alt="google logo" />
                  <span className="text-slate-950 hover:text-slate-600 font-medium font-[Poppins]">Login with Google</span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div >
    </main >
  );
};

export default LoginPage;

import React from "react";
import ThreeWayTab from "../../../components/tabs/ThreeWayTab";
import Journal from './journal/Journal';
import BookChapter from "./bookChapter/BookChapter";
import Conference from "./conference/Conference";
import { useSearchParams } from "react-router-dom";
import { Book as BookIcon } from "@mui/icons-material";
import Book from "./book/Book";

const Publication = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  return (
    <div className="flex flex-col space-y-5 w-full">
      <ThreeWayTab
        btn1={"Journal"} btn2={"Book Chapter"} btn3={"Conference"} btn4={"Book"}
        icon1={<img src='/tabs/Journal.svg' alt='Journal' />} icon2={<img src='/tabs/BookChapter.svg' alt='Book Chapter' />} icon3={<img src='/tabs/Conference.svg' alt='Conference' />} icon4={<BookIcon alt='Book' />}
        iconDisabled1={<img src='/tabs/JournalGrey.svg' alt='Disabled Journal' />} iconDisabled2={<img src='/tabs/BookChapterGrey.svg' alt='Disabled Book Chapter' />} iconDisabled3={<img src='/tabs/ConferenceGrey.svg' alt='Disabled Conference' />} iconDisabled4={<BookIcon className="text-zinc-400" alt='Disabled Book' />}
      />
      {tab === "Journal" ? <Journal /> : null}
      {tab === "Book Chapter" ? <BookChapter /> : null}
      {tab === "Conference" ? <Conference /> : null}
      {tab === "Book" ? <Book /> : null}
    </div>
  );
};

export default Publication;

import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import AnalysisTile from '../../../components/Tiles/AnalysisTile';
import ClassificationTileSec from '../../../components/Tiles/ClassificationTileSec';
import useAcademicYear from '../../../hooks/useAcademicYear';
import useGetData from '../../../hooks/useGetData';
import { CustomAcademicYearPicker } from '../../../mui/Input';
import { useSetBreadcrumb } from '../../../hooks/useSetBreadcrumb';
import RadarGraph from '../../../graphs/RadarChart';

const Department = () => {
    useSetBreadcrumb([
        { name: 'Departments', path: '/admin/departments' },
    ]);
    const [searchParams] = useSearchParams();
    const department = searchParams.get('department');
    const deanName = searchParams.get('dean');

    const currentYear = useAcademicYear();
    const [year, setYear] = useState(currentYear)
    const years = Array.from({ length: currentYear - 1979 }, (_, index) => 1980 + index).reverse();
    const handleYearChange = (event) => {
        const selectedYear = event.target.value;
        setYear(selectedYear);
    };

    const { data: faculty } = useGetData(`/api/department/getFacultyCounts?school=${department}`);

    const { data: journal } = useGetData(`/api/stats/journal/getStats?school=${department}&year=${year}`);
    const { data: bookChapter } = useGetData(`/api/stats/bookChapter/getStats?school=${department}&year=${year}`);
    const { data: book } = useGetData(`/api/stats/book/getStats?school=${department}&year=${year}`);
    const { data: conference } = useGetData(`/api/stats/conference/getStats?school=${department}&year=${year}`);
    const { data: eventConducted } = useGetData(`/api/stats/eventConducted/getStats?school=${department}&year=${year}`);
    const { data: consultancy } = useGetData(`/api/stats/consultancy/getStats?school=${department}&year=${year}`);
    const { data: fundedProject } = useGetData(`/api/stats/fundedProject/getStats?school=${department}&year=${year}`);
    const { data: patent } = useGetData(`/api/stats/patent/getStats?school=${department}&year=${year}`);
    const { data: designPatent } = useGetData(`/api/stats/designPatent/getStats?school=${department}&year=${year}`);
    const { data: copyright } = useGetData(`/api/stats/copyright/getStats?school=${department}&year=${year}`);
    const { data: award } = useGetData(`/api/stats/award/getStats?school=${department}&year=${year}`);

    const { data: journalTopper } = useGetData(`/api/stats/journal/getTopPerformer?school=${department}&year=${year}`);
    const { data: bookChapterTopper } = useGetData(`/api/stats/bookChapter/getTopPerformer?school=${department}&year=${year}`);
    const { data: conferenceTopper } = useGetData(`/api/stats/conference/getTopPerformer?school=${department}&year=${year}`);

    const toppersData = [
        {
            title: 'Journals',
            name: journalTopper?.highest?.name,
            score: journalTopper?.highest?.published,
        },
        {
            title: 'Book Chapter',
            name: bookChapterTopper?.highest?.name,
            score: bookChapterTopper?.highest?.published,
        },
        {
            title: 'Conference',
            name: conferenceTopper?.highest?.name,
            score: conferenceTopper?.highest?.published,
        },
    ];

    const radarData = [
        {
            "label": "Journal",
            "total": journal?.published || 0,
        },
        {
            "label": "Book Chapter",
            "total": bookChapter?.published || 0,
        },
        {
            "label": "Conference",
            "total": conference?.published || 0,
        },
        {
            "label": "Patents Granted",
            "total": patent?.granted || 0,
        },
        {
            "label": "Consultancies",
            "total": consultancy?.completed || 0,
        },
        {
            "label": "R & D Projects",
            "total": fundedProject?.granted || 0,
        },
    ]

    const totalPublication = (journal?.published + bookChapter?.published + conference?.published) || 0;

    return (
        <div className='flex flex-col w-full h-full bg-white-sec rounded-lg p-5 space-y-5'>
            <header className='flex md:flex-row flex-col space-y-5 md:space-y-0 sm:space-x-3 justify-between'>
                <div className='flex space-x-5'>
                    <img src="/profile.svg" alt="" className='w-20 h-20 hidden md:flex' />
                    <div className='flex flex-col space-y-2 md:space-y-1'>
                        <h2 className='h2-p text-center md:text-left'>Department of Computer Engineering and Technology</h2>
                        <hr className='border-none bg-zinc-500 h-0.5 md:w-44 w-full ' />
                        <h5 className='lb-s md:text-left text-center'>{deanName}</h5>
                    </div>
                </div>

                <CustomAcademicYearPicker
                    className='w-full md:w-36'
                    currentYear={currentYear} handleYearChange={handleYearChange} years={years} year={year} />
            </header>

            <section className='grid md:grid-cols-2 xl:grid-cols-4 gap-5'>
                <RadarGraph
                    className='grid col-span-2 bg-white place-items-center h-full xl:min-h-fit min-h-[300px] sm:min-h-[375px]  rounded-lg'
                    heading={'Department Review'}
                    data={radarData} />

                <div className='col-span-2 grid md:grid-cols-2 gap-5'>
                    <AnalysisTile
                        heading='Faculties'
                        icon={'/icons/SuccessPurple.svg'}
                        value={faculty?.total}
                    />

                    <AnalysisTile
                        heading='Professors'
                        icon={'/facultyIcons/Prof.svg'}
                        value={faculty?.professor}
                    />

                    <AnalysisTile
                        heading='Associate Professors '
                        icon={'/facultyIcons/AssociateProf.svg'}
                        value={faculty?.associateProfessor}
                    />

                    <AnalysisTile
                        heading='Assistant Professors'
                        icon={'/facultyIcons/AssistantProf.svg'}
                        value={faculty?.assistantProfessor}
                    />

                </div>
            </section>

            <section className='grid md:grid-cols-2 xl:grid-cols-4 gap-5'>

                <AnalysisTile
                    heading='Journals'
                    value={journal?.published}
                    icon={'/tabs/JournalPurple.svg'}
                />

                <AnalysisTile
                    heading='Book Chapters'
                    value={bookChapter?.published}
                    icon={'/tabs/BookChapterPurple.svg'}
                />

                <AnalysisTile
                    heading='Conferences'
                    value={conference?.published}
                    icon={'/tabs/ConferencePurple.svg'}
                />

                <AnalysisTile
                    heading='Books'
                    value={book?.total}
                    icon={'/tabs/BookPurple.svg'}
                />
            </section>

            <section className='col-span-full grid lg:grid-cols-2 gap-5'>
                <ClassificationTileSec
                    heading='Summary of Consultancies'
                    h1='Completed'
                    h2='Ongoing'
                    v1={consultancy?.completed}
                    v2={consultancy?.inProgress}
                />

                <ClassificationTileSec
                    heading='Summary of Research & Development Projects'
                    h1='Applied'
                    h2='Granted'
                    v1={fundedProject?.applied}
                    v2={fundedProject?.granted}
                />
            </section>

            <section className='grid xl:grid-cols-2 gap-5'>
                <div className='grid md:grid-cols-2 gap-5'>
                    <AnalysisTile
                        heading='Tech Patents Granted'
                        value={patent?.granted}
                        icon={'/tabs/round/PatentPurple.svg'}
                    />

                    <AnalysisTile
                        heading='Design Patents Granted'
                        icon={'/tabs/round/DesignPatentPurple.svg'}
                        value={designPatent?.total}
                        />

                    <AnalysisTile
                        heading='Tech Patents Published'
                        icon={'/tabs/round/PatentOrange.svg'}
                        value={patent?.published}
                        />


                    <AnalysisTile
                        heading='Copyrights Granted'
                        icon={'/tabs/round/CopyrightPurple.svg'}
                        value={copyright?.total}
                    />

                    <AnalysisTile
                        heading='Tech Patents Filed'
                        icon={'/tabs/round/PatentBlue.svg'}
                        value={patent?.filed}
                    />

                    <AnalysisTile
                        heading='Awards faculties received'
                        icon={'/tabs/AwardPurple.svg'}
                        value={award?.awardsCY}
                    />

                    {/* <AnalysisTile
                        heading='Events Organized'
                        icon={'/tabs/round/EventConductedPurple.svg'}
                        value={eventConducted?.eventConducted}
                    /> */}
                </div>

                <div className='bg-white rounded-lg p-5 space-y-5'>
                    <div>
                        <h2 className='h6-p '>{`Exemplary Performers ${year === 'total' ? 'all time' : year + '-' + ((parseInt(year) + 1) % 100)}`}</h2>
                        <hr className='md:flex hidden border-none bg-zinc-300 h-0.25 w-full mt-0.5' />
                    </div>

                    {toppersData?.map((section, index) => (
                        <div className='flex justify-between bg-white-sec w-full p-3 rounded-lg'>
                            <h6 className='text-s'>{section.title}</h6>
                            <div className='flex space-x-5'>
                                <h6 className='text-p'>{section.name}</h6>
                                <h6 className='text-s text-purple5 font-bold'>{section.score}</h6>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Department
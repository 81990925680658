import { useRecoilState } from 'recoil';
import { sortYearState } from '../../../../recoil/state';
import useGetData from '../../../../hooks/useGetData';
import { Table } from '../../../../mui/Table';

const AVConducted = () => {
    const [year, setYear] = useRecoilState(sortYearState);
    const { data, isLoading } = useGetData(`/api/AV/eventConducted/getAV?year=${year}&school=total`);
    const columnsAbstractView = [
        { id: "index", label: "Sr No", minWidth: 50 },
        { id: "name", label: "Name", minWidth: 150 },
        { id: "international", label: "International", minWidth: 50 },
        { id: "national", label: "National", minWidth: 50 },
        { id: "state", label: "State", minWidth: 50 },
        { id: "total", label: "Total", minWidth: 50 },
    ];

    return (
        <Table
            heading={'Total Conducted Events'}
            text={'Faculties Conducted Event'}
            columns={columnsAbstractView}
            data={data?.allEventConductedAV}
            operation={false}
            sortParameters={'total'}
            viewRequired={false}
            sortYear={true}
            loading={isLoading}
        />
    )
}

export default AVConducted;
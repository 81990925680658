import React from 'react'
import { Table } from '../../../../mui/Table';
import PortalPopup from '../../../../components/popups/PortalPopup';
import useOperations from '../../../../hooks/useOperations';
import ConferencePopup from '../../../../components/popupDetailed/ConferencePopup';
import useMultiPDFPopup from '../../../../hooks/useMultiPDFPopup';
import EventConductedPopup from '../../../../components/popupDetailed/EventConductedPopup';
import MultiplePDFPopup from '../../../../components/popups/MultiPDFPopup';

const RecentConducted = ({ data, loading }) => {
    const columns = [
        { id: 'index', label: 'Sr No', minWidth: 71 },
        { id: 'formattedAuthors', label: 'Coordinators', minWidth: 130 },
        { id: 'eventTitle', label: 'Event Title', minWidth: 230 },
        { id: 'eventType', label: 'Event Type', minWidth: 90 },
        { id: 'eventCategory', label: 'Event Category' },
        { id: 'formattedStartingDate', label: 'Starting Date', minWidth: 120 },
        { id: 'formattedEndingDate', label: 'Ending Date', minWidth: 120, align: 'center' },
        { id: 'tickIcons', label: 'Proof | Photos', minWidth: 150 },
        { id: 'operation', label: 'Operations', minWidth: 155, align: 'center' },
    ];

    const buttons = [
        { label: "Events Proof", cloudName: 'proof' },
        { label: "Photos PDF", cloudName: 'photo' },
    ];

    const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Conducted');
    const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Conducted', 'eventConducted', null, buttons);

    return (
        <>
            <Table
                heading={'Events Conducted'}
                text={'Uploaded Events Conducted'}
                columns={columns}
                data={data}
                dateColumns={[{ 'formattedStartingDate': 'startingDate' }, { 'formattedEndingDate': 'endingDate' }]}
                sortParameters={'formattedStartingDate'}
                multiUsers={'coordinators'}
                operation={true}
                viewRequired={true}
                click={openViewDetails}
                tickFields={['proofDocument', 'photoDocument']}
                loading={loading}
            />

            {isViewPopupOpen &&
                <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
                    <EventConductedPopup onClose={closeViewDetails} data={popupData} admin={true}
                        viewPDF={() => openMultiPDFPopup(popupData, "view")} />
                </PortalPopup>
            }

            {isMultiPDFPopup?.open &&
                <MultiplePDFPopup
                    heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
                    icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup} />
            }
        </>
    )
}

export default RecentConducted;
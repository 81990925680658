import { useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";
import TabSmall from "../../../mui/TabSmall";
import { GridViewOutlined, Schedule, CloudDownloadRounded, CloudUploadRounded } from "@mui/icons-material";
import BlackToolTip from "../../../buttons/BlackToolTip";

import useGetData from "../../../hooks/useGetData";
import RecentCopyrights from "./RecentCopyrights";
import AVCopyrights from "./AVCopyrights";
import useAcademicYear from "../../../hooks/useAcademicYear";
import useOperations from "../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../hooks/useSetBreadcrumb";
import QuartileTile from "../../../components/Tiles/QuartileTile";

const CopyrightsAD = () => {
    useSetBreadcrumb([
        { name: 'Copyrights', path: '/admin/copyrightsAD?type=Recent' },
    ]);
    const { data: copyrightsDataAD, isLoading } = useGetData("/api/copyright/getCopyrights?school=total");
    const { data: copyrightCount, isLoading: tileLoading } = useGetData('/api/stats/copyright/getStats?school=total');

    const currentYear = useAcademicYear();
    const [searchParams] = useSearchParams();

    const activeTab = searchParams.get('type');
    const tabs = [
        { label: 'Recent', icon: <Schedule /> },
        { label: 'Abstract View', icon: <GridViewOutlined /> },
    ];

    const { onDownload } = useOperations('Copyright', null, null, copyrightsDataAD);

    const buttonInfo = [
        { title: 'Upload Excel', icon: <CloudUploadRounded fontSize="large" className=" text-[#139404]" />, onClick: null },
        { title: 'Download Excel', icon: <CloudDownloadRounded fontSize="large" className="text-[#0089FF]" />, onClick: onDownload },
    ];

    return (
        <div className="flex flex-col space-y-5 w-full">
            <div className="grid p-5 gap-5 rounded-lg bg-white-sec">
                <QuartileTile
                    heading={`Summary of Copyrights in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
                    h1='India' h2='South Africa' h3='United Kingdom' h4='Germany' h5='Australia' h6="Germany"
                    lb1='IND' lb2='SA' lb3='UK' lb4='Ger' lb5='AUS'
                    v1={copyrightCount?.india}
                    v2={copyrightCount?.southAfrica}
                    v3={copyrightCount?.uk}
                    v4={copyrightCount?.germany}
                    v5={copyrightCount?.australia}
                />
            </div>

            <div className="flex flex-col rounded-lg bg-white-sec">
                <div className="flex flex-col md:space-y-0 space-y-3 md:flex-row justify-between items-center p-3">
                    <TabSmall tabs={tabs} />
                    <div className="flex space-x-5">
                        {buttonInfo.map((button, index) => (
                            <BlackToolTip key={index} title={button.title} arrow>
                                <Button className="bg-white h-11 w-10 hover:bg-slate-50 shadow-sm rounded-md" onClick={button.onClick}>
                                    {button.icon}
                                </Button>
                            </BlackToolTip>
                        ))}
                    </div>
                </div>

                {activeTab === "Recent" && <RecentCopyrights data={copyrightsDataAD} loading={isLoading} />}
                {activeTab === "Abstract View" && <AVCopyrights />}
            </div>
        </div>
    );
};

export default CopyrightsAD;

import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { Cancel } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

function AuthorInput({ options = [{}], loading, name, value, setValue, ...props }) {

    const [inputValue, setInputValue] = useState('');
    const [trigger, setTrigger] = useState(false);

    useEffect(() => { setTrigger(!trigger); }, [value]);

    const handleOptionsChange = (event, updatedValues) => {
        setValue(name, updatedValues);
    };

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue?.trim()) {
            event.preventDefault();
            if (!Array.isArray(value))
                value = [];

            const updatedValues = [...value, { name: inputValue }];
            setValue(name, updatedValues);
            setInputValue('');
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
    };

    const filteredOptions = options.filter(option => {
        if (!value) return true;
        return value.every(val => JSON.stringify(val) !== JSON.stringify(option));
    });


    return (
        <Autocomplete
            {...props}
            multiple
            key={trigger}
            options={filteredOptions}
            getOptionLabel={(option) => option?.name}
            loading={loading}
            onChange={handleOptionsChange}
            value={value}
            inputValue={inputValue}
            onInputChange={handleInputChange}

            filterSelectedOptions
            renderTags={(value, getTagProps) => value?.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option?.name}
                    style={{
                        backgroundColor: '#010851', color: 'white',
                    }}
                    deleteIcon={<Cancel style={{ color: 'white' }} />}
                />
            ))}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label} placeholder={props.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (loading && <CircularProgress color="inherit" size={20} />),
                    }}
                    onKeyDown={handleInputKeyDown}
                />
            )}
        />
    );
}

export default AuthorInput;

import { useState, useEffect } from 'react';

const useAcademicYear = () => {
    // const [academicYear, setAcademicYear] = useState('');

    // useEffect(() => {
    // const getCurrentAcademicYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const academicYearStartingMonth = 6;
    const academicYear = currentMonth < academicYearStartingMonth ? currentYear - 1 : currentYear;

    // setAcademicYear(year);
    // };

    // getCurrentAcademicYear();
    // }, []);

    return academicYear;
};

export default useAcademicYear;

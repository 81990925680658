import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { breadcrumbState, sideDrawerState } from '../../recoil/state';

import IconButton from '@mui/material/IconButton'
import useMediaQuery from '@mui/material/useMediaQuery';
import { Menu, MenuItem, Box, Typography } from '@mui/material'
import { DarkMode, DragHandle, Person, Settings } from '@mui/icons-material';

const TopNavbar = () => {

    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => { setAnchorElUser(event.currentTarget); };
    const handleCloseUserMenu = () => { setAnchorElUser(null); };

    const toggleSidebar = () => { setOpen(!open) }

    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery('(max-width:640px)');

    const [open, setOpen] = useRecoilState(sideDrawerState);

    const settings = [
        { label: 'Dashboard', onClick: () => navigate('/user/home?tab=Publication'), color: 'black' },
        { label: 'Logout', onClick: () => navigate('/'), color: 'red' },
    ];

    const [breadcrumb] = useRecoilState(breadcrumbState);

    return (
        <nav className='flex justify-between items-center bg-slate-50 w-full h-14 lg:px-5 sm:px-3 p-2 shadow-sm sticky top-0 z-50'>
            <div className="flex items-center space-x-1">
                <IconButton onClick={toggleSidebar} aria-label="Toggle navigation" className='lg:hidden flex' size={isSmallScreen ? 'small' : 'medium'}>
                    <DragHandle fontSize={isSmallScreen ? 'small' : 'medium'} sx={{ color: '#11111d' }} />
                </IconButton>

                <div className="flex flex-col">
                    <div className='hidden sm:flex items-center font-medium text-slate-800 space-x-1'>
                        {Object.values(breadcrumb).map((crumb, index, array) => {
                            const total = Object.values(breadcrumb).filter(entry => Object.keys(entry).length > 0).length;
                            return (
                                <React.Fragment key={index}>
                                    {index > 0 && crumb.name && <span className="h5-p px-1">/</span>}
                                    {crumb.name && (
                                        <Link to={crumb.path} className={index === total - 1 ? "h5-p" : "h5-s"}>
                                            {crumb.name}
                                        </Link>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    {breadcrumb.firstCrumb.name === 'DashBoard' && (
                        <p className="text-p text-xs">
                            {new Date().toLocaleDateString('en-US', { day: 'numeric', month: 'short', weekday: 'long', year: 'numeric' }).replace(/,([^,]*)$/, '$1')}
                        </p>
                    )}
                </div>
            </div>

            <div className='w-auto flex items-center justify-center space-x-1'>
                <Link to="/user/settings?type=Edit Profile" aria-label="Edit Profile Settings">
                    <IconButton size={isSmallScreen ? 'small' : 'medium'} aria-label="Settings Icon" >
                        <Settings sx={{ color: '#11111d' }} />
                    </IconButton>
                </Link>

                <IconButton size={isSmallScreen ? 'small' : 'medium'}  aria-label="Switch Mode" >
                    <DarkMode fontSize={isSmallScreen ? 'small' : 'medium'} sx={{ color: '#11111d' }} />
                </IconButton >

                <Box sx={{ flexGrow: 0 }}>
                    <IconButton size={isSmallScreen ? 'small' : 'medium'} onClick={handleOpenUserMenu} aria-label="User Menu">
                        <Person fontSize={isSmallScreen ? 'small' : 'medium'} sx={{ color: '#ef4444' }} />
                    </IconButton>

                    <Menu
                        anchorEl={anchorElUser}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                        sx={{ mt: '45px' }} keepMounted open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting, index) => (
                            <MenuItem key={index} onClick={() => { setting.onClick(); handleCloseUserMenu(); }}>
                                <Typography textAlign="center" color={setting.color}>{setting.label}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

            </div>

        </nav >
    )
}

export default TopNavbar;
import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PortalPopup from "../../../../components/popups/PortalPopup";

import UserContext from "../../../../context/UserContext";

import ConferencePopup from "../../../../components/popupDetailed/ConferencePopup";
import ClassificationTileSec from "../../../../components/Tiles/ClassificationTileSec";
import AnalysisTile from "../../../../components/Tiles/AnalysisTile";
import { DownloadButton, UploadButton } from "../../../../mui/Button";
import { Table } from "../../../../mui/Table";
import useAcademicYear from "../../../../hooks/useAcademicYear";
import usePDFPopup from "../../../../hooks/usePDFPopup";
import useGetData from "../../../../hooks/useGetData";
import useOperations from "../../../../hooks/useOperations";
import { useSetBreadcrumb } from "../../../../hooks/useSetBreadcrumb";

const Conference = () => {
  const { data: conferenceData, isLoading, refreshData } = useGetData('/api/conference/getConferences');
  const { data: conferenceStats, isLoading: tileLoading } = useGetData('/api/stats/conference/getStats');
  const { data: conferenceStatsTotal, isLoading: tileLoadingTotal } = useGetData('/api/stats/conference/getStats?year=total');

  const { deleteConference } = useContext(UserContext);
  const currentYear = useAcademicYear();
  const navigate = useNavigate();
  
  useSetBreadcrumb([
    { name: 'Publication', path: '/user/publication?tab=Conference' },
    { name: 'Conference', path: '/user/publication?tab=Conference' }
  ]);

  const columns = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: "Author's", minWidth: 130 },
    { id: 'paperTitle', label: 'Title of Paper', minWidth: 230 },
    { id: 'conferenceType', label: 'Type' },
    { id: 'category', label: 'Category' },
    { id: 'conferenceIndexedIn', label: 'Indexed in', minWidth: 110 },
    { id: 'formattedDate', label: 'Date of Publication', minWidth: 100 },
    { id: "statusIcon", label: "Status", minWidth: 100, align: "center" },
    { id: 'operation', label: '___', minWidth: 155, align: 'center' },
  ];

  const onUploadNewFrameClick = useCallback(() => {
    navigate("/user/publication/Conference/addConference");
  }, [navigate]);

  const { closeViewDetails, isViewPopupOpen, popupData, onDelete, onDownload, openViewDetails } = useOperations('Conference', deleteConference, refreshData, conferenceData, ['authors']);
  const { handleUploadPDF, handleViewPDF, handleDeletePDF } = usePDFPopup('conferences', 'publication?tab=Conference', 'single');
  const uploadPDF = (data) => { handleUploadPDF(data._id) };
  const viewPDF = (data) => { handleViewPDF(data._id) };
  const deletePDF = (data) => { handleDeletePDF(data._id) };

  return (
    <div className="flex flex-col my-5 space-y-5">
      <div className="grid gap-5 p-5 rounded-lg sm:grid-cols-2 xl:grid-cols-4 bg-white-sec">
        <AnalysisTile
          heading="Total Published Conferences"
          value={conferenceStatsTotal?.published}
          icon={'/tabs/round/ConferencePurple.svg'}
          message={'Published & verified Conferences only considered'}
        />

        <AnalysisTile
          heading={`Published Conferences in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.published}
          icon={'/tabs/round/ConferencePurple.svg'}
          message={'Published & verified Conferences only considered'}
        />

        <AnalysisTile
          heading={`Accepted Conferences in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.accepted}
          icon={'/tabs/round/ConferenceBlue.svg'}
        />

        <AnalysisTile
          heading={`Submitted Conferences in ${currentYear}-${(currentYear + 1).toString().slice(-2)}`}
          value={conferenceStats?.submitted}
          icon={'/tabs/round/ConferenceOrange.svg'}
        />

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading="Total Published Conferences Summary"
            h1='International'
            h2='National'
            v1={conferenceStatsTotal?.internationalPublished}
            v2={conferenceStatsTotal?.nationalPublished}
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <ClassificationTileSec
            heading="Total Conferences Summary"
            h1='Published'
            h2='Accepted'
            h3='Submitted'
            v1={conferenceStatsTotal?.published}
            v2={conferenceStatsTotal?.accepted}
            v3={conferenceStatsTotal?.submitted}
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full py-6 space-y-5 rounded-lg sm:flex-row sm:space-y-0 sm:space-x-10 bg-white-sec">
        <UploadButton text='Conference' onClick={onUploadNewFrameClick} />
        <DownloadButton onClick={onDownload} text='Conferences' />
      </div >

      <div className="rounded-lg bg-white-sec">
        <Table
          heading={'Total Conferences'}
          text={'Uploaded Conferences'}
          columns={columns}
          data={conferenceData}
          yearMonthColumns={[{ 'formattedDate': ['publicationMonth', 'publicationYear'] }]}
          sortParameters={'formattedDate'}
          multiUsers={'authors'}
          statusDateCol={'lastVerified'}
          document={'document'}
          operation={true}
          deleteRequired={true}
          viewRequired={true}
          uploadRequired={true}
          click={openViewDetails}
          onDelete={onDelete}
          onUpload={uploadPDF}
          loading={isLoading}
        />
      </div>

      {isViewPopupOpen &&
        <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" onOutsideClick={closeViewDetails} >
          <ConferencePopup onClose={closeViewDetails} data={popupData}
            uploadPDF={uploadPDF} viewPDF={viewPDF} deletePDF={deletePDF} />
        </PortalPopup>
      }
    </div >
  );
};

export default Conference;

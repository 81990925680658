import { useRecoilState } from 'recoil';
import { popupState } from '../recoil/state';

const usePopup = () => {
    const [popup, setPopup] = useRecoilState(popupState);

    const { open, icon, heading, message, onConfirm, onClose } = popup;

    // const setOpen = (newOpen) => setPopup({ ...popup, open: newOpen });
    // const setIcon = (newIcon) => setPopup({ ...popup, icon: newIcon });
    // const setHeading = (newHeading) => setPopup({ ...popup, heading: newHeading });
    // const setMessage = (newMessage) => setPopup({ ...popup, message: newMessage });
    // const setOnConfirm = (newOnConfirm) => setPopup({ ...popup, onConfirm: newOnConfirm });
    // const setOnClose = (newOnClose) => setPopup({ ...popup, onClose: newOnClose });

    const openPopup = (heading, message, icon, onConfirm) => {
        const iconPaths = {
            download: '/icons/Download.svg',
            delete: '/icons/Delete.svg',
            edit : '/icons/Edit.svg'
        };

        const iconPath = iconPaths[icon] || null;
        setPopup({
            open: true,
            icon: iconPath,
            heading: heading,
            message: message,
            onConfirm: onConfirm,
            onClose: () => setPopup({ ...popup, open: false }),
        });
    };

    const closePopup = () => {
        setPopup({ open: false });
    };

    return {
        open,
        icon,
        message,
        heading,
        onConfirm,
        onClose,
        openPopup,
        closePopup
    };
};

export default usePopup;
import React from 'react'
import useOperations from '../../../../hooks/useOperations';
import { Table } from '../../../../mui/Table';
import ConsultancyPopup from '../../../../components/popupDetailed/ConsultancyPopup';
import PortalPopup from '../../../../components/popups/PortalPopup';
import useMultiPDFPopup from '../../../../hooks/useMultiPDFPopup';
import MultiplePDFPopup from '../../../../components/popups/MultiPDFPopup';

const RecentConsultancy = ({ data, loading }) => {

  const columnsRecent = [
    { id: 'index', label: 'Sr No', minWidth: 71 },
    { id: 'formattedAuthors', label: 'Consultant Names', minWidth: 130 },
    { id: 'consultancyTitle', label: 'Consultancy Title', minWidth: 230 },
    { id: 'consultancyStatus', label: 'Status' },
    { id: 'formattedStartingDate', label: 'Start Date', minWidth: 100 },
    { id: 'revenueGenerated', label: 'Revenue Generated' },
    { id: 'tickIcons', label: 'Proof [AL  CL  BS]', minWidth: 165 },
    { id: 'statusIcon', label: 'Status', align: 'center' },
    { id: 'operation', label: '___', align: 'center' },
  ];

  const buttons = [
    { label: "Approval Letter", cloudName: 'approval' },
    { label: "Completion Letter", cloudName: 'completion' },
    { label: "Audited bank statement", cloudName: 'bankStatement' },
  ];

  const { closeViewDetails, isViewPopupOpen, popupData, openViewDetails } = useOperations('Consultancy');
  const { openMultiPDFPopup, isMultiPDFPopup, buttonConfigurations, closeMultiPDFPopup } = useMultiPDFPopup('Consultancy', 'consultancies', null, buttons);

  return (
    <>
      <Table
        heading={'Consultancies Projects'}
        text={'Faculty Consultancies Projects'}
        columns={columnsRecent}
        data={data}
        yearMonthColumns={[{ 'formattedStartingDate': ['startingMonth', 'startingYear'] }, { 'formattedEndingDate': ['endingMonth', 'endingYear'] }]}
        sortParameters={'formattedStartingDate'}
        multiUsers={'consultants'}
        operation={true}
        viewRequired={true}
        click={openViewDetails}
        statusDateCol={'lastVerified'}
        tickFields={['approvalDocument', 'completionDocument', 'bankStatementDocument']}
        loading={loading}
      />

      {isViewPopupOpen &&
        <PortalPopup placement="Centered" onOutsideClick={closeViewDetails} overlayColor="rgba(0,0,0,0.7)">
          <ConsultancyPopup onClose={closeViewDetails} data={popupData} admin={true}
            viewPDF={() => openMultiPDFPopup(popupData, "view")} />
        </PortalPopup>
      }
      {isMultiPDFPopup?.open &&
        <MultiplePDFPopup
          heading={isMultiPDFPopup.heading} subText={isMultiPDFPopup.subHeading}
          icon={isMultiPDFPopup.icon} buttons={buttonConfigurations} onClose={closeMultiPDFPopup}
        />
      }
    </>
  )
}

export default RecentConsultancy;
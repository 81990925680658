import { useState } from "react";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import UserContext from "../../../context/UserContext";
import PortalPopup from "../../../components/popups/PortalPopup";
import useGetData from "../../../hooks/useGetData";

// ~ ***** A DYNAMIC POPUP WITH 3 OPTION || APPLICANT | INVENTOR | CANCEL 
const PopupDeleteSp = ({ data, onClose, heading, subText, icon, onConfirm }) => {

    const { data: userData, isLoading } = useGetData('/api/user/getUser');
    const [applicant, setApplicant] = useState(false);
    const [inventor, setInventor] = useState(false);

    const handleApplicantChange = () => { setApplicant(!applicant); };
    const handleInventorChange = () => { setInventor(!inventor); };

    return (
        <PortalPopup placement="Centered" overlayColor="rgba(0,0,0,0.5)" onOutsideClick={onClose}>
            <div className="grid grid-cols-12 p-[26px] bg-bgPrimary h-fit min-h-[196px] min-w-[596px] w-fit max-w-[700px] rounded-2xl gap-5">

                <div className="col-span-full grid grid-cols-32 font-[Poppins]">
                    <img className="col-span-4 w-12 h-12" alt="" src={icon} />
                    <div className="col-span-28 grid w-full h-fit">
                        <p className="text-txtblackSecondry text-lg font-semibold">{heading}</p>
                        <p className="text-[#475467] text-sm">{subText}</p>
                    </div>
                </div>

                <div className="col-span-full flex justify-between h-fit mt-2">
                    <Button size="small" style={{ zIndex: 1 }} color="success" type="submit" variant="contained"
                        className="ml-10 px-8 bg-blue-Pri rounded-lg hover:bg-[#262640] "
                        onClick={handleApplicantChange}
                        disabled={userData?._id ? (!data?.applicants?.some(applicant => applicant?._id === userData?._id)) : true}
                    >
                        Delete as Applicant
                        <Checkbox
                            checked={applicant}
                            sx={{ color: "white", '&.Mui-checked': { color: "#2eb82e" } }}
                            color="success"
                        />
                    </Button>

                    <Button size="small" style={{ zIndex: 1 }} color="success" type="submit" variant="contained"
                        className="ml-10 px-8 bg-blue-Pri rounded-lg hover:bg-[#262640] "
                        onClick={handleInventorChange}
                        disabled={userData?._id ? (!data?.inventors?.some(inventor => inventor?._id === userData?._id)) : true}
                    >
                        Delete as Inventor
                        <Checkbox
                            checked={inventor}
                            sx={{ color: "white", '&.Mui-checked': { color: "#2eb82e" } }}
                            color="success"
                        />
                    </Button>
                </div>

                <div className="col-span-full flex justify-between h-fit">
                    <Button size="large" variant="outlined" color="error" type='button' onClick={onClose} className="px-8">Cancel</Button>
                    <Button size="large" style={{ zIndex: 1 }} color="success" type="submit" variant="contained"
                        className="ml-10 px-8 bg-green-700"
                        onClick={() => onConfirm(applicant, inventor)}
                        disabled={!applicant && !inventor}
                    >Confirm
                    </Button>
                </div>
            </div>
        </PortalPopup>
    );
};

export default PopupDeleteSp;
import { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Snackbar, Alert, CircularProgress, Slide } from '@mui/material';

import usePopup from "./hooks/usePopup";
import { useSnackbar } from "./hooks/useSnackbar";
import useAuthenticate from "./hooks/useAuthenticate";
import usePageMetadata from "./hooks/usePageMetadata";

import UserState from "./context/UserState";
import { loadingState } from "./recoil/state";
import { useRecoilState } from "recoil";

import LoginPage from "./pages/login/Login";
import GoogleLogin from "./pages/login/GoogleLogin";

import PortalPopup from "./components/popups/PortalPopup";
import PopupRegular from "./components/popups/PopupRegular";
import UserRoutes from "./routes/UserRoutes";

function App() {
  const location = useLocation();

  const { isSnackbarOpen, snackbarMessage, snackbarType, snackbarIcon, handleSnackbarClose, horizontal, vertical } = useSnackbar();
  const { isUser, isAdmin } = useAuthenticate();
  const [isLoading] = useRecoilState(loadingState);
  const { open, heading, message, onConfirm, onClose, icon } = usePopup();

  const { pathname } = location;
  usePageMetadata(pathname, isAdmin);

  const TransitionSnackbar = (props) => {
    const directionMap = { top: 'down', bottom: 'up', left: 'right', right: 'left', };
    const direction = directionMap[vertical] || directionMap[horizontal] || 'down';
    return <Slide {...props} direction={direction} />;
  };

  return (
    <>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/googleLogin" element={<GoogleLogin />} />
      </Routes>

      <UserState>
        <Suspense > {(isUser || isAdmin) && <UserRoutes />}</Suspense>
      </UserState>

      {isSnackbarOpen && <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: horizontal, vertical: vertical }}
        TransitionComponent={(props) => <TransitionSnackbar {...props} />} >
        <Alert onClose={handleSnackbarClose} severity={snackbarType} sx={{ width: '100%' }} icon={snackbarIcon}>
          {snackbarMessage}
        </Alert>
      </Snackbar>}

      {isLoading && <PortalPopup overlayColor="rgba(0,0,0,0.7)" placement="Centered" >
        <CircularProgress color="secondary" />
      </PortalPopup>}

      {open && (
        <PortalPopup overlayColor="rgba(0,0,0,0.8)" placement="Centered" >
          <PopupRegular heading={heading} icon={icon} onClose={onClose} onConfirm={() => { onConfirm(); onClose(); }} subText={message} />
        </PortalPopup>)}
    </>
  );
}
export default App;

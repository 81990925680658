import React, { useState, useEffect } from "react";

const MainFrameIcon = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day} /${month} /${year}`;
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  return (
    <div className="relative">
      <img className="w-full" alt="" src="/user/MainFrame.svg" />
      <p className="text-white text-lg xl:text-xl w-fit absolute m-7 top-0 left-0">
        {formatDate(currentDate)}
      </p>
      <p className="text-white xl:text-xl w-fit absolute m-7 top-6 left-0">
        {formatTime(currentDate)}
      </p>
    </div>
  );
};

export default MainFrameIcon;
